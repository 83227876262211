import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ToolbarSetupComponent } from './toolbar-setup/toolbar-setup.component';
import { ClientPlatformRoutingModule } from './client-platform-routing.module';
import { MappingSetupModule } from 'src/app/shared/mapping-setup/mapping-setup.module';
import { TableSetupComponent } from './table-setup/table-setup.component';
import { ExecutionHistoryComponent } from './execution-history/execution-history.component';
import { ListPanelModule } from 'src/app/bloom/specialized-panels/list-panel/list-panel.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { ConnectionSuccessComponent } from './connection-success/connection-success.component';
import { ParameterInputsModule } from 'src/app/shared/parameter-inputs/parameter-inputs.module';
import { ReplacePipe } from '../common-pipes/replace/replace.pipe';
import { FlowModule } from 'src/app/flow/flow.module';
import { FlowInstanceModule } from 'src/app/flow/flow-instance/flow-instance.module';
import { ClientPlatformPipesModule } from '../common-pipes/client-platform-pipes.module';

@NgModule({
  declarations: [
    ToolbarSetupComponent,
    TableSetupComponent,
    ExecutionHistoryComponent,
    ConnectionSuccessComponent
  ],
  imports: [
    SharedModule, 
    SpinnerModule, 
    ClientPlatformRoutingModule, 
    MappingSetupModule, 
    ListPanelModule,
    FlowInstanceModule,
    ClientPlatformPipesModule
  ],
  exports: [
    ToolbarSetupComponent,
    TableSetupComponent, 
    SharedModule, 
    SpinnerModule, 
    MappingSetupModule, 
    ParameterInputsModule,
    FlowInstanceModule,
    ListPanelModule,
    ClientPlatformPipesModule
  ],
})
export class ClientPlatformModule {}
