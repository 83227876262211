import { Component, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WidgetService } from '../services/widget-service.service'
import { MetaService } from '../services/meta-service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService, userProfile } from 'src/app/shared/services/auth-service.service';
import { filter } from 'rxjs/operators';
import { LocaleService } from 'src/app/shared/services/translate.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DeviceInfoService } from 'src/app/shared/services/device-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionService } from 'src/app/core/services/permission.service';
import { Meta, Title } from '@angular/platform-browser';
import { MatTabGroup } from '@angular/material/tabs';
import { ResizeService } from '../page/panel/service/resize.service';

@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.css'],
})

export class BuilderComponent implements OnInit, OnDestroy {

  bloomMeta: any
  bloomCode: any
  pageNames: any = []
  page_structure: any
  ready: boolean = false
  isBrowser: boolean;
  isSidebarCollapsed = false;
  settingsTabDisabled: boolean = true

  widgetConfigOpenSub: any;
  widgetDeselectedSub: any;

  @ViewChild('tabGroup') tabGroup!: MatTabGroup;

  constructor(
    private widgetService: WidgetService,
    private route: ActivatedRoute,
    private router: Router,
    private MetaService: MetaService,
    private connectionService: ConnectionService,
    private authService: AuthServiceService,
    private _ngZone: NgZone,
    private resourcePermissionService: ResourcePermissionService,
    private localeService: LocaleService,
    private themeService: ThemeService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) platformId: Object,
    private _snackBar: MatSnackBar,
    public deviceInfo: DeviceInfoService,
    public permission: PermissionService,
    private title: Title,
    private meta: Meta,
    private renderer: Renderer2,
    private resizeService: ResizeService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.translate.use(this.localeService.currentLocale);

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      let url = decodeURI(event.url)
      let pathParts = url.split('/')
      if (pathParts[1] == 'bloom' && pathParts[2]) {
        this.bloomCode = pathParts[2]
      }
    });

    this.renderer.listen('document', 'click', (event) => {
      this.handlePageClick(event);
    });
  }

  handlePageClick(event: MouseEvent): void {
    this.resizeService.resizeMouseUpX(event);
    this.resizeService.resizeMouseUpY(event);
    this.resizeService.resizeMouseUpZ(event);
  }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    if (!this.authService.loggedIn) {
      this.authService.authCheck()
      this.authService.authCheckPositive.subscribe(async res => {
        console.log("auth check result", res);
        window.dispatchEvent(new Event('resize'));
        this.connectionService.getPreAuthenticatedToken();
        if(!this.connectionService.setWorkspaceId()) await this.connectionService.getWorkSpaceId()
        if (res) {
          this.loadBuilder()
        } else {
          this._snackBar.open('Apologies, the login attempt failed. Please reload the page and try logging in again.', 'ok', {
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          console.log("navigating to login page")
          this._ngZone.run(() => {
            this.router.navigate(['../../'], { relativeTo: this.route })
          })
        }
      })
    } else {
      this.loadBuilder()
    }

    this.route.params.subscribe(routeData => {
      // console.log("route data received: ", routeData)
      this.route.queryParams.subscribe(params => {
        if (params.collaborated_bloom) {
          this.resourcePermissionService.isCollaboratedBloom = true;
        } else this.resourcePermissionService.isCollaboratedBloom = false;
      })


    this.widgetConfigOpenSub = this.widgetService.$openWidgetSidebarSettings.subscribe(data => {
      if(!data) return
      console.log("widget config open request", JSON.parse(JSON.stringify(data)))

      if(this.isSidebarCollapsed) this.isSidebarCollapsed = false

      // change tab to settings
      this.settingsTabDisabled = false
      this.tabGroup.selectedIndex = 2 // focus to settings tab
      this.tabGroup.realignInkBar()
    })

    this.widgetDeselectedSub = this.widgetService.$widgetDeselected.subscribe(data => {
      this.settingsTabDisabled = true
      this.tabGroup.selectedIndex = 0
      this.widgetService.openWidgetSidebarSettings.next(null)
      this.tabGroup.realignInkBar()
    })

      this.bloomCode = routeData.bloomcode || undefined;
      // console.log("bloom code:", this.bloomCode)
    })
  }


  ngOnDestroy(){
    console.log("[BLOOM BUILDER] Destroyed");
    this.permission.isSharedBloom = false;
    this.permission.sharedWorkspaceId = null;
    this.MetaService.bloomMeta = null;
    this.MetaService?.fetchedBloomMeta?.next({});
    this.widgetConfigOpenSub?.unsubscribe()
  }


  toggleSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  }

  async delinkBloomPermissions(bloomMap){
    if(bloomMap.isPermissionDelinked) {
      //bloom permission to resource permission migration
      //As we are moving all permissions into resource pemission table we are migrating existing permission into resource
      //is roleMap is null, consider no permission records created
      let roleMap = await this.resourcePermissionService.getPermissionForUser(this.authService.userProfile._id, bloomMap.code, this.connectionService.preAuthenticatedToken);
      if(roleMap) return;;

    }
    try{
      if(this.permission.roleMap && Object.keys(this.permission.roleMap).length == 0) await this.permission.listRole()
      await this.resourcePermissionService.inheritWorkspacePermissions(this.connectionService.selectedWorkSpaceMap._id, bloomMap.code, "bloom");
      bloomMap.isPermissionDelinked = true;
      await this.MetaService.updateBloom(bloomMap);
    } catch(e){
      console.log("error while inheriting permissions", e)
    }
  }

  hasEditAccess(){
    let result = true;
    if(this.resourcePermissionService.isBloomPrivilagesSet){
      return this.resourcePermissionService.hasAccess(['bloom.edit']);
    } else return result
  }

  async setPermissions(bloomMap){
    if(bloomMap) {
      if(!this.permission.isSharedBloom) await this.delinkBloomPermissions(bloomMap);
      await this.resourcePermissionService.setBloomPrivilages(bloomMap, this.authService.userProfile);
    }
  }

  metaSEOInit(bloomMeta){
    this.title.setTitle(`Build ${bloomMeta?.name || 'Bloom'} - Bloom`);
    if(bloomMeta?.description)this.meta.updateTag({ name: 'description', content: bloomMeta?.description });
  }

  loadBuilder() {
    //1. fetch bloom meta


    this.MetaService.getBloom(this.bloomCode, 'draft')
      .then(response => {
        console.log("bloom meta received", response)
        if(response.workspace_id != this.connectionService.setWorkspaceId()) {
          this.permission.isSharedBloom = true;
          this.permission.sharedWorkspaceId = response.workspace_id;
        }
        this.setPermissions(response);
        //2. fetch page_structure meta
        if(this.isBrowser) this.metaSEOInit(response);
        return this.MetaService.getPageStructure(response.page_structure_id)
      })
      .then(response => {
        console.log("page structure meta", response)
        this.page_structure = response

        response.pages.forEach(pageCode => {
          this.pageNames.push(response[pageCode].name)
          //ready flag whether to call canvas or not
          this.ready = true
        });
      })
      .catch(error => {
        console.log("error occured", error)
      })

    this.localeService.localeChangeEvent.subscribe(lang => this.translate.use(lang));
    this.themeService.loadTheme();
    this.themeService
      .getExistingTheme(this.authService.profile._id)
      .then((res: any) => {
        if (res?.data != null) {
          console.log(res);
          this.themeService.settings_Id = res?.data[0]._id;
          let theme = res.data[0].themeSetting.theme;
          this.themeService.setTheme(theme);
          this.themeService.currentLocale = res.data[0].localeSetting;
          this.themeService.textDirection =
            res.data[0].themeSetting.direction;
          if (res.data[0].themeSetting.direction == 'rtl') {
            this.themeService.enableDirMode('rtl');
          }
        }
      });
    console.log("in builder bloom name", this.bloomCode)
    console.log("in builder page names", this.pageNames)
  }


  createPanel(widgetType: string) {
    console.log("create request at builder component", widgetType)
    this.widgetService.widgetNotifier(widgetType)
  }
}
