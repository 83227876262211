<div>
  <autocomplete *ngIf="actionConfig && flowList?.length"
  placeholder="Select flow" [focus]="true"
  [data]="flowList" [selectedValue]="actionConfig?.actionMap?.flow"
  (result)="selectedFlow($event)">
  </autocomplete>
  <mat-spinner *ngIf="spinner" [diameter]="20"></mat-spinner>
</div>

<shared-parameter-inputs  *ngIf="parameterOptions?.list?.length"
    [parameters]="parameterOptions"
    [existingInputs]="{options: actionConfig.actionMap.inputParams}"
    (inputParameter)="inputOptionsRecevied($event)"
    [fieldListingMap]="{list: ['sourceFields'], sourceFields: sourceFields}"
    [isOnlyOptions]="true">
</shared-parameter-inputs>


  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="actionConfig?.actionMap?.flow  && !hideUseInput">
    <div fxFlex="100">
      <mat-slide-toggle [checked]="actionConfig?.actionMap?.useOutput"
      (change)="useOutputCheckedEvent($event, actionConfig?.actionMap)" color="primary">
      <b>Use output</b>
      </mat-slide-toggle>
    </div>
  </div>

  <div>
    <autocomplete *ngIf="actionConfig?.actionMap?.useOutput"
    placeholder="Select Panel"
    [data]="panelFields" [selectedValue]="actionConfig.actionMap?.panel"
    (result)="selectedPanel($event)">
    </autocomplete>
  </div>

  <!-- [fieldListingMap]="{list: ['sourceFields'], sourceFields: sourceFields}" -->
