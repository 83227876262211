import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StarchComponent } from './starch.component';
import { StarchBaseManageComponent } from './starch-base-manage/starch-base-manage.component';
import { ConnectionExplorerComponent } from '../modules/organization/connection/connection-explorer/connection-explorer.component';
import { ObjectExplorerComponent } from '../shared/object-explorer/object-explorer.component';

const routes: Routes = [
  {
    path: '',
    component: StarchComponent,
  },
  {
    path: 'edit',
    component: StarchBaseManageComponent,
    // loadChildren: () => import('./starch-base-manage/starch-base-manage.module').then(m => m.StarchBaseManageModule)
  },
  {
    path: 'explorer',
    component: ConnectionExplorerComponent,
    // loadChildren: () => import('./starch-base-manage/starch-base-manage.module').then(m => m.StarchBaseManageModule)
  },
  {
    path: 'explorer/:objectid',
    component: ObjectExplorerComponent,
    data: { title: 'Starch Explorer - AppiWorks', description: 'Explore your Starch base' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StarchRoutingModule { }
