
<mat-toolbar [color]="themeService.isDefaultTheme() ? '' : 'primary'" [ngClass]="{'custom-toolbar': themeService.isDefaultTheme()}" fxLayout="row" fxLayoutAlign="center center" style="padding: 0">
  <div fxFlex="45" fxFlex.lt-md="60">
    <a  fxLayout="row"
    mat-menu-item [queryParams]="version === 'latest' ? '' : version !=='latest' ? {v: version} : ''"
    [routerLink]="['/p/' + currentBloomCode + '/' + bloomMeta?.page_structure_map.homePageCode]"
    routerLinkActive="active-link"
    [matTooltip]="bloomMeta.name"
    style="text-decoration: none; padding-left: 0.2rem;"
  >
    <div fxFlexFill fxLayout="row" fxLayoutAlign="flex-start center">
      <img
        class="image"
        [src]="bloomMeta.logoUrl" alt="logo"
      >
      <div class="spacer"></div>
      <span fxLayout="column" style="text-overflow: ellipsis;" fxLayoutAlign="center center">
        {{ bloomMeta.name }}
      </span>
    </div>
  </a>
  </div>

  <div fxFlex="3"></div>

  <div fxFlex="47" fxFlex.lt-md="27" fxLayoutAlign="end center" *ngIf="!pages">
  </div>

  <div fxFlex="47" fxFlex.lt-md="27" fxLayoutAlign="end center" *ngIf="pages">
    <button mat-icon-button aria-label="pages" [matMenuTriggerFor]="pageMenu" fxHide fxShow.lt-md>
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #pageMenu class="viewer-canvas-menu" color="accent">
      <ng-container *ngFor="let page of pages; trackBy: trackByFn">
        <a
          (click)="pageChangeDetected($event)"
          mat-menu-item
          [routerLink]="['/p/' + currentBloomCode + '/' + page.code]"
          [queryParams]="version === 'latest' ? '' : version !=='latest' ? {v: version} : ''"
          routerLinkActive="active-link"
        >
          {{page.name}}
        </a>
      </ng-container>
    </mat-menu>
    <span *ngIf="pages.length <= 3" fxHide.lt-md>
      <ng-container *ngFor="let page of pages ; trackBy: trackByFn">
        <a
          (click)="pageChangeDetected($event)"
          mat-button
          [routerLink]="['/p/' + currentBloomCode + '/' + page.code]"
          [queryParams]="version === 'latest' ? '' : version !=='latest' ? {v: version} : ''"
          routerLinkActive="active-link"
        >
        {{ page.name }}
        </a>
      </ng-container>
    </span>

    <span *ngIf="pages.length > 3" fxHide.lt-md>
      <ng-container *ngFor="let page of pages.slice(0,3); trackBy: trackByFn">
        <a
          (click)="pageChangeDetected($event)"
          mat-button
          [routerLink]="['/p/' + currentBloomCode + '/' + page.code]"  [queryParams]="version === 'latest' ? '' : version !=='latest' ? {v: version} : ''"
          routerLinkActive="active-link">
          {{ page.name }}
        </a>
      </ng-container>


      <button fxHide.lt-md mat-icon-button aria-label="pages" color="accent"
        [matMenuTriggerFor]="pageMenuMoreThanThree">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #pageMenuMoreThanThree>
        <ng-container *ngFor="let page of pages.slice(3,pages.length+1); trackBy: trackByFn">
          <a
            (click)="pageChangeDetected($event)"
            mat-menu-item [routerLink]="['/p/' + currentBloomCode + '/' + page.code]"
            routerLinkActive="active-link">
            {{ page.name }}
          </a>
        </ng-container>
      </mat-menu>
    </span>

  </div>


  <div fxFlex="5" fxFlex.lt-md="12" fxLayoutAlign="flex-end center" style="padding-right: 0.2rem;">
    <ng-container *ngIf="bloomMeta.optional_login || bloomMeta.require_login">
      <button mat-mini-fab color="accent" (click)="login();" matTooltip="Login"
       aria-label="" *ngIf="(bloomMeta.optional_login || bloomMeta.require_login) && !isLoggedIn">
        <mat-icon>account_circle</mat-icon>
      </button>
      <button mat-mini-fab color="accent" (click)="logout();" matTooltip="Logout"
       aria-label=""  *ngIf="(bloomMeta.optional_login || bloomMeta.require_login) && isLoggedIn">
        <mat-icon>logout</mat-icon>
      </button>
      <!-- <button fxLayoutAlign="center center" matTooltip="Login as a user" *ngIf="(bloomMeta.optional_login || bloomMeta.require_login) && !isLoggedIn" mat-stroked-button
      style="color: white;border-radius: 20px;background-color: cornflowerblue;" (click)="login();" aria-label="">
        <mat-icon>account_circle</mat-icon> <span fxHide.xs="true"> &nbsp; Sign In</span>
      </button> -->
      <!-- <button fxLayoutAlign="center center" matTooltip="Logout" *ngIf="(bloomMeta.optional_login || bloomMeta.require_login) && isLoggedIn" mat-stroked-button
      style="color: white;border-radius: 20px;background-color: cornflowerblue;" (click)="logout()" aria-label="">
        <mat-icon>logout</mat-icon> <span fxHide.xs="true">&nbsp; Logout</span>
      </button> -->
    </ng-container>
  </div>

</mat-toolbar>
