<div style="min-width: 60vw; max-width: 80vw; padding: 5px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxLayoutAlign="start start" style="color: #003e61; margin-right: 270px;">
        <b><mat-icon>school</mat-icon> Learn more</b>
      </div>

      <div fxLayout="column" fxLayoutAlign="start start" style="color: #003e61;">
        <b><mat-icon>extension</mat-icon> Client Platforms</b>
      </div>
    </div>

  </div>

  <br>
  <div fxLayout="row" fxLayoutAlign="start center">
    <!-- Learn more section -->
    <div fxLayout="column" fxFlex="40" style="margin-right: 20px;">
      <div *ngFor="let res of resourcesMap.list; index as i">
        <mat-card appearance="outlined" class="card-1 mat-card-custom" fxLayout="row" (click)="navigateToResouce(resourcesMap[res])">
          <div fxFlex="80">{{resourcesMap[res]['name']}}</div>
          <div fxFlex="20" fxLayoutAlign="end end"><mat-icon>arrow_right_alt</mat-icon></div>
        </mat-card>
      </div>
    </div>

    <!-- Client Platforms section -->
    <div fxLayout="column" fxFlex="40">
      <div *ngFor="let res of resourcesMap1.list; index as i">
        <mat-card appearance="outlined" class="card-1 mat-card-custom" fxLayout="row" (click)="navigateToResouce(resourcesMap1[res])">
          <div fxFlex="80">{{resourcesMap1[res]['name']}}</div>
          <div fxFlex="20" fxLayoutAlign="end end"><mat-icon>arrow_right_alt</mat-icon></div>
        </mat-card>
      </div>
    </div>
  </div>

</div>