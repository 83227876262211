import { CommonModule } from '@angular/common';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SnackbarModule } from 'src/app/shared/snackbar/snackbar.module';
import { GooglepickerModule } from 'src/app/shared/googlepicker/googlepicker.module';
import { NewConnectionComponent } from './new-connection.component';
import { ParameterInputsModule } from 'src/app/shared/parameter-inputs/parameter-inputs.module';

@NgModule({
  declarations: [
    NewConnectionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    GooglepickerModule,
    SnackbarModule,
    ParameterInputsModule
  ],
  providers: [
    // {provide: ErrorHandler, useClass: GlobalErrorHandler}
  ],
  exports: [NewConnectionComponent]
})
export class NewConnectionModule { }
