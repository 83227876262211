import { Widget } from "./baseWidget";

export class Duration extends Widget {
  type = 'duration';
  gridX = 6;
  minGridX = 3;
  config = {
    props: [
      "title",
      // "placeholder",
      'tooltip',
      'viewOnly'
    ],
    title: {
      displayName: 'Title',
      value: 'Enter duration',
      type: 'text',
    },
    // placeholder: {
    //   displayName: "Duration Placeholder",
    //   value: "Enter duration",
    //   type: 'text'
    // },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the duration value',
      value: false,
      type: 'boolean',
      resetValue: false
    },
  }

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
  }
}
