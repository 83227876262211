import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { WebSocketService } from 'src/app/core/services/SocketService';
import { FlowService } from 'src/app/flow/flow.service';
import { environment } from 'src/environments/environment';
import { BoxCache } from 'src/app/core/boxInstances/BoxCache';

@Component({
  selector: 'flow-trigger-config',
  templateUrl: './flow-trigger-config.component.html',
  styleUrls: ['./flow-trigger-config.component.scss']
})
export class FlowTriggerConfigComponent implements OnInit {
  @Input() editMode:boolean;
  @Input() flow: any;
  @Input() selectedTrigger: any
  @Output() 'nameChanged' = new EventEmitter<any>();
  @Output() 'sourceFields' = new EventEmitter<any>();
  @Output() 'onConfigure' = new EventEmitter<any>();
  @Output() 'appSelected' = new EventEmitter<any>();
  @Output() 'saveSetup' = new EventEmitter<any>();
  @Output() 'onSelectTrigger' = new EventEmitter<any>();
  // @Output() 'userChanges' = new EventEmitter<any>();
  isBrowser: boolean;
  step: any;
  index: any;
  isChangeTrigger:boolean = false;
  triggers: any = [
    {
      id: "schedule",
      title: "Schedule",
      icon: "schedule"
    },
    {
      id: "app_event",
      title: "App Event",
      icon: "event"
    },
    {
      id: "webhook",
      title: "Webhook",
      icon: "webhook"
    }
  ]

  constructor(
    public http: HttpClient,
    private spinner: SpinnerService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: Object,
    public fs: FlowService,
    private cdr: ChangeDetectorRef
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
   }

  ngOnInit(): void {
    if(!this.isBrowser) return;
  }

  async ngOnChanges(changes: any){

  }

  boxSelected(e){
    this.appSelected.emit(e);
  }

  setSourceFields(i, e){
    this.sourceFields.emit(e);
  }

  insertOrUpdateFlow(bool){
    this.saveSetup.emit(bool);
  }

  userMadeChanges(e, i) {
    this.fs.userMadeChanges.next(true);
  }

  onSelectedTrigger(trigger){
    this.selectedTrigger = trigger;
    this.flow.trigger_type = trigger.id;
    this.onSelectTrigger.emit(trigger);
    this.fs.userMadeChanges.next(true)
  }

  ngViewAfterInit(){}

}
