<div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="1rem" class="heading">
  <div fxFlex="100">Please provide the following values for</div>
  <div>{{ data.boxName }} - {{ data.boxObjectId }}</div>
</div>

<mat-dialog-content>

  <ng-container *ngFor="let option of data.getFnOptions; let i = index">
    <mat-form-field>
      <mat-label>{{ option.name }}</mat-label>
      <input matInput (input)="getOptionChanged($event, i)" [required]="option.required" [value]="option.value">
      <mat-hint style="color: #3f51b5;">Input type: {{ option.dataType }}</mat-hint>
    </mat-form-field>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>
