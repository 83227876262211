import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { PageService } from '../../services/page-service.service';
import { WIDGET_OPTIONS } from '../../services/widget-utility.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

interface ContextMenuActionConfig {
  actions: string[],
  [key: string]: any
}

@Component({
  selector: 'app-widget-builder',
  templateUrl: './widget-builder.component.html',
  styleUrls: ['./widget-builder.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class WidgetBuilderComponent implements OnInit, AfterViewInit {
  @ViewChild('menu') menu!: MatMenu;

  widgetContextMenuActions: ContextMenuActionConfig = {
    actions: []
  }
  contextActions: any
  margin: any;

  @Input() widgetMeta: any
  @Input() panelMeta: any
  @Input() pageMeta: any
  @Input() builderMode: any
  @Input() contextMenuBackdrop: boolean = false
  @Input() selectedWidgetId: any
  @Input() widgetOptions: WIDGET_OPTIONS

  @Output() widgetClick = new EventEmitter<any>()
  @Output() widgetSelection = new EventEmitter<any>()
  @Output() widgetDeletion = new EventEmitter<any>()
  @Output() widgetHover = new EventEmitter<any>()
  @Output() newWidgetMeta = new EventEmitter<any>()
  @Output() userInputReceived = new EventEmitter<any>();
  @Output() applyImageHeight = new EventEmitter<any>();

  @ViewChild('menuTrigger') contextMenuTrigger: MatMenuTrigger
  inInternal: boolean;

  constructor(
    public pageService: PageService
  ) {

    // this.on
  }

  ngOnInit(): void {
    if(this.panelMeta?.margin){
      this.margin = this.panelMeta.margin;
    }
    // console.log("widgegMeta", this.widgetMeta)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.contextMenuTrigger && this.contextMenuTrigger.menuOpen) {
        this.contextMenuTrigger.closeMenu()
      }
    }
  }

  ngOnDestroy(){
    this.contextMenuTrigger?.closeMenu()
  }

  ngDoCheck(){
    if(this.pageService.prevContextWidgetId !== this.widgetMeta.id){
      if (this.contextMenuTrigger && this.contextMenuTrigger.menuOpen) {
        this.contextMenuTrigger.closeMenu()
      }
    }
  }

  ngAfterViewInit() {

    // Inject our custom logic of menu close
    // (this.menu as any).closed = this.menu.close = this.configureMenuClose(this.menu.close);

  }

  closeMenu(){
    console.log("close menu")
    this.contextMenuTrigger.closeMenu()
  }

  onTriggerContextMenu(event: MouseEvent) {
    // this.themenu.closeMenu();
    event.preventDefault();
    // this.themenu.menu.focusFirstItem('mouse');
    // this.themenu.openMenu();
}

  onContextMenu(e){
  }

  menuOpened(widgetMeta){
    this.pageService.prevContextWidgetId = widgetMeta.id;
  }



  raiseWidgetHover(event){
    if(!this.builderMode) return
    this.widgetHover.emit(event)
  }
  raiseWidgetSelection(event){
    if(!this.builderMode) return
    this.widgetSelection.emit(event)
  }
  raiseWidgetDeletion(event){
    if(!this.builderMode) return
    this.widgetDeletion.emit(event)
  }
  raiseWidgetClick(event){
    if(!this.builderMode) return
    this.widgetClick.emit(event)
  }
  raiseNewWidgetMeta(data){
    console.log("new widget meta in builder", JSON.parse(JSON.stringify(data)))
    if(!this.builderMode) return
    this.widgetMeta = data
    this.newWidgetMeta.emit(data)
  }
  raiseUserInputReceived(data){
    this.userInputReceived.emit(data)
  }
  raiseApplyImageHeight(data){
    this.applyImageHeight.emit(data)
  }


  contextChanged(data){
    console.log("[WIDGET BUILDER], context changed", JSON.parse(JSON.stringify(data)))
    this.raiseNewWidgetMeta(data.data)
  }

  saveContextMenuActions(data: ContextMenuActionConfig){
    // console.log("this.widgetMeta", this.widgetMeta)
    // console.log("widget menu actions received in widget builder", data)
    this.widgetContextMenuActions = data
  }
  action(data){
    console.log("context menu action detected", data)
    this.contextActions = data;
    this.inInternal = true;
  }

  widgetClicked(meta){
    // console.log("widget clicked", meta, this.contextMenuTrigger)
    // console.log("isOpen", this.contextMenuTrigger.menuOpen)
    this.pageService.selectedWidget = meta.id;
    this.pageService.widgetSelected = true;
    // console.log("this.contextActions", this.widgetContextMenuActions)
  }
}
