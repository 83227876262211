<spinner *ngIf="spinner" [selfSpin]="true" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div fxLayout="row" *ngIf="authService.loggedIn">
  <app-workspace (dataRefresh)="refreshList()" [parentPage]="'starch'"></app-workspace></div>

<display-banner
  [logo] = "'starch/appiworks-starch-logo.png'"
  [Title]="'Empower your data universe with Starch'"
  [Tagline]="'Create, store, and manage custom business objects effortlessly'"
>
</display-banner>

<div *ngIf="!authService.loggedIn" fxLayout="row" fxLayoutAlign="center center">
  <div  class="build_button_div" (click)="navigateToSignUp()">
    <div class="build_button" fxLayout="row">
      <div fxLayout="column" fxLayoutAlign="center start">
        <div style="font-size:  large;; font-weight: bold;" fxLayout="row"
        fxLayoutAlign="start center"> Build Now</div>
     </div>
    </div>
  </div>
</div>

<div fxLayout fxLayoutAlign="center end" *ngIf="!addNewBase && authService.loggedIn && workspaceReady">
    <div fxFlex="2"></div>
    <div fxFlex="78" fxLayoutAlign="space-between center">
      <button
        *ngIf="authService.loggedIn"
        mat-button
        fxLayoutAlign="start center"
        routerLink="/home"
        color="primary"
      >
        <mat-icon color="primary">arrow_back</mat-icon>
        <span style="margin-top: 10px; margin-left: 4px">Back to Home</span>
      </button>
    </div>
    <div fxLayout fxLayoutAlign="end end" fxFlex="20">
      <button mat-flat-button color="primary" style="border-radius: 12px; margin-top: 5px;" color="primary" (click)="addNewBase = true;workspaceReady = false;">
        Create new Base
      </button>
    </div>
  <div fxLayout="column" fxFlex="2"></div>
</div>

<div style="height: 5vh;"></div>
<div fxLayout fxLayoutAlign="center center" *ngIf="authService.loggedIn && workspaceReady"><h1 style="margin-bottom: 0px;" ><b>List of Starch Bases</b></h1></div>
<div fxLayout="row"  *ngIf="authService.loggedIn && workspaceReady">
  <div fxLayout="column" fxFlex="2">
  </div>
  <div fxLayout="column" fxFlex="96">
    <mat-card appearance="outlined">
      <app-list-panel
        #bloomListPanel
        style="width: 100%" *ngIf="authService.loggedIn && workspaceReady"
        [builderMode]="false"
        [panelMeta]="starchListPanelMeta"
        [refreshData]="refreshPanel"
        (rowData)="selectedRowData($event)"
      >
      </app-list-panel>
    </mat-card>
  </div>
  <div fxLayout="column" fxFlex="2"></div>
</div>

<div *ngIf="!addNewBase && authService.loggedIn && workspaceReady">
  <div fxLayout="column" fxFlex="2"></div>
  <div fxLayout fxLayoutAlign="start start" fxFlex="98">
    <button mat-flat-button
  color="primary"
  style="border-radius: 12px; margin-top: 5px;" color="primary" (click)="addNewBase = true;workspaceReady = false;">
      Create new Base</button>
  </div>
</div>


<div *ngIf="addNewBase">
<div fxLayout="column" fxFlex="5"></div>
<div fxLayout="column" fxFlex="90">
  <starch-add-base (baseCreated)="addNewBase = false;workspaceReady = true;refreshPanel = true" (cancelled)="workspaceReady = true;addNewBase = false"> </starch-add-base>
</div>
<div fxLayout="column" fxFlex="5"></div>

</div>


<div class="auth-error" *ngIf="authError">
    <mat-card appearance="outlined">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" style="width: 100%;">
        <div style="text-align: center; width: 100%;">Workspace not loaded. Try signing in.</div>
        <button mat-raised-button class="signin-button" [routerLink]="['/']">
            Sign in
        </button>
      </div>
    </mat-card>
  </div>
