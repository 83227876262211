import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, SimpleChanges, OnChanges, DoCheck } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionBoxPopupComponent } from './connection-popup/connection-box-popup.component';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';


@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.css']
})
export class ConnectionComponent extends BaseWidgetComponent implements OnInit, AfterViewInit, OnChanges, DoCheck {


  contextMenuActions: any = {};


  private destroy:any = new Subject();
  isWhiteLabeled: boolean;
  whiteLabeledConnection: any;
  connectionId: any;
  oldValue: string;
  filterList: any = []

  constructor(
    public pageService: PageService, 
    public metaService: MetaService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    private dialog: MatDialog,
    public resourcePermissionService: ResourcePermissionService
  ) {
      super(metaService, pageService, resourcePermissionService)
  }

  async ngOnInit() {
    super.ngOnInit()
    // this.image.src = this.widgetMeta.config.src.value
    this.destroy = this.metaService?.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        console.log("context changed", contextActions)
        this.action(contextActions)
      }
    })

    this.filterList = this.getFilterIds()

    if(!['formpanel', 'detailspanel'].includes(this.widgetMeta.origin))await this.getWhiteLabeledConnection()
  }

  getFilterIds(){
    console.log("this.widgetMeta.config?.predefinedFilters?.value", this.widgetMeta.config?.predefinedFilters?.value)
    let lists:any = this.widgetMeta.config?.predefinedFilters?.value || [];
    let result = [];
    for (let index = 0; index < lists.length; index++) {
      const element = lists[index];
      result.push(element.__id);
    }
    return result;
  }

  async pickBox(){
    var dialog = this.dialog.open(ConnectionBoxPopupComponent, {
      maxWidth: '500px',
      minWidth: "300px",
      maxHeight: '500px',
      data: { filterList: this.filterList, filterType: this.widgetMeta.config?.predefinedFilters?.filterType }
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("diologResult--> ", diologResult);

    let connection = diologResult?.connection?.connection;
    let bloomMeta = this.metaService.bloomMeta;
    let bloomUser = this.metaService.bloomUserMap;
    let authUser = this.authService.userProfile;
    
    if(!bloomUser || Object.keys(bloomUser).length == 0) {
      bloomUser = authUser;
    }

    console.log("bloomUser", bloomUser)

    let workspace = connection.workspace_id;
    connection.white_label_id = this.widgetMeta.config.whiteLabelId.value;
    connection.white_label_user = bloomUser._id;
    let updatedConnection = await this.connectionService.updateConnection(connection, workspace);
    if(updatedConnection.connection){
      console.log("updatedConnection", updatedConnection)
      this.isWhiteLabeled = true;
      this.whiteLabeledConnection = updatedConnection.connection;
      this.userInputDetected(this.whiteLabeledConnection._id)
    }
  }

  async getWhiteLabeledConnection(){
    let bloomMeta = this.metaService.bloomMeta;
    let bloomUser = this.metaService.bloomUserMap;
    let authUser = this.authService.userProfile;

    if(!bloomUser || Object.keys(bloomUser).length == 0) {
      bloomUser = authUser;
    }

    let filter = `white_label_id=${this.widgetMeta.config.whiteLabelId.value}|string,white_label_user=${bloomUser._id}|string`;
    let connection = await this.connectionService.getTotalConnection(1, 1, null, null, filter);
    console.log("white label connection", connection);
    if(connection?.data?.[0]){
      this.isWhiteLabeled = true;
      this.whiteLabeledConnection = connection.data[0];
      this.userInputDetected(this.whiteLabeledConnection._id)
      console.log("innnn set");
    }
  }

  async editConnection(){
    var dialog = this.dialog.open(ConnectionBoxPopupComponent, {
      maxWidth: '500px',
      minWidth: "300px",
      maxHeight: '500px',
      data: { "edit": true, connection: this.whiteLabeledConnection._id}
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("diologResult--> on edit", diologResult);
    
    
  }

  async deleteConnection(){
    let connectionId = this.whiteLabeledConnection._id;
    await this.connectionService.deleteConnection(connectionId)
    this.isWhiteLabeled = false;
    this.whiteLabeledConnection = null;
  }

  async setConnection(){
    
    if(this.connectionId){
      let connection = await this.connectionService.getConnection(this.connectionId);
      console.log("connection", connection);
      if(connection){
        this.isWhiteLabeled = true;
        this.whiteLabeledConnection = connection;
        this.userInputDetected(this.whiteLabeledConnection._id)
      }
    } else {
      this.isWhiteLabeled = false;
      this.whiteLabeledConnection = null;
      this.userInputDetected("");

      console.log("innnn w");
    }
  }

  ngOnDestroy(): void {
    this.destroy?.unsubscribe();
  }

  ngAfterViewInit(): void { }
  

  userInputDetected(connection){
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: connection
    }
    this.userInputReceived.emit(userInput);
  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes in connection", changes)
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {

    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  async ngDoCheck() {
    let newValue = this.widgetMeta.config?.connection?.value || "";
    if(newValue != this.oldValue){
      this.oldValue = JSON.parse(JSON.stringify(newValue))
      this.connectionId = newValue
      console.log("calling from doCheck", this.connectionId)
      await this.setConnection()
    }
   }


  //-----------------------------METHODS---------------------------------

  onClick(event) {
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "edit"
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)

    switch (event.actionType) {
      case "delete":
        this.onDelete()
        break;
      case "customPropertyUpdate":
        console.log("RETURNED FROM MENU", event)
        this.widgetMeta.config[event.propertyName].value = event.data
        break;

      default:
        break;
    }
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

}
