import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { FieldPickerComponent } from './field-picker.component';
import { SharedModule } from '../shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { AppListingModule } from '../app-listing/app-listing.module';
import { ResourceCollaborationComponent } from './resource-collaboration.component';
// import { CommonMemberComponent } from './common-member.component';

@NgModule({
    declarations: [ResourceCollaborationComponent],
    imports: [
      CommonModule,
      SharedModule,
      SpinnerModule,
      MatProgressSpinnerModule,
      MatButtonModule,
      MatMenuModule,
      MatPaginatorModule,
      MatCardModule,
      MatTooltipModule,
      MatFormFieldModule,
      MatInputModule,
      ReactiveFormsModule,
      FormsModule,
      FlexLayoutModule,
      MatSelectModule,
      MatAutocompleteModule,
      MatIconModule,
      MatProgressSpinnerModule,
      MatButtonModule,
      AppListingModule
    ],
    exports: [ResourceCollaborationComponent]
  })
  export class ResourceCollaborationModule { }
