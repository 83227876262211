<div [ngSwitch]="type" *ngIf="spinnerService.spinner || selfSpin">
  <div *ngSwitchCase="'ball-atom'">
    <app-ball-atom-spinner
      [center]="center"
      [size]="size"
      [color]="color"
    ></app-ball-atom-spinner>
  </div>
  <div *ngSwitchCase="'ball-clip-rotate'">
    <app-ball-clip-rotate-spinner
      [center]="center"
      [size]="size"
      [color]="color"
    ></app-ball-clip-rotate-spinner>
  </div>
  <div *ngSwitchCase="'ball-fussion'">
    <app-ball-fussion-spinner
      [center]="center"
      [size]="size"
      [color]="color"
    ></app-ball-fussion-spinner>
  </div>
  <div *ngSwitchCase="'ball-pulse'">
    <app-ball-pulse-spinner
      [center]="center"
      [size]="size"
      [color]="color"
    ></app-ball-pulse-spinner>
  </div>
  <div *ngSwitchCase="'ball-triangle-path'">
    <app-ball-triangle-path-spinner
      [center]="center"
      [size]="size"
      [color]="color"
    ></app-ball-triangle-path-spinner>
  </div>
  <div *ngSwitchCase="'square-loader'">
    <app-square-loader-spinner
      [center]="center"
      [size]="size"
      [color]="color"
    ></app-square-loader-spinner>
  </div>
  <div *ngSwitchCase="'timer'">
    <app-timer-spinner
      [center]="center"
      [size]="size"
      [color]="color"
    ></app-timer-spinner>
  </div>
  <div *ngSwitchCase="'loading-text'">
    <app-loading-text-spinner
      [center]="center"
      [loadingText]="text"
    ></app-loading-text-spinner>
  </div>
  <div *ngSwitchCase="'skeleton'">
    <app-skeleton
      [center]="center"
      [skeletonSpinnerGridMap]="skeletonSpinnerGridMap"
      [skeletonPresetCode]="skeletonPresetCode"
    ></app-skeleton>
  </div>
  <div *ngSwitchDefault>
    <app-ball-clip-rotate-spinner
      [center]="center"
      [size]="size"
      [color]="color"
    ></app-ball-clip-rotate-spinner>
  </div>
</div>
