import { Component, EventEmitter, Input, NgModule, Output, SimpleChanges, NgZone } from '@angular/core';
import { FilterConfigModule } from '../filter-config/filter-config.module';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule, UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatCardModule} from '@angular/material/card';

interface Condition {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-filter-group-component',
  standalone: true,
  imports: [
    FilterConfigModule,
    MatFormFieldModule, 
    FormsModule, 
    MatInputModule, 
    MatSelectModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule
  ],
  templateUrl: './filter-group-component.component.html',
  styleUrl: './filter-group-component.component.scss'
})
export class FilterGroupComponentComponent {
  @Input() filter;
  @Input() boxObjectAttributes;
  @Input() enableSecurity;
  @Input() supportApplyButton: boolean;
  @Input() hideToggle: boolean;
  @Input() maskOperator: boolean;
  @Input() lockFilterType: boolean;
  @Input() showAttributePicker: boolean;
  @Input() fieldListingMap: any;
  @Input() boxObjectFunction: any;
  @Input() pageMeta: any; // if pageMeta is supplied, page reference map will be created for in field picker
  @Output() "selectedFilter" = new EventEmitter<any>();
  @Output() "cancelFilter" = new EventEmitter<any>();
  @Output() filtersValid = new EventEmitter<any>();

  outerCondition: Condition[] = [
    {value: 'and', viewValue: 'And'},
    {value: 'or', viewValue: 'Or'},
  ];
  selectedOuterCondition = this.outerCondition[0].value;
  groupFilter: any = [];
  isShowFilterGroup: boolean;

  constructor(private ngZone: NgZone){

  }

  ngOnInit(): void {
    if(this.filter){
      this.groupFilter = [];
      for(let obj of this.filter?.filterItems){
        if(obj?.operator && obj?.filterItems) this.groupFilter.push(obj)
      }
    }
  }

  selectOuterCondition(event, item){
    let index;
    if(this.filter?.filterItems) index = this.filter.filterItems.indexOf(item)
    if(this.filter?.filterItems[index]?.operator) this.filter.filterItems[index].operator = (event.target as HTMLSelectElement).value
    this.selectedFilter.emit(this.filter)
  }

  saveFilterConfig(event){
    this.selectedFilter.emit(this.filter)
  }

  addFilterGroupItem(){
    this.ngZone.run(() => {
      this.isShowFilterGroup = true;
      this.filter.filterItems.push({
        operator: 'and',
        filterItems: [
          {
            attribute: '',
            operator: '=',
            value: '',
            dataType: '',
            filterType: 'static_filter'
          }
        ]
      })
    });
    if(this.filter){
      this.groupFilter = [];
      for(let obj of this.filter?.filterItems){
        if(obj?.operator && obj?.filterItems) this.groupFilter.push(obj)
      }
    }
  }

  removeFilterGroupItem(item){
    let filterIndex;
    let groupFilterIndex;
    if(this.filter?.filterItems) filterIndex = this.filter.filterItems.indexOf(item)
    groupFilterIndex = this.groupFilter.indexOf(item)
    this.groupFilter.splice(groupFilterIndex, 1)
    this.filter.filterItems.splice(filterIndex, 1)
    this.selectedFilter.emit(this.filter)
  }
}
