import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';
import { ScoreCardComponent } from './score-card/score-card.component';
import { MatCardModule } from '@angular/material/card';
import {FlexLayoutModule} from "@ngbracket/ngx-layout";
import { MatDividerModule } from '@angular/material/divider';
import { SpinnerModule } from '../spinner/spinner.module';

@NgModule({
  declarations: [
    PieChartComponent,
    BarChartComponent,
    LineChartComponent,
    GaugeChartComponent,
    ScoreCardComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    MatDividerModule,
    SpinnerModule
  ],
  exports: [
    PieChartComponent,
    BarChartComponent,
    LineChartComponent,
    GaugeChartComponent,
    ScoreCardComponent
  ]
})
export class BasicChartsModule { }
