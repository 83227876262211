import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormAttributeSelectionComponent } from './form-attribute-selection.component';
import { AttributeSelectionModule } from 'src/app/shared/query-components/attribute-selection/attribute-selection.module';

@NgModule({
  declarations: [FormAttributeSelectionComponent],
  imports: [
    CommonModule,
    SharedModule,
    AttributeSelectionModule
  ],
  exports: [FormAttributeSelectionComponent]
})
export class FormAttributeSelectionModule { }
