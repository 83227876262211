import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-setup',
  templateUrl: './dialog-setup.component.html',
  styleUrls: ['./dialog-setup.component.css']
})

export class DialogSetupComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogSetupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
