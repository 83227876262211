export class MappingIcons {
   //Icons mapping
  static appFieldIconMap: any = {
    string: 'short_text',
    number: 'pin',
    date: 'event',
    datetime: 'date_range',
    boolean: 'toggle_on',
    bool: 'toggle_on',
    phone_number: 'phone',
    array: 'data_array',
    object: 'data_object',
    image_url: 'link',
    enumeration: 'format_list_numbered',
  };

}