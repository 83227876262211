<div>
  <div fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxLayout="column" fxFlex="100">
      <div fxLayout="row">
        <h4>Invite people to</h4>
      </div>
      {{ resourceMap?.name }}
      <div
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngFor="let map of invitationMap; let i = index"
      >
        <div fxLayout="column" fxFlex="70">
          <mat-form-field class="example-full-width">
            <mat-label>Emails</mat-label>
            <mat-chip-grid #chipList aria-label="email">
              <mat-chip-row
                *ngFor="let email of map.validEmails"
                [removable]
                (removed)="removeChipValue(email, map.validEmails)"
              >
                {{ email }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
              <input
                [(ngModel)]="map.inputValue"
                placeholder="New email"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addChipValue($event, map.validEmails)"
                (paste)="pasteChipValue($event, map.validEmails)"
              />
            </mat-chip-grid>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="20">
          <mat-form-field
            class="custom_select example-full-width"
            *ngIf="availableRoles.length > 0"
          >
            <mat-label>Role</mat-label>
            <mat-select [(ngModel)]="map.role" name="role">
              <mat-option
                *ngFor="let role of availableRoles"
                [value]="role._id"
              >
                <span>{{ role.name }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="5">
          <mat-icon
            *ngIf="invitationMap.length > 1"
            style="margin: 0px; cursor: pointer"
            color="warn"
            matTooltip="Remove"
            (click)="removeInvitation(invitationMap, i)"
          >
            delete</mat-icon
          >
        </div>
      </div>
      <div fxLayout="row">
        <mat-icon
          style="margin: 0px; cursor: pointer"
          matTooltip="Add more"
          (click)="addInvitation()"
        >
          add_circle_outline</mat-icon
        >
      </div>
      <br />
      <div fxLayout="row" fxLayoutAlign="stretch">
        <div fxFlex="5"></div>
        <button
          mat-raised-button
          color="primary"
          (click)="sendWorkspaceInvitation()"
          type="button"
        >
          Send Invitations
        </button>
        <div fxFlex="5"></div>
        <button
          mat-stroked-button
          color="primary"
          (click)="onCancel()"
          type="button"
        >
          Cancel
        </button>
        <div fxFlex="5"></div>
      </div>
    </div>
  </div>
</div>
