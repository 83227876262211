import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatDividerModule } from '@angular/material/divider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import { WorkspaceComponent } from 'src/app/shared/workspace/workspace.component'
import { SpinnerModule } from '../spinner/spinner.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { BoxConfigInputsModule } from '../../bloom/box-config-inputs/box-config-inputs.module';
import { ConnectionSelectionModule } from '../connection-selection/connection-selection.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
@NgModule({
  declarations: [
    WorkspaceComponent,
  ],

  imports: [
    MatDividerModule,
    CommonModule,
    MatMenuModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatCardModule,
    MatTooltipModule,
    SpinnerModule,
    MatSnackBarModule,
    BoxConfigInputsModule,
    MatExpansionModule,
    MatStepperModule,
    ConnectionSelectionModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    WorkspaceComponent,
  ]
})
export class workspaceModule { }
