import { Component, Injectable, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StarchService } from 'src/app/shared/services/starch.service';
import { FlatTreeControl} from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { BoxService } from 'src/app/shared/services/box.service';
import { ConnectionDialogComponent } from 'src/app/shared/dialog/connection-dialog/connection.dialog';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';
import { ConnectionPopupComponent } from 'src/app/shared/connection-selection/connection-popup/connection-popup.component';
import { AppsPopupComponent } from 'src/app/shared/apps-popup/apps-popup.component';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';

export class BaseNode {
  name: string;
  code?:any; _id?:any;
  __id?:any;
  tree_type?:any
  children?: BaseNode[];
}

export class ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  code?:any;
  _id?:any;
  __id?:any;
  tree_type?:any
}

@Component({
  selector: 'connection-panel-list',
  templateUrl: './connection-panel-list.component.html',
  styleUrls: ['./connection-panel-list.component.scss'],
  providers: []
})
export class ConnectionPanelListComponent implements OnInit {


  formGroup: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    name: new UntypedFormControl('', Validators.required)
  });

  timeout: any = null;
  isNameExists: boolean;
  spinner: boolean;
  sugestBaseCode: any;
  addNewBase: boolean = false;
  baseMap: any = {};
  allConnections: any = []
  addNewObject:boolean = false;
  objectMap: any = {}
  pageIndex = 0;
  pageLimit = 10;
  connectionSearchField: string = ""


  private _transformer = (node: BaseNode, level: number) => {
    let flatNode = {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      nodeMap: node,
      tree_type: node.tree_type,
      level: level,
    };
    this.flatNodeMap.set(flatNode, node);
    return flatNode;
  }

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  flatNodeMap: Map<ExampleFlatNode, BaseNode> = new Map<ExampleFlatNode, BaseNode>();
  nestedNodeMap: Map<BaseNode, ExampleFlatNode> = new Map<BaseNode, ExampleFlatNode>();

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  showSearch:boolean = false

  secondTreeData: any = [
    {
      name: "Objects",
      _id:"objects",
      children: [{load: true}]
    },
    {
      name: "Actions",
      _id:"actions",
      children: [{load: true}]
    }
  ]
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    public starchService: StarchService,
    public widgetService: WidgetService,
    public connectionService: ConnectionService,
    private boxService: BoxService,
    public pageService: PageService,
    private automationService: AutomationService,
    public permission: PermissionService,
    public resourcePermissionService: ResourcePermissionService

  ) { }

  async ngOnInit() {
    this.resourcePermissionService.setConnectionsPrivilages();
    await this.getAllConnections()

  }

  ngAfterViewInit(): void {

  }
  mouseover(e, node){
    node.hovered = true;
  }

  mouseout(e, node){
    let target = e.relatedTarget.localName;
    if(target != 'mat-icon')node.hovered = false;
  }

  async getAllConnections(){
    console.log("getting all Connection")
    this.spinner = true;
    this.allConnections = await this.getAllConnectionsTree();
    console.log("allConnections list", this.allConnections);
    this.dataSource.data =  this.allConnections;
    if(!this.showSearch && this.connectionService.connectionLength > 10) this.showSearch = true;
    this.spinner = false;
  }

  async getAllConnectionsTree(){

    // this.spinner = true;
    let filter = "";
    if(this.connectionSearchField)filter = `name%${this.connectionSearchField}`;
    let workspaceId = this.permission.isSharedBloom ? this.permission.sharedWorkspaceId : this.connectionService.workSpaceId;
    let connections = await this.connectionService.getTotalConnection(this.pageIndex + 1, this.pageLimit, this.connectionService.preAuthenticatedToken, workspaceId, filter);
    let result = [];
    connections = connections?.data || [];
    for (let index = 0; index < connections.length; index++) {
      const element = connections[index];
      element.tree_type = 'connection';
      element.children = [{load: true}]
      result.push(element)
    }
    // this.spinner = false;
    return result;
  }

  async loadAndSetChildren(node){
    if(!this.treeControl.isExpanded(node))return;
    console.log("loadAndSetChildren", node);
    this.spinner = true;

    if(node?.tree_type == "connection") {
      await this.loadAndSetSupports(node);

    } else if (node?.tree_type == "support"){
      await this.loadAndSetObjects(node);
    }

    this.spinner = false;
  }

  async loadAndSetObjects(node){
    console.log("supports node", node)
    let baseMap = node?.nodeMap?.baseMap;
    let connections = []
    for (let i = 0; i < this.allConnections.length; i++) {
      const element = this.allConnections[i];
      if(element._id == baseMap._id){
        for (let j = 0; j < element?.children.length || 0; j++) {
          const jElement = element.children[j];
          if(jElement.name == "Objects"){
            let objects = await this.boxService.getBoxObjects(baseMap?._id, baseMap?.box_id);
            objects.forEach(ele => {
              ele.baseMap = jElement,
              ele.tree_type = "object"
            });
            jElement.children = objects;
          } else if(jElement.name == "Actions"){
            let actions = await this.boxService.getBoxActions(baseMap?.box_id, baseMap?._id);
            actions.forEach(ele => {
              ele.baseMap = jElement,
              ele.tree_type = "action"
            });
            jElement.children = actions;
            console.log("--->action",actions)
          }
        }
      }
      connections.push(element);
    }
    this.dataSource.data = connections;
    let firstIndex = this.treeControl.dataNodes.findIndex((x:any) => (x?.nodeMap?._id === baseMap._id));
    this.treeControl.expand(this.treeControl.dataNodes[firstIndex])
    if(node?.tree_type == "support"){
      let secondIndex = this.treeControl.dataNodes.findIndex((x:any) => (x?.nodeMap?.baseMap?._id === baseMap._id && x?.name == node?.name && x?.tree_type == "support") );
      this.treeControl.expand(this.treeControl.dataNodes[secondIndex])
    }
  }

  async loadAndSetSupports(node){
    let boxId = node?.nodeMap?.box_id;
    let box = await this.connectionService.getBox(boxId);
    // let objects = await this.boxService.getBoxObjects(node?.nodeMap?._id, boxId);
    let nodeMap = node?.nodeMap;
    if(box.supports.includes('object')){
      let secondTreeData = []
      let initialSecondTreeData = JSON.parse(JSON.stringify(this.secondTreeData));

      for (let i = 0; i < this.allConnections.length; i++) {
        const element = this.allConnections[i];
        if(element._id == nodeMap._id){
          initialSecondTreeData.forEach(ele => {
            ele.baseMap = element,
            ele.tree_type = "support"
          });
          element.children = initialSecondTreeData;
        } else {
          element.children = [{load: true}]
        }
        secondTreeData.push(element)
      }
      this.dataSource.data =  secondTreeData;
      let index = this.treeControl.dataNodes.findIndex((x:any) => x.nodeMap?._id === nodeMap._id );;
      this.treeControl.expand(this.treeControl.dataNodes[index])
    } else { //if only actions
      let actions = await this.boxService.getAllActions(boxId, node?.nodeMap?._id);
      let secondTreeData = []

      for (let i = 0; i < this.allConnections.length; i++) {
        const element = this.allConnections[i];
        if(element._id == nodeMap._id){
          actions.forEach(ele => {
            ele.baseMap = element,
            ele.tree_type = "action"
          });
          element.children = actions;
        }
        secondTreeData.push(element)
      }
      this.dataSource.data =  secondTreeData;
      let index = this.treeControl.dataNodes.findIndex((x:any) => x.nodeMap?._id === nodeMap._id );;
      this.treeControl.expand(this.treeControl.dataNodes[index])
    }
  }

  async deleteConnection(node){
    let dialogRef = this.dialog.open(ConnectionDialogComponent, {
      width: '600px',
      data: { name: node.name },
    });
    let result = await dialogRef.afterClosed().toPromise();;
    if (result) {
      this.spinner = true;
      try{
        await this.connectionService.deleteConnection(node?.nodeMap?._id, this.connectionService.preAuthenticatedToken)
        let snackBarObj = {
          snackBarMessage: 'Connection Deleted Successfully',
          snackBarIcon: 'check_circle',
          snackBarDuration: 2000,
          snackBarError: false,
        };
        this.openSnackBar(snackBarObj);
        await this.getAllConnections()
      } catch(e){
        this.spinner = false;
        let snackBarObj = {
          snackBarMessage: 'Error while Deleting Connection',
          snackBarIcon: 'error',
          snackBarDuration: 6000,
          snackBarError: true,
        };
        this.openSnackBar(snackBarObj);
      }
      this.spinner = false;
    }
  }

  async connectionPopup(type, node?: any){
    var option: any = {};
    console.log("this.connectionId;", node)
    if(type == "edit"){
      option.connection = node?.nodeMap?._id;
    } else {
        let dialog = this.dialog.open(AppsPopupComponent, {
          // maxWidth: '500px',
          maxHeight: '500px',
          data: {}
        });
        var diologResult = await dialog.afterClosed().toPromise();
        console.log("diologResult", diologResult)
        option.box = diologResult.__id;
    }
    let dialog = this.dialog.open(ConnectionPopupComponent, {
      maxWidth: '500px',
      minWidth: "300px",
      maxHeight: '500px',
      data: option
    });
    var diologResult = await dialog.afterClosed().toPromise();
    console.log("diologResult--> ", diologResult)
    if(!diologResult) return;
    this.spinner = true;
    await this.getAllConnections()
    this.spinner = false;
  }

  hasDeleteAccess(){
    let result = true;
    if(this.resourcePermissionService.isConnectionPrivilagesSet){
      return this.resourcePermissionService.hasAccess(['connection.delete']);
    } else return result
  }


  dropWidget(event){
    console.log("event", event)
    if(event?.tree_type == "object"){
      let object = {
        __type: "connection_panel_object",
        connection: event?.nodeMap?.baseMap?.baseMap,
        object: event?.nodeMap
      }
      this.widgetService.starchDragNotifier(object)
    } else if (event?.tree_type == "action"){
      if(!this.pageService.isPointerInCanvas) return;
      let action = event?.nodeMap;
      let inputs = action?.input;
      let connection = event?.nodeMap?.baseMap;
      let panel = this.automationService.generateActionPanel(inputs, action, connection)
      this.widgetService.actionPanelNotifier(panel);
    }

  }

  async pageChanged($event) {
    console.log($event);
    this.pageIndex = $event.pageIndex;
    this.pageLimit = $event.pageSize;
    await this.getAllConnections()
  }

  openSnackBar(snackBarObj: any) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: snackBarObj.snackBarMessage,
        iconname: snackBarObj.snackBarIcon,
      },
      duration: snackBarObj.snackBarDuration,
      horizontalPosition: 'end',
    });
  }

}


