import { Widget } from "./baseWidget";

export class Period extends Widget {
  type = 'period';
  gridX = 6;
  minGridX = 3;

  config = {
    props: [
      "placeholder",
      'tooltip',
      'viewOnly',
      'periodValue',
      'appearance'
    ],
    appearance: {
      displayName: "Input appearance",
      value: "standard",
      type: "select"
    },
    placeholder: {
      displayName: "Input Placeholder",
      value: "default input placeholder",
      type: 'text'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: this.type,
      type: 'text',
      enabled: true
    },
    periodValue: {
      displayName: "Period Value",
      value: "",
      resetValue: '',
      type: 'text'
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the value',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  }

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
  }
}
