import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { WidgetsModule } from 'src/app/bloom/widgets/widgets.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ExpressionsSelectionComponent } from './expressions-selection/expressions-selection.component'
import { ExpressionConfigurationComponent } from './expression-configuration/expression-configuration.component';
import { WidgetsWrapperModule } from 'src/app/bloom/widget-wrapper/widget-wrapper.module';

@NgModule({
  declarations: [
    ExpressionsSelectionComponent,
    ExpressionConfigurationComponent
  ],
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatCardModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    // WidgetsModule,
    WidgetsWrapperModule,
    MatCheckboxModule
  ],
  exports: [
    ExpressionsSelectionComponent,
    ExpressionConfigurationComponent
  ]
})
export class BuiltInExpressionModule { }
