import { CustomPreloadingService } from './shared/services/custom-preloading.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, } from '@angular/router';
import { parseDomain } from 'parse-domain';
import { ViewerCanvasComponent } from './modules/viewer/viewer-canvas/viewer-canvas.component';
import { PageComponent } from './bloom/page/page.component';

// console.log("host", window.location.href);
// const parseResult: any = parseDomain(window.location.host)
// console.log("parseResult", parseResult);
// const { subDomains, domain, topLevelDomains } = parseResult;
// console.log("subDomainss", subDomains); // ["www", "some"]
// console.log("domain", domain); // "example"

var bloomCode; //= "bloom-5fcfdff6";
var isBloom = false;
// if(subDomains && subDomains.length > 0){
//   let availSubDomains = subDomains;
//   console.log("www index", "www".indexOf(availSubDomains))
//   if(availSubDomains[0] == "www") availSubDomains.splice(0, 1);

//   if(availSubDomains.length > 0) {
//     console.log("subDomains in", availSubDomains)
//     //dev.ci
//     if(availSubDomains.length == 3 || availSubDomains.length == 1) {
//       isBloom = true;
//     }

//   }
//   bloomCode = availSubDomains[0];
//   console.log("isBloom", isBloom)
// }


const bloomRoutes: Routes = [
  {
    path: '',
    // loadChildren: () => import('./modules/viewer/viewer.module').then(m => m.ViewerModule),
    component: ViewerCanvasComponent,
    data: {bloomCode: bloomCode}
  },
  // {
  //   path: ':version',
  //   component: ViewerCanvasComponent,
  //   data: {bloomCode: bloomCode}
  // },
  {
    path: '',
    component: ViewerCanvasComponent,
    data: {bloomCode: bloomCode},
    children: [
      {
        path: ':pagecode',
        component: PageComponent
      }
    ]
  }
]

const routes: Routes = [
  {
    path: 'redirect', loadChildren: () => import('./core/redirect/redirect.module').then(m => m.RedirectModule),
  },
  {
    path: 'login', loadChildren: () => import('./shared/login-google/login-google.module').then(m => m.LoginGoogleModule),
    // data: { preload: true }
  },
  {
    path: 'login-redirect', loadChildren: () => import('./shared/login-redirect/login-redirect.module').then(m => m.LoginRedirectModule)
  },
  {
    path: 'oauth',
    loadChildren: () => import('./shared/oauth-authorize/oauth-authorize.module').then(m => m.OauthAuthorizeModule)
  },
  {
    path: 'login-popup', loadChildren: () => import('./shared/login-popup/login-popup.module').then(m => m.LoginPopupModule),
    // data: { preload: true }
  },
  {
    path: '', loadChildren: () => import('./modules/home/homemodule.module').then(m => m.HomemoduleModule),
    data: { preload: true }
  },
  {
    path: 'connection/manage',
    loadChildren: () => import('./shared/connection-management/connection-management.module').then(m => m.ConnectionManagementModule)
  },
  {
    path: 'connection',
    loadChildren: () => import('./modules/organization/organization.module').then(m => m.OrganizationModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./shared/toolbar/settings/settings.module').then(m => m.SettingsModule)
    // , data: { preload: true }
  },
  {
    path: 'playground',
    loadChildren: () => import('./playground/playground.module').then(m => m.PlaygroundModule)
  },
  {
    path: 'bloom',
    data: { subProduct: 'bloom' },
    loadChildren: () => import('./bloom/bloom.module').then(m => m.BloomModule)
  },
  {
    path: 'flow',
    loadChildren: () => import('./flow/flow.module').then(m => m.FlowModule)
  },
  {
    path: 'starch',
    loadChildren: () => import('./starch/starch.module').then(m => m.StarchModule),
    data: { title: 'Starch Bases - AppiWorks', description: 'Explore your Starch Bases' }
  },
  {
    path: 'form',
    loadChildren: () => import('./form/form.module').then(m => m.FormModule)
  },
  {
    path: 'p',
    loadChildren: () => import('./modules/viewer/viewer.module').then(m => m.ViewerModule)
  },
  {
    path: 'client-platform',
    loadChildren: () => import('./client-platform/common-components/client-platform.module').then(m => m.ClientPlatformModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/admin/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'roles',
    loadChildren: () => import('./modules/admin/roles/roles.module').then(m => m.RolesModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('./modules/admin/groups/groups.module').then(m => m.GroupsModule)
  },
  {
    path: 'privileges',
    loadChildren: () => import('./modules/admin/privileges/privileges.module').then(m => m.PrivilegesModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(isBloom ? bloomRoutes : routes,
    { preloadingStrategy: CustomPreloadingService  })], //, { preloadingStrategy: CustomPreloadingService }
  exports: [RouterModule],
  providers: [CustomPreloadingService]
})
export class AppRoutingModule { }
