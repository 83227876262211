import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';

@Component({
  selector: 'app-parameter-widgets',
  templateUrl: './parameter-widgets.component.html',
  styleUrls: ['./parameter-widgets.component.scss']
})
export class ParameterWidgetsComponent implements OnInit {
  arrayParamWidgets: any[] = []
  constructor() { }
  @Input() index;
  @Input() paramKey;
  @Input() parameters: any;
  @Input() inputMap: any;
  @Input() fieldListingMap: any;
  @Output() "inputParameterWidget" = new EventEmitter<any>();

  ngOnInit(): void {
  }
  // addArrayInputs(widget: any) {
  //   let newWidget = JSON.parse(JSON.stringify(widget));
  //   this.arrayParamWidgets.push(newWidget);
  // }

  // removeArryInputs(index: any) {
  //   this.arrayParamWidgets.splice(index, 1);
  // }
 
  valueReceived(event, param){
    if(this.paramKey) {
      if(!this.inputMap[this.paramKey]) this.inputMap[this.paramKey] = [];
      if(!this.inputMap[this.paramKey][this.index]) this.inputMap[this.paramKey].push({});
      this.inputMap[this.paramKey][this.index] = {...this.inputMap[this.paramKey][this.index], [param.key]: event.value}
    }
    if(this.inputMap?.root){
      let root = this.inputMap?.root
      let item = this.inputMap[this.paramKey][this.index]
      item["_id"] = param.firstKey ? param.firstKey : param.key
      if(this.inputMap[root]) this.inputMap[root][this.index] = item
    }
    console.log("this.inputMap", JSON.parse(JSON.stringify(this.inputMap)));
    this.inputParameterWidget.emit(this.inputMap);
  }
  
  insertCursor(e, param){
    let value = e.__id;
    let newValue: any;
    let cursorValue = '${' + value + '}';
    let newWidget: any = WidgetManager.getWidget(param?.widget.type)
    //set new value based on key
    if (this.paramKey) {
      newValue = this.inputMap[this.paramKey][this.index][param.key] + cursorValue;

      this.inputMap[this.paramKey][this.index] = {
        ...this.inputMap[this.paramKey][this.index],
        [param.key]: newValue
      }
    }
    //create new widget using existing widget.
    Object.keys(param.widget).forEach(key => {
      newWidget[key] = param.widget[key]
    });
    newWidget.setValue(newValue, false);
    //assign new widget config to existing param widget.
    param.widget = newWidget;
    console.log("this.inputMap", JSON.parse(JSON.stringify(this.inputMap)));
    this.inputParameterWidget.emit(this.inputMap);
  }
}
