import { AuthServiceService } from './../../services/auth-service.service';
import { ThemeService } from './../../services/theme.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from '../sidenav.service';
import { ClientPlatformService } from 'src/app/client-platform/client-platform.service';
import { Router } from '@angular/router';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ScrollService } from 'src/app/core/services/ScrollService';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NavComponent implements OnInit {

  @ViewChild('leftSideNav', { static: false }) leftSideNav: MatSidenav;
  @ViewChildren('leftSideNav', { read: MatSidenav }) modelRefList: QueryList<MatSidenav>

  private _mobileQueryListener: () => void;
  isBrowser: any;
  routerSubscription: any;
  scrollSubscription: any;
  hideScrollbar: boolean;
  window:any;
  hideNavbar: boolean;
  constructor(
    public sidenavService: SidenavService,
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    public themeService: ThemeService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    public clientPlatformService: ClientPlatformService,
    private scrollService: ScrollService,
    @Inject(PLATFORM_ID) platformId?: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    console.log('leftnav sub-->');
    if(!this.isBrowser) return;
    this.sidenavService.mobileQuery = this.media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.sidenavService.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.scrollSubscription = this.scrollService.hideScrollBar$.subscribe(hide => {
      this.hideScrollbar = hide;
    });

    this.window = window;
    let routeUrl = window?.location?.pathname || null;
    console.log("window routeUrl", routeUrl)
    if(!routeUrl) routeUrl = this.router.url;
    const containsSubstring = ['/redirect', 'connection/manage', '/oauth/authorize'].some(substring => routeUrl.includes(substring));
    if(containsSubstring){
      this.hideNavbar = true;
    }
  }

  platformId(platformId: any) {
    throw new Error('Method not implemented.');
  }

  ngAfterViewInit() {
    this.sidenavService.setSidenav(this.leftSideNav, 'left');
    this.routerSubscription = this.modelRefList?.changes?.subscribe((data: QueryList<MatSidenav>) => {
      if (data.first) {

        this.sidenavService.setSidenav(data.first, 'left');
      }
    })
  }

  ngOnDestroy() {
    // Unsubscribe from router events to prevent memory leaks
    this.routerSubscription?.unsubscribe();
    this.scrollSubscription?.unsubscribe();
  }
}
