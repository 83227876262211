import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { Component, NgZone, OnInit, ChangeDetectorRef, ViewChild, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { environment } from 'src/environments/environment';
import { EventEmitter } from 'stream';

@Component({
  selector: 'menu-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class MenuIntegrationsComponent implements OnInit {

  baseURL: any = environment.LOGO_BASE_URL
  isBrowser: boolean;
  constructor(
    private authService: AuthServiceService,
    private themeService: ThemeService,
    private connectionService: ConnectionService,
    private cd: ChangeDetectorRef,
    public router: Router,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
  }

  integrationsMap :any = {
    list: ["all-apps", "database", "crm", "accounting", "productivity","social media","google workspace","AWS"],
    "all-apps": {
      title:"All Apps",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/platform/aw-bloom-integration-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.INTEGRATIONS}/all-apps`,
      type: 'external-bloom',
      tagline: "Discover a World of Integrated Possibilities"
    },
    "database": {
      title:"Database",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/integration/aw-integration-database-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.INTEGRATIONS}/category-detail/q/__id/database`,
      type: 'external-bloom',
      tagline: "Discover all integrated databases"
    },
    "crm": {
      title:"CRM",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/integration/aw-integration-crm-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.INTEGRATIONS}/category-detail/q/__id/crm`,
      type: 'external-bloom',
      tagline: "Discover all integrated CRM systems"
    },
    "accounting": {
      title:"Accounting",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/integration/aw-integration-accounting-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.INTEGRATIONS}/category-detail/q/__id/accounting`,
      type: 'external-bloom',
      tagline: "Discover all integrated Accounting Apps"
    },
    "productivity": {
      title:"Productivity",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/integration/aw-integration-productivity-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.INTEGRATIONS}/category-detail/q/__id/productivity`,
      type: 'external-bloom',
      tagline: "Discover all integrated Productivity Apps"
    },
    "social media": {
      title:"Social Media",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/integration/aw-integration-socialmedia-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.INTEGRATIONS}/category-detail/q/__id/social_media`,
      type: 'external-bloom',
      tagline: "Seamlessly integrate and manage all your social media platforms"
    },
    "google workspace": {
      title:"Google Workspace",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/integration/aw-integration-google-workspace-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.INTEGRATIONS}/category-detail/q/__id/google_workspace`,
      type: 'external-bloom',
      tagline: "Connect and optimize your workflows with Google Workspace tools"
    },
    "AWS": {
      title:"Amazon Web Services",
      logo:'https://storage.googleapis.com/jivrus-web-images/products/appiworks/bloom/integration/aw-integration-aws-logo.png',
      link: `${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.INTEGRATIONS}/category-detail/q/__id/aws`,
      type: 'external-bloom',
      tagline: "Leverage the power of AWS for robust cloud solutions and integrations"
    }
  }

  ngOnInit(): void {

  }

  navigateToIntegration(app){
    window.open(`${app.link}`, "_blank");
  }


  openConnections(){
    this.themeService.closeMenu.next(null)
  }
}
