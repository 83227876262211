import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeFormatConfigComponent } from './date-time-format-config.component';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import { WidgetsModule } from 'src/app/bloom/widgets/widgets.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [DateTimeFormatConfigComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatSelectModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    WidgetsModule,
    MatSlideToggleModule
  ],
  exports: [DateTimeFormatConfigComponent]
})
export class DateTimeFormatConfigModule { }
