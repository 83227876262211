<!-- <div>
    <div fxLayout="row">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="90">
            <div fxLayout="row">
                <h1 style="margin-bottom: 0px;"><b>List of workspaces</b></h1>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>
    <br/>

    <div fxLayout="row"  >
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <mat-card   class="list-card-head" fxLayout="row wrap" 
                 layout-wrap fxLayoutAlign="start center" 
                   style="word-break: break-all;width: 100%" >
                <div fxLayout="column wrap" style="text-align: center;word-break: break-word;" fxFlex="5">
                    #
                 </div>
                 <div fxFlex="5" fxLayout="column wrap" style="word-break: break-word;">
                   
                    </div>
                <div fxFlex="50" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    Workspace Name
                </div>
                <div fxFlex="30" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    Action
                </div>
                <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                
                </div>
              </mat-card>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>

    <div fxLayout="row" *ngIf="connectionService.workSpaces.length > 0" >
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <div   *ngFor="let workspace of connectionService.workSpaces;let i = index">
                <mat-card class="list-card" fxLayout="row wrap" 
                    layout-wrap fxLayoutAlign="start center" 
                    style="word-break: break-all;width: 100%" >

                    <div fxLayout="column wrap" style="text-align: center;word-break: break-word;" fxFlex="5">
                        {{i + 1}}
                    </div>
                    <div fxFlex="5" fxLayout="column wrap" style="word-break: break-word;">
                        
                    </div>
                    <div fxFlex="50" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                        {{workspace.name}} &nbsp;&nbsp;
                    </div>
                    <div matTooltip="Status" fxFlex="30" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                        <button (click)="selectedWorkspace(workspace)">Select</button>
                    </div>
              </mat-card>
            </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>

</div> -->
<h1 style="margin-bottom: 0px;"><b>List of workspaces</b></h1><br>
<div style="width: 100%;">
    <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Select Workspace</mat-label>
    <mat-select>
        <mat-option *ngFor="let workspace of connectionService.workSpaces;let i = index" [value]="workspace.name" (click)="selectedWorkspace(workspace)">{{workspace.name}}</mat-option>
    </mat-select>
    </mat-form-field>
</div>
