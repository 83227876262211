<!-- <mat-button-toggle-group class="layoutToggle custom-toggle-group" #group="matButtonToggleGroup" (change)="alignmentChanged($event)">
  <mat-button-toggle class="layoutToggleButton custom-toggle" [class.checkedView]="alignment === 'flex-start' ? true : false" value="flex-start" aria-label="Text align left" matTooltip="Align left">
    <mat-icon class="layoutIcon">format_align_left</mat-icon>
  </mat-button-toggle>
  <mat-button-toggle class="layoutToggleButton custom-toggle" [class.checkedView]="alignment === 'center' ? true : false" value="center" aria-label="Text align center" matTooltip="Align center">
    <mat-icon class="layoutIcon">format_align_center</mat-icon>
  </mat-button-toggle>
  <mat-button-toggle class="layoutToggleButton custom-toggle" [class.checkedView]="alignment === 'flex-end' ? true : false" value="flex-end" aria-label="Text align right" matTooltip="Align right">
    <mat-icon class="layoutIcon">format_align_right</mat-icon>
  </mat-button-toggle>
  <mat-button-toggle *ngIf="!disableSpaceBetween" class="layoutToggleButton custom-toggle" [class.checked]="alignment === 'space-between' ? true : false" value="space-between" aria-label="spaced out" matTooltip="Spaced out">
    <mat-icon class="layoutIcon">horizontal_distribute</mat-icon>
  </mat-button-toggle>
</mat-button-toggle-group>

<button mat-button [matMenuTriggerFor]="fontFamilyMenu" class="font-family-trigger">
  <span [ngSwitch]="alignment">
    <p *ngSwitchCase="'flex-start'">
      <mat-icon class="layoutIcon">format_align_left</mat-icon>
    </p>
    <p *ngSwitchCase="'center'">
      <mat-icon class="layoutIcon">format_align_center</mat-icon>
    </p>
    <p *ngSwitchCase="'flex-end'">
      <mat-icon class="layoutIcon">format_align_right</mat-icon>
    </p>
    <p *ngSwitchCase="'space-between'">
      <mat-icon class="layoutIcon">horizontal_distribute</mat-icon>
    </p>
  </span>
</button>

<mat-menu #fontFamilyMenu="matMenu">
  <button mat-menu-item (click)="fontStyleChanged(font)">
    {{ font }}
  </button>
</mat-menu> -->

<button mat-button [matMenuTriggerFor]="indentationMenu" matTooltip="Align and indent" class="indentation-trigger">
  <div fxFlex="50" fxLayoutAlign="center center"><mat-icon>{{ iconMap[alignment] }}</mat-icon></div>
  <div fxFlex="50"><mat-icon fxLayoutAlign="center center">arrow_drop_down</mat-icon></div>
  <!-- <mat-icon>{{ iconMap[alignment] }}</mat-icon> -->
</button>

<mat-menu #indentationMenu="matMenu">
  <button mat-menu-item (click)="alignmentChanged('flex-start')">
    <mat-icon>{{iconMap['flex-start']}}</mat-icon>
  </button>
  <button mat-menu-item (click)="alignmentChanged('center')">
    <mat-icon>{{iconMap['center']}}</mat-icon>
  </button>
  <button mat-menu-item (click)="alignmentChanged('flex-end')">
    <mat-icon>{{iconMap['flex-end']}}</mat-icon>
  </button>
  <button mat-menu-item (click)="alignmentChanged('space-between')">
    <mat-icon>{{iconMap['space-between']}}</mat-icon>
  </button>
</mat-menu>

