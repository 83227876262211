import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { ExpressionUtility } from 'src/app/shared/built-in-expression/expressionUtility';
import { MetaService } from '../../services/meta-service';
import { PageService } from '../../services/page-service.service';
import { ActionServiceUtility } from './ActionServiceUtility';
import { BaseAction } from './BaseAction';
import { FlowService } from 'src/app/flow/flow.service';
import { ContextDataService } from 'src/app/shared/services/context-data.service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';

@Injectable({
  providedIn: 'root',
})
export class FlowAction extends BaseAction {
  previousUrl: string;
  constructor(
    public router: Router,
    public pageService: PageService,
    public metaService: MetaService,
    public actionServiceUtility: ActionServiceUtility,
    public expressionUtility: ExpressionUtility,
    public flowService: FlowService,
    public contextDataService: ContextDataService
  ) {
    super();
  }

  getActionDetail() {
    return {
      name: 'Flow',
      id: 'flow',
    };
  }

  getActionConfiguration() {
    let config = {
    };
    return config;
  }

  public async doAction(options?: any, event?: any, widgetOptions?: any) {
    let actionMap = options.actionMap;
    let onMouseEvent = options.event;
    if(!this.actionServiceUtility.isEventMatch(onMouseEvent, event)) return;
    console.log('options in doactions flow', options);
    console.log('event in doactions', event);

    let pageModel:any = JSON.parse(JSON.stringify(this.pageService.getPageModel()))
    pageModel = this.expressionUtility.resolvePageModelExpressions(pageModel);
    pageModel = await this.contextDataService.getContextData(pageModel);
    console.log("[Flow construct payload()] pageModel", pageModel)

    let te = new TemplateEngine();

    let inputParams = actionMap?.inputParams;

    if(inputParams) {
      let convertedData = this.actionServiceUtility.convertDataWithDots(pageModel);
      inputParams = te.fillAny(inputParams, convertedData)
    }

    let trigger = actionMap?.flow?.trigger;
    let queryString;
    if(trigger?.query_data?.length){
        queryString = trigger?.query_data?.map(param => {
          return `${param.__id}=${encodeURIComponent(inputParams[param.__id])}`;
        }).join('&');
    }

    let method = trigger?.method || "POST";

    console.log("[Flow construct payload()] inputParams", inputParams)

    let flowResults = await this.flowService.executeFlow(actionMap.flow._id, inputParams, method?.toLowerCase(), queryString);
    let data = flowResults?.result?.steps[flowResults.result.steps.length - 1]?.result || [];
    console.log('flowResults', data);
    if(actionMap?.useOutput){
      this.metaService.pageMeta.subscribe(pageMeta => {
        console.log("pageMeta", pageMeta)
        pageMeta.panels.forEach(panelMeta => {
          if(panelMeta.id == actionMap?.panel?.id){
            let panel = panelMeta;
            console.log("panel", panel);
            panel.customData = data;
            this.pageService.customDataSource.next(panel);
          }
        })

      })
    }
  }


}
