<div *ngFor="let paramId of selectedExpression.parameters.paramIds">
  <div>
    Choose value(s) for {{ selectedExpression.parameters[paramId].name }}
  </div>

  <div
    *ngFor="let param of paramsData[paramId].currentValues; let i = index"
    fxLayoutAlign="space-around center" fxLayoutGap=".5rem"
  >
    <!-- serial number -->
    <div fxFlex="5">
      {{ i + 1 }}
    </div>

    <div fxFlex="80" fxLayoutAlign="flex-start center" fxLayoutGap=".5rem">
      <!-- value input -->
      <app-widget-wrapper
        [ngClass]="{'disabled': param.dynamic}"
        fxFlex="30"
        [widgetMeta]="param.widget"
        [builderMode]="false"
        (userInputReceived)="expressionValueReceived($event, paramId, i)"
      ></app-widget-wrapper>

      <!-- value type input -->
      <app-widget-wrapper
        *ngIf="param.typeSelectionWidget && param.dynamicInputWidget"
        fxFlex="30"
        [widgetMeta]="param.typeSelectionWidget"
        [builderMode]="false"
        (userInputReceived)="valueTypeChanged($event, paramId, i)"
      ></app-widget-wrapper>

      <!-- dynamic value input -->
      <app-widget-wrapper
        *ngIf="param.typeSelectionWidget && param.dynamicInputWidget && param.dynamic"
        fxFlex="30"
        [widgetMeta]="param.dynamicInputWidget"
        [builderMode]="false"
        (userInputReceived)="dynamicValueSelected($event, paramId, i)"
      ></app-widget-wrapper>
    </div>

    <!-- delete icon -->
    <div fxFlex="5">
      <button
        *ngIf="(paramsData[paramId].currentValues.length - 1) >= paramsData[paramId].min"
        (click)="removeItem(paramId, i)"
        mat-icon-button
      >
        <mat-icon style="color: #444; cursor: pointer">delete</mat-icon>
      </button>
    </div>
  </div>

  <div>
    <button
      *ngIf="paramsData[paramId].max == -1 || (paramsData[paramId].max !== -1 && paramsData[paramId].currentValues < paramsData[paramId].max)"
      mat-stroked-button
      (click)="addParamItem(paramId)"
    >
      New {{ selectedExpression.parameters[paramId].name }}
      <mat-icon>add</mat-icon>
    </button>
  </div>

</div>
