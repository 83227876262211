import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetDialogComponent } from './widget.dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragnDropFileModule } from '../../dropfile/dragndropfile.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ChartDataConfigModule } from 'src/app/bloom/widget-configuration/chart-data-config/chart-data-config.module';
import { AppFilterConfigModule } from '../../app-filter-config/app-filter-config.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    DragnDropFileModule,
    MatAutocompleteModule,
    SpinnerModule,
    MatProgressSpinnerModule,
    ChartDataConfigModule,
    AppFilterConfigModule
  ],
  declarations: [
    WidgetDialogComponent
  ],
  exports: [
    WidgetDialogComponent
  ]
})
export class WidgetDialogModule { }
