import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { AdminService } from 'src/app/modules/admin/admin.service';


@Component({
  selector: 'app-widget-dialog',
  templateUrl: './widget.dialog.html',
  styleUrls: ['./widget.dialog.scss']
})
export class WidgetDialogComponent implements OnInit {
  filteredPages: any;
  linkError: boolean = false;
  linkErrorMessage: any;
  isReadyToSave: boolean = false;
  spinner: boolean = false;

  //get
  get data() { return this._data; }


  selectPageControl = new UntypedFormControl();
  pageNameList: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private dialogRef: MatDialogRef<WidgetDialogComponent>,
    private adminService: AdminService,
    private metaService: MetaService
  ) {
    console.log(this._data.widgetConfig);
    console.log(this._data.widgetConfig.props);
  }

  ngOnInit(): void {
    console.log("config", this.data);
    this.metaService.get_page_structure.subscribe(page_structure => {
      page_structure.pages.forEach(code => {
        if(page_structure[code]?.name){
          this.pageNameList.push(page_structure[code].name)
        }
      })
    })
    this.filteredPages = this.selectPageControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this.pageNameList.filter(pageName => pageName.toLowerCase().includes(value.toLowerCase())))
    );
    console.log(this.filteredPages)
  }

  async imageSelectionChange(event: any, id: string) {
    console.log(event);
    if(event){
      this.spinner = true;
      await this.adminService.fileUpload(event)
      .then((res: any) => {
        console.log("response", res)
        this.data.widgetConfig[id].value = res;
        this.isReadyToSave = true;
      })
      .catch((e) => {
        console.log("responseError", e)
      });
    }else{
      console.log(this.spinner)
      this.data.widgetConfig[id].value = null;
      this.isReadyToSave = false;
    }
    this.spinner = false;
    console.log(this.spinner)
  }

  linkPageSelected(event, id: string){
    console.log("page selected",event);
    this.linkInputReceived(event.option.value, id)
  }

  linkInputReceived(linkInput, id: string){
    // this.isReadyToSave = false;
    // console.log(linkInput, id)
    // console.log(this.pageNameList)
    // console.log(this.pageNameList.includes(linkInput))
    if(id == 'linkUrl'){
      if(!this.pageNameList.includes(linkInput)){
        // this.isReadyToSave = false;
        if(!this.isValidUrl(linkInput)){
          this.linkError = true;
          this.linkErrorMessage = 'please give a valid and complete URL';
          this.isReadyToSave = false;
        }else{
          this.linkError = false;
          this.isReadyToSave = true;
          this.data.widgetConfig[id].value = linkInput;
        }
      }
      else{
        this.linkError = false;
        this.isReadyToSave = true;
        this.data.widgetConfig[id].value = linkInput;
      }
    }else if(id == 'url' || id == 'src'){
      if(!this.isValidUrl(linkInput)){
        this.isReadyToSave = false;
        this.linkError = true;
        this.linkErrorMessage = 'please give a valid and complete URL';
      }else{
        this.linkError = false;
        this.isReadyToSave = true;
        this.data.widgetConfig[id].value = linkInput;
      }
    }
  }

  newChartDataSource(data){
    // this.data.widgetConfig
    console.log("new data source", data)
    console.log("widgetConfig", this.data.widgetConfig)
    this.data.widgetConfig = data.config
    this.isReadyToSave = true
  }

  newAppFilterList(data){
    // this.data.widgetConfig
    this.data.widgetConfig = data.config
    this.isReadyToSave = true
  }

  isValidUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  closeDialog(){
    console.log("closing dialog with data", this.data.widgetConfig)
    this.dialogRef.close(this.data.widgetConfig || null)
  }

}
