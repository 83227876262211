import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { SpinnerService } from '../spinner.service';

@Component({
  selector: 'app-ball-atom-spinner',
  templateUrl: './ball-atom-spinner.component.html',
  styleUrls: ['./ball-atom-spinner.component.scss'],
})
export class BallAtomSpinnerComponent implements OnInit {
  @Input() size = '';
  @Input() center = false;
  @Input() color = this.themeService.isDarkTheme ? '#fff' : '#333';

  constructor(
    public spinnerService: SpinnerService,
    public themeService: ThemeService
  ) {}

  ngOnInit(): void {}
}
