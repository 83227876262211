import { QuillModule } from 'ngx-quill';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// --------- FEATURE MODULES ---------------
import { SharedModule } from '../shared/shared.module';
import { Router, RouterModule, Routes } from '@angular/router';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { SpecializedPanelsModule } from './specialized-panels/specialized-panels.module';
import { DurationPickerModule } from '../shared/duration-picker/duration-picker.module';
import { MccTimerPickerModule } from 'material-community-components/timer-picker';
import { CommonComponentsModule } from './common-components/common-components.module'
import { BoxConfigInputsModule } from './box-config-inputs/box-config-inputs.module';

//-------MATERIAL MODULES------------
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { ClipboardModule } from '@angular/cdk/clipboard';

//------COMPONENTS----------
import { BloomComponent } from './builder/bloom-listing/bloom-listing.component';
// import { BuilderToolbarComponent } from './builder/builder-toolbar/builder-toolbar.component';
import { BuilderComponent } from './builder/builder.component';
// import { BuilderMenuPagesComponent } from './builder/builder-menu-pages/builder-menu-pages.component';
import { BuilderMenuThemesComponent } from './builder/builder-menu-themes/builder-menu-themes.component';
import { BuilderMenuToolsComponent } from './builder/builder-menu-tools/builder-menu-tools.component';
import { PanelComponent } from './page/panel/panel.component';
import { CanvasComponent } from './builder/canvas/canvas.component';
import { PageComponent } from './page/page.component';
import { PanelConfigurationComponent } from './page/panel-configuration/panel-configuration.component';
import { PublishConfigComponent } from './builder/publish-config/publish-config.component'
import { PageDeleteDialogComponent } from './builder/page-delete-dialog/page-delete-dialog.component';
import { PageCreateDialogComponent } from './builder/page-create-dialog/page-create-dialog.component';
import { workspaceModule } from 'src/app/shared/workspace/workspace.module'
// import { PanelSettingsDialog2Component } from './page/panel-settings-dialog2/panel-settings-dialog2.component';
// import { BloomRoutingModule } from './bloom-routing.module';

import { DateTimePickerModule } from '../shared/datetimepicker/datetimepicker.module';
import { DragnDropFileModule } from '../shared/dropfile/dragndropfile.module';

// import Quill from 'quill';

import { WidgetsModule } from './widgets/widgets.module';
import { BloomCollaborationComponent } from './builder/bloom-collaboration/bloom-collaboration.component';
import { BloomMemberComponent } from './builder/bloom-member/bloom-member.component';
import { CreateBloomModule } from '../shared/create-bloom/create-bloom.module';
import { ResizeService } from './page/panel/service/resize.service';
import { WidgetWrapperComponent } from './widget-wrapper/widget-wrapper.component';
import { BloomErrorDiologComponent } from './builder/bloom-error-diolog/bloom-error-diolog.component';

import { RoleViewModule } from '../shared/security/config/role/role-view/role-view.module';
import { PrivilegeViewModule } from '../shared/security/config/privilege/privilege-view/privilege-view.module';
import { WidgetBuilderModule } from './widget-builder/widget-builder.module';
import { ListPanelModule } from './specialized-panels/list-panel/list-panel.module';
import { StarchBaseModule } from '../starch/starch-base/starch-base.module';
import { StarchBasePanelPopupModule } from '../starch/starch-base-panel-popup/starch-base-panel-popup.module';
import { BaseSelectionModule } from '../shared/base-selection/base-selection.module';
import { ConnectionPanelListModule } from '../connection-panel/connection-panel-list/connection-panel-list.module';
import { DisplayBannerModule } from '../shared/display-banner/display-banner.module';
import { ParameterInputsModule } from '../shared/parameter-inputs/parameter-inputs.module';
import { NewConnectionModule } from '../modules/organization/connection/new-connection/new-connection.module';
import { WidgetsWrapperModule } from './widget-wrapper/widget-wrapper.module';
import { CollectTemplateDataComponent } from './builder/publish-config/collect-template-data/collect-template-data.component';
import { autocompleteModule } from '../shared/mat-autocomplete/mat-autocomplete.module';
import { DeleteDialogModule } from '../shared/delete-dialog/delete-dialog.module';
import { CommonMemberModule } from '../shared/common-member/common-member.module';
import { ResourceCollaborationModule } from '../shared/resource-collaboration/resource-collaboration.module';
import { BuilderToolbarModule } from './builder/builder-toolbar/builder-toolbar.module';
import { BuilderMenuToolsModule } from './builder/builder-menu-tools/builder-menu-tools.module';
import { PanelModule } from './page/panel/panel.module';
import { TemplateDataCollectionModule } from './builder/publish-config/collect-template-data/template-data-collection.module';
import { BloomListingModule } from './builder/bloom-listing/bloom-listing.module';
import { BloomPublishDialogModule } from './builder/publish-config/bloom-publish-dialog.module';
import { BuilderMenuPagesModule } from './builder/builder-menu-pages/builder-menu-pages.module';
import { PageCreateDialogModule } from './builder/page-create-dialog/page-create-dialog.module';
import { FieldPickerModule } from '../shared/field-picker/field-picker.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { WidgetConfigurationModule } from "./widget-configuration/widget-configuration.module";
import { NgxColorsModule } from 'ngx-colors';
import { FormsModule } from '@angular/forms';
import { PreviewDialogModule } from '../modules/viewer/preview-dialog/preview-dialog.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

// const font = Quill.import('formats/font');
// font.whitelist = ['mirza', 'roboto', 'aref', 'serif', 'sansserif', 'monospace'];
// Quill.register(font, true);

// const Size = Quill.import('attributors/style/size');
// Size.whitelist = [
//   '10px',
//   '12px',
//   '14px',
//   '16px',
//   '18px',
//   '20px',
//   '22px',
//   '24px',
//   '30px',
// ];
// Quill.register(Size, true);

@NgModule({
    declarations: [
        // BloomComponent,
        // BuilderToolbarComponent,
        BuilderComponent,
        // BuilderMenuPagesComponent,
        BuilderMenuThemesComponent,
        // BuilderMenuToolsComponent,
        // PanelComponent,
        PageComponent,
        CanvasComponent,
        PanelConfigurationComponent,
        // PublishConfigComponent,
        BloomCollaborationComponent,
        BloomMemberComponent,
        // PageCreateDialogComponent,
        PageDeleteDialogComponent,
        // WidgetWrapperComponent,
        BloomErrorDiologComponent,
        // CollectTemplateDataComponent
    ],
    imports: [
    // workspaceModule,
    CommonModule,
    CommonMemberModule,
    ResourceCollaborationModule,
    SharedModule,
    DragDropModule,
    FlexLayoutModule,
    MatGridListModule,
    MatButtonModule,
    MatToolbarModule,
    MatTabsModule,
    MatDividerModule,
    MatListModule,
    MatSidenavModule,
    RouterModule,
    EditorModule,
    ClipboardModule,
    QuillModule.forRoot(),
    // BloomRoutingModule,
    SpinnerModule,
    DurationPickerModule,
    MccTimerPickerModule,
    DateTimePickerModule,
    // DragnDropFileModule,
    // SpecializedPanelsModule,
    BoxConfigInputsModule,
    // WidgetsModule,
    // WidgetBuilderModule,
    CommonComponentsModule,
    CreateBloomModule,
    RoleViewModule,
    PrivilegeViewModule,
    // WidgetsModule,
    // ListPanelModule,
    StarchBaseModule,
    // ConnectionPanelListModule,
    // DisplayBannerModule,
    ParameterInputsModule,
    // WidgetsWrapperModule,
    DeleteDialogModule,
    BuilderToolbarModule,
    BuilderMenuToolsModule,
    PanelModule,
    TemplateDataCollectionModule,
    BloomListingModule,
    DragnDropFileModule,
    BloomPublishDialogModule,
    BuilderMenuPagesModule,
    PageCreateDialogModule,
    FieldPickerModule,
    // BuilderToolbarModule
    // autocompleteModule
    // NewConnectionModule
    WidgetConfigurationModule,
    NgxColorsModule,
    FormsModule,
    PreviewDialogModule,
    ScrollingModule
],
    exports: [
        BuilderComponent,
        PanelComponent,
        // BloomComponent,
        // BuilderToolbarComponent,
        BuilderComponent,
        // BuilderMenuPagesComponent,
        BuilderMenuThemesComponent,
        // BuilderMenuToolsComponent,
        PageComponent,
        CanvasComponent,
        // PublishConfigComponent,
        // CollectTemplateDataComponent
        // WidgetWrapperComponent
    ]
})
export class BloomWithoutRoutingModule { }
