import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { MatDialog } from '@angular/material/dialog';
import { AppsPopupComponent } from '../apps-popup/apps-popup.component';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { BoxCache } from 'src/app/core/boxInstances/BoxCache';

@Component({
  selector: 'app-app-selection',
  templateUrl: './app-selection.component.html',
  styleUrls: ['./app-selection.component.scss']
})
export class AppSelectionComponent implements OnInit {
  boxSearchObj: any;

  constructor(
    public con: ConnectionService,
    private dialog: MatDialog,
    // private spinner: SpinnerService
  ) { }

  @Input() config: any;
  @Input() boxType:any;
  @Input() onlyDisplay: boolean = false;
  @Input() "existingBox": any;
  @Output() "selectedBox" = new EventEmitter<any>();

  appListingACFrom = new UntypedFormControl();
  appListingOptions: any;
  spinner: boolean = false;
  supportStarch: boolean = false;
  isEdit:boolean = false;
  // boxesReady: boolean = false

  boxes: any = [];
  box: any = {};
  starchMap: any = {
    name: "Starch",
    __id: "starch",
    logo: "database"
  }

  async ngOnInit() {
    console.log("[APP SELECTION]")
    this.spinner = true;
    console.log(this.existingBox);
    if(this.config?.isSupportStarch) this.supportStarch = true;

    if(this.existingBox && typeof this.existingBox === "string"){
      console.log("[APP SELECTION] string input", typeof this.existingBox === "string")
      if(this.existingBox == 'starch'){
        this.box = this.starchMap;
      } else if (BoxCache.boxObject[this.existingBox]) {
        this.box = BoxCache.boxObject[this.existingBox];
      } else {
        let box = await this.con.getBox(this.existingBox);
        this.box = box;
      }
      this.selectedBox.emit(this.box);
      this.appListingACFrom.setValue(this.box);
    } else if(this.existingBox && Object instanceof this.existingBox){
      console.log("[APP SELECTION] string Object", Object instanceof this.existingBox)
      this.selectedBox.emit(this.box);
      this.appListingACFrom.setValue(this.box);
    }

    this.boxSearchObj = {page: "1|100"};
    if(this.config?.filter){
      this.boxSearchObj.filter = this.config?.filter;
    }

    if(this.isEdit){
      let boxes:any = await this.con.getBoxes(this.boxSearchObj);
      this.boxes = boxes?.data;
      this.setFilterOptions();
    }

    this.spinner = false;
  }

  clearSelection(e){
    e.stopPropagation()
    this.appListingACFrom.disable();
    this.appListingACFrom.enable();
    this.appListingACFrom.patchValue("");
    //pass empty string when clearing the app.
    this.selectedBox.emit('');
  }

  async ngAfterViewInit(){
    this.appListingACFrom.valueChanges.pipe(startWith(''),
    map((event: any) => event)).subscribe(async (response) => {
      console.log("response", response)
      this.spinner = true;
      this.boxSearchObj = {
        box_name: this.appListingACFrom.value ? `name%${this.appListingACFrom.value}` : null,//
        page: `1|100`,
      };
      if(this.config?.filter){
        this.boxSearchObj.filter = this.config?.filter;
      }
      if(this.isEdit){
        let boxes:any  = await this.con.getBoxes(this.boxSearchObj);
        this.boxes = boxes?.data;
        this.setFilterOptions();
      }
      this.spinner = false;
    })
  }


  async setFilterOptions() {
    if(!this.isEdit) {
      let boxes:any  = await this.con.getBoxes(this.boxSearchObj);
      this.boxes = boxes?.data;
    }
    this.isEdit = true;
    this.appListingOptions = this.getFilteredOptions(this.appListingACFrom, this.boxes, "name")
  }

  getFilteredOptions(form: UntypedFormControl, values:any, key?:any){
    return form.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, values, key))
      );
  }

  displayFunction(subject) {
    if(!subject) return undefined;
    return subject.name;
  }

  async appSelected(box: any){
    this.box = box;
    this.selectedBox.emit(box);
  }

  async appsPopup(){
    var dialog = this.dialog.open(AppsPopupComponent, {
      // maxWidth: '500px',
      maxHeight: '500px',
      data: this.config
    });
    var diologResult = await dialog.afterClosed().toPromise();
    this.appListingACFrom.setValue(diologResult);
    this.selectedBox.emit(diologResult);
  }

  private _filter(value: string, array?:any, key?:any): string[] {
    value = value ? value : ""
    if(typeof value != "string") return;
    const filterValue = value.toLowerCase();
    if(key){
      return array.filter(option => option[key].toLowerCase().includes(filterValue));
    } else {
      return array.filter(option => option.toLowerCase().includes(filterValue));
    }
  }

}
