import { environment } from './../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenUtil } from '../../core/services/TokenUtil.service';
import {
  Inject,
  Injectable,
  NgZone,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { AuthServiceService } from './auth-service.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { Subject } from 'rxjs';
import * as Moment from 'moment-timezone';
import { HttpCacheService } from 'src/app/core/services/HttpCacheService';

class theme {
  theme: string = '';
  themeMode: boolean = false;
  direction: string = 'ltr';
  dateFormat: string = 'MM/dd/yyyy';
}

class lcoale {
  currency?: string;
  timeZone?: string;
  dateFormat?: string;
  timeFormat?: string;
  decimalFormat?: string;
  digitFormat?: string
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  isDarkTheme = false;
  getCurrenttheme = '';
  textDirection = 'ltr';
  currentLocale: lcoale = {
    timeZone: Moment.tz.guess()
  };
  settings_Id: string;
  spinner: boolean;
  private renderer: Renderer2;
  constructor(
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
    public authService: AuthServiceService,
    private http: HttpClient,
    private TokenUtil: TokenUtil,
    public connectionService: ConnectionService,
    private httpCs: HttpCacheService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  public closeMenu = new Subject<string>();
  public closeMenu$ = this.closeMenu.asObservable();

  setDateFormat(dateFormat: string) {
    this.currentLocale.dateFormat = dateFormat;
    // Save the theme settings with updated date format in the database
    this.saveTheme(this.currentLocale);
  }
  setDarkTheme(isDarkTheme: boolean, Theme?: any) {
    this.isDarkTheme = isDarkTheme;
    if (!Theme) {
      if (isDarkTheme) {
        this.setTheme('aw-brand-dark');
        document.body.classList.toggle('dark-mode');
      } else {
        this.setTheme('aw-brand');
      }
    } else {
      if (isDarkTheme) {
        document.body.classList.toggle('dark-mode');
        this.setTheme(Theme, true);
      } else {
        this.setTheme(Theme);
      }
    }
  }

  isDefaultTheme(){
    let bool = true;
    if(this.getCurrenttheme && !["aw-brand", "aw-brand-dark"].includes(this.getCurrenttheme)) bool = false;
    return bool;
  }

  setTheme(Theme: any, isDarkTheme?: boolean) {
    this.getCurrenttheme = Theme;
    if (Theme.indexOf('dark') != -1) {
      this.isDarkTheme = true;
    } else {
      this.isDarkTheme = false;
    }
    if (isDarkTheme) {
      Theme = Theme + '-dark';
      this.setTheme(Theme);
    }
    this.getCurrenttheme = Theme;
    this.ngZone.run(() => {
      this.renderer.setAttribute(this.document.body, 'class', Theme);
    });
  }

  resetTheme(isDarkTheme?: boolean) {
    this.getCurrenttheme = '';
    if (isDarkTheme) {
      this.setTheme('indigo-pink-dark');
    } else {
      this.setTheme('');
    }
  }

  enableDirMode(textDirection) {
    // console.log("Text direction is set to == ", textDirection);
    this.textDirection = textDirection;
    this.ngZone.run(() => {
      this.renderer.setAttribute(this.document.body, 'dir', textDirection);
    });
  }

  //get Theme From database
  async getExistingTheme(uid: any) {

    if(!uid) return null;
    if (this.connectionService.getPreAuthenticatedToken()) {
      let url = `${environment.SERVER_BASE_URL}/settings/${uid}`;
      console.log(url);

      let headers = {
        'Authorization':  this.connectionService.setWorkspaceToken()
      }

      let result = null
      try{
        result = await this.httpCs.get(url, { headers: headers }, {keyPrefix: "settings_",isLocalStorage: true});
        // result = await this.http.get(url, { headers: headers }).toPromise();
        console.log("existing settings", result)
      } catch(e){
        console.log("error on geting settings", e)
      }
      return result;
    } else {
      //TODO
      console.log('Please Login/Sign Up First');
    }
  }

  //save Theme in database
  async saveTheme(localeObj: any) {
    let url = '';
    const headers = new HttpHeaders().set(
      'Authorization',
      'PreAuthenticatedToken ' + this.connectionService.preAuthenticatedToken
    );
    let themeObj: theme = {
      theme: this.getCurrenttheme,
      themeMode: this.isDarkTheme,
      direction: this.textDirection,
      dateFormat: ''
    };
    let payload: any = {
    };
    if (this.settings_Id != undefined) {
      url = `${environment.SERVER_BASE_URL}/settings/${this.settings_Id}`;
      payload = {
        _id: this.settings_Id,
        user_id: this.authService.profile._id,
        themeSetting: themeObj,
        localeSetting: localeObj,
      };
      let promise = this.http.put(url, payload, { headers }).toPromise();
      await promise.then(
        (res) => {
          console.log(res);
          this.currentLocale = localeObj;
          if(!this.currentLocale.timeZone) this.currentLocale.timeZone = Moment.tz.guess();
          this.httpCs.deleteCacheBasedOnKeyPrefix("settings_");
        },
        (err) => {
          console.log(err);

        }
      );
    } else {
      url = `${environment.SERVER_BASE_URL}/settings`;
      payload = {
        user_id: this.authService.profile._id,
        themeSetting: themeObj,
        localeSetting: localeObj,
      };
      let promise = this.http.post(url, payload, { headers }).toPromise();
      await promise.then(
        (res: any) => {
          console.log(res);
          this.settings_Id = res?.data[0]._id
          this.currentLocale = localeObj;
          if(!this.currentLocale.timeZone) this.currentLocale.timeZone = Moment.tz.guess();
          this.httpCs.deleteCacheBasedOnKeyPrefix("settings_");
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  async getAutorizationTokenHeader() {
    var result = { Authorization: '' };
    var token = await this.TokenUtil.getStatelessToken();
    result.Authorization = `Bearer ${token}`;
    return result;
  }

  loadTheme() {
    console.log('theme css loaded');
    const existingLinkEl = this.document.getElementById('material-theme');
    if (!existingLinkEl) {
      const headEl = this.document.getElementsByTagName('head')[0];
      const newLinkEl = this.document.createElement('link');
      newLinkEl.id = 'material-theme';
      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = "material-theme.css"
      headEl.appendChild(newLinkEl);
    }
  }
}
