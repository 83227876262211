

<!-- <div class="header" [class.disabled] = "disabled" fxLayout="row" fxLayoutAlign="space-between start">
  <div mat-dialog-title>Publish Flow</div>
  <button #closeButton mat-button mat-dialog-close style="cursor: pointer;">
    <mat-icon>close</mat-icon>
  </button>
</div> -->

<h2 mat-dialog-title [class.disabled] = "disabled">Publish Flow</h2>

<mat-dialog-content>
  <spinner *ngIf="publishSpinner" [selfSpin]="true" [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

  <div *ngIf="ready" fxLayout="column" fxLayoutAlign="center center" style="padding: 1rem">
    <div *ngIf="!publishSuccess" fxLayoutAlign="center center" style="color: #444; font-size: 1.2rem">
      <div>{{ publishedFlow ? "Updates are ready to be published" : "Flow is ready to be published" }}</div>
      <div style="height: 0.5rem"></div>
    </div>

    <div *ngIf="!publishSuccess" fxLayoutAlign="start center">
      <div *ngIf="!isAllStepsValid" style="color: brown;font-size: small;">
         Some steps are not fully configured. Although you can still proceed with publishing, be aware that some steps might not execute as expected. </div>
         <div style="height: 0.5rem"></div>
    </div>

    <div *ngIf="publishSuccess">
      <div fxLayoutAlign="center center" style="color: #444; font-size: 1.2rem">
        Updates are published. &nbsp;&nbsp;<span class="live-blink">Flow is live.</span>
      </div>
      <div style="height: 1rem"></div>
      <button mat-flat-button color="primary" class="smallButton" (click)="navigateToExecutionPage()">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span><mat-icon class="smallIcon" fxLayout="row" fxLayoutAlign="center center" style="margin-right: .3em;">timeline</mat-icon></span>
          <span>Check executions</span>
        </div>
      </button>
    </div>

    <div *ngIf="publishedFlow && !publishHit && publishedFlow.published_at">
      <div style="height: 1rem"></div>
      <div fxLayoutAlign="center center" style="color: #666; font-style:italic">
        {{ "Last published : " + (publishedFlow.published_at ? (publishedFlow.published_at | date:'medium') : "") }}
      </div>
    </div>
  </div>

  <mat-slide-toggle *ngIf="ready" [checked]="isPublishTemplate" style="margin: 1rem" color="primary" (change)="publishAsTemplateChanged($event)">
    Publish as Template
  </mat-slide-toggle>

  <div *ngIf="ready && isPublishTemplate">
    <app-collect-template-data
      [flow]="publishedFlow || flow"
      (emitTemplateData)="templateDataReceived($event)"
    ></app-collect-template-data>
  </div>

  <div *ngIf="!isPublishSupported" fxLayoutAlign="center center" style="color: #444; font-size: 1.2rem">
    <div>Publish not required for older flows. Simply saving your updates will take effect.</div>
    <div style="height: 1rem"></div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" [class.disabled] = "disabled">
  <button mat-raised-button (click)="cancel()">{{ publishSuccess ? 'Close' : 'Cancel' }}</button>
  <button mat-raised-button color="primary" (click)="publishClicked()" [disabled]="publishDisabled || publishSuccess" cdkFocusInitial>{{ 'Publish' }}</button>
</mat-dialog-actions>
