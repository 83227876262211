<div class="wrap" *ngIf="deviceInfo.isSmallSize" style="padding: 5px;">
  <h1 class="heading">Bloom Configuration</h1>
  <div fxLayoutAlign="center" class="subheading" style="color: coral;padding-bottom: 15px;">
    Whoops! It looks like your device's screen may not be a bit cozy for Bloom's big ideas.  </div>
    <div fxLayoutAlign="center" class="subheading" style="padding-bottom: 15px;">
    For the full editing experience, we recommend switching to a larger screen where your creativity can truly bloom.
  </div>
  <div fxLayoutAlign="center" class="subheading" style="padding-bottom: 15px;">
    Happy creating!
  </div>
</div>

<div *ngIf="!hasEditAccess()">
  <h1 class="heading">Bloom Configuration</h1>
  <div fxLayoutAlign="center" class="subheading" style="color: coral;padding-bottom: 15px;">
    Apologies, it appears that you currently lack the necessary permissions to make edits in Bloom. </div>
    <div fxLayoutAlign="center" class="subheading" style="padding-bottom: 15px;">
      Please get in touch with your Bloom administrator to request the necessary permissions.
  <div fxLayoutAlign="center" class="subheading" style="padding-bottom: 15px;">
    Happy creating!
  </div>
</div>
</div>

<div class="builder">

  <!-- builder header -->
  <div class="builder-toolbar" fxHide.xs>
    <app-builder-toolbar [hide]="!hasEditAccess()"></app-builder-toolbar>
  </div>
  <!-- builder body -->
  <div class="builder-body main-content" *ngIf="hasEditAccess()">

    <!-- builder canvas -->
    <div 
      class="builder-canvas" 
      [ngClass.gt-md]="{'builder-canvas-gt-md': true,'canvas-gt-md-full': isSidebarCollapsed}"
      [ngClass.md]="{'builder-canvas-md': true,'canvas-md-full': isSidebarCollapsed}"
      [ngClass.lt-md]="{'builder-canvas-lt-md': true,'canvas-lt-md-full': isSidebarCollapsed}"
    >
      <app-canvas *ngIf="ready && !deviceInfo?.isSmallSize"></app-canvas>
      <!-- <router-outlet></router-outlet> -->
       
    </div>


    
    <!-- ngClass.gt-lg="builder-menu-gt-md" ngClass.md="builder-menu-md" ngClass="builder-menu-lt-md"  -->
    <div 
      id="builder-menuid" style="height: 1px solid red"
      class="sidebar builder-menu"
      [ngClass.lt-md]="{'builder-menu-lt-md': true,'sidebar-lt-md-expanded': !isSidebarCollapsed}"
      [ngClass.md]="{'builder-menu-md': true,'sidebar-md-expanded': !isSidebarCollapsed}"
      [ngClass.gt-md]="{'builder-menu-gt-md': true,'sidebar-gt-md-expanded': !isSidebarCollapsed}" 
      fxHide.xs 
      [class.collapsed]="isSidebarCollapsed"
    >
      <button class="toggle-button" (click)="toggleSidebar()">
        <span *ngIf="isSidebarCollapsed" class="arrow"><mat-icon class="arrow-icon" fxLayoutAlign="center center">chevron_left</mat-icon></span>
        <span *ngIf="!isSidebarCollapsed" class="arrow"><mat-icon class="arrow-icon" fxLayoutAlign="center center">chevron_right</mat-icon></span>
      </button>

      <!-- Sidebar content -->
      <!-- builder menu -->
      <div class="menu-inner" [hidden]="isSidebarCollapsed">
        <!-- <div class="sidebar-overlay" *ngIf="isSidebarCollapsed"></div> -->
        <mat-tab-group mat-stretch-tabs class="tabGroup" #tabGroup>
          <mat-tab mat-align-tabs="center" [label]="'Tools'">
            <!-- <ng-template mat-tab-label class="tools">Tools</ng-template> -->
            <app-builder-menu-tools (createPanel)="createPanel($event)"></app-builder-menu-tools>
          </mat-tab>
          <mat-tab class="pagesTab" label="Pages" mat-align-tabs="center">
            <!-- <ng-template mat-tab-label class="pages">Pages</ng-template> -->
            <app-builder-menu-pages [bloomCode]="bloomCode"></app-builder-menu-pages>
          </mat-tab>
          <mat-tab class="settingsTab" [disabled]="settingsTabDisabled" label="Settings" mat-align-tabs="center">
            <!-- <ng-template mat-tab-label class="settings">Settings</ng-template> -->
            <ng-container *ngIf="!settingsTabDisabled">
              <app-widget-configuration></app-widget-configuration>
            </ng-container>
            
          </mat-tab>
          <!-- <mat-tab label="Themes" mat-align-tabs="center">
            <ng-template mat-tab-label class="themes">Themes</ng-template>
            <app-builder-menu-themes></app-builder-menu-themes>
          </mat-tab> -->
        </mat-tab-group>
      </div>
      
    </div>

  </div>
</div>



<!-- builder header -->

<!-- builder body -->
