<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div fxLayout="column">

  <div fxLayout="row">
    <div *ngFor="let header of columnHeader;let i = index">
      <div fxLayout="column" style="width: 188px;font-weight: bold;padding: 10px;background-color: #a7a7a7;">
          {{header}}
      </div>
    </div>
  </div>

  <div   *ngFor="let attr of existingAttributes;let i = index">
    <div fxLayout="row" style="margin-top: -15px;margin-bottom: -15px;">


    <div *ngFor="let param of attr;let j = index" style="padding-bottom: 0; width: 188px">
      <div fxLayout="column" #container *ngIf="param.key != 'dataType'">
          <app-widget-wrapper
          fxFlex="100"
          [widgetMeta]="param.widget"
          [builderMode]="false"
          [isDisabled]="true"
          ></app-widget-wrapper>
      </div>

      <div fxLayout="column" *ngIf="param.key == 'dataType'" style="height: 100%; margin-left: 8px; width: 180px">
          <datatype-selection style="margin-top: 0.65rem;"
          [existingType]="" [appearance]="'standard'" [isDisabled]="true"></datatype-selection>
      </div>
    </div>
    </div>
  </div>

  <div   *ngFor="let attr of inputAttributes;let i = index">
    <div fxLayout="row" style="margin-top: -15px;margin-bottom: -15px;">


    <div *ngFor="let param of attr;let j = index" style="padding-bottom: 0; width: 188px">
      <div fxLayout="column" #container *ngIf="param.key != 'dataType'">
          <app-widget-wrapper
          fxFlex="100"
          [widgetMeta]="param.widget"
          [builderMode]="false"
          [isDisabled]="param.isDisabled"
          (userInputReceived)="attributeInputReceived($event, param, i)"
          ></app-widget-wrapper>
      </div>

      <div fxLayout="column" *ngIf="param.key == 'dataType'" style="height: 100%; margin-left: 8px; width: 180px">
          <datatype-selection style="margin-top: 0.65rem;"
          (selectedType)="attributeInputReceived($event, param, i)"
          [existingType]="" [appearance]="'standard'"></datatype-selection>
      </div>
    </div>

    <div fxLayout="column">
        <button style="margin-top: 25px;" *ngIf="inputAttributes.length > 1" mat-icon-button color="warn" (click)="removeAttribute(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div>
    <button mat-stroked-button color="primary" (click)="addAttribute()">Add attribute</button>
</div>


