import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';

interface ComponentsEnablementMap {
  filter?: boolean,
  sort?: boolean,
  attributes?: boolean,
  group?: boolean,
  limit: boolean
}

interface ExistingConfig {
  filterConfig?: any,
  sortConfig?: any,
  groupConfig?: any,
  attributesConfig?: any,
  limit?: number
}

interface Query {
  filter?: string,
  sort?: string,
  attributes?: string,
  page?: string,
  group?: string
}

@Component({
  selector: 'query-inputs-component',
  templateUrl: './query-inputs-component.component.html',
  styleUrls: ['./query-inputs-component.component.scss']
})
export class QueryInputsComponent implements OnInit {

  @Input() componentsToShow: ComponentsEnablementMap
  @Input() boxObjectAttributes: any[]
  @Input() existingConfig: ExistingConfig
  @Input() fieldListingMap: any;
  @Input() supportApplyButton: boolean  // if true, shows "apply" button, on click of apply, emits new config. otherwise, emits on every change
  @Input() retainSubComponentOutput: boolean  // if true, component outputs of sort, filter etc will be passed with final output
  @Input() recommendAttributes: boolean // if true, select recommended attributes

  @Output() newConfig = new EventEmitter<any>();

  query: Query = {
    filter: '',
    sort: '',
    attributes: '',
    page: '1|100|100',
    group: ''
  }

  filterOutput: any;
  sortOutput: any;
  attributesOutput: any;
  groupOutput: any;
  limitOutput: any;
  recommended: any;

  constructor(
    private widgetUtilityService: WidgetUtilityService,
  ) { }

  ngOnInit(): void {
    // console.log("componentsToShow", this.componentsToShow)

    if(this.recommendAttributes) {
      this.recommended = this.widgetUtilityService.intelliSenseAttribute(this.boxObjectAttributes, 10);
      this.recommended.forEach(attr => {
        attr['isColumnSelected'] = true;
      });
    }

    // this.boxObjectAttributes.forEach(attr => {
    //   if(reccommended.find(rAttr => rAttr.__id == attr.__id)) attr['isColumnSelected'] = true
    //   else attr['isColumnSelected'] = false
    // });
    // console.log("===> existingConfig", JSON.parse(JSON.stringify(this.existingConfig)))
    // this.emitQuery()
    if(this.existingConfig.limit) {
      this.limitOutput = this.existingConfig.limit
      this.query.page = `1|${this.limitOutput}|100`
    }
    this.emitQuery()
  }


  filtersReceived(filter: any){
    this.filterOutput = filter
    console.log("filtersReceived", filter)
    let fs: any = ''  // fs for filterString
    filter.filterItems.forEach(f => {
      if (f.value) {
        let attribute = f.parameter ? f.parameter : f.attribute ? f.attribute : f.attributeId;
        fs = fs + `${attribute}${f?.operator || "=" }${f.value || ''}|${f.dataType || 'string'},`
      }
    });
    fs = fs ? fs.substring(0, fs.length - 1) : '';
    console.log("filterString", fs)
    this.query.filter = fs
    if(!this.supportApplyButton) this.emitQuery()
  }


  sortReceived(sort: any){
    this.sortOutput = sort
    console.log("sortReceived", sort)
    let sortString = ''
    if(sort.sortEnabled && sort.sortAttributes?.length) {
      sort.sortAttributes.forEach((sortBy, i) => {
        if(sortBy.attribute){
          if(i > 0) sortString += `,`
          sortString += `${sortBy.attribute}=${sortBy.order}`
        }
      });
    }
    console.log("sortString", sortString)
    this.query.sort = sortString
    if(!this.supportApplyButton) this.emitQuery()
  }


  groupReceived(group: any){
    console.log("groupReceived", group)
    this.groupOutput = group
    let groupString = ''
    group?.attributes?.forEach((attr, i) => {
      if(i > 0) groupString += ','
      groupString += attr.__id
    })
    console.log("groupString", groupString)
    this.query.group = groupString
    if(!this.supportApplyButton) this.emitQuery()
  }


  attributesSelectionReceived(attributes: any){
    this.attributesOutput = attributes
    console.log("attributeSelectionReceived", attributes)
    let attrString: string = ''
    attributes.forEach((attr, i) => {
      if(i > 0) attrString += ','
      attrString += attr.__id
    });
    console.log("attributesString", attrString)
    this.query.attributes = attrString
    if(!this.supportApplyButton) this.emitQuery()
  }


  limitReceived(limitEvent: any){
    console.log("limit received", limitEvent.srcElement.value)
    this.limitOutput = limitEvent.srcElement.value
    // let limit = limitEvent.srcElement.value
    this.limitOutput = this.limitOutput == '' ? 10 : this.limitOutput
    let pageString: string = `1|${this.limitOutput}|100`
    console.log("pageString", pageString)
    this.query.page = pageString
    if(!this.supportApplyButton) this.emitQuery()
  }

  emitQuery(){
    // console.log("query ready to emit", this.query)
    if(!this.query.sort) delete this.query.sort
    if(!this.query.group) delete this.query.group
    if(!this.query.attributes) delete this.query.attributes
    let emitterData = {
      query: this.query
    }
    if(this.retainSubComponentOutput){
      let subCompData: any = {}
      subCompData['filter'] = this.filterOutput,
      subCompData['sort'] = this.sortOutput,
      subCompData['group'] = this.groupOutput,
      subCompData['attributes'] = this.attributesOutput,
      subCompData['limit'] = this.limitOutput || 100
      // this.subComponentsOutput.emit(data)
      emitterData['subComponentOutput'] = subCompData
    }
    console.log("emitterData => ", emitterData)
    this.newConfig.emit(emitterData)
  }

}
