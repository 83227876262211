import { Component, Inject, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { debounceTime, map } from 'rxjs/operators';
const generateName = require("project-name-generator");
const uuid = require('uuid');


@Component({
  selector: 'app-create-bloom-popup',
  templateUrl: './create-bloom-popup.component.html',
  styleUrls: ['./create-bloom-popup.component.scss']
})
export class CreateBloomPopupComponent implements OnInit {


  timeout: any = null;
  bloomMap: any = {};
  sugestBloomCode: any;
  isCodeExists: boolean = false
  spinner:boolean = false;

  formGroup: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.pattern('[a-z0-9\-]*')]),
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)])
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public dataInput: any,
    public dialog: MatDialogRef<CreateBloomPopupComponent>, 
    public metaService: MetaService
  ) {

  
   }

  async ngOnInit() {
    this.sugestBloomCode = await this.checkAndGetRandomCode();
    this.formGroup.get('name').setValue(`My Bloom ${this.getRandomCode()}`);
  }

  onKeySearch(event: any) {
    this.isCodeExists = false
    // clearTimeout(this.timeout);
    // var $this = this;
    // this.timeout = setTimeout(function () {
    //   if (event.keyCode != 13) {
    //     $this.executeListing(event.target.value);
    //   }
    // }, 1000);
  }

  async executeListing(value: string) {
    console.log("value", value);
    let code = this.constructPagePath(value);
    this.formGroup.get("code").setValue(code);
    await this.checkBloomExists(code);
  }

  getRandomCode(){
    let randomCode = uuid.v4().substring(0, 8);
    if(!randomCode){
      randomCode = Date.now()
    }
    return randomCode;
  }

  
  async checkAndGetRandomCode(code?:any){
    this.spinner = true;
    let randomCode
    if(!code){
      randomCode = uuid.v4().substring(0, 8);
      if(!randomCode){
        randomCode = Date.now()
      }
  
      randomCode = `${generateName.generate().dashed}-${randomCode}`;
    } else randomCode = code;
   

    console.log("randomCode", randomCode)
    let result = await this.metaService.getBloomByCode(randomCode);
    console.log("result", result[0]);
    if(result?.length) return this.checkAndGetRandomCode();
    else {
      this.spinner = false;
      this.isCodeExists = false;
      this.sugestBloomCode = randomCode;
      // this.bloomMap.code = this.sugestBloomCode;
      this.formGroup.get('code').setValue(this.sugestBloomCode);
      return randomCode;
    } 
    
  }

  async checkBloomExists(code){
    this.spinner = true;
    this.isCodeExists = false;
    let result = await this.metaService.getBloomByCode(code);
    console.log("result", result);
    if(result?.length) this.isCodeExists = true;
    this.spinner = false;
  }

  constructPagePath(code){
    //reference https://quickref.me/convert-a-string-to-url-slug;
    let slugify = (string) => string.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
    return slugify(code);
  }

  async createBloom(){
    console.log("this.formGroup.", this.formGroup.value)
    console.log("code value.", this.formGroup.controls.code.value)

    await this.checkBloomExists(this.formGroup.controls.code.value)
    if (this.isCodeExists) return

    var result = null;
    this.dialog.close(this.formGroup.value);
  }

}
