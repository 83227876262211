import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { BoxService } from './box.service';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    public cs: ConnectionService,
    private bs: BoxService
  ) { }

  async sendSesEmail(mailOptions, data){
    let boxToken = undefined;
    if(environment.SES_CONNECTION_ID) boxToken = await this.cs.getBoxConfigToken(environment.SES_CONNECTION_ID)

    let payload = this.constructPayload(mailOptions, data)
    var boxUrl = `${environment.BOX_URL}/awsses/sendEmail`;
    var result = await this.bs.execute(boxUrl, payload, 'post', boxToken);
    return result;
  }

  constructPayload(mailOptions, data) {
    let te = new TemplateEngine();
    let mailMap = te.fillTemplateObject(mailOptions, data);
    let emailContent = te.fillTemplateObject(this.payloadTemplate(), mailMap)
    emailContent.ToAddresses = this.normalizeEmailContent(emailContent.ToAddresses);
    let payload = {
      parameters: {
        "emailContents": [emailContent]
      }
    }

    return payload;
  }

  normalizeEmailContent(emailContent) {
    // Check if the first element of emailContent is an array
    if (Array.isArray(emailContent) && Array.isArray(emailContent[0])) {
      return emailContent[0];
    }
    return emailContent;
  }

  template(type){
    let templateMap = {
      workspace_invitation: '<p><a title="AppiWorks" href="https://appiworks.com/" target="_blank" rel="noopener"><img src="https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-text-horizontal.png" alt="AppiWorks" width="200" height="67" /></a>&nbsp; &nbsp; &nbsp;</p>\
      <table style="border-color:white;border-collapse: collapse; width: 99.922%; height: 111.777px;" border="1"><tbody><tr style="height: 111.777px;">\
      <td style="width: 13.6137%; height: 111.777px;"><img style="border-radius:50px;margin-top:-40px" src="${profile.image_url}" alt="" width="100" height="100" /></td>\
      <td style="width: 86.3093%; height: 111.777px;"><h2><strong>${profile.first_name} ${profile.last_name} has invited you to join ${workspace.name} on AppiWorks</strong></h2>\
      <p>Join ${profile.first_name} ${profile.last_name}(${profile.email}) to start collaborating visually</p><p>&nbsp;</p>\
      <p><a style="text-decoration: none; display: inline-block; color: #ffffff; padding: 5px; background-color: #4f62cc; border: 1px solid #4F62CC; border-radius: 4px; width: auto; font-family: Arial,Helvetica Neue,Helvetica,sans-serif; text-align: center;cursor:pointer" href="${acceptUrl}" target="_blank" rel="noopener"> <span style="padding-left: 20px; padding-right: 20px; display: inline-block; font-size: 12px; line-height: 26px;"> <strong>Accept</strong></span></a> \
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;\
      <a style="text-decoration: none; display: inline-block; color: #4f62cc; padding: 5px; background-color: #ffffff; border: 1px solid #ffffff; border-radius: 4px; width: auto; font-family: Arial,Helvetica Neue,Helvetica,sans-serif; text-align: center;cursor:pointer" href="${denyUrl}" target="_blank" rel="noopener">\
      <span style="padding-left: 20px; padding-right: 20px; display: inline-block; font-size: 12px; line-height: 26px;"> <strong>Deny </strong></span></a></p>\
      </td></tr></tbody></table><p>&nbsp;</p><hr/><p>AppiWorks is an intelligent integration and automation platform that lets you connect with your favorite applications, and build and automate your business or professional processes.</p>',

      bloom_invitation: '<p><a title="AppiWorks" href="https://appiworks.com/" target="_blank" rel="noopener"><img src="https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-text-horizontal.png" alt="AppiWorks" width="200" height="67" /></a>&nbsp; &nbsp; &nbsp;</p>\
      <table style="border-color:white;border-collapse: collapse; width: 99.922%; height: 111.777px;" border="1"><tbody><tr style="height: 111.777px;">\
      <td style="width: 13.6137%; height: 111.777px;"><img style="border-radius:50px;margin-top:-40px" src="${profile.image_url}" alt="" width="100" height="100" /></td>\
      <td style="width: 86.3093%; height: 111.777px;"><h2><strong>${profile.first_name} ${profile.last_name} has invited you to join ${bloom.name} on AppiWorks</strong></h2>\
      <p>Join ${profile.first_name} ${profile.last_name}(${profile.email}) to start collaborating visually</p><p>&nbsp;</p>\
      <p><a style="text-decoration: none; display: inline-block; color: #ffffff; padding: 5px; background-color: #4f62cc; border: 1px solid #4F62CC; border-radius: 4px; width: auto; font-family: Arial,Helvetica Neue,Helvetica,sans-serif; text-align: center;cursor:pointer" href="${acceptUrl}" target="_blank" rel="noopener"> <span style="padding-left: 20px; padding-right: 20px; display: inline-block; font-size: 12px; line-height: 26px;"> <strong>Accept</strong></span></a> \
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;\
      <a style="text-decoration: none; display: inline-block; color: #4f62cc; padding: 5px; background-color: #ffffff; border: 1px solid #ffffff; border-radius: 4px; width: auto; font-family: Arial,Helvetica Neue,Helvetica,sans-serif; text-align: center;cursor:pointer" href="${denyUrl}" target="_blank" rel="noopener">\
      <span style="padding-left: 20px; padding-right: 20px; display: inline-block; font-size: 12px; line-height: 26px;"> <strong>Deny </strong></span></a></p>\
      </td></tr></tbody></table><p>&nbsp;</p><hr/><p>AppiWorks is an intelligent integration and automation platform that lets you connect with your favorite applications, and build and automate your business or professional processes.</p>',

      resource_invitation: '<p><a title="AppiWorks" href="https://appiworks.com/" target="_blank" rel="noopener"><img src="https://storage.googleapis.com/jivrus-web-images/products/appiworks/appiworks-text-horizontal.png" alt="AppiWorks" width="200" height="67" /></a>&nbsp; &nbsp; &nbsp;</p>\
      <table style="border-color:white;border-collapse: collapse; width: 99.922%; height: 111.777px;" border="1"><tbody><tr style="height: 111.777px;">\
      <td style="width: 13.6137%; height: 111.777px;"><img style="border-radius:50px;margin-top:-40px" src="${profile.image_url}" alt="" width="100" height="100" /></td>\
      <td style="width: 86.3093%; height: 111.777px;"><h2><strong>${profile.first_name} ${profile.last_name} has invited you to join ${resource.name} on AppiWorks</strong></h2>\
      <p>Join ${profile.first_name} ${profile.last_name}(${profile.email}) to start collaborating visually</p><p>&nbsp;</p>\
      <p><a style="text-decoration: none; display: inline-block; color: #ffffff; padding: 5px; background-color: #4f62cc; border: 1px solid #4F62CC; border-radius: 4px; width: auto; font-family: Arial,Helvetica Neue,Helvetica,sans-serif; text-align: center;cursor:pointer" href="${acceptUrl}" target="_blank" rel="noopener"> <span style="padding-left: 20px; padding-right: 20px; display: inline-block; font-size: 12px; line-height: 26px;"> <strong>Accept</strong></span></a> \
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;\
      <a style="text-decoration: none; display: inline-block; color: #4f62cc; padding: 5px; background-color: #ffffff; border: 1px solid #ffffff; border-radius: 4px; width: auto; font-family: Arial,Helvetica Neue,Helvetica,sans-serif; text-align: center;cursor:pointer" href="${denyUrl}" target="_blank" rel="noopener">\
      <span style="padding-left: 20px; padding-right: 20px; display: inline-block; font-size: 12px; line-height: 26px;"> <strong>Deny </strong></span></a></p>\
      </td></tr></tbody></table><p>&nbsp;</p><hr/><p>AppiWorks is an intelligent integration and automation platform that lets you connect with your favorite applications, and build and automate your business or professional processes.</p>'

  }
    return templateMap[type];
  }

  payloadTemplate(){
    let content = {
        "Html":  "${body}",
        "Subject":  "${subject}",
        "ToAddresses": [ '${toEmail}' ],
        "FromEmailAddress": '${fromEmail}'
    }
    return content;
  }

}
