export class Panel {
  id: number;
  order: number;
  name: string;
  type: string = 'regular';
  alignment: string = 'flex-start';
  layoutCount: string = "1";
  layoutMap: {}
  widgets: any = [];

  constructor(id: number, name: string, widget?:any){
    this.id = id;
    this.name = name;
    
    let elements = [];
    if(widget) elements = [widget];
    let layoutId = new Date().setMilliseconds(new Date().getMilliseconds() + 1).valueOf();
    let rowId = new Date().setSeconds(new Date().getSeconds() + 1).valueOf();
    console.log("rowId", rowId)
    this.layoutMap = {
      list: [layoutId],
      [layoutId]: {
        gridX: 12,
        list: [rowId],
        [rowId]: {
          type: "elements",
          elements: elements
        }
      }
    }
  }
};
