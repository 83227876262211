import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'
import { BuilderComponent } from './builder/builder.component';
import { PageComponent } from './page/page.component';
import { BloomComponent } from './builder/bloom-listing/bloom-listing.component';
import { CreateBloomComponent } from '../shared/create-bloom/create-bloom.component';

const routes: Routes = [
  {
    path: '',
    component: BloomComponent,
    data: { title: 'Blooms - AppiWorks', description: 'Explore your flows', subProduct: 'bloom' }
  },
  {
    path: 'new',
    component: CreateBloomComponent,
    data: { title: 'Bloom - New - AppiWorks', description: 'Create new Bloom', subProduct: 'bloom' }
  },
  {
    path: ':bloomcode',
    component: BuilderComponent,
    data: { subProduct: 'bloom' },
    children: [
      { path: ':pagecode', data: { subProduct: 'bloom' }, component: PageComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BloomRoutingModule { }
