import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StarchService } from 'src/app/shared/services/starch.service';
import { StarchAtrributesSidePanelComponent } from '../starch-attibute-side-panel/starch-attibute-side-panel.component';
const uuid = require('uuid');
const generateName = require("project-name-generator");

@Component({
  selector: 'starch-base-diolog',
  templateUrl: './starch-base-diolog.component.html',
  styleUrls: ['./starch-base-diolog.component.scss']
})
export class StarchBaseDiologComponent implements OnInit {

  baseMap: any;
  object:any
  // objects: any[] = [];
  // addNewObject: boolean;
  boxFunctions: any;
  // objectMap: any = {}
  builderMode:boolean = false;

  attributePanelMeta: any = {
    type: "listpanel",
    viewTypes: {
      defaultView: "table",
    },
    listPanelTitle: ' ',
    listAttributes: [
        {
          columnName : "Edit",
          dataType : "NA",
          fieldType : "icon",
          name : "edit",
          show_hide : false,
          sortEnabled : false,
          value : "edit",
          widgetType : "icon",
          __id : "edit"
        }
    ],
  };

  mongoDbAttribute =  {
        "__id": "attributes",
        "dataType": "array",
        "array": {
          "elementType": "object",
          "list": [
            "name",
            "dataType",
            "defaultValue"
          ],
          "name": {
            "__id": "name",
            "name": "Name",
            "dataType": "string"
          },
          "dataType": {
            "__id": "dataType",
            "name": "Data Type",
            "dataType": "string"
          },
          "defaultValue": {
            "__id": "defaultValue",
            "name": "Default Value",
            "dataType": "string"
          }
        }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataInput: any,
    public dialog: MatDialogRef<StarchBaseDiologComponent>,
    private dialogPopup: MatDialog,
    private con: ConnectionService,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    public starchService: StarchService,
    private ngZone: NgZone,

  ) { }



  async ngOnInit() {

    this.baseMap = this.dataInput?.base;
    this.object = this.dataInput?.object?.nodeMap;
    console.log("this.dataInput", this.dataInput);
    let attributes = await this.getBaseObjectAttributes(this.object.__id);
    this.object.attributes = attributes;
    this.object.addNewAttribute = false;
    // await this.getBaseObjects();
    // this.getAllFunctions()

  }

  cancelAttribute(object){
    console.log("object", object)
  }

  async selectedAttribute(e, object){
    console.log("selectedAttribute", e)
    var dialog = this.dialogPopup.open(StarchAtrributesSidePanelComponent, {
      // maxWidth: '30%',
      // width: '20vh',
      minHeight: '100vh',
      position: { right: '0%'},
      data: {
        object: this.object,
        row: e
      }
    });
    var result = await dialog.afterClosed().toPromise();
    if(result){
      object.attributes = null;
      let attributes = await this.getBaseObjectAttributes(object.__id);

      this.ngZone.run(() => {
        object.attributes = attributes;
        object.addNewAttribute = false;
          console.log("attributes", object.attributes)
       })

    this.spinnerService.hide();
    }
  }

  async getAllFunctions(){
    this.boxFunctions = await this.starchService.getBoxActions(this.baseMap);
  }

  // async createObject(){
  //   //todo - enhance to generate dynamicaly when more storage types comesin
  //   // let functions = this.boxFunctions.filter((e) => e.__id == "createobject");
  //   // let functionMap = functions[0];

  //   this.spinnerService.show();
  //   let payload = {
  //     object: this.objectMap.object
  //   }
  //   await this.starchService.createBaseObject(this.baseMap, payload);
  //   await this.getBaseObjects();
  //   this.spinnerService.hide();
  // }

  async getBaseObjectAttributes(object){
    this.spinnerService.show();
    let payload = {
      object: object,
      options: {
        relationObject: "starch_relationship"
      }
    }
    let result = [];
    let attributes = await this.starchService.getBaseAttributes(this.baseMap, payload);
    console.log("attributes", attributes)
    attributes.forEach((e) => {
      if(!e.parent) result.push(e);
    })
    console.log("result attributes", result)
    this.spinnerService.hide();
    return result;
  }


  async addNewAttributes(e){
    console.log("addNewAttributes", e);
    this.spinnerService.show();
    let payload = {
      object: e.__id,
      attributes: e.attributesInput,
      options: {
        relationObject: 'starch_relationship'
      }
    }
    let createRes = await this.starchService.createBaseAttributes(this.baseMap, payload);
    let attributes = await this.getBaseObjectAttributes(e.__id);
    this.ngZone.run(() => {
      e.attributes = attributes;
      e.addNewAttribute = false;
      console.log("attributes", e.attributes)
    })

    this.spinnerService.hide();
  }


  // async getBaseObjects(){
  //   this.spinnerService.show();
  //   this.objects = await this.starchService.getBaseObjects(this.baseMap);
  //   this.spinnerService.hide();
  // }

  // async panelOpened(event){
  //   console.log("opend", event);
  //   let attributes = await this.getBaseObjectAttributes(event.__id);
  //   event.attributes = attributes;
  // }

  ngAfterViewInit(): void {

  }

}


