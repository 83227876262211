<div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="!hideHeader">
        <div fxLayout="column" fxFlex="100">
            <div fxLayout="row" *ngIf="!input?.hideTitle">
               <h1> {{input?.title ? input.title : 'New Form'}} </h1>
            </div>
        </div>
    </div>


    <div style="min-height: 100px;background-color: transparent;" fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="100">
            <form [formGroup]="formGroup" >
            <div fxLayout="row" style="margin-top: 10px;" fxLayoutAlign="center center" >

                <div fxLayout="column" class="bloom-field" fxFlex="80">

                    <mat-form-field appearance="outline" style="width: 80%;">
                        <mat-label> Form name </mat-label>
                        <input required formControlName="name" matInput placeholder="Form name">

                    </mat-form-field>
                    <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.name.errors?.required">
                        <mat-icon>error</mat-icon>Flow name is required.
                    </mat-error>

                </div>
                <div fxLayout="column" fxFlex="20">
                </div>
            </div>


        </form>
        </div>
  </div>
</div>


