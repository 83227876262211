import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  grabber: boolean = false;
  oldX: any = 0;
  oldY: any;
  width: any = 0
  height: any = 0
  resizingWidgetMeta: any = {};
  widgetWrap: any
  targetGridX: number = 0
  panelWidth: number = 0
  panelHeight: number = 0
  gridWidth: number = 0
  gridXWidth: number = 0
  gridYWidth: number = 0
  minWidth: number = 0
  minHeight: number = 0
  clone: any
  hostPanel: any

  panelOriginalHeight: number;
  widgetOriginalHeight: number
  widgetOriginalWidth: number
  percentageWidth: number;
  percentageHeight: number;
  underflow: boolean = false;
  resizingIndex = -1
  resizeType: string = ''

  $resizedWidgetMeta = new Subject<any>()


  layoutSelected: boolean = false
  isLeftSide: boolean;
  roundedGridXDifference: number;

  constructor(
    private metaService: MetaService,
  ) { }


  layoutClickedDown(event, layout){
    console.log("down event", event)
    console.log("down layout", layout);
    this.layoutSelected = true;
    this.oldX = event.clientX;
    this.widgetWrap = event.srcElement.parentElement
  }

  layoutClickedUp(event, layout){
    console.log("up event", event);
    this.layoutSelected = false;
  }

  resizeMouseMoveLayout(event){
    // console.log("this.layoutSelected", this.layoutSelected)
    // if(!this.layoutSelected) return
    // console.log("Layout event", event)
    // console.log("event.pageX", event.pageX)
  }

  resizeMouseMoveLayoutOut(event, i, layout){
    if(!this.layoutSelected) return;
    console.log("i", i)
    let totalGridX = 12;
    // let leftLayout = layout[layout.list[i]]
    // console.log("leftLayout", leftLayout);
    // let rightLayout:any = layout?.[layout?.list[i + 1]] || null
    // console.log("rightLayout", rightLayout);
    let pageX =  event.event.pageX;
    let difference;

    this.isLeftSide = true;
    if(this.oldX < pageX){
      difference = pageX - this.oldX;
      this.isLeftSide = false;
    } else if(this.oldX > pageX){
      difference = this.oldX - pageX;
    }
    console.log("Layout", layout)
    let width = this.widgetWrap.clientWidth;
    const columnSize = width / totalGridX;
    const gridXDifference = difference / columnSize;
    this.roundedGridXDifference = Math.round(gridXDifference);
    console.log("roundedGridXDifference", this.roundedGridXDifference)

  }

  resizeMouseMoveLayoutEnd(event, i, layout){
    console.log("Layout end event", event);
    event.source._dragRef.reset();
    let leftLayout = layout[layout.list[i]]
    let rightLayout:any = layout?.[layout?.list[i + 1]] || null
    if(this.isLeftSide) {
      leftLayout.gridX = leftLayout.gridX - this.roundedGridXDifference;
      if(rightLayout) rightLayout.gridX = rightLayout.gridX + this.roundedGridXDifference;
    } else {
      leftLayout.gridX = leftLayout.gridX + this.roundedGridXDifference;
      if(rightLayout) rightLayout.gridX = rightLayout.gridX - this.roundedGridXDifference;
    }
  }

  /**
   * ------------ Resize strategy ----------------
   * 1. create a clone of the existing element
   * 2. place it exactly on top of the original element by position absolute
   * 3. let the clone undergo resizing
   * 4. finally when resizing is done, take its dimentions and remove clone from DOM
   * 5. calculate gridX and gridY from final dimensions
   * 6. apply on the original widget
   * 7. save new gridX and gridY in widget meta
   */

  /**
   * ------------- ASSOCIATED FUNCTIONS -----------
   * 1. there are three types of resizing allowed : x(horizontal), y(vertical) and z(diagonal)
   * 2. there are three sets of functions for each type of resizing (they must trigger in sequence)
   *    - resizeMouseDown: the start of a resize cycle
   *    - resizeMouseMove: as the mouse moves pixel by pixel, this function is fired when a resize is in progress
   *    - resizeMouseUp: when user ends the resize event by a mouseup event
   */


  /**
   * rmdZ stands for Resize Mouse Down X-axis (handles horizontal resize mousedown)
   * @param event
   * @param widgetMeta
   */
   resizeMouseDownX(event, widgetMeta, index) {
    this.resizeType = 'X'
    this.resizingIndex = index
    let widgetWrap = event.srcElement.parentElement
    event.stopPropagation()

    // console.log("widgetWrap", widgetWrap)
    // console.log("original height", widgetWrap.clientHeight)
    // this.widgetOriginalHeight = widgetWrap.clientHeight

    // INITIALIZE VARIABLES TO HANDLE RESIZE EVENT
    this.grabber = true;
    this.hostPanel = widgetWrap.parentElement
    this.panelWidth = this.hostPanel.clientWidth
    this.gridWidth = this.panelWidth / 12

    // make a clone
    let clone = widgetWrap.cloneNode(true)
    clone.classList.add('cloneClass')

    // place it correctly
    clone.style.left = '0px'
    clone.style.top = '0px'
    clone.style.width = widgetWrap.clientWidth + 'px'
    clone.style['max-width'] = this.panelWidth + 'px'
    clone.style['max-height'] = 'none'
    // clone.style['border'] = '1px solid red'
    clone.style.position = 'absolute'

    this.clone = clone

    // add it to dom
    widgetWrap.appendChild(clone)

    this.widgetWrap = widgetWrap

    // get initial width
    this.width = this.widgetWrap.clientWidth

    this.percentageWidth = Math.floor((this.width / this.panelWidth) * 100)
    this.resizingWidgetMeta = widgetMeta

    // save the initial x position
    this.oldX = event.clientX
  }

  /**
   * rmmZ stands for Resize Mouse Move X-axis (handles horizontal resize mousemove)
   * @param event
   * @returns
   */
  resizeMouseMoveX(event) {
    if (!this.grabber || this.resizeType !== 'X') {
      return;
    }

    console.log("resizeMouseMoveX", event)
    let eventX = event.clientX
    let newX: number;

    // check if underflow occurred
    if (this.underflow) {
      if (eventX > this.oldX) {
        newX = eventX
        this.underflow = false
      } else {
        return
      }
    } else {
      newX = eventX
    }

    // this.widgetWrap.style.height = this.widgetOriginalHeight + 'px'
    // this.clone.style.height = this.widgetOriginalHeight + 'px'


    // get the difference between old x position and new x position of cursor
    let offset = newX - this.oldX // +ve or -ve

    // check if the size went below minimum allowed width for the widget
    this.minWidth = this.gridWidth * this.resizingWidgetMeta.minGridX
    if ((this.width + offset) >= this.minWidth) {

      this.oldX = newX    // make the new  x positon as old for checking in next iteration
      this.width += offset;   // update width
      console.log("new width", this.width)
      this.percentageWidth = Math.floor((this.width / this.hostPanel.clientWidth) * 100)

      // apply the new width to the clone element
      this.clone.style.width = this.width + 'px'
    } else {
      this.underflow = true
      console.log("cant shrink anymore, min width reached")
    }
  }

  /**
   * rmuZ stands for Resize Mouse Up X-axis (handles horizontal resize mouseup)
   * @param event
   * @returns
   */
  resizeMouseUpX(event) {
    if (!this.grabber || this.resizeType !== 'X') {
      return
    }

    // remove element
    this.widgetWrap.removeChild(this.widgetWrap.lastElementChild)

    this.targetGridX = this.getGridCount(this.percentageWidth)
    this.resizingWidgetMeta.gridX = this.targetGridX
    let newEffectiveWidth = Math.floor(this.targetGridX * this.gridWidth)

    this.widgetWrap.style.width = newEffectiveWidth + 'px'
    // this.widgetWrap.style.height = this.widgetOriginalHeight + 'px'

    console.log("new widget meta", this.resizingWidgetMeta)

    this.$resizedWidgetMeta.next(this.resizingWidgetMeta)
    this.metaService.contextChanged.next({  //this is to make the change in widgetMeta reach down to widget component level
      actionType: "resize",
      data: this.resizingWidgetMeta,
      widgetId: this.resizingWidgetMeta.id,
      newDimensions: {
        width: newEffectiveWidth,
        height: this.widgetWrap.clientHeight
      }
    })
    this.resizingWidgetMeta = {}

    // RESET INTERMEDIATE VARIABLES
    this.grabber = false;
    this.widgetWrap = undefined
    this.hostPanel = undefined
    this.width = 0
    this.percentageWidth = 0
    this.resizeType = ''
  }


  /**
   * Vertical resizing strategy :
   * (They will automatically adjusted horizontally because of fxFlex but not vertically)
   *
   * ----------------- steps --------------------
   *
   * FOR IMAGE: (some calculations happens inside the widget because we have to wait for the image to load)
   * refer inside widget component
   */


  /**
   * rmdX stands for Resize Mouse Down X-axis
   * @param event
   * @param widgetMeta
   */
  resizeMouseDownZ(event, widgetMeta, i) {
    this.resizeType = 'Z'
    let widgetWrap = event.target.parentElement
    event.stopPropagation()

    // INITIALIZE VARIABLES TO HANDLE RESIZE EVENT
    this.grabber = true;
    this.hostPanel = widgetWrap.parentElement
    this.panelWidth = this.hostPanel.clientWidth
    this.panelHeight = this.hostPanel.clientHeight
    this.gridXWidth = this.panelWidth / 12
    this.widgetOriginalHeight = widgetWrap.clientHeight

    let clone = widgetWrap.cloneNode(true)
    clone.classList.add('cloneClass')

    clone.style.left = '0px'
    clone.style.top = '0px'

    clone.style.width = widgetWrap.clientWidth + 'px'
    clone.style.height = widgetWrap.clientHeight + 'px'
    clone.style['max-width'] = (widgetWrap.clientWidth / widgetMeta.gridX) * 12 + 'px'
    clone.style['max-height'] = 'none'
    clone.style.position = 'absolute'

    this.clone = clone

    widgetWrap.appendChild(clone)

    this.widgetWrap = widgetWrap
    this.width = this.widgetWrap.clientWidth
    this.height = this.widgetWrap.clientHeight

    this.percentageWidth = Math.floor((this.width / this.panelWidth) * 100)
    this.resizingWidgetMeta = widgetMeta

    this.minWidth = this.gridWidth * this.resizingWidgetMeta.minGridX
    this.oldX = event.clientX
    this.oldY = event.clientY

    this.hostPanel.style.height = (this.hostPanel.clientHeight + 20) + 'px'
  }

  /**
   * rmmX stands for Resize Mouse Move Z-axis (handles diagonal resize mousemove)
   * @param event
   * @returns
   */
   resizeMouseMoveZ(event) {
    if (!this.grabber || this.resizeType !== 'Z') {
      return;
    }

    // take the bigger height (widget original/clone) and compare with panel boundary if the panel needs resize (keeping 20px buffer)
    // if needed, increase panel height
    let refHeight = this.clone.clientHeight > this.widgetWrap.clientHeight ? this.clone.clientHeight : this.widgetWrap.clientHeight
    if(this.hostPanel.clientHeight - refHeight < 20){
      this.hostPanel.style.height = (this.hostPanel.clientHeight + 20) + 'px'
    }

    this.widgetWrap.style.height = this.widgetOriginalHeight + 'px'

    let newY =  event.clientY

    // let offsetY = cloneY - this.oldY
    let offsetY = newY - this.oldY // +ve or -ve
    console.log("offsetY", offsetY)

    let hostPanelHeight = this.hostPanel.clientHeight
    if(hostPanelHeight + offsetY >= this.panelHeight - 10){
      this.hostPanel.style.height = (hostPanelHeight + offsetY) + 'px'
    }

    // handle height change
    this.oldY = newY
    console.log("old height", this.height)
    console.log("new height", this.height + offsetY)
    this.height = this.height + offsetY
    if(this.resizingWidgetMeta.type == 'chart'){
      this.clone.style.height = this.height + 'px'
    }else{
      this.clone.style.height = 'auto'
    }

    let eventX = event.clientX
    let newX: number;

    if (this.underflow) {
      if (eventX > this.oldX) {
        newX = eventX
        this.underflow = false
      } else {
        return
      }
    } else {
      newX = eventX
    }

    // handle width change
    let offset = newX - this.oldX // +ve or -ve
    if ((this.width + offset) >= this.minWidth) {
      this.oldX = newX
      this.width += offset;
      // console.log("new width", this.width)
      this.percentageWidth = Math.floor((this.width / this.hostPanel.clientWidth) * 100)

      this.clone.style.width = this.width + 'px'
      // this.clone.style.height = this.widgetOriginalHeight + 'px'
    } else {
      this.underflow = true
      console.log("cant shrink anymore, min width reached")
    }
  }

  /**
   * rmuZ stands for Resize Mouse Up Z-axis (handles diagonal resize mouseup)
   * @param event
   * @returns
   */
   resizeMouseUpZ(event) {
    // return
    if (!this.grabber || this.resizeType !== 'Z') {
      return
    }

    this.targetGridX = this.getGridCount(this.percentageWidth)
    this.resizingWidgetMeta.gridX = this.targetGridX
    let targetGridY = Math.round(this.clone.clientHeight / this.gridXWidth)

    this.resizingWidgetMeta.gridY = Math.round(targetGridY)
    this.widgetWrap.style.height = (targetGridY * this.gridXWidth) + 'px'

    this.widgetWrap.removeChild(this.widgetWrap.lastElementChild)
    this.widgetWrap.style.height = 'auto'

    this.hostPanel.style.height = 'auto'

    this.$resizedWidgetMeta.next(this.resizingWidgetMeta)
    this.metaService.contextChanged.next({  //this is to make the change in widgetMeta reach down to widget component level
      actionType: "resize",
      data: this.resizingWidgetMeta,
      widgetId: this.resizingWidgetMeta.id,
      newDimensions: {
        width: this.widgetWrap.clientWidth,
        height: targetGridY * this.gridXWidth,
      }
    })
    this.resizingWidgetMeta = {}

    // RESET INTERMEDIATE VARIABLES
    this.grabber = false;
    this.widgetWrap = undefined
    this.hostPanel = undefined
    this.width = 0
    this.percentageWidth = 0
    this.resizeType = ''
  }

  /**
   * rmdY stands for Resize Mouse Down Y-axis
   * @param event
   * @param widgetMeta
   */
  resizeMouseDownY(event, widgetMeta, i) {
    this.resizeType = 'Y'

    let widgetWrap = event.srcElement.parentElement
    event.stopPropagation()

    // INITIALIZE VARIABLES TO HANDLE RESIZE EVENT
    this.grabber = true;
    this.hostPanel = widgetWrap.parentElement
    this.panelWidth = this.hostPanel.clientWidth
    this.panelHeight = this.hostPanel.clientHeight
    this.gridXWidth = this.panelWidth / 12
    this.widgetOriginalHeight = widgetWrap.clientHeight

    let clone = widgetWrap.cloneNode(true)
    clone.classList.add('cloneClass')

    // adjust equal offset/padding as to the original element
    // clone.style.left = widgetWrap.offsetLeft + 'px'
    // clone.style.top = widgetWrap.offsetTop + 'px'
    clone.style.left = '0px'
    clone.style.top = '0px'
    // assign original dimension
    clone.style.width = widgetWrap.clientWidth + 'px'
    clone.style.height = widgetWrap.clientHeight + 'px'
    clone.style['max-width'] = widgetWrap.clientWidth + 'px'
    clone.style['min-height'] = '30px'
    clone.style.position = 'absolute'

    this.clone = clone

    widgetWrap.appendChild(clone)

    this.widgetWrap = widgetWrap
    this.width = this.widgetWrap.clientWidth
    this.height = this.widgetWrap.clientHeight

    this.percentageWidth = Math.floor((this.width / this.panelWidth) * 100)
    this.resizingWidgetMeta = widgetMeta

    // this.minWidth = this.gridWidth * this.resizingWidgetMeta.minGridX
    this.minHeight = this.gridWidth * this.resizingWidgetMeta.minGridY
    this.oldX = event.clientX
    this.oldY = event.clientY

    this.hostPanel.style.height = (this.hostPanel.clientHeight + 20) + 'px'
  }

  /**
   * rmmY stands for Resize Mouse Move Y-axis (handles vertical resize mousemove)
   * @param event
   * @returns
   */
  resizeMouseMoveY(event) {
    if (!this.grabber || this.resizeType !== 'Y') {
      return;
    }

    let newY = event.clientY
    let offsetY = newY - this.oldY // +ve or -ve
    let refHeight = this.clone.clientHeight > this.widgetWrap.clientHeight ? this.clone.clientHeight : this.widgetWrap.clientHeight
    if(
        this.hostPanel.clientHeight - refHeight < 20 &&
        offsetY > 0
      ){
      this.hostPanel.style.height = (this.hostPanel.clientHeight + 20) + 'px'
    }

    this.widgetWrap.style.height = this.widgetOriginalHeight + 'px'

    // handle height change
    this.oldY = newY
    this.height = this.height + offsetY
    console.log("new clone height", this.height)
    this.clone.style.height = this.height + 'px'
  }

  /**
   * rmuX stands for Resize Mouse Up Y-axis (handles vertical resize mouseup)
   * @param event
   * @returns
   */
   resizeMouseUpY(event) {
    if (!this.grabber || this.resizeType !== 'Y') {
      return
    }
    console.log("final height", this.clone.clientHeight)

    let targetGridY = Math.round(this.clone.clientHeight / this.gridXWidth)
    // console.log("gridX width", this.gridXWidth)
    // console.log("targetGridY", targetGridY)
    let newPixelHeight = targetGridY * this.gridXWidth
    this.widgetWrap.style.height = newPixelHeight + 'px'
    // console.log("new applied height", this.widgetWrap.clientHeight)

    // console.log("new widget meta", this.resizingWidgetMeta)
    this.resizingWidgetMeta.gridY = targetGridY

    // this.widgetWrap.style.visibility = 'visible'
    this.widgetWrap.removeChild(this.widgetWrap.lastElementChild)

    this.hostPanel.style.height = 'auto'

    this.$resizedWidgetMeta.next(this.resizingWidgetMeta)
    this.metaService.contextChanged.next({  //this is to make the change in widgetMeta reach down to widget component level
      actionType: "resize",
      data: this.resizingWidgetMeta,
      widgetId: this.resizingWidgetMeta.id,
      newDimensions: {
        width: this.clone.clientHeight,
        height: newPixelHeight,
      }
    })
    this.resizingWidgetMeta = {}

    // RESET INTERMEDIATE VARIABLES
    this.grabber = false;
    this.widgetWrap = undefined
    this.hostPanel = undefined
    this.width = 0
    this.percentageWidth = 0
    this.resizeType = ''
  }

  /**
   *
   * @param percent gets the percentage width of the widget in panel and
   *  rounds off to nearest 12 column grid line
   * @returns new Gridline
   */
  getGridCount(percent) {
    let oneGrid = 8.33
    console.log("percent", percent)
    let fraction = percent / oneGrid
    console.log("fraction", fraction)

    // floor
    let lower = Math.floor(fraction)
    let lowerPercent = oneGrid * lower
    let lowerDiff = Math.abs(lowerPercent - percent)

    // ceil
    let upper = Math.ceil(fraction)
    let upperPercent = oneGrid * upper
    let upperDiff = Math.abs(upperPercent - percent)

    let targetGrid = lowerDiff <= upperDiff ? lower : upper

    return targetGrid
  }

}
