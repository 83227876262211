import { NgModule } from "@angular/core";
import { ReplacePipe } from "./replace/replace.pipe";
import { TruncatePipe } from "./truncate/truncate.pipe";

const pipes = [ReplacePipe, TruncatePipe];

@NgModule({
    declarations: [pipes],
    exports: [pipes]
})

export class ClientPlatformPipesModule { }