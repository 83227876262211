<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div>

    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <div fxLayout="row" >
               <h4> Invite people to {{workspace.name}} </h4>
            </div>
            <div  fxLayout="row wrap" *ngFor="let map of invitationMap; let i = index" fxLayoutAlign="space-around center">
                <div fxLayout="column" fxFlex="65" fxFlex.lt-md="50" fxFlex.lt-sm="80">
                    <mat-form-field appearance="outline" class="example-full-width">
                        <mat-label>Emails</mat-label>
                        <mat-chip-grid #chipList aria-label="email">
                          <mat-chip-row *ngFor="let email of map.validEmails"
                          [removable]
                            (removed)="removeChipValue(email, map.validEmails)">
                            {{email}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip-row>
                          <input placeholder="New email" [matChipInputFor]="chipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            (matChipInputTokenEnd)="addChipValue($event, map.validEmails, i)"
                            (paste)="pasteChipValue($event, map.validEmails)">
                            <mat-error *ngIf="!isValidEmail && i==failedIndex">
                                valid email id is required
                            </mat-error>
                        </mat-chip-grid>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="30" fxFlex.lt-md="50" fxFlex.lt-sm="80">
                    <mat-form-field appearance="outline" class="custom_select example-full-width" *ngIf="availableRoles.length > 0">
                        <mat-label>Role</mat-label>
                        <mat-select  [(ngModel)] = 'map.role' name="role">
                          <mat-option *ngFor="let role of availableRoles"  [value]="role._id">
                             <span>{{role.name}}</span> </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="5" fxLayout="column wrap" fxLayoutAlign="center start" style="word-break: break-word;">
                    <button mat-icon-button [disabled]="invitationMap.length < 2">
                        <mat-icon style="margin: 0px;cursor: pointer;"  matTooltip="Delete record" (click)="deleteInvitationRow(map)">delete</mat-icon>
                    </button>
                </div>
            </div>

            <div fxLayout="row" >
                <mat-icon style="margin: 0px;cursor: pointer;"  matTooltip="Add more people"
                (click)="addInvitation()">
                add_circle_outline</mat-icon>
           </div>
           <br/>
            <div fxLayout="row" fxLayoutAlign="stretch">
                <button mat-raised-button color="primary" (click)="sendWorkspaceInvitation()"  type="button" [disabled]="!(emailLength > 0)">Send Invitation{{ emailLength > 1 ? 's':'' }} </button>
           </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div>
</div>
