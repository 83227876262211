<div >
  <mat-form-field style="width: 100%;">
    <mat-label *ngIf="placeholder">{{placeholder}}</mat-label>
    <mat-select
    [(value)]="selectedValue"
    (selectionChange)="onWidgetActionSelected($event)"
    (click)="$event.preventDefault()">
      @for (obj of list; track obj) {
        <mat-option [value]="obj.__id">{{obj.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
