<h2 mat-dialog-title>{{ action.name }}</h2>

<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxLayoutAlign="start start" style="width: 100%;">
        <h3 style="width: 100%;">Input Parameters</h3>
        <div style="width: 100%; max-height: 300px; overflow-y: scroll;">
            <shared-parameter-inputs
            style="width: 100%;"
            [isSupportQuery]="true"
            [queryInputs]="{ subComponentsToShow: { filter: true, sort: true, attributes: true, limit: true, group: true }}"
            [action]="action"
            [getAttributesFunction]="dialogData.getAttributesFunction"
            (enableExecute)="triggerExecute($event)"
            (inputParameter)="inputRecevied($event)"
            (getAttributesResponse)="attributeRecevied($event)"
            [connection]="connection"
            ></shared-parameter-inputs>

            <app-collect-action-data
            *ngIf="['C','U','S'].includes(action.crudType) && inputParams"
            [action]="action"
            [boxObjectAttributes]="availableAttr||[]"
            (constructedData)="columnSelectionChanged($event)"
            >
            </app-collect-action-data>
        </div>
    </div>
    <!-- <div fxLayout="column" fxLayoutAlign="start start"
        *ngIf=" boxObjectFunctionInputLists && boxObjectFunctionInputLists.length > 0">
        <h3>Input Parameters</h3>
    
        <shared-parameter-inputs [isSupportQuery]="true" [action]="action"
        (inputParameter)="inputRecevied($event)" [connection]="connection"></shared-parameter-inputs>
        <div fxLayout="row wrap" *ngFor="let list of boxObjectFunctionInputLists">
            <div>
    
                <mat-label style="margin: 5px;">{{ list.name }}</mat-label>
                <textarea matInput [(ngModel)]="list.value"
                    style="border: 1px solid black;margin-bottom: 5px"></textarea>
    
            </div>
            <div fxFlex="5"></div>
        </div>
    
        <div fxLayout="row">
            <div fxLayoutAlign="center center" style="margin: 5px;">
                <button fxLayoutAlign="center center" mat-raised-button color="primary"
                    (click)="executeBoxObjectFunction()">
                    Execute
                </button>
            </div>
        </div>
    </div> -->
    <div style="text-align:left;">
        <mat-spinner *ngIf="spinner" diameter="50" class="spinner" color="primary"></mat-spinner>
    
        <div *ngIf="executionResponse && executionResponse.error;">
            <h2>Result</h2>
            {{executionResponse | json}}
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="enableExecute" fxLayoutAlign="center center" mat-raised-button color="primary"
        (click)="executeBoxObjectFunction()">
        Execute
    </button>
</mat-dialog-actions>


