import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-attribute-selection',
  templateUrl: './attribute-selection.component.html',
  styleUrls: ['./attribute-selection.component.scss']
})
export class AttributeSelectionComponent implements OnInit {

  @Input() boxObjectAttributes: any[]
  @Input() listAttributes: any[]
  @Input() supportApplyButton: boolean
  @Input() allowReorder: boolean = true
  @Input() attributeDisplayName?: string;
  @Input() emitAtInit: boolean = true

  @Output() listAttributeSelected: EventEmitter<any> = new EventEmitter();

  filteredAttributes: any[] = []
  isSearching: boolean = false;

  constructor() { }

  ngOnInit(): void {
    console.log("[ATTRIBUTE SELECTION] boxObjectAttributes", this.boxObjectAttributes)
    console.log("[ATTRIBUTE SELECTION] listAttributes", this.listAttributes)
    // this.filteredAttributes = this.boxObjectAttributes
    this.init(this.boxObjectAttributes, this.listAttributes)
    if(this.emitAtInit) this.listAttributeSelected.emit(this.listAttributes)
  }

  ngOnChanges(changes: SimpleChanges): void {

    // if(changes.boxObjectAttributes?.firstChange) return
    console.log("CHANGES", changes)
    let listAttributes = changes.listAttributes?.currentValue
    let boxAttributes = changes.boxObjectAttributes?.currentValue
    // changes.boxObjectAttributes?.currentValue?.length && 
    if(listAttributes?.length && boxAttributes?.length){
      this.init(boxAttributes, listAttributes)
      // console.log("new list assigned", JSON.parse(JSON.stringify(this.filteredAttributes)))
      // console.log("original list", JSON.parse(JSON.stringify(this.boxObjectAttributes)))
      // this.listAttributeSelected.emit(changes.listAttributes.currentValue)
    }
    // if(changes.listAttributes?.currentValue){
    //   console.log("listAttributeChanged", this.listAttributes)
    //   this.columnSelectionChanged()
    // }
  }

  /**
   * Initializes the filteredAttributes array from the boxObjectAttributes and listAttributes.
   * The isColumnSelected property is set to true if the attribute is in listAttributes and false otherwise.
   * @param boxAttributes 
   * @param listAttributes 
   */
  init(boxAttributes, listAttributes){
    // this.filteredAttributes = JSON.parse(JSON.stringify(boxAttributes))
    this.filteredAttributes = boxAttributes
    this.filteredAttributes.forEach((attr, i) => {
      // if(!attr.hasOwnProperty('isColumnSelected')) attr['isColumnSelected'] = true;
      let listAttr = listAttributes.find(a => a.__id == attr.__id)
      if(listAttr) {
        // console.log("attr", JSON.parse(JSON.stringify(attr)))
        // console.log("listAttr", JSON.parse(JSON.stringify(listAttr)))
        // console.log("isColumnSelected", listAttr.isColumnSelected)
        if (listAttr['isColumnSelected']) {
          attr = listAttr
          attr['isColumnSelected'] = true
        } else {
          attr['isColumnSelected'] = false;
        }
        this.filteredAttributes[i] = attr
      }
    })
    console.log("filtered attributes", JSON.parse(JSON.stringify(this.filteredAttributes)))
  }

  emitSelectedColumns(){
    console.log("boxObjectAttributes", this.boxObjectAttributes)
    // this.listAttributes = []
    // this.boxObjectAttributes.forEach((attr) => {
    //   // let attr = e; 

    //   let indexInListAttributes = this.listAttributes.findIndex(a => a.__id == attr.__id)
    //   if (attr.isColumnSelected && indexInListAttributes == -1) this.listAttributes.push(attr)
    //   else if (!attr.isColumnSelected && indexInListAttributes > -1) this.listAttributes.splice(indexInListAttributes, 1)
    //   // if(e.isColumnSelected){ // && !this.containsObject(attr.__id, this.listAttributes)
    //   //   this.listAttributes.push(attr)
    //   // }
    //   // else if (!e.isColumnSelected){  // && this.containsObject(attr.__id, this.listAttributes)
    //   //   let i = this.listAttributes.findIndex(a => a.__id == attr.__id)
    //   //   this.listAttributes.splice(i, 1)
    //   // }
    // })
    // console.log("returning selected", JSON.parse(JSON.stringify(this.listAttributes)))
    // console.log("bx obj", JSON.parse(JSON.stringify(this.boxObjectAttributes)))
    // console.log("filtered", JSON.parse(JSON.stringify(this.filteredAttributes)))
    this.listAttributeSelected.emit(this.listAttributes)

    // reset the filteredAttributes
    // this.filteredAttributes = this.boxObjectAttributes
  }

  containsObject(id:any, list:any) {
    var i;
    let res = false;
    for (i = 0; i < list.length; i++) {
      if (list[i].__id === id) {
        res = true;
        break;
      }
    }
    // console.log("id", id, res)
    return res;
  }

  attributeSelected(event, attribute){
    // event.stopPropagation()
    console.log("[attribute selected] checked", event.checked, "attr", JSON.parse(JSON.stringify(attribute)))

    let i = this.boxObjectAttributes.findIndex(a => a.__id == attribute.__id)
    // console.log("attribute found at", i, JSON.parse(JSON.stringify(this.boxObjectAttributes[i])))
    this.boxObjectAttributes[i].isColumnSelected = event.checked
    // console.log("listAttributes now", JSON.parse(JSON.stringify(this.listAttributes)))

    let indexInListAttributes = this.listAttributes.findIndex(a => a.__id == attribute.__id)
    if (event.checked && indexInListAttributes == -1) this.listAttributes.push(this.boxObjectAttributes[i])
    else if (!event.checked && indexInListAttributes > -1) this.listAttributes.splice(indexInListAttributes, 1)
    // console.log("listAttributes after selection", JSON.parse(JSON.stringify(this.listAttributes)))
    // this.listAttributes.forEach(listAttr => {
    //   if(listAttr.__id == attribute.__id) listAttr['isColumnSelected'] = event.checked
    // })
    // this.init(this.boxObjectAttributes, this.listAttributes)
    this.filterAttributeList({srcElement: {value: ''}})

    // console.log("boxObjectAttributes finally", JSON.parse(JSON.stringify(this.boxObjectAttributes)))
    // console.log("listAttributes finally", JSON.parse(JSON.stringify(this.listAttributes)))
    // console.log("this.supportApplyButton", this.supportApplyButton)
    if(!this.supportApplyButton) this.emitSelectedColumns()
  }

  filterAttributeList(event){
    let txt = event.srcElement.value
    console.log("search text", txt)
    if (txt == '') {
      this.isSearching = false
      this.filteredAttributes = this.boxObjectAttributes
      this.filteredAttributes.forEach(attr => {
        if(this.containsObject(attr.__id, this.listAttributes)) attr['isColumnSelected'] = true
      })
    } else {
      this.isSearching = true
      this.filteredAttributes = []
      this.boxObjectAttributes.forEach(attr => {
        if (attr.__id.includes(txt) || attr.name.includes(txt)) {
          this.filteredAttributes.push(attr)
        }
      })
    }
    console.log("filtered list", JSON.parse(JSON.stringify(this.filteredAttributes)))
  }

  drop(event: CdkDragDrop<string[]>) {
    if(this.filteredAttributes.length != this.boxObjectAttributes.length) return
    moveItemInArray(this.boxObjectAttributes, event.previousIndex, event.currentIndex);
    const checkboxAttrIndex = this.boxObjectAttributes.findIndex((attr) => attr.__id === "__checkbox__")
    if (checkboxAttrIndex != -1) {
      const checkboxAttr = this.boxObjectAttributes.splice(checkboxAttrIndex, 1);
      this.boxObjectAttributes.unshift(checkboxAttr[0]);
    }
    this.filteredAttributes = this.boxObjectAttributes
    console.log("reordered", this.boxObjectAttributes)
    if(!this.supportApplyButton) this.emitSelectedColumns()
  }

}
