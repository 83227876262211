export const ScorecardConfig: any = {
  otherProps: {
    props: ["scorecardLabel"],
    scorecardLabel: {
      displayName: "Score card text",
      value: "Score",
      type: "text"
    },
    precision: {
      displayName: "Decimal point precision",
      type: "text",
      value: 2,
    }
  }
}
