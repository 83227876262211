
import { NgModule } from '@angular/core';
import { FormRoutingModule } from './form-routing.module';
import { FormWithoutRoutingModule } from './form-without-routing.module';

@NgModule({
  declarations: [

  ],
  imports: [
    FormRoutingModule,
    FormWithoutRoutingModule
  ],
  exports: [

  ]
})
export class FormModule { }
