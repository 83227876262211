import { Injectable } from "@angular/core";
import { MetaService } from "src/app/bloom/services/meta-service";
import { BaseExpression } from "./BaseExpression";


@Injectable({
  providedIn: 'root',
})
export class BloomOwnerExpression extends BaseExpression {

  config: any = {}

  constructor(private metaService?: MetaService){
    super()
    this.config.id = '__bloomowneremail__';
    this.config.name = "Bloom publisher's email";
    this.config.description = "Returns bloom publisher's email address";
    this.config.parameters = {
      paramIds: [],
    }
  }

  evaluateExpression(config?: any) {
    if(config?.id !== this.config.id) return ''
    let currentBloom = this.metaService?.fetchedBloomMeta?.value
    console.log("current bloom meta", currentBloom)
    if(!currentBloom) return ''
    return currentBloom.publisher_email || ''
  }

  getExpressionDetail() {
    return this.config
  }
}
