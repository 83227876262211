import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormService } from '../form.service';


@Component({
  selector: 'app-form-action-popup',
  templateUrl: './form-action-popup.component.html',
  styleUrls: ['./form-action-popup.component.scss']
})
export class FormActionPopupComponent implements OnInit {


  spinner:boolean = false;
  widgetMeta:any
  action: any;
  origin: any;
  attributes: any;
  selectedAttributes: any;
  displayConfig: any = {supportActionCrudTypes: 'C', isHideWhiteLabel: true, isListOnlyObject: true}
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataInput: any,
    public dialogRef: MatDialogRef<FormActionPopupComponent>,
    public formService: FormService,
    private dialog: MatDialog,
  ) {


   }

  async ngOnInit() {
    this.widgetMeta = this.dataInput.widgetMeta;
    this.origin = this.dataInput?.origin || "automate";
    if(this.origin == "automate") this.displayConfig = {
      isHideWhiteLabel: true,
      supportActionCrudTypes: "C,U,E"
    }
    this.action = {
      "event": "click",
      "action": "application",
      "actionMap": {
        boxId: this.dataInput.selectedBox?.__id || null
      }
    }
  }

  async actionSelectionChanged(e){
    console.log("actionSelectionChanged", e)
    if(e?.type == "actionSelected"){
      this.attributes = e.event
    }
  }

  attibutesSelected(e){
    this.selectedAttributes = e;
  }
  attributeOptionsInput(e){
    this.action.actionMap.inputParams = e;
    console.log("attributeOptionsInput", e, this.action)
  }

  onSave(options?:any){
    console.log("this.action", this.action)
    let data = {action: this.action, attributes: this.dataInput?.origin == "automate" ? this.attributes : this.selectedAttributes};
    if(options)  Object.assign(data, options)
    this.dialogRef.close(data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
