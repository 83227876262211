import { SharedModule } from '../../app-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { StepActionComponent } from './step-action.component';
import { ConnectionSelectionModule } from '../../connection-selection/connection-selection.module';
import { AppSelectionModule } from '../../app-selection/app-selection.module';
import { ActionSelectionModule } from '../../action-selection/action-selection.module';
import { AppsPopupModule } from '../../apps-popup/apps.popup.module';
import { MappingSetupModule } from '../../mapping-setup/mapping-setup.module';
import { ParameterInputsModule } from '../../parameter-inputs/parameter-inputs.module';
import { BaseSelectionModule } from '../../base-selection/base-selection.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

const routes: Routes = [
  {
    path: '', component: StepActionComponent
  }]

@NgModule({
  declarations: [StepActionComponent],
  imports: [
    CommonModule,
    SharedModule,
    ConnectionSelectionModule,
    AppSelectionModule,
    ActionSelectionModule,
    AppsPopupModule,
    MappingSetupModule,
    ParameterInputsModule,
    BaseSelectionModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatFormFieldModule,
    RouterModule.forChild(routes)
  ],
  exports: [StepActionComponent]
})
export class StepActionModule { }
