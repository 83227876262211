import { Widget } from "./baseWidget";
import { DEFAULT_VALUE } from "./dynamicDefaultValues";
import { TextFormatting } from './textFormat'

export class Label extends Widget {
  //widget type
  type = 'label';
  gridX = 6
  minGridX = 3

  //widget specific properties
  config = {
    props: [
      "labelText",
      "prefixText",
      "alignment",
      "defaultValue",
      'tooltip',
      'hidden'
    ],
    labelText: {
      displayName: "Label Text",
      value: "Click to customize this text as you need, adjust the styles and more",
      resetValue: '',
      type: 'longtext'
    },
    prefixText: {
      displayName: "Prefix Text",
      value: "",
      type: 'text'
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      resetValue: 'flex-start',
      type: 'app-alignment-settings'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    hidden: {
      displayName: 'Hidden',
      type: 'boolean',
      value: false,
    },
    defaultValue: DEFAULT_VALUE,
  };

  //text formatting properties
  // textFormat = JSON.parse(JSON.stringify(textFormatting));
  textFormat = undefined;

  constructor(id?: number, name?: string) {
    super(id, name)
    this.attachCommonConfig(this)

    // console.log("in constructor of :", this.type, "-- before init style--", JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))
    // console.log("attached styles", JSON.parse(JSON.stringify(this)))

    // add widget specific text formatting if any

  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig(){
    let map = {
      valuePath: "config.labelText.value"
    }
    return map;
  }
}

