<h2 mat-dialog-title>Setup your integration</h2>

<mat-dialog-content class="mat-typography">
  <div fxLayout="row">
    <div fxLayout="column"></div>
    <div fxLayout="column">
      <app-action-application *ngIf="!attributes?.length || origin == 'automate'"
        [actionConfig]="action"
        [widget] = "widgetMeta"
        [displayConfig] = "displayConfig"
        (actionSelectionOptionsInput)="attributeOptionsInput($event)"
        (selectionChanged)="actionSelectionChanged($event)"
      ></app-action-application>
      <app-form-attribute-selection *ngIf="attributes?.length && origin != 'automate'"
          [widgetMeta]="widgetMeta" [attributes]="attributes"

          (attibutesSelected)="attibutesSelected($event)">
      </app-form-attribute-selection>
    </div>
    <div fxLayout="column"></div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button color="primary" (click)="onSave()" cdkFocusInitial>Save</button>
</mat-dialog-actions>




