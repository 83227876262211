<button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
  <img class="logo_image" src="{{ authService.profile?.image_url }}" onerror="this.onerror = null; this.src = 'https://storage.googleapis.com/jivrus-web-images/avatar/no-user-photo.jpg';" />
</button>
<mat-menu #menu="matMenu">
  <header class="header" (click)="$event.stopPropagation();">
    <div class="div_account" title="Account">Account</div><button aria-label="Close popover"
      (click)="menuTrigger.closeMenu()" class="close_button button_layout" data-test-id="popover-close"><span
        class="button_span" role="presentation"><svg width="17" height="10" viewBox="0 0 14 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M5.58579 7L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L8.41421 7L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L7 8.41421L1.70711 13.7071C1.31658 14.0976 0.683418 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L5.58579 7Z"
            fill="currentColor"></path>
        </svg></span></button>
  </header>
  <mat-card appearance="outlined" fxLayout="column" class="mat-elevation-z0" (click)="$event.stopPropagation();">
    <div fxLayout="column" fxLayoutGap="5px" class="divider">
      <div fxLayout="row" fxLayoutGap="10px" class="center_items">
        <img class="logo_image_inside" src="{{ authService.profile?.image_url }}" onerror="this.onerror = null; this.src = 'https://storage.googleapis.com/jivrus-web-images/avatar/no-user-photo.jpg';" />
        <div fxLayout="column">
          <span>{{authService.profile?.first_name}} {{authService.profile?.last_name}}</span>
          <span>{{authService.profile?.email}}</span>
        </div>
      </div>
    </div>

    <button mat-menu-item [routerLink]="['profile']" matTooltip="profile" aria-label="profile">
      <mat-icon>manage_accounts</mat-icon> <span>Profile</span>
    </button>
    <button mat-menu-item [routerLink]="['about']" matTooltip="about" aria-label="about">
      <mat-icon>info</mat-icon> <span>About</span>
    </button>
    <button *ngIf="internalUser" (click)="showSimulation = !showSimulation" mat-menu-item  matTooltip="Simulate" aria-label="simulate">
      <mat-icon>supervised_user_circle</mat-icon> <span>Simulate User</span>
    </button>
    <div *ngIf="showSimulation">
      <div style="height: .5rem"></div>
      <mat-form-field style="width: 100%">
        <mat-label>Enter User Email</mat-label>
        <input matInput #simulateEmail [(ngModel)]="userEmail">
      </mat-form-field>
      <div fxLayoutAlign="center center"><button mat-raised-button color="primary" (click)="setSimulation()">Simulate</button></div>
      <div style="height: .5rem"></div>
      <div *ngIf="isSimulating">
        <div fxLayoutAlign="center center"><button mat-raised-button style="background-color: grey;color: black" (click)="resetSimulation()">Back to my Profile</button></div>
        <div style="height: .5rem"></div>
      </div>
    </div>
    <button mat-menu-item (click)="signOut()">
      <mat-icon>logout</mat-icon> <span>Logout</span>
    </button>
  </mat-card>
</mat-menu>
