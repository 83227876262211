<div *ngIf="clientPlatformService.clientType && !['ZOHOCRM','ZOHOBOOKS'].includes(clientPlatformService.clientType)" class="footer" fxLayout="row" >
    <div fxFlex="7" fxFlex.xs="20" fxLayout="column">
        <a href="https://www.jivrus.com/" target="_blank" aria-label="Home" fxLayout fxLayoutAlign="center center">
            <img src="https://storage.googleapis.com/jivrus-web-images/jivrus/jivrus-logo-3d-without-padding.png"
              class="" style="height: auto;width: 100%" alt="" loading="eager" />
          </a>
    </div>
    <div fxFlex.xs="10" fxFlex="13" fxLayout="column">
    </div>

    <div fxFlex="60" fxFlex.xs="70" fxLayout="column" fxLayoutAlign="center">
        <div fxHide.xs="true"  fxLayout="row" fxLayoutAlign="center center"> AppiWorks is a product of
            <a href="https://www.jivrus.com/" target="_blank" style="color: rgb(202, 54, 9);text-decoration:none"><b> &nbsp; Jivrus Technologies</b></a>
        </div>
        <div fxShow.xs="true" fxHide  fxLayout="row" fxLayoutAlign="end end">
            Product of  <a href="https://www.jivrus.com/" target="_blank" style="color: rgb(202, 54, 9);text-decoration:none"><b> &nbsp; Jivrus Technologies</b></a>
        </div>
        <div  fxLayout="row" fxLayoutAlign.xs="end end" fxLayoutAlign="center center"> 2016 - 2023 &nbsp;
            <a href="{{environment.BASE_URL}}/p/{{environment.INTERNAL_BLOOM.LEGAL}}/privacy-policy" target="_blank" class="link-common"> Privacy</a> &nbsp;|&nbsp;
            <a href="{{environment.BASE_URL}}/p/{{environment.INTERNAL_BLOOM.LEGAL}}/terms-and-conditions" target="_blank" class="link-common"> Terms </a>
        </div>
    </div>

    <div fxFlex="5" fxHide.xs="true" fxLayout="column"></div>
    <div fxFlex="15" fxHide.xs="true" fxLayout="column" fxLayoutAlign="center">
        <div  fxLayout="row wrap" fxLayoutAlign="space-between center">
                <a fxLayout  href="https://www.facebook.com/jivrustechnologies" target="_blank"><img class="socialicon" src="https://storage.googleapis.com/jivrus-web-images/jcp/box/facebook/facebook-logo.png" alt="Facebook"></a>
                <a fxLayout  href="https://www.instagram.com/jivrustechnologies/" target="_blank"><img class="socialicon" src="https://storage.googleapis.com/jivrus-web-images/jcp/box/instagram/instagram-logo.png" alt="Instagram"></a>
                <a fxLayout  href="https://www.linkedin.com/company/jivrus-technologies" target="_blank"><img class="socialicon" src="https://storage.googleapis.com/jivrus-web-images/jcp/box/linkedin/linkedin-logo.png" alt="Linkedin"></a>
                <a fxLayout  href="https://twitter.com/jivrus"><img class="socialicon" src="https://storage.googleapis.com/jivrus-web-images/jcp/box/twitter/twitter-logo.png" alt="Twitter"></a>
        </div>
    </div>

</div>
