import { HttpClient } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleiconPickerService {

  private _iconMeta: any = [];
  //return Icon Meta
  get iconMeta() { return this._iconMeta; }
  private _topTenPopularIcons: any = [];
  //return Top 10 Poplar Icons
  get topTenPopularIcons() { return this._topTenPopularIcons }

  constructor(@Optional() private _http: HttpClient) { }

  //get Icon Meta from assets
  async getIconsMeta() {
    let result: any = {};
    try {
      let response: any = await this._http.get(`assets/icon/iconmeta.json`).toPromise();
      result.icons = this._iconMeta = response.icons;
      this._topTenPopularIcons = result.icons.slice(0, 10);
      return response.icons;
    } catch (error) {
      console.log(error)
      return error
    }
  }
}
