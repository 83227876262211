import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConnectionService } from 'src/app/modules/organization/connection.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectExplorerService {

  parentConnection = new BehaviorSubject<any>('');
  $parentConnection = this.parentConnection.asObservable()

  parentBase = new BehaviorSubject<any>('');
  $parentBase = this.parentBase.asObservable()

  parentBox = new BehaviorSubject<any>('');
  $parentBox = this.parentBox.asObservable()

  parentObject = new BehaviorSubject<any>('');
  $parentObject = this.parentObject.asObservable()

  constructor(
    private connectionService: ConnectionService
  ) { }

  setConnectionData(connection: any){
    console.log("setting connection", connection)
    this.parentConnection.next(connection)
  }

  setBaseData(base: any){
    console.log("setting base", base)
    this.parentBase.next(base)
  }
  
}
