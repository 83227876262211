import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Base } from 'crypto-ts/src/lib/Base';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';

@Component({
  selector: 'app-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.css']
})
export class SeparatorComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;

  @ViewChild('menuTrigger') separatorMenuTrigger: MatMenuTrigger;
  private destroy:any = new Subject();

  constructor(
    public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
  }

  ngOnInit(): void {
    // super.ngOnInit()
    // console.log("SEPARATOR ONINIT", this.selectedWidgetId)

    //separator component probably wont have styles attached, no need to run generateStyles()
    // super.ngOnInit()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.separatorMenuTrigger && this.separatorMenuTrigger.menuOpen) {
        this.separatorMenuTrigger.closeMenu()
      }
    }
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
    }
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        // "delete",
        // "copy"
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    if (event.actionType == "delete") {
      this.onDelete()
    }
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    // this.separatorMenuTrigger.closeMenu();
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

  onClick() {
    console.log("separator clicked", this.widgetMeta)
    this.selectedWidgetId = this.widgetMeta.id
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  customMouseEnter() {
    console.log("custom mouseenter fired")
  }
}
