<div class="outerCover"
  *ngIf="builderMode"
  #cover
  fxLayout="row" fxLayoutAlign="flex-start center"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onOuterClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle *ngIf="widgetOptions?.dragDrop !== false" [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover dotted-bg" (click)="onClick($event)" fxLayout="column">
    <div style="width: 100%;" [ngStyle]="{height: height}"></div>
  </div>
</div>


<div *ngIf="!builderMode" class="outerCover" #cover>
  <div class="innerCover">
    <div style="width: 100%;" [ngStyle]="{height: height}"></div>
  </div>
</div>
