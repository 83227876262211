<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<mat-card appearance="outlined">
  <h2 fxLayoutAlign="center start"> Privilege</h2>
  <div>
    <form [formGroup]="privilegeFormGroup" class="full-width"  fxLayoutAlign="center stretch" fxLayout="column" flex="100"
      (ngSubmit)="isNewPrivilege ? createPrivilege() : updatePrivilege()">
      <mat-form-field fxLayout="row">
        <mat-label>Privilege Code</mat-label>
        <input matInput formControlName="code" required />
        <mat-error *ngIf="privilegeFormGroup.controls.code.errors?.required">
          Please Enter Privilege Code !!
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Privilege Name</mat-label>
        <input matInput formControlName="name" required />
        <mat-error *ngIf="privilegeFormGroup.controls.name.errors?.required">
          Please Enter Privilege Name !!
        </mat-error>
        <mat-error *ngIf="privilegeFormGroup.controls.name.errors?.minlength">
          Privilege Name must be at least 3 characters long !!
        </mat-error>
        <mat-error *ngIf="privilegeFormGroup.controls.name.errors?.maxlength">
          Privilege Name mustn't exceed 20 characters !!
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <textarea matInput formControlName="description" required></textarea>
        <mat-error *ngIf="privilegeFormGroup.controls.description.errors?.required">
          Please Enter Description !!
        </mat-error>
      </mat-form-field>
      <div fxLayout="row" class="full-width" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button mat-stroked-button type="submit" color="primary"><span style="margin-top: 10px">Save</span>
        </button>
        <button mat-stroked-button type="button" (click)="backToListing()"><span
            style="margin-top: 10px">Back</span>
        </button>
      </div>
    </form>
  </div>

</mat-card>
