import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetUtilityService } from 'src/app/bloom/services/widget-utility.service';

import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  textEditMode: boolean = false;
  hoveredNow: boolean = false;
  inputVisible: boolean = false;
  editingHeading: boolean = false;
  availableOptions: any[] = []
  private destroy:any = new Subject();

  @ViewChild('menuTrigger') checkboxMenuTrigger: MatMenuTrigger

  @Output() raiseCheckboxChange: EventEmitter<any> = new EventEmitter()
  @Output() userInputReceived: EventEmitter<any> = new EventEmitter()

  constructor(
    private widgetUtilityService: WidgetUtilityService,
    public pageService: PageService,
    public metaService: MetaService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)
  }

  ngOnInit(): void {
    // console.log("checkbox component ngOnInit: ", this.widgetMeta)
    super.ngOnInit()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta && changes.widgetMeta.currentValue){
      // console.log("calling from on changes", JSON.parse(JSON.stringify(changes.widgetMeta.currentValue)))
      this.generateAvailableOptions()
      this.setContextActions()
    }
    if (changes.selectedWidgetId && (changes.selectedWidgetId.currentValue !== this.widgetMeta.id)) {
      if (this.checkboxMenuTrigger && this.checkboxMenuTrigger.menuOpen) {
        this.checkboxMenuTrigger.closeMenu()
      }
    }
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "underline",
        "italic",
        "alignment",
        "color",
        "backgroundColor",
        "fontSize",
        "fontFamily",
        "class",
        "edit",
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    // console.log("action is", JSON.parse(JSON.stringify(event)))
    // console.log("localMeta before", JSON.parse(JSON.stringify(this.widgetMeta)))

    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case "updateStyles":
        if (event?.data) {
          this.widgetMeta = event.data;
          // console.log("localMeta changed", JSON.parse(JSON.stringify(this.widgetMeta)))
          // this.newWidgetMeta.emit(this.widgetMeta)
          this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
          // console.log("after updateWidgetInPage", JSON.parse(JSON.stringify(this.widgetMeta)))

          super.generateStyles();
          this.generateAvailableOptions()
        }
        break;
      case "newOption":
        // console.log("need to add option", event.returnData)

        if(event.returnData && typeof event.returnData == 'string'){
          this.addItem(event.returnData)
          // this.newWidgetMeta.emit(this.widgetMeta)
          this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        }

        this.checkboxMenuTrigger.closeMenu();
      case 'settingsChanged':
        this.widgetMeta = event.data
        this.generateAvailableOptions()
        // this.newWidgetMeta.next(this.widgetMeta)
        this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
        break;
      default:
        break;
    }
  }

  checkboxChange(checked, item, index) {
    console.log("checked:", checked, "item:", item, "index:", index)

    if(!this.builderMode){
      // this.userInputReceived.emit({ item: item, index: index, value: checked });
      this.userInputReceived.emit({ 
        widgetId: this.widgetMeta.id,
        value: checked, 
      });
    }else{
      this.widgetMeta.config.availableOptions.staticOptions[index].default = checked
      this.generateAvailableOptions()
      this.newWidgetMeta.emit(this.widgetMeta)
      // this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
    }
  }

  /**
   * adds new static option
   * @param newOption is an object containing name[string], value[string] and default[boolean]
   */
  addItem(newOption: any) {
    // console.log("checkbox addItem hit with text:", newOption)

    let optionObject = {
      name: newOption.name,
      value: newOption.value,
      default: newOption.default
    }
    this.widgetMeta.config.availableOptions.staticOptions.push(optionObject)
    this.generateAvailableOptions(true)
    // this.newWidgetMeta.next(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  removeOption(i: number){
    this.widgetMeta.config.availableOptions.staticOptions.splice(i, 1)
    console.log("option removed")
    this.generateAvailableOptions(true)
    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  /**
   * changes the header text
   * @param newHeaderRef reference to the input field that will hold the new header text
   */
  saveNewHeading(newHeaderRef: any) {
    if (newHeaderRef.value == '') {
      console.log("emoty text")
      //show snackbar
      return
    }
    console.log("new title will be saved", newHeaderRef.value)
    this.widgetMeta.config.title.value = newHeaderRef.value

    // this.newWidgetMeta.emit(this.widgetMeta)
    this.pageService.updateWidgetInPage(this.widgetMeta, this.panelId)
  }

  /**
   * selects the widget, will also fire context menu(mat-menu)
   * @param event
   */
  onClick(event: any) {
    console.log("checkbox clicked", this.widgetMeta.id)
    this.selectedWidgetId = this.widgetMeta.id
    console.log("icon clicked", this.widgetMeta.id)
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
    this.checkboxMenuTrigger.closeMenu();
  }

  async generateAvailableOptions(noRefetch: boolean = false){
    // console.log("generate available options hit", noRefetch, JSON.parse(JSON.stringify(this.widgetMeta)))
    let staticOptions: any[] = this.widgetMeta.config.availableOptions.staticOptions

    let rawDynamicOptions: any[] = []
    let dynamicOptionItems: any[] = []
    if(noRefetch){
      dynamicOptionItems = this.availableOptions.filter(opt => opt.type == 'dynamic')
      console.log("preserved dynamic options", dynamicOptionItems)
    }else{
      if(this.widgetMeta.config.availableOptions.dynamicOptions.enabled){
        rawDynamicOptions = await this.widgetUtilityService.fetchDynamicOptions(this.widgetMeta, this.builderMode)
        dynamicOptionItems = this.widgetUtilityService.processDynamicOptions(rawDynamicOptions, this.widgetMeta)
      }
    }

    if(this.builderMode){
      staticOptions.map(opt => opt['type'] = 'static')
      dynamicOptionItems.map(opt => opt['type'] = 'dynamic')
    }

    this.availableOptions = []
    this.availableOptions = this.availableOptions.concat(staticOptions)
    this.availableOptions = this.availableOptions.concat(dynamicOptionItems)

    // console.log("options", this.availableOptions)
  }
}
