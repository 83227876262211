<div
  *ngIf="builderMode"
  class="outerCover"
  fxLayout
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onOuterClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover">
    <mat-form-field
      fxFlexFill
      [appearance]="this.widgetMeta.config.appearance && this.widgetMeta.config.appearance.value ? this.widgetMeta.config.appearance.value : 'standard'"
      (click)="$event.preventDefault()"
    >
      <mat-label [ngStyle]="styles">{{ widgetMeta.config.label.value }}</mat-label>
      <mat-select
        (click)="$event.preventDefault()"
        [(value)]="widgetMeta.config.textContent.value"
        (selectionChange)="selectionHandler($event)"
      >
        <ng-container *ngFor="let option of availableOptions; let i = index">
          <mat-option [disabled]="builderMode" [value]="option.value">
            <div fxLayout fxLayoutAlign="space-between center">
              <span>{{option.name}}</span>
              <!-- <mat-icon *ngIf="builderMode && option.type === 'static'" (click)="removeOption(i)">close</mat-icon> -->
            </div>
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<!-- style="border: 1px solid blue" -->
<div
  *ngIf="!builderMode" class="outerCover" (click)="onClick($event)"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" *ngIf="!widgetMeta.config.viewOnly?.value">
    <!-- <div>{{ "value :" + widgetMeta.config.textContent.value }}</div> -->
    <!-- style="border: 1px solid black" -->
    <mat-form-field
      fxFlexFill
      [appearance]="this.widgetMeta.config.appearance && this.widgetMeta.config.appearance.value ? this.widgetMeta.config.appearance.value : 'standard'"
    >
      <mat-label [ngStyle]="styles">{{ widgetMeta.config.label.value }}</mat-label>
      <mat-select
        [required]="widgetMeta.config.required && widgetMeta.config.required.value"
        [formControl]="fc"
        [(ngModel)]="widgetMeta.config.textContent.value"
        (selectionChange)="selectionHandler($event)"
      >
        <mat-option [value]="option.value" *ngFor="let option of availableOptions">{{ option.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="fc.invalid && fc.hasError('required')">This field is required</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="widgetMeta.config.viewOnly?.value"
    class="innerCover label-z-index content" [ngStyle]="styles">
    <mat-label [ngStyle]="styles">{{ widgetMeta.config?.textContent?.value }}</mat-label>
  </div>
</div>
