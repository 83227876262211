import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
// import { BuilderToolbarComponent } from './builder-toolbar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { DeleteDialogComponent } from './delete-dialog.component';
import { FormsModule } from '@angular/forms';
import { PanelComponent } from './panel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpecializedPanelsModule } from '../../specialized-panels/specialized-panels.module';
import { WidgetBuilderModule } from '../../widget-builder/widget-builder.module';
import { WidgetsWrapperModule } from '../../widget-wrapper/widget-wrapper.module';
import { ListPanelModule } from '../../specialized-panels/list-panel/list-panel.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';

@NgModule({
  declarations: [PanelComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatToolbarModule,
    FormsModule,
    ListPanelModule,
    SpecializedPanelsModule,
    WidgetBuilderModule,
    WidgetsWrapperModule,
    SpinnerModule
  ],
  exports: [PanelComponent]
})
export class PanelModule { }
