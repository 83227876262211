import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from '../../services/auth-service.service';
import { ConnectionService } from '../../../modules/organization/connection.service';
import { SidenavService } from '../sidenav.service';
import { PermissionService } from 'src/app/core/services/permission.service';
import { environment } from 'src/environments/environment';
import { UIConfigService } from '../../services/UIConfigService';

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.scss'],
})
export class LeftnavComponent implements OnInit {
  bloomList: any = [];
  sidenavMode = '';
  baseURL: any = environment.LOGO_BASE_URL

  //get methods
  get encodeURIComponent() { return encodeURIComponent }

  get router() { return this._router; }

  get authService() { return this._authService; }

  get connectionService() { return this._connectionService; }

  get sidenavService() { return this._sidenavService; }

  get permission() { return this._permission; }

  constructor(
    private _router: Router,
    private _authService: AuthServiceService,
    private _connectionService: ConnectionService,
    private _sidenavService: SidenavService,
    private _permission: PermissionService,
    public uic: UIConfigService,
  ) { }

  ngOnInit(): void {
  }
  onConnectionClicked() {
    this.router.navigate(['connection'], {
      queryParams: {
        workspaceid: this.connectionService.workSpaceId,
        authorization: encodeURIComponent(
          this.connectionService.preAuthenticatedToken
        ),
      },
    });
    this.isMobile();
  }
  onBloomClicked() {
    this.router.navigate(['bloom']);
    this.isMobile();
  }

  onFlowClicked() {
    this.router.navigate(['flow']);
    this.isMobile();
  }

  onStarchClicked() {
    this.router.navigate(['starch']);
    this.isMobile();
  }

  onFormClicked() {
    this.router.navigate(['form']);
    this.isMobile();
  }
  onHomeClicked(){
    this.router.navigate(['home'])
    this.isMobile();
  }
  isMobile() {
    if (this.sidenavService.mobileQuery.matches)
      this.sidenavService.toggle('left');
  }
}
