<div style="padding: 1rem;">
  <h2 mat-dialog-title>{{data?.title||'Confirm to delete'}}</h2>
  <mat-dialog-content>Do you want to delete {{data.name}}? <br>This action cannot be reverted !</mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="true" color="primary">
      Confirm
    </button>
    <button mat-raised-button [mat-dialog-close]="false" color="cancel">
      Cancel
    </button>
  </mat-dialog-actions>
</div>