import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { environment } from 'src/environments/environment';

//variable use for getting access token for drive files
declare var gapi: any;
//variable use for create google picker GUI options
declare var google: any;

@Component({
  selector: 'googlepicker',
  templateUrl: './googlepicker.component.html',
  styleUrls: ['./googlepicker.component.scss']
})
export class GooglepickerComponent implements OnInit {

  //Event to communicate with parent component
  @Output() setFileId = new EventEmitter();
  //Get Input from parent component
  @Input() InputId = '';
  //Get Input from parent component
  @Input() variants = ['googlesheets'];
  //flag
  pickerApiLoaded = false;
  //local variable
  accesstoken: any;
  mimeType: string;
  tokenClient:any

  //credentials
  pickerCredentials = {
    clientId: "588098188067-mhekasl0ustc3n35a4lvbm6kbn7kfa10.apps.googleusercontent.com",
    developerKey: "AIzaSyCUzWHtmlthsndwsB3xDtW0qo1TpPT1eJ8",
    appId: "588098188067",
    scope: ['https://www.googleapis.com/auth/drive.file']
  }
  //MIME Type Map in google drive supports
  mimeTypeMap: Map<string, string> = new Map([['googlesheets', 'application/vnd.google-apps.spreadsheet'], ['googleforms', 'application/vnd.google-apps.form'], ['googledocs', 'application/vnd.google-apps.document']])
  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    console.log('Cookie is enabled...?', navigator.cookieEnabled);
    if (!navigator.cookieEnabled) {
      this._snackBar.open(`Cookies are not enabled, please enable the cookies in order to use Google Picker. For the steps to enable cookies in your browser, visit here https://www.jivrus.com/resources/articles/g-suite/add-ons-faq#h.uptbpcsnc4n9`, 'Ok')
    }
    // this.tokenClient = google.accounts.oauth2.initTokenClient({
    //   client_id: this.pickerCredentials.clientId,
    //   scope: this.pickerCredentials.scope[0],
    //   ux_mode: 'popup',
    //   callback: this.handleAuthResult.bind(this)
    // });

    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.pickerCredentials.clientId,
      scope: this.pickerCredentials.scope[0],
      ux_mode: 'popup',
      prompt: undefined,
      callback: (response) => {
        console.log("token client initialized")
      }
    });

    console.log("google.accounts.oauth2", google.accounts.oauth2)

    // this.loadPicker();

  }

  //load auth or picker api
  loadPicker() {
    if (!this.accesstoken) {
      gapi.load('auth', { 'callback': this.onAuthApiLoad.bind(this) });
    }
    // gapi.load('picker', { 'callback': this.onPickerApiLoad.bind(this) });
    // gapi.load('picker', this.onPickerApiLoad.bind(this))
    gapi.load('client:picker',  this.onPickerApiLoad.bind(this));
    console.log("gapi", gapi)
    this.onPickerApiLoad()
  }

  //picker callback
  async onPickerApiLoad() {
    console.log("[ON PICKER API LOAD]")
    // await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    this.pickerApiLoaded = true;
    this.createPicker();
  }

  //open auth page to get access token
  onAuthApiLoad() {
    console.log("[AUTH API LOADED]")
  }

  //callback of auth API
  handleAuthResult(authResult: any) {
    console.log("authResult", authResult)

    if (authResult && !authResult.error) {
      this.accesstoken = authResult?.access_token || '';
      this.createPicker();
    }
  }

  // this.tokenClient.callback = async (response) => {
  //   if (response.error !== undefined) {
  //     throw (response);
  //   }
  //   accessToken = response.access_token;
  //   showPicker();
  // };

  //Create Google picker GUI
  createPicker() {
    console.log("[CREATE PICKER]")

    if (this.mimeType == undefined) {
      this.variants.forEach(i => {
        if (!this.mimeType) { this.mimeType = this.mimeTypeMap.get(i) || '' }
        else {
          this.mimeType = this.mimeType + ',' + i
        }
      })
    }
    // var view = new google.picker.View(google.picker.ViewId.DOCS);
    // view.setMimeTypes(this.mimeType);
    // var picker = new google.picker.PickerBuilder()
    //   .enableFeature(google.picker.Feature.NAV_HIDDEN)
    //   .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
    //   .setAppId(this.pickerCredentials.appId)
    //   .setOAuthToken(this.accesstoken)
    //   .addView(view)
    //   .addView(new google.picker.DocsUploadView())
    //   .setDeveloperKey(this.pickerCredentials.developerKey)
    //   .setCallback(this.pickerCallback.bind(this))
    //   .build();
    // picker.setVisible(true);



    console.log("response callbackee", this.tokenClient.callback())

    let that = this
    this.tokenClient.callback = async function (response) {

      console.log("response callback", response)
      if (response?.error !== undefined) {
        throw (response);
      }

      console.log("response", response)
      that.accesstoken = response.access_token;
      that.createPicker();
    };

    console.log('his.tokenClient', this.tokenClient.requestAccessToken())

    if (this.accesstoken) {
      console.log('access Token ', this.accesstoken)
      this.tokenClient.requestAccessToken({prompt: ''});

      var view = new google.picker.View(google.picker.ViewId.DOCS);
      view.setMimeTypes(this.mimeType);
      var picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(this.pickerCredentials.appId)
        .setOAuthToken(this.accesstoken)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setOrigin(this.getOrigins())
        .setDeveloperKey(this.pickerCredentials.developerKey)
        .setCallback(this.pickerCallback.bind(this))
        .setSize(1051,650)
        .build();
      picker.setVisible(true);

    } else {
      console.log('access Token undefined', this.tokenClient)
      this.tokenClient.requestAccessToken({prompt: 'consent'}) //.bind(this);
      console.log('access Token ', this.tokenClient)
    }
  }

  getOrigins() {
    let origin = document.location.origin;
    const origins = document.location.ancestorOrigins;
    console.log("Location", document.location)
    if (origins.length) origin = origins[origins.length - 1];
    console.log("Origin", origin)
    return origin;
  }

  //Google Picker Callback
  pickerCallback(data: any) {
    if (data.action == google.picker.Action.PICKED) {
      let fileId = data.docs[0].id;
      let resultObj = {};
      resultObj[this.InputId] = fileId;
      this.setFileId.emit(resultObj);
    }
  }

}
