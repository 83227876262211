import { Injectable } from "@angular/core";
import { PageService } from "src/app/bloom/services/page-service.service";
import { BaseExpression } from "./BaseExpression";


@Injectable({
  providedIn: 'root',
})
export class AddExpression extends BaseExpression {

  config: any = {}

  constructor(private pageService?: PageService){
    super()
    this.config.id = '__add__';
    this.config.name = 'Add';
    this.config.description = 'Add expression takes n number of static or dynamic values and returns the additon result'
    this.config.parameters = {
      paramIds: ['itemsToAdd'],
      itemsToAdd: {
        id: 'addItems',
        name: 'Numbers to add',
        minCount: 2,
        maxCount: -1,
        required: true,
        dataType: 'number',
        widgetType: 'numberinput',
        value: 0,
        defaultValue: 0,
        dynamicAllowed: true,
        dynamic: false,
        dynamicSource: ''
      }
    }
  }

  evaluateExpression(expConfig){
    console.log("[add expression]", expConfig)
    if(expConfig?.id !== this.config.id || !expConfig?.parameters?.itemsToAdd?.length) return null
    let sum: any
    expConfig.parameters.itemsToAdd.forEach((item, i) => {
      if(item.dynamic){
        let widgetId = item.dynamicSource.split('.')[1]
        let panelId = item.dynamicSource.split('.')[0]
        item.value = this.getPageValue(widgetId,  panelId)
      }
      if(item.value !== undefined || item.value !== null){
        let val = parseFloat(item.value)
        if(isNaN(val)) val = item.value

        if(i == 0) sum = val
        else sum += val
      }
    });
    return sum
  }

  getExpressionDetail() {
    return this.config
  }

  getPageValue(wId, pId){
    let val
    let pageModel = JSON.parse(JSON.stringify(this.pageService.getPageModel()))
    Object.keys(pageModel).forEach(panelId => {
      Object.keys(pageModel[panelId]).forEach(widgetId => {
        if(panelId == pId  && widgetId == wId){
          val = pageModel[panelId][widgetId]['value']
        }
      })
    })
    return val
  }
}
