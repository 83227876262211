import { DefaultURL } from "./DefaultURL";
import productionJson from "./urlConfig/production.json";
import stagingJson from "./urlConfig/staging.json";
import developmentJson from "./urlConfig/development.json";
import localJson from "./urlConfig/local.json";

export abstract class RouterUtility {
    constructor() {
    }

  static envConfigRegistry(env: string) {
      const config: any = {
          "production": productionJson,
          "staging": stagingJson,
          "development": developmentJson,
          "local": localJson
      };

      const jsonConfig = config[env] || config["local"];
      // console.log("[envConfigRegistry] jsonConfig-->", jsonConfig);
      return jsonConfig;
  }

    static getBaseUrls(env:any, type:any){
        const defaultUrl = new DefaultURL();
        return defaultUrl.getBaseUrls(env, type)
    }

    static getConfig(env:any){
        return RouterUtility.envConfigRegistry(env);
    }

    public abstract getBaseUrls(env: any, type:any): any;
}
