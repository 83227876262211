import { Widget } from "./baseWidget";
import { TextFormatting } from './textFormat'

export class Link extends Widget {
  type = 'link';
  gridX = 4
  minGridX = 3

  config = {
    props: [
      "linkText",
      "linkUrl",
      'tooltip'
    ],
    linkText: {
      displayName: "Link Text",
      value: "clickable link",
      type: 'text'
    },
    linkUrl: {
      displayName: "Link URL",
      value: "",
      type: 'text'
    },
    tooltip: {
      displayName: 'Tooltip',
      value: this.type,
      type: 'text',
      enabled: true
    },
  };

  textFormat;

  constructor(id: number, name: string) {
    super(id, name);
    this.attachCommonConfig(this)

    // initialize default text formatting
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    // add widget specific text formatting
    this.textFormat.color.value = '#0000EE'
    this.textFormat.underline.value = true
  }

  public getWidgetConfig(){
    let map = {
      valuePath: "config.linkUrl.value"
    }
    return map;
  }
}

