<div fxLayout="column" fxLayoutAlign="center center">
        <h2 fxLayoutAlign="center center">Edit Attribute</h2>
            <div fxLayout="row" *ngIf="this.dialogData?.row?.__id?.value" fxLayoutAlign="center center">
                Exisitng attribute: {{this.dialogData?.row?.__id?.value}}
            </div>
            <br/>
            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label> Update attribute </mat-label>
                    <input required [(ngModel)]="attributeMap.__id" matInput placeholder="Update attribute">
                </mat-form-field>
            </div>

            <div>
                <mat-slide-toggle (change)="relationshipChanged()" [(ngModel)]="isRelation"> Add relationship</mat-slide-toggle>
            </div>

            <div *ngIf="isRelation">
                <autocomplete placeholder="Select Object" key="id" *ngIf="objects.length"
                [data]="objects" [selectedValue]="exisitngRelationMap.object" (result)="selectedObject($event)">
                </autocomplete>

                <autocomplete placeholder="Select Attribute" key="id" *ngIf="relationObjectAttributes.length"
                [data]="relationObjectAttributes" [selectedValue]="exisitngRelationMap.attribute" (result)="selectedAttribute($event)">
                </autocomplete>

                <autocomplete placeholder="Select Attribute Name" key="id" *ngIf="relationObjectAttributes.length"
                [data]="relationObjectAttributes" [selectedValue]="exisitngRelationMap.name" (result)="selectedName($event)">
                </autocomplete>

                <autocomplete placeholder="Select Cardinality" key="id" *ngIf="cardinalities.length"
                [data]="cardinalities" [selectedValue]="exisitngRelationMap.cardinality" (result)="selectedCardinality($event)">
                </autocomplete>
            </div>


            <div class="fullWidth" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%" style="margin: 5px;">
                <button mat-raised-button
                 color="primary" style="margin-top: 2rem; margin-left: 1rem;"
                   (click)="updateAttribute()">
                    Update Attribute
                </button>
            </div>



            <div class="fullWidth" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%" style="margin: 5px;">
                <button mat-button color="warn" style="margin-top: 2rem; margin-left: 1rem;" (click)="close()">
                    Close
                </button>
            </div>
    </div>



