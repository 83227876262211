import { SharedModule } from '../../app-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRangeSliderModule } from '../../mat-range-slider/mat-range-slider.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { TriggerWebhookComponent } from './trigger-webhook.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TestWebhookComponent } from './test-webhook/test-webhook.component';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';



const routes: Routes = [
  {
    path: '', component: TriggerWebhookComponent
  }]

@NgModule({
  declarations: [TriggerWebhookComponent, TestWebhookComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatPaginatorModule,
    MatCardModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatRangeSliderModule,
    ClipboardModule,
    MatExpansionModule,
    SpinnerModule,
    RouterModule.forChild(routes)
  ],
  exports: [TriggerWebhookComponent]
})
export class TriggerWebhookModule { }
