import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { COMMA, E, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-group-config',
  templateUrl: './group-config.component.html',
  styleUrls: ['./group-config.component.scss']
})
export class GroupConfigComponent implements OnInit {
  @Input() group;
  @Input() boxObjectAttributes;
  @Input() hideToggle: boolean;
  @Output() newGroupConfig = new EventEmitter<any>();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  groupAttriCtrl = new UntypedFormControl('');
  attributesForGroup: any;

  constructor() { }

  ngOnInit(): void {
    // console.log("group in panel group config", this.group)
    // console.log("showToggle", this.hideToggle)
    this.emitGroup()
  }

  displayFn(attr) {
    return attr && attr.__id ? attr.__id : ''
  }

  private _filterAttributesFilter(value: string): string[] {
    const filterValue = value?.toLowerCase();
    let filterables: any[] = this.boxObjectAttributes.filter((attr: any) => attr) //.filterable
    return filterables.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  groupToggleChanged(toggleValue:any){
    this.group.groupEnabled = toggleValue.checked;
    this.emitGroup()
  }

  addGroup(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.group.attributes.push(value);
    }
    // Clear the input value
    event.chipInput!.clear();

    this.groupAttriCtrl.setValue(null);
  }

  removeGroup(fruit: string): void {
    const index = this.group.attributes.indexOf(fruit);
    if (index >= 0) {
      this.group.attributes.splice(index, 1);
    }
    this.emitGroup()
  }

  selectedGroup(event: any): void {
    this.group.attributes.push(event.option.value);
    console.log("selectedGroup attributes", this.group.attributes)
    // this.fruitInput.nativeElement.value = '';
    this.groupAttriCtrl.setValue(null);
    // this.addGroupInQuery()
    this.emitGroup()
  }

  setGroupList(){
    console.log("this.attributesForGroup", this.attributesForGroup)
    this.attributesForGroup = this.groupAttriCtrl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value?.__id)),
      map(value => this._filterAttributesFilter(value))
    );
  }

  // addGroupInQuery(){
  //   let groupStr = "";
  //   console.log("this.group.attributes", this.group.attributes)
  //   this.group.attributes.forEach(element => {
  //     if(element.__id || element.name){
  //       if(groupStr) groupStr = groupStr + ",";
  //       groupStr = `${element.__id || element.name}`;
  //     }
  //   });
  //   this.customQuery.group = groupStr;
  //   this.inputMap['groupConfig'] = this.group;
  //   this.inputMap['query'] = this.customQuery;
  //   this.inputParameter.emit(this.inputMap);
  // }

  emitGroup(){
    this.newGroupConfig.emit(this.group)
  }


}
