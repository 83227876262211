<div class="header" fxLayout="row" fxLayoutAlign="space-between start">
  <h2 mat-dialog-title>{{ showDetails ? viewTitle : (showForm ? editTitle : "") }}</h2>
  <button #closeButton mat-button mat-dialog-close (click)="cancel()" style="cursor: pointer;">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <div *ngIf="showDetails">
    <app-panel [panelMeta]="detailsPanel" [builderMode]="false"></app-panel>
    <div style="height: 1rem"></div>
  </div>
  
  <div *ngIf="showForm">
    <app-panel [panelMeta]="formPanel" [builderMode]="false" (userInputReceived)="userInputHandler($event)"></app-panel>
  </div>
  
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button *ngIf="isViewEdit && showDetails" mat-flat-button color="primary" (click)="editData()">Edit Data</button>
  <button *ngIf="isViewEdit && showForm" mat-flat-button color="primary" (click)="cancelEdit()">Cancel Edit</button>
</mat-dialog-actions>
<!-- <button mat-flat-button color="basic" (click)="cancelEdit()">Cancel edit</button> -->
