<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div style="min-height: 100px;background-color: transparent;" fxLayout="row wrap" fxLayoutAlign="center center">
  <br/>
  <div fxLayout="column" fxFlex="5"></div>
  <div fxLayout="column" fxFlex="90">
      <div fxLayout="row" style="margin-top: 10px;" fxLayoutAlign="center center" >
          <div fxLayout="column" class="base-field" fxFlex="100">
              <mat-form-field style="width: 100%;">
                  <mat-label> Object name</mat-label> 
                  <input required [(ngModel)]="inputMap.object" (input)="objectInputReceived()" matInput placeholder="Object name">
              </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="20">
          </div>
      </div>
  </div>
  <div fxLayout="column" fxFlex="5"></div>
</div>

<div *ngIf="object.input.list.includes('attributes')">
  <div fxLayout="row"> <div fxLayout="column" fxFlex="5"></div> 
    <h2 fxLayout="column" fxFlex="90"> Create attributes </h2>
   </div>
  <div fxLayout="row"> 
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout="column" fxFlex="90">
       <attribute-parameter-inputs [attribute]="object.input.attributes" (inputParameter)="attributesReceived($event)" > </attribute-parameter-inputs>
    </div>
     <div fxLayout="column" fxFlex="5"></div>
  </div>
  
</div>

