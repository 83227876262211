import { Injectable } from "@angular/core";
import { AddExpression } from "./expressionClasses/Add";
import { BloomLoggedUserExpression } from "./expressionClasses/BloomLoggedUser";
import { BloomOwnerExpression } from "./expressionClasses/BloomOwner";
import { BloomUserLocaleExpression } from "./expressionClasses/BloomUserLocaleExpression";
import { CurrentDateTimeExpression } from "./expressionClasses/CurrentDateTime";

@Injectable({
  providedIn: 'root'
})
export class ExpressionRegistry {

    constructor(
      public addExpression: AddExpression,
      public currentDateTimeExpression: CurrentDateTimeExpression,
      public bloomOwnerExpression: BloomOwnerExpression,
      public bloomLoggedUserExpression: BloomLoggedUserExpression,
      public bloomUserLocaleExpression: BloomUserLocaleExpression,
    ){}

    widgetToDatatype: any = {
      label: 'string',
      button: 'string',
      input: 'string',
      textarea: 'string',
      image: null,
      autocomplete: 'string',
      card: null,
      checkbox: 'string',
      choice: 'string',
      chips: 'string',
      date: 'datetime',
      datetime: 'datetime',
      duration: null,
      embed: null,
      icon: null,
      imageinput: null,
      link: null,
      numberinput: 'number',
      period: null,
      richtext: 'string',
      select: 'string',
      separator: null,
      slider: 'number',
      star: 'number',
      time: 'datetime'
    }

    compatibilityMapping: any = {
      string: ['__bloomowneremail__', '__bloomuseremail__'],
      datetime: ['__currentdatetime__', '__bloomuserlocale__'],
      number: ['__add__']
    }

    registry: any = {
        __add__: this.addExpression,
        __currentdatetime__: this.currentDateTimeExpression,
        __bloomowneremail__: this.bloomOwnerExpression,
        __bloomuseremail__ : this.bloomLoggedUserExpression,
        __bloomuserlocale__: this.bloomUserLocaleExpression
    }

    getExpressionDetail(type){
        let expressionFunction = this.registry[type];
        if (!expressionFunction) throw "No expression available: " + type;
        console.log("expression found:", expressionFunction)
        return expressionFunction.getExpressionDetail()
    }

    getAllExpressionDetails(widgetType?: string){
      console.log("widgetType", widgetType)
        let result: any[] = []
        var keys =  Object.keys(this.registry);
        for(var k of keys){
            console.log("[registry] key", k)
            var expression = this.getExpressionDetail(k)
            console.log("[registry] expression", expression)
            result.push(expression)
        }
        if(widgetType){
          console.log("widgetType block")
          let compatibleExpressionIds = this.compatibilityMapping[this.widgetToDatatype[widgetType]] || []
          console.log("compatible expressionIds")
          result = result.filter(exp => compatibleExpressionIds.includes(exp.id))
          console.log("filtered expressions", result)
        }
        return result;
    }

    getExpressionClass(type){
      let expressionFunction = this.registry[type];
      if (!expressionFunction) throw new Error("No expression available: " + type);
      return expressionFunction
    }

    getAllExpressionClasses(){
      let result: any[]
      Object.keys(this.registry).forEach(exp => result.push(this.registry[exp]))
      return result
    }
}
