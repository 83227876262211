<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<div fxLayout="row" class="mat-h3" fxLayoutAlign="center center"> Test your webhook</div>
<div fxLayout="row">
  <div fxLayout="column" *ngIf="flow.trigger?.webhook_url">
    <div fxLayout="row" class="mat-h3" fxLayoutAlign="start center">
      Webhook URL
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      {{ flow.trigger?.webhook_url }}
    </div>
    <div fxLayout="row" class="mat-h3" fxLayoutAlign="start center">
      Method: {{ flow.trigger?.method || "POST" }}
    </div>
  </div>
</div>

<!-- <div fxLayout="column" *ngIf="flow.trigger?.is_authenticate">
  <div fxLayout="row" class="mat-h2" fxLayoutAlign="start center">
    Headers
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let header of headers">
    <div fxLayout="column" fxFlex="28">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Key</mat-label>
        <input matInput placeholder="Key" [(ngModel)]="header.key" class="input-field">
      </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="2"></div>
    <div fxLayout="column" fxFlex="70">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Value</mat-label>
        <input matInput placeholder="Value" [(ngModel)]="header.value" class="input-field">
      </mat-form-field>
    </div>
    <button mat-icon-button matTooltip="Delete Header" (click)="deleteHeader(header)" class="delete-icon">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center">
    <button style="cursor: pointer;" (click)="addHeader()" color="primary" mat-stroked-button matTooltip="Add header">
      <mat-icon>add_circle_outline</mat-icon>
      Add Header
    </button>
  </div>
</div> -->

<div fxLayout="row">
  <div fxLayout="column">
    <!-- Headers Section -->
    <div fxLayout="row" class="mat-h2" fxLayoutAlign="start center">
      Headers
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="28">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Key</mat-label>
          <input matInput placeholder="Key" [(ngModel)]="headerKey">
        </mat-form-field>
      </div>
      <div fxLayout="column" fxFlex="2"></div>
      <div fxLayout="column" fxFlex="70">
        <mat-form-field class="example-full-width" width= 50% appearance="outline">
          <mat-label>Value</mat-label>
          <input matInput placeholder="Value" [(ngModel)]="headerValue">
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button style="cursor: pointer;" (click)="addNewHeader()" color="primary" mat-stroked-button matTooltip="Add Header parameters">
        <mat-icon>add_circle_outline</mat-icon>
        Add Header
      </button>
      <br> <br>
    </div>
    <!-- List of Headers -->
    <div fxLayout="row" *ngFor="let header of headers; let i = index" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutGap="10px" id="header-key-value">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Key</mat-label>
          <input matInput placeholder="Key" [(ngModel)]="header.key" class="input-field">
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Value</mat-label>
          <input matInput placeholder="Value" [(ngModel)]="header.value" class="input-field">
        </mat-form-field>
      </div>
      <button mat-icon-button matTooltip="Delete Header" (click)="deleteHeader(i)" class="delete-icon">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>



<div fxLayout="row">
  <div fxLayout="column">
    <div fxLayout="row" class="mat-h2" fxLayoutAlign="start center">
      Query Parameters
    </div>
    <div fxLayout="row" *ngFor="let data of flow.trigger.query_data; let i = index" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutGap="10px" id="body-key-value">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Attribute</mat-label>
          <input matInput placeholder="Key" [(ngModel)]="data.__id" class="input-field">
        </mat-form-field>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Value</mat-label>
          <input matInput placeholder="Value" [(ngModel)]="queryBody[data.__id]" class="input-field">
        </mat-form-field>
      </div>
      <button mat-icon-button matTooltip="Delete Query Parameter" (click)="deleteQueryParameter(i)" class="delete-icon">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button style="cursor: pointer;" (click)="addNewQueryParameter()" color="primary" mat-stroked-button matTooltip="Add query parameters">
        <mat-icon>add_circle_outline</mat-icon>
        Add Parameters
      </button>
    </div>
  </div>
</div>

<div fxLayout="row">
  <div fxLayout="column">
    <div fxLayout="row" class="mat-h2" fxLayoutAlign="start center">
      Body Parameters
    </div>
    <div fxLayout="row" *ngFor="let data of flow.trigger.payload_data; let i = index" fxLayoutAlign="start center">
      <div fxLayout="column" fxLayoutGap="10px" id="body-key-value">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Attribute</mat-label>
          <input matInput placeholder="Key" [(ngModel)]="data.__id" class="input-field">
        </mat-form-field>
      </div>
      <div fxLayout="column" fxFlex="70" id="body-key-value" class="value-field-container">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Value</mat-label>
          <input matInput placeholder="Value" [(ngModel)]="body[data.__id]" class="input-field">
        </mat-form-field>
      </div>
      <button mat-icon-button matTooltip="Delete Body Parameter" (click)="deleteBodyParameter(i)" class="delete-icon">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <button style="cursor: pointer;" (click)="addNewBodyParameter()" color="primary" mat-stroked-button matTooltip="Add body Parameter">
        <mat-icon>add_circle_outline</mat-icon>
        Add Parameters
      </button>
    </div>
  </div>
</div>
<br>
<div fxLayout="row" fxLayoutAlign="center center">
  <button id="scrollTarget" style="cursor: pointer;" (click)="sendWebhook()" color="primary" mat-raised-button matTooltip="Test webhook">
    Test Webhook
  </button>
  
  <div fxFlex="5"></div>
  <button style="cursor: pointer;" (click)="closePopup()" color="accent" mat-stroked-button matTooltip="Go back">
    Back
  </button>
</div>
<div id="executionDetails" style="margin: 10px" *ngIf="response" fxLayout="row">
  <mat-accordion multi="true" *ngIf="response">
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title fxLayout="row" matTooltip="Click to see the details of execution">
          Webhook executed
        </mat-panel-title>
        <!-- <mat-panel-description fxLayout="row">
          Status Code: {{ response?.status ?? 'N/A' }}
        </mat-panel-description> -->
        <mat-icon matTooltip="Click to see the details of execution">expand_more </mat-icon>
      </mat-expansion-panel-header>
      <div fxLayout="row">
        <div fxLayout="column" fxFlex="5">
        </div>
        <div fxLayout="column" fxFlex="90">
          <div style="font-size: small;">{{ this.flow?.trigger?.webhook_url }}</div>
          <h2 style="margin-bottom: 10px; font-size: 14px;">Response Code: {{ response?.status ?? 'N/A' }}</h2> 
          <h2 style="margin-bottom: 10px; font-size: 14px;" *ngIf="response">Response</h2>
          <pre *ngIf="response">{{ response | json }}</pre>
        
        </div>
        <div fxLayout="column" fxFlex="5">
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>