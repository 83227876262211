<button mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>calendar_month</mat-icon>
</button>
<mat-menu #menu="matMenu" class="menu-overflow-hidden date-timepicker">
  <form [formGroup]="DateTime">
    <input fxHide formControlName="date">
    <mat-calendar (click)="$event.stopPropagation();" [(selected)]="date">
    </mat-calendar>
    <mcc-timer-picker (click)="$event.stopPropagation();" hideButtons [mccTimerPickerFormat]="timeFormat"
      (selected)="this.DateTime.controls['time'].setValue(($event))">
    </mcc-timer-picker>
    <input fxHide placeholder="timer" formControlName="time" />
  </form>
</mat-menu>
