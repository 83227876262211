import { Widget } from "./baseWidget";
import { AvailableOptions } from './optionsConfig'

export class Select extends Widget {
  type = 'select';
  gridX = 6
  minGridX = 3
  config = {
    props: [
      "availableOptions",
      "label",
      'tooltip',
      "textContent",
      'appearance',
      'required',
      'viewOnly'
    ],
    availableOptions: JSON.parse(JSON.stringify(AvailableOptions)),
    label: {
      displayName: "Label",
      value: "select option",
      type: 'text'
    },
    textContent: {
      displayName: "Default selected value",
      value: '',
      resetValue: '',
      type: 'text',
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    appearance: {
      displayName: " input appearance",
      value: "standard",
      type: "select"
    },
    required: {
      displayName: 'Required',
      type: 'boolean',
      value: false,
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not edit the value',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  };

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
  }

  public getMappingConfig(){
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig(){
    let map:any = {
      valuePath: "config.textContent.value",
    }
    return map;
  }

  /**
   * to indicate this widget is a selectable widget with options
   */
  public isOptionBasedWidget(){
    return true
  }

  public setOptions(values){
    if(!values){
      this.config.availableOptions.staticOptions.forEach(opt => {
        if(opt.default) opt.default = false
      });
    }
    return values;
  }
}
