
<div>
<div (click)="$event.stopPropagation()"  fxLayout="column" fxLayoutAlign="center center">
  <div  *ngFor="let app of templatesMap.list; index as i">
    <div class="build_button_div"  style="margin: 10px;" (click)="navigateToIntegration(templatesMap[app])">
      <div class="build_button" fxLayout="row">
        <div fxLayout fxLayoutAlign="center center" fxLayout="column" fxFlex="20">
          <img class="image" [src]="templatesMap[app].logo">
        </div>
        <div fxLayout="column" fxLayoutAlign="center start" fxFlex="80">
          <div style="color: #3f51b5; font-size:  large;; font-weight: bold;" fxLayout="row" 
          fxLayoutAlign="start center"> {{templatesMap[app].title}}</div>
          <div *ngIf="templatesMap[app].tagline" fxLayout="row" style="color: #6f7385;font-size: 12px;" fxLayoutAlign="start center">
            {{templatesMap[app].tagline}}</div>
      </div>
      </div>
    </div>

  </div>
</div>

</div>