import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit, Output, EventEmitter, ElementRef, Input, Inject, PLATFORM_ID } from '@angular/core';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { environment } from 'src/environments/environment';
import { FlowService } from '../../flow.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'flow-builder-tools',
  templateUrl: './flow-builder-tools.component.html',
  styleUrls: ['./flow-builder-tools.component.css'],
  host: {
    class: "container-with-thin-scrollbar"
  }
})
export class FlowBuilderToolsComponent implements OnInit {

  data: any = [];
  boundEvent: any;
  starchPanelOpenState:boolean = false
  panelPanelOpenState:boolean = false;
  connectionPanelOpenState: boolean = false;
  sectionsVisibilityMap = {
    display: true,
    standard: true,
    dateTime: true,
    media: true,
    charts: true,
    connections: true,
    panels: true,
    starch: true
  }
  environment = environment

  allApps: any[] = []
  filteredApps: any[] = []
  value: string = ""
  appsLoaded: boolean = false;

  dragData: any = {
    __action__: {toolType: "action"},
    __branch__: {toolType: "control"},
    // connection_id: {type: "connection", ...} one entry for each app goes here also, added later
  }

  @Input() sectionsToHide: any;
  isBrowser: any;

  constructor(
    private elementRef:ElementRef,
    // public widgetService: WidgetService,
    // public pageService: PageService,
    public flowService: FlowService,
    @Inject(PLATFORM_ID) platformId: Object,
    private connectionService: ConnectionService
  ){
    this.isBrowser = isPlatformBrowser(platformId);
    if(!this.isBrowser) return;
  }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    console.log("sections to hide", this.sectionsToHide)
    if(this.sectionsToHide?.length){
      this.sectionsToHide.forEach(section => {
        this.sectionsVisibilityMap[section] = false
      })
    }
    console.log("sections vis map", this.sectionsVisibilityMap)
    this.fetchAllBoxes()
  }

  @Output() createPanel = new EventEmitter<string>();

  createNewTool(data){
    this.flowService.toolDirectCreate(data)
  }

  dropWidget(event: CdkDragDrop<string[]>) {
    console.log("drop event", event)
    this.flowService.dropTool(event)
  }

  dragStarted(event: any){
    console.log("drag started", event.source.data)
    this.flowService.dragStarted(event.source.data)
  }

  inputDetected(searchValue: string){
    // console.log("app search input", searchValue)
    if(!searchValue) {
      this.filteredApps = this.allApps.filter(box => box.isPopular)
    }else{
      this.filteredApps = this.allApps.filter(app =>
        app.name.includes(searchValue) ||
        app?.description?.includes(searchValue) ||
        app?.organization.includes(searchValue) ||
        app?.type.includes(searchValue)
      )
    }
    this.filteredApps.forEach(app => {
      this.dragData[app.__id] = {...app, toolType: "action"}
    })
    console.log("drag data updated", this.dragData)
    console.log("filtered apps", this.filteredApps)
  }

  async fetchAllBoxes(){
    let res = await this.connectionService.getBoxes()
    this.allApps = res.data;
    console.log("all apps", this.allApps)
    this.appsLoaded = true
    this.inputDetected("")
    console.log("filtered apps", this.filteredApps)
  }
}
