import { SharedModule } from '../../app-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { StepControlComponent } from './step-control.component';
import { ConnectionSelectionModule } from '../../connection-selection/connection-selection.module';
import { AppSelectionModule } from '../../app-selection/app-selection.module';
import { ActionSelectionModule } from '../../action-selection/action-selection.module';
import { AppsPopupModule } from '../../apps-popup/apps.popup.module';
// import { MappingSetupModule } from 'src/app/shared/mapping-setup/mapping-setup.module';
import { ParameterInputsModule } from '../../parameter-inputs/parameter-inputs.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';


const routes: Routes = [
  {
    path: '', component: StepControlComponent
  }]

@NgModule({
  declarations: [StepControlComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    RouterModule.forChild(routes)
  ],
  exports: [StepControlComponent]
})
export class StepControlModule { }
