import { Widget } from "./baseWidget";
import { TextFormatting } from "./textFormat";

export class Slider extends Widget {
  type = 'slider';
  gridX = 6
  minGridX = 3

  config = {
    props: [
      "title",
      'value',
      'showTitle',
      "alignment",
      "minimum",
      "maximum",
      "initial",
      "step",
      "thumbLabel",
      "vertical",
      "inverted",
      "disabled",
      "leftMarker",
      "rightMarker",
      'tooltip',
      'viewOnly'
    ],
    title: {
      displayName: 'Title',
      value: 'Slide the handle to set value',
      type: 'text',
    },
    value: {
      displayName: 'Slider value',
      value: 0,
      type: 'number',
    },
    showTitle: {
      displayName: 'Show Title',
      value: true,
      type: 'boolean'
    },
    alignment: {
      displayName: "Text Alignment",
      value: "flex-start",
      type: 'app-alignment-settings'
    },
    minimum: {
      displayName: "Minimum Value",
      value: 0,
      type: 'number'
    },
    maximum: {
      displayName: "Maximum Value",
      value: 100,
      type: 'number'
    },
    initial: {
      displayName: "Initial Value",
      value: 0
    },
    step: {
      displayName: "Step Change",
      value: 1,
      type: 'number'
    },
    thumbLabel: {
      displayName: "Thumb Label",
      value: true    //we can add new modes later(we can put variable/function return values in thumb label)
    },
    vertical: {
      displayName: "Orientation Vertical",
      value: false
    },
    inverted: {
      displayName: "Inverted",
      value: false
    },
    disabled: {
      displayName: "Disabled",
      value: false
    },
    leftMarker: {
      displayName: "Left Marker",
      value: "",
      type: 'text',
    },
    rightMarker: {
      displayName: "Right Marker",
      value: "",
      type: 'text',
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the slider',
      value: false,
      type: 'boolean',
      resetValue: false
    }
  }

  textFormat;

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)

    //initialize the styles with default values
    this.textFormat = TextFormatting.initStyles(JSON.parse(JSON.stringify(TextFormatting.textFormatting())))

    // add widget specific text formatting if any

  }   //should apply to the heading

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.value.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map);
    return map;
  }
}
