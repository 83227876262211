import { SharedModule } from '../app-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { DisplayBannerComponent } from './display-banner.component';

const routes: Routes = [
  {
    path: '', component: DisplayBannerComponent
  }]

@NgModule({
  declarations: [DisplayBannerComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    DisplayBannerComponent
  ]
})
export class DisplayBannerModule { }
