import { QuillModule } from 'ngx-quill';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { NewConnectionModule } from 'src/app/modules/organization/connection/new-connection/new-connection.module';
import { BoxPickerModule } from 'src/app/shared/box-picker/box-picker.module';
import { ConnectionListComponent } from './connection-list.component';
import { ConnectionListBoxPopupComponent } from './connection-list-popup/connection-list-box-popup.component';

// import { GoogleChartsModule } from 'angular-google-charts';

@NgModule({
  declarations: [
    ConnectionListComponent,
    ConnectionListBoxPopupComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    QuillModule.forRoot(),
    BoxPickerModule,
    NewConnectionModule
  ],
  exports: [
    ConnectionListComponent,
    ConnectionListBoxPopupComponent
  ]
})
export class ConnectionListWidgetModule { }
