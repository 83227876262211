import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';

// export interface SnackBarValues {
//   snackBarMessage: string;
//   snackBarIcon?: string;
//   snackBarError?: boolean;
//   snackBarDuration?: number;
//   snackBarRecommendation?: string
// }

@NgModule({
  declarations: [SnackbarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatButtonModule
  ],
  exports: [SnackbarComponent]
})
// export type { snackBarValues as SnackBarValues }
export class SnackbarModule { }
