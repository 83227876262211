import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alignment-settings',
  templateUrl: './alignment-settings.component.html',
  styleUrls: ['./alignment-settings.component.css']
})
export class AlignmentSettingsComponent implements OnInit {

  @Input() alignment: string
  @Input() disableSpaceBetween: boolean
  @Output() newAlignment = new EventEmitter()

iconMap: any = {
  "flex-start": "format_align_left",
  "center": "format_align_center",
  "flex-end": "format_align_right",
  "space-between": "horizontal_distribute"
}

  constructor() { }

  ngOnInit(): void {
  }

  alignmentChanged(val){
    console.log('layout alignment changed', val)
    this.alignment = val
    this.newAlignment.next(this.alignment)
  }

}
