import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { WidgetManager } from '../../../WidgetManager';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { SystemFieldsService } from 'src/app/shared/services/system-fields.service';

@Component({
  selector: 'app-action-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {


  constructor(
    public metaService: MetaService,
    public widgetService: WidgetService,
    public systemFieldsService: SystemFieldsService
  ) {

   }

   widgetManager: any = WidgetManager;
   whitelabeledFields: any = [];

  @Input() "actionConfig": any;
  @Input() "displayConfig": any;
  @Input() "widget": any;
  @Input() "pagemeta": any;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
  @Output() actionSelectionOptionsInput: EventEmitter<any> = new EventEmitter();

  config: any;
  actionFields: any = [];
  sourceFields: any = [];
  systemFields: any = [];

  selectedActionMap:any

  ngOnInit(): void {
    this.getSystemFields();
    console.log("in app action", this.actionConfig);
    if(this.actionConfig.action) {
      if(!this.actionConfig.actionMap) this.actionConfig.actionMap = {};
      if(!this.actionConfig.actionMap.mapping) this.actionConfig.actionMap.mapping = [];
    }


    this.metaService.pageMeta.subscribe(pageMeta => {
      console.log("pageMeta--->", pageMeta)
      // this.pageMeta = pageMeta
      if(pageMeta) this.manageSourceFields(pageMeta);
    })

    console.log("loadedPageStructure in ", this.metaService.loadedPageStructure)
  }

  attributeOptionsInput(e){
    this.actionSelectionOptionsInput.emit(e);
    this.triggerChange()
  }

  triggerChange(){
    this.metaService.userChangeDetected()
  }


  async getSystemFields(){
    let res = await this.systemFieldsService.getSystemFields();
    for (let index = 0; index < res?.fields?.length; index++) {
      const field = res?.fields[index];
      this.systemFields.push(res[field])
    }
    console.log("SYSTEM FIELDS: ",this.systemFields);
  }

  appSelected(e){
    console.log("appselected", e);
    this.actionConfig.actionMap.boxId = e.__id;
    this.triggerChange()
  }

  baseSelected(base){
    this.actionConfig.actionMap['baseMap'] = {
      box_id: base.storage_box,
      base_id: base._id
    };
    this.actionConfig.actionMap.boxConfigToken = base.storage_token;
    this.triggerChange()
  }

  actionSelected(e){
    console.log("actionSelected", e);
    this.actionFields = e;
    this.selectionChanged.emit({type: "actionSelected", event: e})
    this.triggerChange()
  }

  inputOptionsRecevied(e){
    console.log("inputOptionsRecevied", e);
    this.actionConfig.actionMap.inputParams = e?.options
    this.triggerChange()
  }

  onmappingChange(e){
    console.log("onmappingChange", e)
    this.actionConfig.actionMap.mapping = JSON.parse(JSON.stringify(e));
    this.triggerChange()
  }

  selectedWhiteLabeledConnection(e){
    console.log("selectedWhiteLabeledConnection", e);
    this.actionConfig.actionMap.whiteLabelMap = {
      widget: e
    }
    this.triggerChange()
  }

  whiteLabelChanged(e){
    console.log("whiteLabelChanged", e);
    this.actionConfig.actionMap.whiteLabelConnection = e;
    this.triggerChange()
  }

  manageSourceFields(pageMeta){
    let te = new TemplateEngine();
    this.sourceFields = [];
    pageMeta?.panels?.forEach(panelMeta => {
      let panel = panelMeta;
      let widgets = this.widgetService.getWidgetsFromPanel(panel);
      widgets.forEach((widget ) => {

        if(WidgetManager.getWidget(widget.type)?.getMappingConfig(this.widget)){
          let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
          let data = {
            panel: panel,
            widget: widget
          }
          this.sourceFields.push(te.fillTemplateObject(config, data))
        }

        if(widget.type == "connection" && WidgetManager.getWidget(widget.type)?.getMappingConfig(this.widget)){
          let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
          let data = {
            panel: panel,
            widget: widget
          }
          this.whitelabeledFields.push(te.fillTemplateObject(config, data));
          console.log("this.whitelabeledFields", this.whitelabeledFields)
        }
      })
    })
    this.triggerChange()
  }

}
