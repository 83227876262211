<spinner *ngIf="spinner" [center]="true" [selfSpin]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>

<div *ngIf="onlyDisplay && currentSelectedAction" fxLayout="row">
  <div fxLayout="column" fxFlex="5">
    <mat-icon color="primary" matPrefix>event</mat-icon> </div>
  <div fxLayout="column" fxFlex="5">
  </div>
  <div fxLayout="column" fxFlex="80">
     {{currentSelectedAction?.name}}
  </div>
  <div fxLayout="column" fxFlex="10"></div>
</div>

<div fxLayout="row" *ngIf="display && !onlyDisplay && !config?.hideSelection">
    <mat-form-field fxFlex.lt-sm="100%" fxFlex="80%">
        <mat-icon color="primary" matPrefix>event</mat-icon>
        <mat-label>
          <span>&nbsp;Choose {{actionType ? actionType : config.isListOnlyObject ? 'Object' : 'Action'}}</span>
        </mat-label>
        <input matInput #actionInput
        [formControl]="actionListingACFrom"
        (focus)="setStepFilterOptions()"
        [matAutocomplete]="autoBoxEvent" />
        <button *ngIf="actionListingACFrom.value"  matTooltip="Clear selected {{actionType ? actionType : 'Action'}}" matSuffix mat-icon-button aria-label="Clear"
          (click)="actionListingACFrom.patchValue('');$event.stopPropagation();selectedAction.emit(null)">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete
          #autoBoxEvent="matAutocomplete"
          [displayWith]="displayFunction"
          (optionSelected)="actionSelected($event.option.value);">
          <mat-option *ngFor="let option of actionListingOptions | async" [value]="option">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="actionListingACFrom?.errors?.errorMsg">
          {{ actionListingACFrom.errors.errorMsg }}
        </mat-error>
      </mat-form-field>
      <mat-spinner
        *ngIf="spinner"
        color="warn"
        [diameter]="20"
      ></mat-spinner>
</div>

<div *ngIf="isOptionsToCollect" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="1rem">
  <ng-container *ngFor="let option of attributeOptions; let i = index">
    <mat-form-field>
      <mat-label>{{ option.name }}</mat-label>
      <input matInput (input)="attributeOptionChanged($event, i)" [required]="option.required" [(ngModel)]="option.value">
      <mat-hint style="color: #3f51b5;">Type: {{ option.dataType }}</mat-hint>
    </mat-form-field>
  </ng-container>

  <button mat-stroked-button color="primary" (click)="getAttributes()"><mat-icon>east</mat-icon></button>
</div>
