export const ChartDataSource: any = {
  displayName: "Chart data source",
  connectionId: '',
  boxId: '',
  boxName: '',
  boxObjectId: '',
  metric: {
    attribute: {},
    operation: ''
  },
  getFnOptions: [],
  filter: {
    filterEnabled: false,
    filterItems: []
  },
  chartOrder: {
    field: 'none',
    order: "ASC"
  },
  dataSourceType: 'dynamic',    // 'dynamic' || 'static'
  staticData: [],
  type: 'chart-data-source'
}
