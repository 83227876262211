import { SharedModule } from '../app-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MatSliderModule } from '@angular/material/slider';
import { MatRangeSliderComponent } from '../mat-range-slider/mat-range-slider.component';



const routes: Routes = [
  {
    path: '', component: MatRangeSliderComponent
  }]

@NgModule({
  declarations: [ MatRangeSliderComponent],
  imports: [
    CommonModule,
    SharedModule,
    CommonModule,
    SharedModule,
    MatSliderModule,
    RouterModule.forChild(routes)
  ],
  exports: [MatRangeSliderComponent]
})
export class MatRangeSliderModule { }
