import { AfterContentInit, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER, F } from '@angular/cdk/keycodes';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { BoxService } from 'src/app/bloom/services/box-service.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog } from '@angular/material/dialog';
import { CollectOptionsDialogComponent } from '../collect-options-dialog/collect-options-dialog.component';

// interface destinationConnection {
//   connectionId: string,
//   boxName: string,
//   boxId: string,
//   boxObject: string,
//   logoUrl: string,
//   selected: boolean,
//   // attrMap is a mapping from source attribute Id to destination attribute Id
//   attrMap: {
//     [key: string] : string
//   }
// }

interface BOX_CONFIG {
  boxId: string,
  boxObjectId: string,
  connectionId: string,
  boxName: string,
  attributeOptions: any[],
  getFnOptions?: any[],
  getFn?: any
}

@Component({
  selector: 'app-navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.css']
})
export class NavigationPanelComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @Input() attr: any;
  @Input() panelMeta: any;
  @Input() boxConfig: BOX_CONFIG;
  @Input() attributeList: any;
  @Input() connectionId: any;
  @Input() boxObjectId: any;
  @Input() bypassSave: boolean;
  @Output() emitCollapsePanel = new EventEmitter;

  selectPageControl = new UntypedFormControl();
  linkAttributesControl = new UntypedFormControl();
  linkAttributesFrom = new UntypedFormGroup({});
  sourceAttrControl = new UntypedFormControl();
  destAttrControl = new UntypedFormControl();

  @ViewChild('linkAttributeInput') linkAttributeInput: ElementRef<HTMLInputElement>;
  @ViewChild('linkInput') linkInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  navigationEnabled: boolean = true;
  navigationType: string; // 'internal' || 'external'
  externalLinkUrl: string;
  relativeLinkUrl: string;
  pageStructure: any;
  pageCodeList: string[] = [];
  pageNameList: string[] = [];
  filteredPages: any;
  isLinkSet: boolean = false;
  // isNavigationTypeSelected: boolean = false;
  selectedPageCode: string;
  currentPageCode: string;
  linkAttributes: any[] = [];
  isReadyToSave: boolean = false;
  filteredLinkAttributes: any;

  linkErrorMessage: string
  linkError: boolean = false

  destinationDataModel: any;
  destConnList: any = []
  selectedConnIndex: number;

  boxObjectAttributes: any = {};
  mappingOpenedFor: number;
  destAttrs: any[] = []
  mappingSpinner: boolean = false;

  autoCreatePageConfigOpened: boolean = false;
  diffOjectPageCreateConfig: boolean = false;
  isDetailsBoxChosen: boolean = true

  isBoxConfigError: boolean = false;
  isBoxObjectConfigError: boolean = false;
  boxConfigError: any = ''
  boxObjectConfigError: any = '';

  selectedDestBoxName: string;
  selectedDestBoxId: string;
  destBoxConfigToken: string;
  selectedDestConnectionId: string;
  selectedDestObjectId: string;
  isBoxSelected: boolean = false;
  canGetBoxObjects: boolean = false;
  isBoxObjectSelected; boolean = false;
  boxFunctions: any[];

  readyToChoseConn: boolean = false
  pageCreatingSpinner: boolean = false
  gettingObjFunSpinner: boolean = false

  terminationError: boolean = false
  terminationErrorMessage: string

  isGetOptionsToCollect: boolean = false
  getFnOptions: any[] = []

  navFilterAttributes: any[] = []
  navAttributeSelectionAreaOpened: boolean = false

  lastPlacedCursorPosition: number = -1
  listAttributesMap: any = {
    list: []
  }
  parameterTypes: any[] = [
    {name: "Value", icon:"text_fields", value: 'value', description: ''},
    {name: "Attribute", icon:"text_rotation_none", value: 'attribute', description: ''},
  ]

  paramTypeLogoMap: any = {
    "value": {
      icon: "text_fields",
      name: "Value"
    },
    'attribute': {
      icon: "text_rotation_none",
      name: "Attribute"
    },
  }

  pageStructureObserver = {
    next: (pageStructure: any) => {
      console.log("page structure received from service", pageStructure)
      if(!pageStructure) return
      this.pageStructure = pageStructure
      // this.pageCodeList = this.pageStructure.pages.filter(code => code !== this.currentPageCode)
      this.pageCodeList = this.pageStructure.pages
      this.pageCodeList.forEach(code => {
        if(this.pageStructure[code]?.name){
          this.pageNameList.push(this.pageStructure[code].name)
        }
      })
    },
    error: err => {},
    complete: () => {}
  }

  constructor(
    private metaService: MetaService,
    private pageService: PageService,
    private boxService: BoxService,
    private connectionService: ConnectionService,
    private automationService: AutomationService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    // this.filteredLinkAttributes = this.attributeList
    // .filter(attr => this.panelMeta.listAttributes.find(listAttr => listAttr.__id == attr.__id))

    // console.log("filtered link attributes assigned", this.filteredLinkAttributes)

    if(this.attr && !this.attr.navigationSettings) {
      this.attr.navigationSettings = { tooltip: ""}
    }

    this.pageService.$currentPageCode.subscribe(pageCode => {
      this.currentPageCode = pageCode
    })

    this.metaService.get_page_structure.subscribe(this.pageStructureObserver)

    // FILTER FOR PAGES
    this.filteredPages = this.selectPageControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this.pageNameList.filter(pageName => pageName.toLowerCase().includes(value.toLowerCase())))
    );

    this.createAttributePickerMap()
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.panelMeta?.currentValue) {
      this.panelMeta = changes.panelMeta.currentValue
      this.createAttributePickerMap()
    }
  }

  setAttributeList(i){
    this.filteredLinkAttributes = this.linkAttributesFrom.get(`form${i}`).valueChanges.pipe(
      startWith(''),
      map(value => {
        //the whole attribute object comes here as value
        // console.log("value from attributes input", value)
        return typeof value === 'string' ? value : value.name
      }),
      map(value => this._linkAttributesFilter(value))
    );
  }

  ngAfterViewInit(): void {
    this.processExistingSettings(this.attr)
  }

  ngAfterViewChecked(){
    // this.cdr.detectChanges();
  }

  private _linkAttributesFilter(value: any): string[] {
    if (this.attributeList && !this.attributeList.length) return []
    const filterValue = value.toLowerCase();
    // let linkAttributeMasterList = this.attributeList.filter(attr => this.panelMeta.listAttributes.find(listAttr => listAttr.__id == attr.__id))
    // return linkAttributeMasterList.filter(option => option.name.toLowerCase().includes(filterValue));

    return this.attributeList?.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  processExistingSettings(attr){
    console.log("hit process existing", attr)
    if(!attr || !attr.navigationSettings) {
      if(!this.attr){
        this.attr = {
          navigationSettings: {
            tooltip: ""
          }
        }
      }
      return
    }
    let oldSettings = attr.navigationSettings
    if(oldSettings.enabled){
      this.isReadyToSave = true
      if (this.bypassSave) {
        this.saveNavigationSettings()
      }
      this.isLinkSet = true
      this.linkAttributes = oldSettings.linkAttributes

      this.navAttributeSelectionAreaOpened = true
      this.navFilterAttributes = oldSettings.navFilterAttributes

      if(this.navFilterAttributes?.length > 0){
        for(var i = 0; i < this.navFilterAttributes.length; i++){
          if(!this.navFilterAttributes[i].paramType) this.navFilterAttributes[i].paramType = "attribute";
          this.linkAttributesFrom.addControl("form" + i, new UntypedFormControl());
          let obj = {
            __id: this.navFilterAttributes[i].__id,
            dataType: this.navFilterAttributes[i].dataType,
            filterable: this.navFilterAttributes[i].filterable,
            name: this.navFilterAttributes[i].name,
            sortable: this.navFilterAttributes[i].sortable
          }
          this.linkAttributesFrom.patchValue({[`form${i}`]: obj});
        }
      }

      if(oldSettings.type == 'internal'){
        //check if the selected page code is still valid. page may have been deleted
        if(this.pageCodeList.length && this.pageCodeList.findIndex(code => code == oldSettings.pageCode) == -1 ){
          this.linkError = true
          this.linkErrorMessage = 'selected page does not exist'
          console.log('selected page does not exist')
        }
        this.navigationType = 'internal'
        this.selectedPageCode = oldSettings.pageCode
        this.selectPageControl.patchValue(this.selectedPageCode)
        this.cdr.detectChanges();
        console.log("existing page code", this.selectedPageCode)
      }else if (oldSettings.type == 'external'){
        this.navigationType = 'external'
        this.externalLinkUrl = oldSettings.linkUrl
        this.selectPageControl.patchValue(this.externalLinkUrl)
        this.cdr.detectChanges();
      }else if (oldSettings.type == 'relative'){
        this.navigationType = 'relative'
        this.relativeLinkUrl = oldSettings.linkUrl
        this.selectPageControl.patchValue(this.relativeLinkUrl)
        this.cdr.detectChanges();
      }
    }
  }

  displayFnAttribute(attribute) {
    return attribute && attribute.name ? attribute.name : ''
  }

  async linkPageSelected(event){

    if(this.attr && !this.attr.navigationSettings) {
      this.attr.navigationSettings = { tooltip: ""}
    }

    let destinationPageMeta: any
    this.readyToChoseConn = false
    console.log("link page selected", event.option.value)
    console.log("pageStructure", this.pageStructure)
    this.linkError = false

    if(event.option.value == 'Auto create details page'){
      this.autoCreatePageConfigOpened = true


    }else if(event.option.value == 'Auto create form page'){
      let formPageMeta: any
      try{
        formPageMeta = await this.createFormPage()
      }catch(e){
        console.log("new page could not be created", e)
        this.selectPageControl.patchValue('')
        return
      }
      console.log("form page ready", formPageMeta)
      this.selectedPageCode = formPageMeta.code
      this.selectPageControl.patchValue(this.selectedPageCode)

      this.navigationType = 'internal'
      this.isReadyToSave = true
      if (this.bypassSave) {
        this.saveNavigationSettings()
      }
      this.isLinkSet = true

    }else{
      this.pageCreatingSpinner = false
      console.log("other page")
      let pageCode = this.pageStructure.pages.find(code => this.pageStructure[code].name == event.option.value)

      console.log("page code found", pageCode)
      if(pageCode){
        this.selectedPageCode = pageCode
      }else{
        console.error("associated page code not found")
        return
      }
      this.isReadyToSave = true
      if (this.bypassSave) {
        this.saveNavigationSettings()
      }
      this.navAttributeSelectionAreaOpened = true
      this.isLinkSet = true
      this.navigationType = 'internal'
      // this.autoCreatePageConfigOpened = false
      // let pageId = this.pageStructure[event.option.value]['id']
      // try{
      //   destinationPageMeta = await this.metaService.get(pageId)
      //   this.selectedPageCode = destinationPageMeta.code
      //   console.log("destination page meta fetched", destinationPageMeta)
      // } catch(err){
      //   console.error("error in getting data model", err)
      // }
      // this.processDestinationDM(destinationPageMeta)
    }
  }

  tooltipChanged(){
    console.log("tooltipChanged")
    if (this.bypassSave) {
      console.log("inside if")
      this.saveNavigationSettings()
    }
  }

  navFilterAttrValue(event, param){
    console.log("nav filter attr value", event, param);
    param.__id = event;
    param.dataType = "string";
    param.filterable = false
    param.name = event
    param.sortable = false;
    this.isReadyToSave = true
    if (this.bypassSave) {
      this.saveNavigationSettings()
    }
  }

  navFilterAttrSelected(event, param){
    console.log("nav filter attr selected", event.option.value, param)
    let linkAttr: any = event.option.value
    // let obj = {
      param.__id= linkAttr.__id;
      param.dataType= linkAttr.dataType;
      param.filterable= linkAttr.filterable;
      param.name= linkAttr.name;
      param.sortable= linkAttr.sortable
    // }
    // this.navFilterAttributes.push(obj)
    // this.linkAttributesControl.patchValue('')
    if (this.bypassSave) {
      this.saveNavigationSettings()
    }
  }

  onSelectParamType(param, obj){
    param.paramType = obj.value;
  }

  addParamItem(){
    let obj = {
      paramType: "attribute"
    }
    this.navFilterAttributes.push(obj);
    this.linkAttributesFrom.addControl("form" + (this.navFilterAttributes.length - 1), new UntypedFormControl())
    console.log("linkAttributesFrom", this.linkAttributesFrom)
  }

  removeNavFilterAttr(i: number){
    this.navFilterAttributes.splice(i, 1)

    console.log(" this.navFilterAttributes",  this.navFilterAttributes)
  }

  createDetailsPageSwitch(){
    if(!this.diffOjectPageCreateConfig){
      this.createSameBoxDetails()
    }else{
      this.createDiffBoxDetailsPage()
    }
  }

  async createSameBoxDetails(){
    this.pageCreatingSpinner = true
    this.isGetOptionsToCollect = this.checkGetFnOptions(this.boxConfig.getFnOptions)
    if(this.isGetOptionsToCollect){
      this.getFnOptions = this.boxConfig.getFnOptions
      console.log("get fn options to collect", this.getFnOptions)
    }else{
      console.log("no options to collect")
    }

    let boxBindConfig: any = {
      boxId: this.boxConfig.boxId,
      boxObjectId: this.boxConfig.boxObjectId,
      connectionId: this.boxConfig.connectionId,
      boxName: this.boxConfig.boxName,
      attributeOptions: this.boxConfig.attributeOptions,
      getFnOptions: this.boxConfig.getFnOptions,
    }
    let detailsPanelMeta: any
    try{
      detailsPanelMeta = await this.automationService.createDetailsPanelMeta(boxBindConfig)
      console.log("details panelMeta", detailsPanelMeta)
    }catch(e){
      console.log("error occurred in creating details panel meta", e)
      throw e
    }

    // create page
    let newPageMeta: any
    try{
      newPageMeta = await this.createNewPage(
        this.boxConfig.connectionId,
        this.boxConfig.boxObjectId,
        this.pageStructure,
        detailsPanelMeta,
        'Details'
      )
    }catch(e){
      console.log("new page could not be created", e)
      this.selectPageControl.patchValue('')
      return
    }

    console.log("details page ready", newPageMeta)
    this.selectedPageCode = newPageMeta.code
    this.selectPageControl.patchValue(this.selectedPageCode)

    this.navigationType = 'internal'
    this.isReadyToSave = true
    if (this.bypassSave) {
      this.saveNavigationSettings()
    }
    this.isLinkSet = true
    this.pageCreatingSpinner = false
  }

  createDiffBoxDetailsPage(){
    console.log("will create diff box details page")
  }

  async createFormPage(){
    this.pageCreatingSpinner = true
    console.log("create form page hit")
    // create form panel meta
    let boxBindConfig: any = {
      boxId: this.boxConfig.boxId,
      boxObjectId: this.boxConfig.boxObjectId,
      connectionId: this.boxConfig.connectionId,
      boxName: this.boxConfig.boxName,
      attributeOptions: this.boxConfig.attributeOptions,
      getFnOptions: this.boxConfig.getFnOptions,
    }
    let formPanelMeta: any
    try{
      formPanelMeta = await this.automationService.createFormPanelMeta(boxBindConfig)
      console.log("form panelMeta", formPanelMeta)
    }catch(e){
      console.log("error occurred in creating form panel meta", e)
      throw e
    }

    // create page
    let newPageMeta: any = await this.createNewPage(
      this.boxConfig.connectionId,
      this.boxConfig.boxObjectId,
      this.pageStructure,
      formPanelMeta,
      'Form'
    )
    return newPageMeta
  }


  async createNewPage(connectionId: string, boxObjectId: string, pageStructure: any, panelMeta: any, nameSuffix: string){
    // create page
    let newPageMeta: any
    try{
      newPageMeta = await this.automationService.createPageMeta(connectionId, boxObjectId, pageStructure)

      // augment the page meta
      newPageMeta.name += ` ${nameSuffix}`
      newPageMeta.code += `_${nameSuffix}`
      newPageMeta.panels.push(panelMeta)

      console.log("pageMeta after panel addition", newPageMeta)

      let response: any
      response = this.automationService.createPageInDb(newPageMeta, pageStructure)
      console.log("page create response", response)

      console.log("new page created", newPageMeta)
    }catch(e){
      console.log("error in creating new page", e)
      throw e
    }
    this.pageCreatingSpinner = false
    this.isReadyToSave = true
    if (this.bypassSave) {
      this.saveNavigationSettings()
    }
    return newPageMeta
  }

  /**
   * check if get function has options involved
   */
   checkGetFnOptions(getFnOptions: any){
    console.log("checkGetFnOptions hit")
    if(!getFnOptions || !Array.isArray(getFnOptions) || !getFnOptions.length){
      console.log("no options to collect")
      return false
    }
    return true
    // // console.log("getFn", getFn)
    // if(getFn){
    //   // this.getFnOptions = this.pageService.checkOptionsToCollect(getFn)
    //   // check if already provided
    //   // if(
    //   //   this.boxConfig.getFnOptions.length == this.getFnOptions.length &&
    //   //   this.boxConfig.getFnOptions.findIndex(opt => opt.required && !opt.value) == -1
    //   // ){
    //   //   // already provided, nothing to do unless we want to re-confirm
    //   //   console.log("options already provided")
    //   //   this.isGetOptionsToCollect = false
    //   // }else{
    //   //   // collect options now
    //   //   console.log("options need to be collected")
    //   //   this.isGetOptionsToCollect = true
    //   // }
    // }else{
    //   return false
    // }
  }

  openOptionsDialog(){
    // open dialog to collect options
    let data: any = {
      boxName: this.boxConfig.boxName,
      boxObjectId: this.boxConfig.boxObjectId,
      getFnOptions: this.getFnOptions
    }

    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(CollectOptionsDialogComponent, {
        width: '50vw',
        data: data,
      });

      dialogRef.afterClosed().subscribe((data) => {
        console.log("options collection response", data)
        if (data && Array.isArray(data.getFnOptions)) {
          this.getFnOptions = data.getFnOptions
          resolve(true)
        } else {
          console.log('details page creation cancelled');
          reject(false)
        }
      });
    });
  }

  processDestinationDM(destinationPageMeta: any){
    this.destinationDataModel = this.pageService.getDataModel(destinationPageMeta)
    console.log("data model received in navigation panel", this.destinationDataModel)

    this.navigationType = 'internal'
    this.isLinkSet = true
    this.generateConnectionOptions(this.destinationDataModel)
    this.pageCreatingSpinner = false
    this.isReadyToSave = true
    if (this.bypassSave) {
      this.saveNavigationSettings()
    }
    this.readyToChoseConn = true
  }

  detailsBoxTypeChosen(event: MatRadioChange){
    this.readyToChoseConn = false
    console.log("details page type chosen", event)
    if(event.value == 'same'){
      this.isGetOptionsToCollect = this.checkGetFnOptions(this.boxConfig.getFnOptions)
      if(this.isGetOptionsToCollect){
        this.getFnOptions = this.boxConfig.getFnOptions
        console.log("get fn options to collect", this.getFnOptions)
      }else{
        console.log("no options to collect")
      }
      this.isDetailsBoxChosen = true
      this.diffOjectPageCreateConfig = false

    }else if(event.value == 'different'){
      this.isGetOptionsToCollect = false
      this.isDetailsBoxChosen = false
      this.diffOjectPageCreateConfig = true
    }
  }

  boxSelected(box: any) {
    console.log("selected box", box)
    this.selectedDestBoxName = box.name
    this.selectedDestBoxId = box.box_id
    this.destBoxConfigToken = box.box_token
    this.selectedDestConnectionId = box._id

    this.getBoxFunctions()

    this.isBoxSelected = true;
  }

  async getBoxFunctions(){
    this.gettingObjFunSpinner = true
    let res = await this.boxService.getBoxFunctions(this.selectedDestBoxId, this.destBoxConfigToken)
    this.gettingObjFunSpinner = false
    console.log("box functions received", res)
    this.boxFunctions = res

    // if boxFuntions has getobjects, enable/render boxObject selection component (*ngIf on isBoxSelected)
    if(this.boxFunctions.find(fn => fn.__id == 'getobjects')){
      this.isBoxObjectConfigError = false
      this.canGetBoxObjects = true
    }else if(!this.boxFunctions.find(fn => fn.__id == 'getattributes')){
      this.canGetBoxObjects = false
      this.isBoxObjectConfigError = true
      this.boxObjectConfigError['error'] = {}
      this.boxObjectConfigError['error']['message'] = "'getattributes' not available in box functions"
    }else{
      this.canGetBoxObjects = false
      this.isBoxObjectConfigError = true
      this.boxObjectConfigError['error'] = {}
      this.boxObjectConfigError['error']['message'] = "'getobjects' not available in box functions"
    }
  }

  boxObjectSelected(boxObject: any) {
    console.log("box object selected", boxObject)

    let getFn: any = boxObject.functions['get']
    console.log("getFn", getFn)
    let getFnOptions: any = this.pageService.checkOptionsToCollect(getFn)
    console.log("getFnOptions", getFnOptions)
    this.isGetOptionsToCollect = this.checkGetFnOptions(getFnOptions)

    if(this.isGetOptionsToCollect){
      this.getFnOptions = getFnOptions
      console.log("get fn options to collect", this.getFnOptions)
    }else{
      console.log("no options to collect")
    }

    this.selectedDestObjectId = boxObject.__id
    this.isBoxObjectSelected = true
    this.isDetailsBoxChosen = true
  }

  boxSelectionError(event){
    console.log("box selection error", event)
    this.isBoxConfigError = true
    this.boxConfigError = event
  }

  boxObjectSelectionError(event){
    console.log("box object selection error", event)
    this.isBoxObjectConfigError = true
    this.boxObjectConfigError = event
  }

  /**
   * 1. loop over all the connections in dataModel
   * 2. fetch connection details by Id
   * 3. create records for each boxObject associated with that connection
   * 4. push into connection list
   */
  async generateConnectionOptions(dataModel){
    this.destConnList = []
    Object.keys(dataModel).forEach(async connId => {
      const headers = new HttpHeaders()
      .set(
        'Authorization',
        'PreAuthenticatedToken ' + this.connectionService.preAuthenticatedToken
      );
      let url = `${environment.SERVER_BASE_URL}/connection/id/${connId}`

      try{
        let connection: any = await this.http.get(url, {headers}).toPromise()
        console.log("connection received", connection)

        Object.keys(dataModel[connId]).forEach(boxObject => {
          let boxConnection = {
            connectionId: connId,
            boxName: connection.data.name,
            boxId: connection.data.box_id,
            boxObjectId: boxObject,
            logoUrl: connection.data.options ? (connection.data.options.box_logo_url || '') : '',
            selected: false,
          }
          if(this.connectionId == boxConnection.connectionId && this.boxObjectId == boxConnection.boxObjectId){
            boxConnection['selfMap'] = true
          } else {
            boxConnection['selfMap'] = false
          }
          this.destConnList.push(boxConnection)
        })
      }catch(error){
        console.log("navigationPanel: Error in getting connection by id:", error)
      }
      console.log("connection list is now:", this.destConnList)
      this.isReadyToSave = true
      if (this.bypassSave) {
        this.saveNavigationSettings()
      }
    })
  }

  resetPageSelection(){
    this.selectPageControl.patchValue('')
    this.isLinkSet = false
    this.isReadyToSave = false
  }

  linkInputReceived(url, skipValidityCheck: boolean = false){
    if(url.startsWith("/") || url.startsWith("./")){
      this.linkError = false
      this.isLinkSet = true
      this.navigationType = 'relative'
      this.relativeLinkUrl = url
      this.isReadyToSave = true
      if (this.bypassSave) {
        this.saveNavigationSettings()
      }
    }else if(this.isValidUrl(url) && !skipValidityCheck){
      this.linkError = false
      this.isLinkSet = true
      this.navigationType = 'external'
      this.externalLinkUrl = url
      this.isReadyToSave = true
      if (this.bypassSave) {
        this.saveNavigationSettings()
      }

      if(this.linkAttributes?.length){
        this.isReadyToSave = true
        if (this.bypassSave) {
          this.saveNavigationSettings()
        }
      }
    }else{
      if(skipValidityCheck){
        this.linkError = false
        this.isLinkSet = true
        this.navigationType = 'external'
        this.externalLinkUrl = url
        this.isReadyToSave = true
        if (this.bypassSave) {
          this.saveNavigationSettings()
        }
      }else{
        this.navigationType = ''
        this.linkError = true
        this.linkErrorMessage = 'please give a valid and complete URL'
        this.isLinkSet = false
        this.isReadyToSave = false
        this.readyToChoseConn = false
        this.autoCreatePageConfigOpened = false
      }
    }
  }

  isValidUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  addLinkAttribute(event: MatChipInputEvent): void {
    console.log("add fired", event)
    // if (event.value) {
    //   this.searchAttributes.push(event.value);
    // }

    // Clear the input value
    event.chipInput!.clear();
    this.linkAttributesControl.setValue('');
  }

  selectedLinkAttribute(event: MatAutocompleteSelectedEvent): void {
    console.log("link attribute selected ", event)
    let attributeObj = {
      __id: event.option.value['__id'],
      name: event.option.value['name'],
      dataType: event.option.value['dataType']
    }
    this.linkAttributes.push(attributeObj);
    this.linkAttributeInput.nativeElement.value = '';
    this.linkAttributesControl.setValue('');

    console.log("linkAttributes", this.linkAttributes)

    // this.excludeSelected('search')

    // this case occurs when list panel was selected before and all search attributes were removed after that
    if (this.linkAttributes.length && this.isLinkSet && this.selectedConnIndex >= 0) {
      this.isReadyToSave = true
      if (this.bypassSave) {
        this.saveNavigationSettings()
      }
    }
  }

  removeLinkAttribute(attribute: string): void {
    const index = this.linkAttributes.indexOf(attribute);
    if (index >= 0) {
      this.linkAttributes.splice(index, 1);
    }
    // this.excludeSelected('search')
    console.log("link attributes", this.linkAttributes)
    if (this.linkAttributes.length == 0) {
      this.isReadyToSave = false
    }
  }

  // collapsePanel(){
  //   this.saveNavigationSettings()
  //   this.emitCollapsePanel.next(this.attr)
  // }
  cancelNavigation(){
    // let navigationSettings = {
    //   enabled: false
    // }
    // this.attr['navigationSettings'] = navigationSettings
    this.emitCollapsePanel.next(this.attr)
  }

  saveNavigationSettings(){
    let navigationSettings = {
      enabled: false
    }
    if(this.attr['navigationSettings']){
      navigationSettings = this.attr['navigationSettings'];
    }
    if(this.isReadyToSave){
      if(this.navigationType == 'relative' && this.isLinkSet && this.relativeLinkUrl){
        navigationSettings['type'] = 'relative'
        navigationSettings['linkUrl'] = this.relativeLinkUrl
        navigationSettings['enabled'] = true;
        navigationSettings['navFilterAttributes'] = this.navFilterAttributes
      } else if(this.navigationType == 'external' && this.isLinkSet && this.externalLinkUrl){
        navigationSettings['type'] = 'external'
        navigationSettings['linkUrl'] = this.externalLinkUrl
        navigationSettings['enabled'] = true;
        navigationSettings['navFilterAttributes'] = this.navFilterAttributes
      } else if(this.navigationType == 'internal' && this.isLinkSet && this.selectedPageCode){
        navigationSettings['type'] = 'internal'
        navigationSettings['pageCode'] = this.selectedPageCode
        navigationSettings['enabled'] = true
        navigationSettings['navFilterAttributes'] = this.navFilterAttributes
      }
      this.attr['navigationSettings'] = navigationSettings
      console.log("attribute navigation set", this.attr)
    }
    this.emitCollapsePanel.next(this.attr)
  }

  async connectionSelected(event: any, i: number){
    if(!event.checked){
      console.log("connection deselected:", this.destConnList[i])
      this.destConnList[i].selected = false
      this.mappingOpenedFor = -1
      return
    }
    this.mappingSpinner = true
    console.log("connection selected:", this.destConnList[i])

    this.addMapping(i)
  }


  async createDetailsPage(){
    console.log("")
    this.pageCreatingSpinner = true
    if(!this.diffOjectPageCreateConfig){
      // create details panel meta
      let boxBindConfig: any = {
        boxId: this.boxConfig.boxId,
        boxObjectId: this.boxConfig.boxObjectId,
        connectionId: this.boxConfig.connectionId,
        boxName: this.boxConfig.boxName,
        attributeOptions: this.boxConfig.attributeOptions,
        getFnOptions: this.boxConfig.getFnOptions,
      }
      let detailsPanelMeta: any
      try{
        detailsPanelMeta = await this.automationService.createDetailsPanelMeta(boxBindConfig)
        console.log("details panelMeta", detailsPanelMeta)
      }catch(e){
        console.log("error occurred in creating details panel meta", e)
        throw e
      }

      // create page
      let newPageMeta: any = await this.createNewPage(
        this.boxConfig.connectionId,
        this.boxConfig.boxObjectId,
        this.pageStructure,
        detailsPanelMeta,
        'Form'
      )
      return newPageMeta

      // get and update page structure
    }else{

    }
    // let createdPageMeta: any
    // try{
    //   if(this.diffOjectPageCreateConfig){
    //     createdPageMeta = await this.createNewPage(this.selectedDestConnectionId, this.selectedDestObjectId)
    //   }else{
    //     createdPageMeta = await this.createNewPage(this.connectionId, this.boxObjectId)
    //   }
    // }catch(error){
    //   this.linkError = true
    //   this.linkErrorMessage = error
    //   this.selectPageControl.patchValue('')
    //   return
    // }
    // this.diffOjectPageCreateConfig = false  // resetting the value
    // console.log("destination page meta created", createdPageMeta)
    // this.selectedPageCode = createdPageMeta.code
    // this.selectPageControl.patchValue(this.selectedPageCode)

    // this.processDestinationDM(createdPageMeta)
  }


  /**
   * @returns createdPageCode: string ( <pageCode> | '' )
  */
  // async createNewPage(connectionId: string, boxObjectId: string){
  //   console.log("create details page hit, connection to create:", connectionId, boxObjectId)
  //   let response = await this.automationService.createPage(connectionId, boxObjectId, this.pageStructure)
  //   return response
  // }

  async addMapping(i){
    if(!this.destConnList[i]['selfMap']){
      let prepared = await this.prepareAttributes(this.destConnList[i])
      if(prepared){
        this.destConnList[i].selected = true
        this.mappingOpenedFor = i
        this.mappingSpinner = false
      } else {
        this.destConnList[i].selected = false
        this.mappingSpinner = false
      }
    } else {
      this.destAttrs = this.attributeList
      this.destConnList[i].selected = true
      this.mappingOpenedFor = i
    }
  }

  getOptionChanged(event, index){
    this.getFnOptions[index]['value'] = event.srcElement.value
  }

  /**
   *
   * @param i index of the connection from this.destConnList
   * @returns
   */
  async prepareAttributes(connection){
    let connectionId = connection.connectionId
    let boxId = connection.boxId
    let boxObjectId = connection.boxObjectId
    let attributeOptions = []

    // if attributes for this combination exists, use, else fetch afresh
    let key = connectionId + '-' + boxObjectId
    if(this.boxObjectAttributes[key] && this.boxObjectAttributes[key].length){
      console.log("attributes already existed")
      this.destAttrs = this.boxObjectAttributes[key]
      return true
    }else{
      // get boxFunctions and find getAttributes
      let fn = this.boxFunctions.find(fn => fn.__id == 'getattributes')
      if(fn){
        let options = this.pageService.checkOptionsToCollect(fn)
        if(options && Array.isArray(options)){
          attributeOptions = options
        }else{
          attributeOptions = []
        }
      }else{
        this.terminationError = true
        this.terminationErrorMessage = 'getAttributes function not available'
      }

      let response: any = await this.boxService.getAttributes(connectionId, boxId, boxObjectId, attributeOptions)

      //check if valid response
      if(!(response.result && Array.isArray(response.result) && response.result.length)){
        console.log("no attributes found")
        return false
      }
      console.log("attributes fetched for: ", response.boxId, response.boxObjectId, response.result)
      let receivedKey = response.connectionId + '-' + response.boxObjectId
      this.boxObjectAttributes[receivedKey] = response.result
      this.destAttrs = this.boxObjectAttributes[receivedKey]
      return true
    }
  }

  mappingSelected(data: any, i: number){
    this.mappingOpenedFor = -1
    if(!this.destConnList[i].selected){
      return
    }

    let sourceAttr: any = {
      name: data.sourceAttr.name,
      __id: data.sourceAttr.__id,
      dataType: data.sourceAttr.dataType
    }
    let destAttr: any
    if(data['destAttr']){
      destAttr = {
        name: data.destAttr.name,
        __id: data.destAttr.__id,
        dataType: data.destAttr.dataType
      }
    }

    console.log("mapping received for ", i, data)

    if(this.destConnList[i]['attrMapping'] && Array.isArray(this.destConnList[i]['attrMapping'])){
      this.destConnList[i]['attrMapping'].push({sourceAttr: sourceAttr, destAttr: destAttr})
    }else{
      this.destConnList[i]['attrMapping'] = []
      this.destConnList[i]['attrMapping'].push({sourceAttr: sourceAttr, destAttr: destAttr})
    }
    console.log("destConnList:", this.destConnList[i])
  }

  cancelAddMapping(flag, i){
    if(flag){
      this.mappingOpenedFor = -1
      if(!this.destConnList[i]['attrMapping'] || !this.destConnList[i]['attrMapping'].length){
        this.destConnList[i]['selected'] = false
      }
    }
  }

  deleteMapAttr(connIndex: number, mapIndex: number){
    console.log("delete map for ", this.destConnList[connIndex], "at index ", mapIndex)
    this.destConnList[connIndex]['attrMapping'].splice(mapIndex, 1)

    // if(this.destConnList[connIndex]['attrMapping'] && !this.destConnList[connIndex]['attrMapping'].length && !this.destConnList[connIndex]['selfMap']){
    //   this.destConnList[connIndex]['selected'] = false
    // }

    console.log("new connection list", this.destConnList)
  }

  createAttributePickerMap(){
    this.listAttributesMap = { list: [] }
    this.listAttributesMap.list.push("appFields")
    this.listAttributesMap["appFields"] = {
      id: "appFields",
      displayName: "App fields",
      fields: [],
      options: {}
    }
    this.attributeList.forEach(attr => {
      this.listAttributesMap["appFields"]["fields"].push({
        name: attr.name,
        __id: attr.__id,
        value: '${' + attr.__id + '}',
        dataType: attr.dataType
      })
    })
    console.log("list attr map", this.listAttributesMap)
  }

  templateValueSelected(event){
    console.log("event", event)
    console.log("typed", this.selectPageControl.value)
    let value = this.selectPageControl.value
    let position = this.lastPlacedCursorPosition == -1 ? value.length : this.lastPlacedCursorPosition
    value = (value?.substring(0, this.lastPlacedCursorPosition) || "") + event.value + (value?.substring(this.lastPlacedCursorPosition) || "")
    console.log("after template addition", value)
    this.linkInputReceived(value, true)
    this.selectPageControl.patchValue(value)
  }

  cursorPlaced(event){
    // console.log("cursor placed", event)
    this.lastPlacedCursorPosition = event.target?.selectionStart
  }

}
