<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 mat-dialog-title>Provide following inputs to proceed</h2>
  <button matTooltip="Close" matSuffix mat-icon-button aria-label="Close"
      (click)="closeDialog()" >
      <mat-icon>close</mat-icon>
  </button>
</div>
<!-- <div class="subheading">Provide following inputs to get records</div> -->
<mat-dialog-content class="mat-typography">
  <shared-parameter-inputs
    style="width: 100%;"
    [queryInputs]="{ 
      subComponentsToShow: { attributes: true, limit: true },
      retainSubComponentOutput: true
    }"
    [action]="fn"
    [allActions]="allAction"
    [dependancyMapObj]="dependancyMap"
    [isSupportQuery]="isSupportQuery"
    [optionalInputs]="availableInputParams"
    (inputParameter)="optionInputsRecevied($event)"
    [connection]="connection"
    [isOnlyOptions]="isOnlyOptions"
  ></shared-parameter-inputs>
  <!-- [getAttributesFunction]="getAttributesFunction" -->
  <span *ngIf="errorNoteShow && !executeEnabled" class="info info-text-container">
    <strong>Note:&nbsp;</strong>Please provide input for all the required fields
  </span>
  <p></p>
  <button *ngIf="executeEnabled" mat-raised-button color="primary" (click)="returnInputs()">
    Next
    <mat-icon>east</mat-icon>
  </button>
  <button *ngIf="!executeEnabled" mat-stroked-button color="primary" (click)="returnInputs()">
    Next
    <mat-icon>east</mat-icon>
  </button>
</mat-dialog-content>


