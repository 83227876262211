<spinner  size="la-1x" center="true" *ngIf="spinner" style="margin: auto auto;"></spinner>

<div class="wrap" *ngIf="deviceInfo.isSmallSize" style="padding: 5px;">
  <h1 class="heading">Bloom Configuration</h1>
  <div fxLayoutAlign="center" class="subheading" style="color: coral;padding-bottom: 15px;">
    Whoops! It looks like your device's screen may not be a bit cozy for Bloom's big ideas.  </div>
    <div fxLayoutAlign="center" class="subheading" style="padding-bottom: 15px;">
    For the full editing experience, we recommend switching to a larger screen where your creativity can truly bloom.
  </div>
  <div fxLayoutAlign="center" class="subheading" style="padding-bottom: 15px;">
    Happy creating!
  </div>
</div>

<div  [class.disabled] = "disabled" class="wrap" *ngIf="!deviceInfo?.isSmallSize">
  <h1 class="heading">Create New Bloom</h1>
  <div>
    <div fxFlex="10"></div>
    <div fxFlex="80" fxLayoutAlign="start">How do you want to create a new bloom?</div>
    <div fxFlex="10"></div>
  </div>
  <div style="height: 1rem;"></div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="10"></div>
    <div fxFlex="80">
      <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div  fxFlex="30" fxLayout="column">
          <mat-card  fxLayout="column" appearance="outlined"
          class="listcard" matTooltip="Create Blank Bloom" fxLayoutAlign="center center"
          (click)="createBlankSelected = !createBlankSelected ;createNewBloom()" matTooltip="Create Blank Bloom" [ngClass]="createBlankSelected ? 'listcard-1-selected' : ''">
            <mat-card-content>
              <span fxLayout="row" class="subheading-1"> Blank Bloom </span>
              <div  fxLayoutAlign="center center" fxLayout="row"  >
                <mat-icon class="createIcon" color="primary">add</mat-icon>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="5"></div>
        <div fxFlex="30" fxLayout="column">
          <mat-card appearance="outlined" (click)="showTemplates = !showTemplates"
          class="listcard" matTooltip="Create Form from template" fxLayoutAlign="center center"
          [ngClass]="showTemplates ? 'listcard-1-selected' : ''">
          <mat-card-content>
            <span fxLayout="row" class="display-1"> From Template </span>
            <div fxLayoutAlign="center center" fxLayout="row">
              <mat-icon class="createIcon" color="primary">gamepad</mat-icon>
            </div>
          </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div fxFlex="10"></div>
  </div>
  <!-- <button
    mat-button
    fxLayoutAlign="start start"
    routerLink="/bloom"
    color="primary"
  >
    <mat-icon color="primary">arrow_back</mat-icon>
    <span style="margin-top: 10px; margin-left: 4px">Back to Home</span>
  </button> -->
<div fxLayout="row" *ngIf="showTemplates">
  <div fxLayout="column" fxFlex="10"></div>
  <div fxLayout="column" fxFlex="80">
    <mat-stepper orientation="vertical" [linear]="linear" #stepper>
      <mat-step step1 [completed]="stepperOptions.completed1">
        <ng-template matStepLabel>Choose Template</ng-template>
        <ng-template matStepContent>

          <!-- <div fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%;">
            <mat-form-field style="width: 50%;" appearance="outline">
                <input matInput #searchInput [(ngModel)]="searchString" (input)="onSearchInput(searchInput.value)" placeholder="Search templates by name">
                <mat-icon matSuffix style="cursor:pointer;" (click)="searchByTemplateName()">search</mat-icon>
            </mat-form-field>
          </div> -->

          <div fxLayout fxLayoutAlign="center center" fxFlexFill>
            <spinner *ngIf="templatesLoaded && searchingTemplateSpinner"></spinner>
          </div>

          <div>
            <mat-card class="stepperCard">
              <app-search-panel *ngIf="templateSearchPanel"
                style="width: 100%;margin-top: -25px;"
                [builderMode]="false"
                [panelMeta]="templateSearchPanel"
              >
              </app-search-panel>
              <app-list-panel *ngIf="templatePanel"
                #bloomListPanel
                style="width: 100%;margin-top: -55px;"
                [builderMode]="false"
                [panelMeta]="templatePanel"
                (rowData)="selectedRowData($event, stepper)"
              >
              </app-list-panel>
            </mat-card>
            <div style="height: 8px"></div>
          </div>

          <!-- <div class="section template" [class.disabled]="searchingTemplateSpinner" fxLayout="row wrap" fxLayoutAlign="flex-start center" fxLayoutGap="1rem" fxFlexFill>
            <ng-container *ngFor="let template of templateList; let i = index">
              <mat-card
                appearance="outlined"
                fxFlex.xs="75" fxFlex.gt-xs="30" fxFlex.gt-sm="25" fxFlex.gt-md="20" fxFlex.gt-lg="16"
                class="blankCard templateCard" [ngClass]="{'selectedTemplate' : i == selectedTemplateIndex}" (click)="templateSelected(template, i, stepper)"
              >
                <div fxLayoutAlign="flex-end center">
                  <mat-icon matTooltip="View Template" (click)="openViewTemplate($event, i)">open_in_new</mat-icon>
                </div>
                <div fxLayout fxLayoutAlign="center" class="templateName">{{ template.name }}</div>
                <div fxLayout fxLayoutAlign="center center" style="width: 100%;">
                  <img class="templateBannerImage" [src]="template.templateData && template.templateData.bannerImage ? template.templateData.bannerImage : template.logoUrl" [alt]="template.name + 'logo'">
                </div>
                <div class="tagline">{{ template.templateData && template.templateData.tagline ? template.templateData.tagline : "" }}</div>
                <div class="description">{{ template.templateData && template.templateData.description ? template.templateData.description : "" }}</div>
              </mat-card>
            </ng-container>
          </div>
          <div fxFlexlayout="row" style="height: 1rem"></div>
          <div fxLayout="row" fxLayoutAlign="flex-end center">
            <mat-paginator
              [pageSize]="pageSize"
              [length]="templateBloomCount"
              showFirstLastButtons="true"
              (page)="paginateTemplates($event)"
            >
            </mat-paginator>
          </div> -->
          <!-- <button mat-raised-button [disabled]="!stepperOptions.step2" color="primary" mat-button matStepperNext (click)="this.stepper.next();">Next</button> -->
        </ng-template>
      </mat-step>
      <mat-step [completed]="stepperOptions.completed2" [editable]="stepperOptions.step2" step2>
        <ng-template matStepLabel>Connect with your Apps</ng-template>
        <ng-template matStepContent>
          <div class="contentSection" *ngIf="isTemplateSelected">
            <div class="subsection" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="1.5rem">

              <!-- <div class="templateSideSection" fxLayout fxLayoutAlign="flex-start start" fxFlex="30">
                <img class="previewLogo" [src]="templateUnderPreview.logoUrl" [alt]="templateUnderPreview.name + 'logo'">
              </div> -->

              <div class="templateSideSection" fxFlex="70" fxLayout="column" fxLayoutAlign="flex-start start" fxLayoutGap=".6rem" style="padding: .5rem;">
                <div fxLayout fxLayoutAlign="flex-start start" class="previewTemplateName">
                  {{ templateUnderPreview.name }}
                </div>
                <div *ngIf="templateUnderPreview.description" fxLayout fxLayoutAlign="flex-start start" class="previewTemplateDescription">
                  {{ templateUnderPreview.description }}
                </div>
              </div>
            </div>

            <div style="height: 1rem"></div>
            <div fxLayout fxLayoutAlign="center center"  *ngIf="loadingConnectionsSpinner && !connectionsLoaded">
              <spinner [selfSpin]="true"></spinner>
            </div>

            <div class="subsection connectionListing" *ngIf="connectionsLoaded" fxFlexFill>
              <div class="subsectionHeading" fxLayout="row" fxLayoutAlign="center center">Connect with your Apps</div>
              <div style="height: .5rem"></div>
              <div class="subText" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".5rem">
                <span>Cloning from bloom template</span>
                <span><b>{{ templateUnderPreview.name }}</b></span>
                <span>requires connection with the following Apps</span>
              </div>
              <div style="height: .8rem"></div>

              <div class="tableHeader" fxLayout fxLayoutAlign="flex-start center">
                <span fxFlex="15"></span>
                <span fxFlex="35">Apps</span>
                <span fxFlex="50">Your connections</span>
              </div>
              <div class="subsectionItem" *ngFor="let connection of connectionsUsed; let i = index" fxLayout fxLayoutAlign="flex-start center">
                <span fxFlex="15">
                  <img class="boxLogo" [src]="connection.logoUrl" alt="">
                </span>
                <span fxFlex="35">{{ connection.boxName }}</span>
                <span fxFlex="50">
                  <app-connection-selection
                    [config]="{'box_id': connection.boxId}"
                    (selectedConnection)="boxSelected($event, i)"
                  ></app-connection-selection>
                </span>
              </div>
            </div>

          </div>
          <div *ngIf="!isTemplateSelected">
            <div fxFlexlayout="row" style="height: 1rem"></div>
            <div>Please select a template in previous step</div>
          </div>

          <div fxLayout fxLayoutAlign="flex-end center">
            <button mat-button matStepperPrevious>Back</button>
            <div *ngIf="connectionsLoaded">
              <button mat-raised-button color="primary" matStepperNext [disabled]="(connectionsUsed.length > 0 && !connectionSelected)" (click)="this.stepper.next();">Next</button>
            </div>
          </div>
        </ng-template>
      </mat-step>
      <mat-step [editable]="stepperOptions.step3" step3>
        <ng-template matStepLabel>Finalize</ng-template>

        <ng-template matStepContent>
          <div fxFlexlayout="row" style="height: 1rem"></div>

          <!-- <div fxLayout fxLayoutAlign="center center">
            <mat-form-field style="width: 50%;">
              <mat-label>Bloom Name</mat-label>
              <input matInput [(ngModel)]="newBloomName">
            </mat-form-field>
          </div>
          <div fxFlexlayout="row" style="height: 1rem"></div> -->

          <ng-container *ngIf="replacementConnections.length > 0">
            <div class="subText" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".5rem">
              <span>You are going to clone bloom template</span>
              <span><img [src]="templateUnderPreview.logoUrl" alt="template logo" class="boxLogo"></span>
              <span><b>{{ templateUnderPreview.name }}</b></span>
              <span>with Apps</span>
            </div>

            <ng-container *ngFor="let box of replacementConnections">
              <div fxLayout fxLayoutAlign="flex-start center">
                <span><img [src]="box.options.box_logo_url" alt="template logo" class="boxLogo" style="margin-right: 1rem;"></span>
                <span><b>{{ box.name }}</b></span>
              </div>
              <div fxFlexlayout="row" style="height: .8rem"></div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="replacementConnections.length == 0">
            <div class="subText" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".5rem">
              <span>You are going to clone bloom template</span>
              <span><img [src]="templateUnderPreview.logoUrl" alt="template logo" class="boxLogo"></span>
              <span><b>{{ templateUnderPreview.name }}</b></span>
              <span *ngIf="connectionsUsed.length">without connecting Apps</span>
            </div>
          </ng-container>
          <div fxFlexlayout="row" style="height: 1rem"></div>

          <div fxLayout fxLayoutAlign="center center" fxLayoutGap="1rem">
            <button mat-button matStepperPrevious>Back</button>
            <button mat-raised-button color="primary" (click)="cloneNow()" [disabled]="!isTemplateSelected">Clone Now</button>
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
  <div fxLayout="column" fxFlex="10"></div>
</div>


  <!-- <div *ngIf="showTemplates">
    <div *ngIf="fetchingTemplateSpinner" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".1rem" style="width: 100%;">
      <div fxFlexlayout="row" style="height: 4rem"></div>
      <span class="loadingText" *ngFor="let letter of loadingTemplateLetters">{{letter}}</span>
    </div>
  
    <div [class.blur]="!templatesLoaded">
      <div fxFlexlayout="row" style="height: 2rem"></div>
      <div class="subheading" fxFlexFill>Jump-start with a built-in template</div>
      <div fxFlexlayout="row" style="height: .8rem"></div>
  
      <mat-stepper orientation="vertical" #stepper>
        <mat-step #step1>
          <ng-template matStepLabel>Choose Template</ng-template>
          <ng-template matStepContent>
  
            <div fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%;">
              <mat-form-field style="width: 50%;" appearance="outline">
                  <input matInput #searchInput [(ngModel)]="searchString" (input)="onSearchInput(searchInput.value)" placeholder="Search templates by name">
                  <mat-icon matSuffix style="cursor:pointer;" (click)="searchByTemplateName()">search</mat-icon>
              </mat-form-field>
            </div>
  
            <div fxLayout fxLayoutAlign="center center" fxFlexFill>
              <spinner *ngIf="templatesLoaded && searchingTemplateSpinner"></spinner>
            </div>
  
            <div class="section template" [class.disabled]="searchingTemplateSpinner" fxLayout="row wrap" fxLayoutAlign="flex-start center" fxLayoutGap="1rem" fxFlexFill>
              <ng-container *ngFor="let template of templateList; let i = index">
                <mat-card
                  appearance="outlined"
                  fxFlex.xs="75" fxFlex.gt-xs="30" fxFlex.gt-sm="25" fxFlex.gt-md="20" fxFlex.gt-lg="16"
                  class="blankCard templateCard" [ngClass]="{'selectedTemplate' : i == selectedTemplateIndex}" (click)="templateSelected(template, i, stepper)"
                >
                  <div fxLayoutAlign="flex-end center">
                    <mat-icon matTooltip="View Template" (click)="openViewTemplate($event, i)">open_in_new</mat-icon>
                  </div>
                  <div fxLayout fxLayoutAlign="center" class="templateName">{{ template.name }}</div>
                  <div fxLayout fxLayoutAlign="center center" style="width: 100%;">
                    <img class="templateBannerImage" [src]="template.templateData && template.templateData.bannerImage ? template.templateData.bannerImage : template.logoUrl" [alt]="template.name + 'logo'">
                  </div>
                  <div class="tagline">{{ template.templateData && template.templateData.tagline ? template.templateData.tagline : "" }}</div>
                  <div class="description">{{ template.templateData && template.templateData.description ? template.templateData.description : "" }}</div>
                </mat-card>
              </ng-container>
            </div>
            <div fxFlexlayout="row" style="height: 1rem"></div>
            <div fxLayout="row" fxLayoutAlign="flex-end center">
              <mat-paginator
                [pageSize]="pageSize"
                [length]="templateBloomCount"
                showFirstLastButtons="true"
                (page)="paginateTemplates($event)"
              >
              </mat-paginator>
            </div>
          </ng-template>
        </mat-step>
        <mat-step #step2>
          <ng-template matStepLabel>Connect with your Apps</ng-template>
          <ng-template matStepContent>
            <div class="contentSection" *ngIf="isTemplateSelected">
              <div class="subsection" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="1.5rem">
  
                <div class="templateSideSection" fxLayout fxLayoutAlign="flex-start start" fxFlex="30">
                  <img class="previewLogo" [src]="templateUnderPreview.logoUrl" [alt]="templateUnderPreview.name + 'logo'">
                </div>
  
                <div class="templateSideSection" fxFlex="70" fxLayout="column" fxLayoutAlign="flex-start start" fxLayoutGap=".6rem" style="padding: .5rem;">
                  <div fxLayout fxLayoutAlign="flex-start start" class="previewTemplateName">
                    {{ templateUnderPreview.name }}
                  </div>
                  <div *ngIf="templateUnderPreview.description" fxLayout fxLayoutAlign="flex-start start" class="previewTemplateDescription">
                    {{ templateUnderPreview.description }}
                  </div>
                </div>
              </div>
  
              <div style="height: 1rem"></div>
  
              <div fxLayout fxLayoutAlign="center center" fxFlexFill>
                <spinner *ngIf="loadingConnectionsSpinner && !connectionsLoaded"></spinner>
              </div>
  
              <div class="subsection connectionListing" *ngIf="connectionsLoaded" fxFlexFill>
                <div class="subsectionHeading" fxLayout="row" fxLayoutAlign="center center">Connect with your Apps</div>
                <div style="height: .5rem"></div>
                <div class="subText" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".5rem">
                  <span>Cloning from bloom template</span>
                  <span><b>{{ templateUnderPreview.name }}</b></span>
                  <span>requires connection with the following Apps</span>
                </div>
                <div style="height: .8rem"></div>
  
                <div class="tableHeader" fxLayout fxLayoutAlign="flex-start center">
                  <span fxFlex="15"></span>
                  <span fxFlex="35">Apps</span>
                  <span fxFlex="50">Your connections</span>
                </div>
                <div class="subsectionItem" *ngFor="let connection of connectionsUsed; let i = index" fxLayout fxLayoutAlign="flex-start center">
                  <span fxFlex="15">
                    <img class="boxLogo" [src]="connection.logoUrl" alt="">
                  </span>
                  <span fxFlex="35">{{ connection.boxName }}</span>
                  <span fxFlex="50">
                    <app-connection-selection
                      [config]="{'box_id': connection.boxId}"
                      (selectedConnection)="boxSelected($event, i)"
                    ></app-connection-selection>
                  </span>
                </div>
              </div>
  
            </div>
            <div *ngIf="!isTemplateSelected">
              <div fxFlexlayout="row" style="height: 1rem"></div>
              <div>Please select a template in previous step</div>
            </div>
  
            <div fxLayout fxLayoutAlign="flex-end center">
              <button mat-button matStepperPrevious>Back</button>
              <button mat-raised-button color="primary" matStepperNext [disabled]="!isTemplateSelected">Next</button>
            </div>
          </ng-template>
        </mat-step>
        <mat-step #step3>
          <ng-template matStepLabel>Finalize</ng-template>
  
          <ng-template matStepContent>
            <div fxFlexlayout="row" style="height: 1rem"></div>
  
            <div fxLayout fxLayoutAlign="center center">
              <mat-form-field style="width: 50%;">
                <mat-label>Bloom Name</mat-label>
                <input matInput [(ngModel)]="newBloomName">
              </mat-form-field>
            </div>
            <div fxFlexlayout="row" style="height: 1rem"></div>
  
            <ng-container *ngIf="replacementConnections.length > 0">
              <div class="subText" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".5rem">
                <span>You are going to clone bloom template</span>
                <span><img [src]="templateUnderPreview.logoUrl" alt="template logo" class="boxLogo"></span>
                <span><b>{{ templateUnderPreview.name }}</b></span>
                <span>with Apps</span>
              </div>
  
              <ng-container *ngFor="let box of replacementConnections">
                <div fxLayout fxLayoutAlign="flex-start center">
                  <span><img [src]="box.options.box_logo_url" alt="template logo" class="boxLogo" style="margin-right: 1rem;"></span>
                  <span><b>{{ box.name }}</b></span>
                </div>
                <div fxFlexlayout="row" style="height: .8rem"></div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="replacementConnections.length == 0">
              <div class="subText" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap=".5rem">
                <span>You are going to clone bloom template</span>
                <span><img [src]="templateUnderPreview.logoUrl" alt="template logo" class="boxLogo"></span>
                <span><b>{{ templateUnderPreview.name }}</b></span>
                <span *ngIf="connectionsUsed.length">without connecting Apps</span>
              </div>
            </ng-container>
            <div fxFlexlayout="row" style="height: 1rem"></div>
  
            <div fxLayout fxLayoutAlign="center center" fxLayoutGap="1rem">
              <button mat-button matStepperPrevious>Back</button>
              <button mat-raised-button color="primary" (click)="cloneNow()" [disabled]="!isTemplateSelected">Clone Now</button>
            </div>
          </ng-template>
        </mat-step>
      </mat-stepper>
  
    </div>
  </div> -->
</div>
