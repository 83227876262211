<!-- <div>CHART DATA CONFIG</div> -->
<div class="cover">
  <div class="gap" style="height: 1rem;"></div>

  <div class="section">
    <div>
      <app-app-selection
        [existingBox]="config.boxId"
        [config]="{isSupportStarch: true}"
        (selectedBox)="appSelected($event)"
      >
      </app-app-selection>

      <app-connection-selection
        *ngIf="(isAppSelected || config.connectionId) && selectedBoxId != 'starch'"
        [existingCon]="config.connectionId"
        [config]="{ box_id: selectedBoxId }"
        (selectedConnection)="boxSelected($event)"
      ></app-connection-selection>

      <base-selection *ngIf="isAppSelected && selectedBoxId == 'starch'" [existingBase]="config.baseId"
      (selectedBase)="baseSelected($event)"></base-selection>

      <div *ngIf="boxFunctionSpinner" fxLayout fxLayoutAlign="center center">
        <spinner></spinner>
      </div>

      <app-select-box-object
        *ngIf="(isConnectionSelected && canGetBoxObjects) || (selectedBoxId && selectedConnectionId && selectedBoxObjectId)"
        [boxId]="getSelectedBoxId()"
        [boxConfigToken]="boxConfigToken"
        [connectionId]="getSelectedConnectionId(selectedBoxId)"
        [oldValue]="selectedBoxObjectId"
        [disableBoxObjectInput]="disableBoxObjectInput"
        (boxObjectInput)="boxObjectSelected($event.__id)"
        (boxObjectSelectionError)="boxObjectSelectionError($event)"
      >
      </app-select-box-object>
      <small *ngIf="isBoxObjectConfigError" style="color: red;">{{ boxObjectConfigError && boxObjectConfigError.error ? (boxObjectConfigError.error.message | json) : 'some error occurred' }}</small>

      <div *ngIf="attributeSpinner || gettingObjFunSpinner" fxLayout fxLayoutAlign="center center">
        <spinner></spinner>
      </div>
    </div>


    <div *ngIf="collectAttrOptions">
      <div style="height: 1rem;"></div>
      <div class="subheading">Input params to fetch attributes</div>

      <shared-parameter-inputs
        [action]="getAttrFn"
        [existingInputs]="availableInputParams"
        [isOnlyOptions]="true"
        (inputParameter)="attrOptionInputsRecevied($event)"
      ></shared-parameter-inputs>

      <button
        mat-stroked-button
        color="primary"
        (click)="attributeOptionsCollected()"
      ><mat-icon>east</mat-icon>
      </button>
    </div>

    <div *ngIf="collectGetOptions">
      <div style="height: 1rem;"></div>
      <div class="subheading">Input params to get record</div>
      <div style="height: .5rem;"></div>
      <shared-parameter-inputs
        [action]="getFn"
        [existingInputs]="availableInputParams"
        [isOnlyOptions]="true"
        (inputParameter)="getOptionInputsRecevied($event)"
      ></shared-parameter-inputs>

      <button
        mat-stroked-button
        color="primary"
        (click)="saveChanges()"
      ><mat-icon>east</mat-icon>
      </button>
    </div>
    <!-- <fieldset class="dataPointsFieldset">
    </fieldset> -->
  </div>

  <div style="height: 1rem;"></div>
</div>
