<div
  class="outerCover"
  *ngIf="builderMode"
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover">
    <mat-form-field
      class="input"
      (mousedown)="$event.stopPropagation()"
      [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
    >
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input
        matInput
        disabled
        type="number"
        [ngStyle]="styles"
        (keyup)="emitUserInput($event)"
        [(ngModel)]="widgetMeta.config.textContent.value"
        placeholder=""
      >
    </mat-form-field>
  </div>
</div>


<div class="outerCover" *ngIf="!builderMode" (click)="onClick()"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div class="innerCover" *ngIf="!widgetMeta.config.viewOnly?.value">
    <mat-form-field class="input" (mousedown)="$event.stopPropagation()"
      [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
    >
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input
        matInput
        type="number"
        [ngStyle]="styles"
        (keyup)="emitUserInput($event)"
        [(ngModel)]="widgetMeta.config.textContent.value"
        placeholder=""
      >
    </mat-form-field>
  </div>

  <div *ngIf="widgetMeta.config.viewOnly?.value"
    class="innerCover label-z-index content" [ngStyle]="styles">
    <mat-label [ngStyle]="styles">{{ widgetMeta.config?.textContent?.value }}</mat-label>
  </div>
</div>
