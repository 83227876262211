import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ConnectionService } from '../../organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';

@Component({
  selector: 'app-workpace-diolog',
  templateUrl: './workpace-diolog.component.html',
  styleUrls: ['./workpace-diolog.component.scss']
})
export class WorkpaceDiologComponent implements OnInit {

  workspaceMap: any = {}
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public dataInput: any,
    public dialog: MatDialogRef<WorkpaceDiologComponent>, 
    public spinner: SpinnerService,
    public connectionService: ConnectionService,
    public authService: AuthServiceService
  ) { }

  ngOnInit(): void {
    console.log("dialogRef", this.dataInput);
    if(this.dataInput.workspaceMap) this.workspaceMap = this.dataInput.workspaceMap;
    this.spinner.hide();
  }

  async createOrUpdateWorkspace(type){
    this.spinner.show();
    var result = null;
    if(type == 'create'){
      result = await this.connectionService.createWorkspace(this.workspaceMap);
    } else {
      result = await this.connectionService.updateWorkspace(this.workspaceMap);
    }
    // this.authService.profile.last_workspace = result._id;
    // await this.authService.updateUser(this.authService.profile);
    this.spinner.hide();
    this.dialog.close(result);
    
  }

}
