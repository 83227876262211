import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if(window){
    window.console['orgLog'] = window.console.log;
    window.console['orgInfo'] = window.console.info;
    window.console.log = function(){};
    window.console.info = function() {};
  }
}


if(environment.production){
  //Set Hotjar
  let hotjarScript = document.createElement('script');
  hotjarScript.innerHTML = `
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${environment.HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;
document.head.appendChild(hotjarScript);
}


// //Set Google Tag Manager
const gtmScript = document.createElement('script');
gtmScript.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${environment.GTM_KEY}')
      `;
document.head.appendChild(gtmScript);


const gtmNoScript = document.createElement('noscript');
gtmNoScript.innerHTML = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${environment.GTM_KEY}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

document.body.appendChild(gtmNoScript);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
