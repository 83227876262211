import { ProfileMenuModule } from './shared/toolbar/profile-menu/profile-menu.module';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/app-shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenUtil } from './core/services/TokenUtil.service';
import { BloomModule } from './bloom/bloom.module';
import { FlowModule } from './flow/flow.module';
import { SpinnerService } from './shared/spinner/spinner.service';
import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavComponent } from './shared/sidenav-bar/nav/nav.component';
import { LeftnavComponent } from './shared/sidenav-bar/leftnav/leftnav.component';
import { ToolbarComponent } from './shared/toolbar/toolbar.component';
import { LocaleService } from './shared/services/translate.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FooterComponent } from './shared/footer/footer.component';
import { MenuProductsModule } from './shared/toolbar/menu-setup/products/products.module';
import { MenuIntegrationsModule } from './shared/toolbar/menu-setup/integrations/integrations.module';
import { MenuSolutionsModule } from './shared/toolbar/menu-setup/solutions/solutions.module';
import { MenuResourcesModule } from './shared/toolbar/menu-setup/resources/resources.module';
import { StarchModule } from './starch/starch.module';
import { FormModule } from './form/form.module';


@NgModule({
    declarations: [
        AppComponent,
        LeftnavComponent,
        NavComponent,
        ToolbarComponent,
        FooterComponent,
    ],
    providers: [
        TokenUtil,
        // ThemeService,
        SpinnerService,
        LocaleService,
        provideClientHydration(),
        // { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    bootstrap: [AppComponent],
    exports: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        ProfileMenuModule,
        SharedModule,
        AppRoutingModule,
        BloomModule,
        BrowserAnimationsModule,
        FlowModule,
        MenuProductsModule,
        MenuIntegrationsModule,
        MenuSolutionsModule,
        MenuResourcesModule,
        StarchModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //     enabled: false,//environment.production || environment.env == "development",
        //     registrationStrategy: "registerWithDelay:0"
        //     // Register the ServiceWorker as soon as the app is stable
        //     // or after 30 seconds (whichever comes first).
        //     // registrationStrategy: 'registerWhenStable:30000' registerWithDelay:0
        // }),
        FormModule
    ]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
