import { Widget } from "./baseWidget";


export class Login extends Widget {
  type = 'login';
  gridX = 6
  minGridX = 1
  config = {
    props: [],
  }

  //text formatting properties
  textFormat = undefined

  constructor(id: number, name: string) {
    super(id, name)
  }


  public getMappingConfig() {
    let map = {
    }
    return map;
  }

  public getWidgetConfig() {
    let map: any = {
    }
    return map;
  }
}
