export const LineChartConfig: any = {
  dataSource: {
    dimensions: [
      {
        attribute: {},
        drillDownPath: '',
      }
    ],
    sort: {
      sortEnabled: false,
      sortAttributes: []
    },
  },
  otherProps: {
    props: ['title', 'yAxisTitle'],
    title: {
      displayName: "Line chart title",
      type: "text",
      value: "",
    },
    yAxisTitle: {
      displayName: "Vertical axis title",
      type: "text",
      value: "",
    },
    precision: {
      displayName: "Decimal point precision",
      type: "text",
      value: 2,
    }
  }
}
