import { Widget } from "./baseWidget";
import { v4 as uuidv4 } from 'uuid';

export class ConnectionList extends Widget {
  type = 'connection-list';
  gridX = 6
  minGridX = 4

  config = {
    hidden: false,
    // editable: true,
    props: [
      'predefinedFilters',,
      // 'placeholder',
      // 'connection',
      "title",
      // 'defaultValue',
      // 'noEditable',
      'tooltip',
      // 'nofloat',
      // 'appearance',
      "viewOnly"
    ],
    appearance: {
      displayName: " input appearance",
      value: "standard",
      type: "select"
    },
    placeholder: {
      displayName: 'Input Placeholder',
      value: "default input placeholder",
      resetValue: '',
      type: 'text'
    },
    title: {
      displayName: "Title",
      value: 'Channel List',
      resetValue: '',
      type: 'text',
    },
    whiteLabelId: {
      displayName: "whiteLabelId",
      value: uuidv4(),
      resetValue: '',
      type: 'text',
    },
    connections: {
      displayName: "connections",
      value: "",
      resetValue: '',
      type: 'text',
    },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    noEditable: {
      displayName: 'Non Editable',
      type: 'boolean',
      value: false,
    },
    nofloat: {
      displayName: 'No Float',
      type: 'boolean',
      value: false,
    },
    viewOnly: {
      displayName: 'View only',
      value: false,
      type: 'boolean'
    },
    predefinedFilters: {
      displayName: 'View only',
      filterType: 'any',
      value: [],
      type: 'app-filter'
    }
  }

  constructor(id: number, name: string) {
    super(id, name)
  }

  public getMappingConfig() {
    let map = {
      __id: "${panel.id}.${widget.id}.value",
      name: "${panel.name} ${widget.name}",
      dataType: "string"
    }
    return map;
  }

  public getWidgetConfig() {
    let map: any = {
      valuePath: "config.connections.value",
      effect: {
        styles: []
      }
    }
    map = this.mergeEffectStyle(map);

    const appearance = this.config.appearance.value;
    if (appearance === 'rounded') {
      map.effect.styles.push('rounded');
    } else if (appearance === 'outlined') {
      map.effect.styles.push('outlined');
    }

    return map;
  }
}
