
<ng-container *ngIf="widgetMeta && widgetMeta.type && (builderMode || (!builderMode))">
  <!--  && !sps.spinner -->
  <ng-container [ngSwitch]="widgetMeta.type"
  >
    <ng-container *ngSwitchCase="'input'">
      <app-textinput
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"
        [isDisabled]="isDisabled"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)"
      >
      </app-textinput>
    </ng-container>

    <ng-container *ngSwitchCase="'button'">
      <app-button
         #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)"
        (onExecuteAction)="raiseOnExecuteAction($event)"
      >
      </app-button>
    </ng-container>

    <ng-container *ngSwitchCase="'label'">
      <app-label
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [pageMeta]="pageMeta"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)"
      >
      </app-label>
    </ng-container>

    <div *ngSwitchCase="'image'" style="height: 100%">
      <app-image
        style="width: 100%;" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"
        [cardImage]="widgetOptions?.cardImage"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (applyImageHeight)="raiseApplyImageHeight($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)"
      >
      </app-image>
    </div>

    <ng-container *ngSwitchCase="'embed'">
      <app-embed
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (applyWidgetHeight)="raiseApplyImageHeight($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-embed>
    </ng-container>

    <ng-container *ngSwitchCase="'tags'">
      <app-tags
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (applyWidgetHeight)="raiseApplyImageHeight($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-tags>
    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <app-select
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-select>
    </ng-container>

    <ng-container *ngSwitchCase="'separator'">
      <app-separator
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-separator>
    </ng-container>

    <ng-container *ngSwitchCase="'imageinput'">
      <app-imageinput
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-imageinput>
    </ng-container>

    <ng-container *ngSwitchCase="'icon'">
      <app-icon
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-icon>
    </ng-container>

    <ng-container *ngSwitchCase="'checkbox'">
      <app-checkbox
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-checkbox>
    </ng-container>

    <ng-container *ngSwitchCase="'choice'">
      <app-choice
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-choice>
    </ng-container>

    <ng-container *ngSwitchCase="'textarea'">
      <app-textarea
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-textarea>
    </ng-container>

    <ng-container *ngSwitchCase="'richtext'">
      <app-richtextinput
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"

        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-richtextinput>
    </ng-container>
    <!-- (widgetClick)="raiseWidgetClick($event)" -->
    <ng-container *ngSwitchCase="'slider'">
      <app-slider
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-slider>
    </ng-container>

    <ng-container *ngSwitchCase="'autocomplete'">
      <app-autocomplete
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-autocomplete>
    </ng-container>

    <ng-container *ngSwitchCase="'chips'">
      <app-chips
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-chips>
    </ng-container>

    <ng-container *ngSwitchCase="'numberinput'">
      <app-numberinput
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-numberinput>
    </ng-container>

    <ng-container *ngSwitchCase="'time'">
      <app-time
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-time>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <app-date
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-date>
    </ng-container>

    <ng-container *ngSwitchCase="'star'">
      <app-star
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-star>
    </ng-container>

    <ng-container *ngSwitchCase="'link'">
      <app-link
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-link>
    </ng-container>

    <ng-container *ngSwitchCase="'datetime'">
      <app-datetime
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-datetime>
    </ng-container>

    <ng-container *ngSwitchCase="'duration'">
      <app-duration
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-duration>
    </ng-container>

    <ng-container *ngSwitchCase="'period'">
      <app-period
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-period>
    </ng-container>

    <div *ngSwitchCase="'chart'" style="height: 100%;">
      <app-chart
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (applyImageHeight)="raiseApplyImageHeight($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-chart>
    </div>

    <div *ngSwitchCase="'space'" style="height: 100%;">
      <app-space
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (applyImageHeight)="raiseApplyImageHeight($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-space>
    </div>

    <div *ngSwitchCase="'connection'" style="height: 100%;">
      <app-connection
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (applyImageHeight)="raiseApplyImageHeight($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-connection>
    </div>

    <div *ngSwitchCase="'connection-list'" style="height: 100%;">
      <app-connection-list
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (applyImageHeight)="raiseApplyImageHeight($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-connection-list>
    </div>

    <div *ngSwitchCase="'login'" style="height: 100%;">
      <app-login
        style="width: 100%" #widgets
        [widgetMeta]="widgetMeta"
        [panelId]="panelId"
        [builderMode]="builderMode"
        [selectedWidgetId]="selectedWidgetId"
        [contextActions]="contextActions"
        [widgetOptions]="widgetOptions"

        (newWidgetMeta)="raiseNewWidgetMeta($event)"
        (userInputReceived)="raiseUserInputReceived($event)"
        (widgetDeletion)="raiseWidgetDeletion($event)"
        (widgetSelection)="raiseWidgetSelection($event)"
        (widgetHover)="raiseWidgetHover($event)"
        (applyImageHeight)="raiseApplyImageHeight($event)"
        (raiseContextMenuActions)="raiseContextMenuActions($event)">
      </app-login>
    </div>

  </ng-container>
</ng-container>

