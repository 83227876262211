import { MatButtonModule } from '@angular/material/button';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatIconModule } from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
// import { BuilderToolbarComponent } from './builder-toolbar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { DeleteDialogComponent } from './delete-dialog.component';
import { FormsModule } from '@angular/forms';
import { BuilderMenuPagesComponent } from './builder-menu-pages.component';
import { MatTreeModule } from '@angular/material/tree';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [BuilderMenuPagesComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTooltipModule,
    MatToolbarModule,
    FormsModule,
    MatTreeModule,
    SpinnerModule,
    DragDropModule,
    MatMenuModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatInputModule
  ],
  exports: [BuilderMenuPagesComponent]
})
export class BuilderMenuPagesModule { }
