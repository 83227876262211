import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ConnectionService } from '../../modules/organization/connection.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { FlowService } from "../../flow/flow.service";
import { FormService } from '../../form/form.service';
import { StarchService } from 'src/app/shared/services/starch.service';

interface snackBarValues {
  snackBarMessage: string;
  snackBarIcon: string;
  snackBarError: boolean;
  snackBarDuration: number;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
  snackBarObj: any;
  spinner:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinnerService: SpinnerService,
    public connectionService: ConnectionService,
    private _snackBar: MatSnackBar,
    public metaService: MetaService,
    public fs: FlowService,
    public formService: FormService,
    public starchService: StarchService) {}

    
  ngOnInit(): void {
    console.log("Resource", this.data?.resource, "Resource Name", this.data?.resourceName)
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  delete(){
    // this.dialogRef.close(true);
    this.spinner = true
    if(this.data?.resource == "Connection"){
      this.deleteConnection(this.data.dataObj)
    }else if(this.data?.resource == "Bloom"){
      this.deleteBloom(this.data.dataObj)
    }else if(this.data?.resource == "Flow"){
      this.deleteFlow(this.data.dataObj)
    }else if(this.data?.resource == "Form"){
      this.deleteForm(this.data.dataObj)
    }else if(this.data?.resource == "Starch"){
      this.deleteStarch(this.data.dataObj)
    }
  }

  deleteConnection(connection){
    this.connectionService
          .deleteConnection(
            connection._id,
            this.connectionService.preAuthenticatedToken
          )
          .then(
            (response) => {
              console.log("Connection deletion responce", response);
              this.spinner = false;
              this.snackBarObj = {
                snackBarMessage: 'Connection Deleted Successfully',
                snackBarIcon: 'check_circle',
                snackBarDuration: 2000,
                snackBarError: false,
              };
              this.openSnackBar(this.snackBarObj);
              this.dialogRef.close(true);
            },
            (error) => {
              console.log(error);
              this.spinner = false;
              this.snackBarObj = {
                snackBarMessage: 'Error while Deleting Connection',
                snackBarIcon: 'error',
                snackBarDuration: 6000,
                snackBarError: true,
              };
              this.openSnackBar(this.snackBarObj);
              this.dialogRef.close(false);
            }
          )
          .catch((e) => {
            console.log(e);
            this.dialogRef.close(false);
          });
  }

  openSnackBar(snackBarObj: snackBarValues) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      data: {
        message: snackBarObj.snackBarMessage,
        iconname: snackBarObj.snackBarIcon,
      },
      duration: snackBarObj.snackBarDuration,
      horizontalPosition: 'end',
    });
  }

  async deleteBloom(bloom){
    try {
      let deleteResponse = await this.metaService.deleteBloom(bloom);
      console.log('bloom deletion response ------> ', deleteResponse);
      if(deleteResponse && bloom.publishedUrl){
        let publishedBloom = await this.metaService.getBloomByCode(bloom.code)
        console.log("published bloom found", publishedBloom)
        if(publishedBloom.length){
          for(let i=0; i<publishedBloom.length; i++){
            let deletePublished = this.metaService.deleteBloom(publishedBloom[i]);
            console.log("published bloom deleted", deletePublished)
          }
        }
      }
      if(deleteResponse){
        this.spinner = false;
        this.snackBarObj = {
          snackBarMessage: 'Bloom Deleted Successfully',
          snackBarIcon: 'check_circle',
          snackBarDuration: 2000,
          snackBarError: false,
        };
        this.openSnackBar(this.snackBarObj);
        this.dialogRef.close(true);
      }
    }catch(error){
      console.log(error);
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Error while Deleting Bloom',
        snackBarIcon: 'error',
        snackBarDuration: 6000,
        snackBarError: true,
      };
      this.openSnackBar(this.snackBarObj);
      this.dialogRef.close(false);
    }

  }

  async deleteFlow(flow){
    try{
        var result = await this.fs.deleteFlow(flow);

        // delete published flow also
        if(result && flow.published_flow_id) {
          let res = this.fs.deleteFlow({_id: flow.published_flow_id})
          console.log("published flow deleted", res)
        }
        console.log("Flow deletion result", result);
        if(result){
          this.spinner = false;
          this.snackBarObj = {
            snackBarMessage: 'Flow Deleted Successfully',
            snackBarIcon: 'check_circle',
            snackBarDuration: 2000,
            snackBarError: false,
          };
          this.openSnackBar(this.snackBarObj);
          this.dialogRef.close(true);
        }
        
    }catch(error){
      console.log("could not delete flow", error)
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Error while Deleting Flow',
        snackBarIcon: 'error',
        snackBarDuration: 6000,
        snackBarError: true,
      };
      this.openSnackBar(this.snackBarObj);
      this.dialogRef.close(false);
    }
  }

  async deleteForm(form){
    try {
        let deleteResponse = await this.formService.deleteForm(form);
        console.log('form deletion response ------> ', deleteResponse);
        if(deleteResponse && form.publishedUrl){
          let filterStr = `code=${form.code},status=published`;
          let publishedForm = await this.formService.getFormByFilter(filterStr);
          console.log("published form", publishedForm)
          if(publishedForm){
            let result = this.formService.deleteForm(publishedForm);
            console.log(' published form deletion response ------> ', result);
          }
        }
        if(deleteResponse){
          this.spinner = false;
          this.snackBarObj = {
            snackBarMessage: 'Form Deleted Successfully',
            snackBarIcon: 'check_circle',
            snackBarDuration: 2000,
            snackBarError: false,
          };
          this.openSnackBar(this.snackBarObj);
          this.dialogRef.close(true);
        }
      }catch (error) {
      console.log("could not delete form", error)
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Error while Deleting Form',
        snackBarIcon: 'error',
        snackBarDuration: 6000,
        snackBarError: true,
      };
      this.openSnackBar(this.snackBarObj);
      this.dialogRef.close(false);
    }
  }

  async deleteStarch(starch){
    try {
      let deleteResponse = await this.starchService.deleteStarchBase(starch);
      console.log('starch deletion response ------> ', deleteResponse);
        if(deleteResponse){
          this.spinner = false;
          this.snackBarObj = {
            snackBarMessage: 'Starch Deleted Successfully',
            snackBarIcon: 'check_circle',
            snackBarDuration: 2000,
            snackBarError: false,
          };
          this.openSnackBar(this.snackBarObj);
          this.dialogRef.close(true);
        }
    } catch (error) {
      console.log("could not delete starch", error)
      this.spinner = false;
      this.snackBarObj = {
        snackBarMessage: 'Error while Deleting Starch',
        snackBarIcon: 'error',
        snackBarDuration: 6000,
        snackBarError: true,
      };
      this.openSnackBar(this.snackBarObj);
      this.dialogRef.close(false);
    }
  }

  dontDelete(){
    this.dialogRef.close(false);
  }

}
