<div *ngIf="step" [class.disabled]="!editMode" class="">
  <div>
    {{step.step_type === 'action' ? 'Action ' : step.step_type === 'control' ? 'Control ': ''}} Configuration
  </div>
  <div style="padding: 0px 10px 0px 10px;" class="container-with-thin-scrollbar scrollable-container">
      <div *ngIf="step.step_type === 'action'" (click)="$event.stopPropagation()">
          <step-action
              [step]="step"
              [editMode]="editMode"
              [sourceFields]="sourceFields"
              (onConfigure)="setStep($event, step)"
              (stepSourceFields)="setSourceFields(index + 1, $event)"
              (userChanges)="userMadeChanges($event, index)"
              (actionChanged)="stepActionChanged($event, step)">
          </step-action>
      </div>
      <div *ngIf="step.step_type === 'control'" (click)="$event.stopPropagation()">
          <step-control
              [step]="step"
              [sourceFields]="sourceFields"
              (onConfigure)="setStep($event, step)"
              (stepSourceFields)="setSourceFields(index + 1, $event)"
              (actionChanged)="stepActionChanged($event, step)">
          </step-control>
      </div>

      <div *ngIf="step.step_type === 'processor'" (click)="$event.stopPropagation()">
        <step-processor
            [step]="step"
            [sourceFields]="sourceFields"
            (onConfigure)="setStep($event, step)"
            (stepSourceFields)="setSourceFields(index + 1, $event)"
            (userChanges)="userMadeChanges($event, index)"
            (actionChanged)="stepActionChanged($event, step)">
        </step-processor>
    </div>

</div>
</div>

