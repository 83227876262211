<div *ngIf="builderMode" class="outerCover" fxLayout [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow" (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()" (click)="onClick($event)"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>

  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <div class="innerCover" fxLayout="column" fxLayoutAlign="flex-start center">
    <div fxFlexFill>
      <ng-container *ngIf="!editingHeading; else elseTemplate">
        <div class="editHeadingBlock" fxFlexFill fxLayout="row wrap"
          [style.justify-content]="widgetMeta?.config?.alignment?.value" fxLayoutGap=".5rem">
          <div [class.blurred]="!widgetMeta.config.showTitle?.value" [ngStyle]="styles">{{
            widgetMeta.config.title?.value }}</div>
          <mat-icon [class.blurred]="!widgetMeta.config.showTitle.value" class="icon editHeadingIcon"
            (click)="editingHeading = widgetMeta.config.showTitle.value ? true : false; $event.stopPropagation()"
            matTooltip="Edit Title">edit</mat-icon>
          <mat-icon class="icon" *ngIf="widgetMeta.config.showTitle.value"
            (click)="widgetMeta.config.showTitle.value = false; $event.stopPropagation()" matTooltip="Hide Title">
            visibility_off</mat-icon>
          <mat-icon class="icon" *ngIf="!widgetMeta.config.showTitle.value"
            (click)="widgetMeta.config.showTitle.value = true; $event.stopPropagation()" matTooltip="Show Title">
            visibility</mat-icon>
        </div>
      </ng-container>
      <ng-template #elseTemplate>
        <mat-form-field class="editHeadingBlock" style="width: 100%">
          <mat-label>Change Title</mat-label>
          <input matInput #newHeading type="text" [value]="widgetMeta.config.title.value"
            (click)="$event.stopPropagation()">
          <mat-icon matSuffix class="icon saveHeadingIcon"
            (click)="editingHeading = false; saveNewHeading(newHeading); $event.stopPropagation()">done</mat-icon>
        </mat-form-field>
      </ng-template>
    </div>


    <div style="height: .5rem; width: 100%;"></div>

    <span fxLayout fxLayoutAlign="center center" (click)="onClick($event)" (mousedown)="$event.stopPropagation()"
      style="width: 80%" fxLayoutGap="1rem">
      <ng-container *ngFor="let i of stars; let index = index">
        <mat-icon class="icon star" [id]="'star_' + i" *ngIf="i === 0" (click)="starred(index + 1)">{{ 'star_outline' }}
        </mat-icon>
        <mat-icon class="icon star" [id]="'star_' + i" *ngIf="i === 1" (click)="starred(index + 1)">{{ 'star' }}
        </mat-icon>
      </ng-container>
    </span>
  </div>
</div>


<div
  class="outerCover" *ngIf="!builderMode" (click)="onClick($event)"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
>
  <div class="innerCover" fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="widgetMeta.config.showTitle.value" class="editHeadingBlock" fxLayout="row wrap"
      fxLayoutAlign="center center" fxLayoutGap=".5rem" style="width: 100%;">
      <span [ngStyle]="styles">{{ widgetMeta.config.title.value }}</span>
      <div style="height: .5rem; width: 100%"></div>
    </div>
    <div class="innerCover" style="width: 80%;" fxLayout fxLayoutAlign="space-around center" (click)="onClick($event)">
      <ng-container *ngFor="let i of stars; let index = index">
        <mat-icon class="icon star" [id]="'star_' + i" *ngIf="i == 0" (click)="starred(index + 1)"
          (mousedown)="$event.stopPropagation()">{{ 'star_outline' }}</mat-icon>
        <mat-icon class="icon star" [id]="'star_' + i" *ngIf="i == 1" (click)="starred(index + 1)"
          (mousedown)="$event.stopPropagation()">{{ 'star' }}</mat-icon>
      </ng-container>
    </div>
  </div>
</div>
