import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { BoxCache } from 'src/app/core/boxInstances/BoxCache';
import { FlowService } from '../../flow.service';
import { BoxService } from 'src/app/shared/services/box.service';


@Component({
  selector: 'app-flow-template',
  templateUrl: './flow-template.component.html',
  styleUrls: ['./flow-template.component.scss']
})
export class FlowTemplateComponent implements OnInit {

  spinner:boolean = false;
  replacedStepMap: any[];
  @Input() templateSteps: any;
  @Output() actionMapped = new EventEmitter<any>();
  isObjectCreationRequired: boolean;
  isSchemeSet: boolean = false;
  isSchemeSetError: boolean = false;
  constructor(
    public flowService: FlowService,
    public boxService: BoxService
  ) {}

  async ngOnInit() {

    let templateActionMaps = JSON.parse(JSON.stringify(this.templateSteps));
    this.customInit(templateActionMaps);
  }

  async customInit(templateSteps){
    console.log("templateSteps", templateSteps)
    this.replacedStepMap = []
    for (let index = 0; index < templateSteps.length; index++) {
      const element = templateSteps[index];
      let obj = element;
      obj.index = index;
      if(obj.connection && obj.step_type == "action"){
        obj.boxMap = BoxCache.boxObject[obj.box_id];
        this.replacedStepMap.push(obj)
      }
    }
    console.log("replacedActionMaps", this.replacedStepMap);
    // if(this.replacedStepMap?.length == 0)this.actionMapped.emit(this.templateSteps);
  }

  async initActions(steps){
    console.log("steps", steps)
    this.templateSteps = steps;
    await this.customInit(this.templateSteps);
  }



  // nameChanged(e){
  //   console.log("name", e);
  //   this.nameMap = e;
  // }

  connectionSelected(e, step){
    step.replacedConnection = e._id;
    console.log("this.dataInput.templateSteps", this.templateSteps);
    this.replacedStepMap.forEach(element => {
      element.isObjectCreationRequired = this.isObjectAttriMappingRequire(element)
      if(element.isObjectCreationRequired) {
        this.isObjectCreationRequired = true;
      }
    });
    if(!this.isObjectCreationRequired){
      this.assignReplacedConnection()
    }
  }

  assignReplacedConnection(){
    let templateSteps = this.templateSteps;
    console.log("replacedStepMap",this.replacedStepMap);
    let valid = true;
    for (let index = 0; index < templateSteps.length; index++) {
      let step = templateSteps[index];
      if(step?.connection && step?.step_type == "action"){
        this.replacedStepMap.forEach(element => {
          if(!element.replacedConnection) valid = false;
          if(index == element.index && element.replacedConnection){
            step.connection = element.replacedConnection;
          }
        });
      }
    }
    console.log("templateSteps", this.templateSteps);
    if(valid) this.actionMapped.emit(this.templateSteps);
  }

  async cloneObjectNow(){
    console.log("replacedStepMap", this.replacedStepMap);
    this.spinner = true;
    for (let index = 0; index < this.replacedStepMap.length; index++) {
      const element = this.replacedStepMap[index];
      if(element.isObjectCreationRequired){
        let action = element.action;
        let object = "";
        if(action.split("/").length == 2) {
          object = action.split("/")[0];
          action = action.split("/")[1];
        };
        console.log("action object", action, object);

        if(action && object){

          try {
            let isObjectExists = await this.checkObjectAndAttribute(element, action, object);
            console.log("isObjectExists", isObjectExists)
            if(!isObjectExists) {
            await this.createObjectAndAttributes(element, action, object);
              this.isSchemeSet = true;
            }
          } catch (error) {
            this.isSchemeSetError = true;
            console.log("error on setting schema", error);
          }
          // await this.createObjectAndAttributes(element, action, object);
        }
      }
    }
    this.assignReplacedConnection()
    this.spinner = false;
  }

  async createObjectAndAttributes(step, action, object){
    console.log("element", step, action, object);
    let actions = await this.boxService.getBoxActions(step.box_id, step.replacedConnection, null, true);
    let actionFun;
    if (actions && Array.isArray(actions)) {
      actionFun = actions.find(obj => obj.__id === "createobject");
    }
    if(actionFun){
      console.log("creating ObjectAndAttributes")
      let payload = this.constructObjectPayload(actionFun, step, object);
      await this.boxService.executeBoxFunction(step.replacedConnection, step.box_id, actionFun.__id, payload);
    }
  }

  async checkObjectAndAttribute(step, action, object){
    let exists = false;
    let objects = await this.boxService.getBoxObjects(step.replacedConnection, step.box_id, true);
    if (objects && Array.isArray(objects)) {
      exists = objects.some(obj => obj.__id === object);
    }
    return exists;
  }

  isObjectAttriMappingRequire(step){
    let action = step.action;
    let object = "";
    let bool = false;
    if(action.split("/").length == 2) {
      action = action.split("/")[1];
      object = action.split("/")[0];
      //box map customerobject
      if(action == "create") bool = true;
    }
    return bool;
  }

  constructObjectPayload(actionFun, step, object){
    let mapping = step?.mapping || [];
    let attributes = mapping.map(item => ({
      name: item.appField.__id,
      dataType: item.appField.dataType
    }));
    let payload = {
      parameters: {
        object: object,
        attributes:attributes
      }
    }
    return payload;
  }
}
