<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1rem" class="heading">
  <div fxFlex="5" fxLayout fxLayoutAlign="center center"><mat-icon class="matIcon">warning</mat-icon></div>
  <div fxFlex="95">Page Deletion</div>
</div>

<div style="height: 1rem;"></div>

<div>
  <span>Are you sure you want to delete</span>
  <br>
  <span style="font-weight: 500;">{{ data.name + "?" }}</span>
</div>

<div style="height: 1rem;"></div>

<div class="warning">This action is not reversible. Dependent pages may be affected.</div>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close (click)="dontDelete()">Cancel</button>
  <button mat-stroked-button color="warn" (click)="delete()">Delete</button>
</mat-dialog-actions>
