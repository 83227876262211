import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout'

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';



import { SpinnerModule } from '../spinner/spinner.module';
import { BoxConfigInputsModule } from '../../bloom/box-config-inputs/box-config-inputs.module';
import { ConnectionSelectionComponent } from './connection-selection.component';
import { ConnectionPopupComponent } from './connection-popup/connection-popup.component';
import { OrganizationModule } from 'src/app/modules/organization/organization.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NewConnectionModule } from 'src/app/modules/organization/connection/new-connection/new-connection.module';

import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    ConnectionSelectionComponent,
    ConnectionPopupComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    // BoxConfigInputsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    SpinnerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    // OrganizationModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
    NewConnectionModule,
    MatMenuModule
  ],
  exports: [
    ConnectionSelectionComponent,
    ConnectionPopupComponent
  ]
})
export class ConnectionSelectionModule { }
