import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout'
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import { MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';

import { AttributeSelectionModule } from './attribute-selection/attribute-selection.module';
import { SecurityConfigModule } from '../security/config/security-config/security-config.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { FilterConfigModule } from './filter-config/filter-config.module';
import { SortConfigModule } from './sort-config/sort-config.module';
import { GroupConfigModule } from './group-config/group-config.module';

// import { FilterConfigComponent } from './panel-filter-config/panel-filter-config.component';
// import { SortConfigComponent } from './sort-config/sort-config.component';
import { QueryInputsComponent } from './query-inputs-component/query-inputs-component.component';
import { FilterComponentComponent } from './filter-component/filter-component.component';
import { FilterComponentModule } from './filter-component/filter-component.module';

@NgModule({
    declarations: [
        QueryInputsComponent
    ],
    exports: [
        QueryInputsComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatIconModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatInputModule,
        MatButtonModule,
        MatTooltipModule,
        SpinnerModule,
        MatSelectModule,
        MatMenuModule,
        MatRadioModule,
        MatCardModule,
        MatDividerModule,
        SecurityConfigModule,
        AttributeSelectionModule,
        FilterConfigModule,
        FilterComponentModule,
        SortConfigModule,
        GroupConfigModule
    ]
})
export class QueryComponentsModule { }
