<div [class.disabled]="!editMode">
  <div>
    Trigger Configuration
  </div>
  <br/>
  <div style="padding: 0px 10px 0px 10px;" class="container-with-thin-scrollbar scrollable-container">

    <!-- <div *ngIf="selectedTrigger && editMode" fxLayout="row wrap" fxLayoutAlign="start center">
      <div >
        <button *ngIf="editMode" class="runbutton" mat-stroked-button color="primary"
        aria-label=""
        (click)="isChangeTrigger = !isChangeTrigger;">
          <mat-icon >rule_settings</mat-icon>
          <span style="text-wrap: nowrap;">Change Trigger</span>
        </button>
      </div>
    </div> -->

    <div *ngIf="selectedTrigger && editMode">
      <br/>
      <div fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="100%">
        <!-- <div> -->
          <div *ngFor="let trigger of triggers;">
              <mat-card (click)="onSelectedTrigger(trigger)"
                  class="card-1 mat-card-custom" style="cursor: pointer; margin-right: 5px;margin-bottom: 5px;"
                  [ngClass]="trigger.id === flow.trigger_type ? 'card-1-selected' : ''"
              >
                  <mat-card-content fxLayout="row" >
                      <div fxLayoutAlign="center center">
                        <mat-icon color="primary" class="trigger-icon"> {{trigger.icon}}</mat-icon>
                      </div>
                      <div fxLayoutAlign="center center">
                          <div class="trigger-title">{{trigger.title}}</div>
                      </div>

                  </mat-card-content>
              </mat-card>
              <!-- <div style="height: 5px;"></div> -->
          </div>
        <!-- </div> -->
      </div>
    </div>



    <div *ngIf="selectedTrigger" [class.disabled]="!editMode" fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxLayout="column" fxFlex="5"></div>
      <div fxLayout="column" fxFlex="90">
        <app-trigger-event *ngIf="flow.trigger_type === 'app_event'"
            [editMode]="editMode"
            [hideRibbon]="true"
            [flowMap]="flow"
            (sourceFields)="setSourceFields(0, $event)"
            (onConfigure)="flow.trigger = $event"
            (appSelected)="boxSelected($event)"
          ></app-trigger-event>
          <app-trigger-scheduler *ngIf="flow.trigger_type === 'schedule'"
              [editMode]="editMode"
              [hideRibbon]="true"
              [flowMap]="flow"
              (onConfigure)="flow.trigger.schedulers = $event"
              (sourceFields)="setSourceFields(0, $event)">
          </app-trigger-scheduler>
          <app-trigger-webhook *ngIf="flow.trigger_type === 'webhook'"
          [hideRibbon]="true"
              [editMode]="editMode"
              [flowMap]="flow"
              (onConfigure)="flow.trigger = $event"
              (sourceFields)="setSourceFields(0, $event)"
              (saveSetup)="insertOrUpdateFlow(true)">
          </app-trigger-webhook>

      </div>
      <div fxLayout="column" fxFlex="5"></div>
  </div>
  </div>
</div>

