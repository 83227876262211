import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-box-picker',
  templateUrl: './box-picker.component.html',
  styleUrls: ['./box-picker.component.scss']
})
export class BoxPickerComponent implements OnInit {

  // config: any;
  @Output() "selectedBox" = new EventEmitter<any>();
  @Input() config: any;
  @Input() header: any;

  constructor() { }

  ngOnInit(): void {
  }

  newAppSelected(app){
    console.log("BOX-PICKER - APP SELECTED : ", app);
    this.selectedBox.emit(app);

  }

}
