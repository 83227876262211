<div (contextmenu)="onTriggerContextMenu($event)">
</div>


<div style="height: 100%;">
<!-- [ngStyle]="margin? {'margin-left': margin.left + '%', 'margin-right': margin.right + '%'} : {'margin-left': '0%', 'margin-right': '0%'}" -->
  <app-widget-wrapper
    [widgetMeta]="widgetMeta"
    [panelId]="panelMeta.id"
    [builderMode]="builderMode"
    [selectedWidgetId]="selectedWidgetId"
    [contextActions]="contextActions"
    [pageMeta]="pageMeta"
    [widgetOptions]="widgetOptions"

    (newWidgetMeta)="raiseNewWidgetMeta($event)"
    (widgetDeletion)="raiseWidgetDeletion($event)"
    (widgetSelection)="raiseWidgetSelection($event)"
    (widgetClick)="raiseWidgetClick($event)"
    (widgetHover)="raiseWidgetHover($event)"
    (userInputReceived)="raiseUserInputReceived($event)"
    (applyImageHeight)="raiseApplyImageHeight($event)"
    (contextMenuActions)="saveContextMenuActions($event)"

    [class.panelCut]="widgetMeta.id === pageService?.copiedMeta?.id && pageService.isToolCutted"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="widgetOptions?.configurationEnabled === false || !builderMode ? null : contextmenu" 
    (menuOpened)="menuOpened(widgetMeta)" 
    (click)="widgetClicked(widgetMeta)"
  ></app-widget-wrapper>
    <!-- [matMenuTriggerFor]="contextmenu"  -->
  </div>

<!-- [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" -->
<mat-menu
  #contextmenu="matMenu"
  id="mymenu"
  class="myMenu"
  [hasBackdrop]="contextMenuBackdrop"
  [xPosition]="'after'"
  [yPosition]="'above'"
  [overlapTrigger]="false"
  (click)="$event.stopPropagation()"
>

  <app-context-menu
    [actionConfig]="widgetContextMenuActions"
    [widgetMeta]="widgetMeta"
    [panelId]="panelMeta.id"
    
    (closeMenu)="closeMenu()"
    (contextChanged)="contextChanged($event)"
  >
  </app-context-menu>
</mat-menu>

<div (contextmenu)="onTriggerContextMenu($event)" ></div>

