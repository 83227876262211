<spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner>
<mat-card appearance="outlined" style="padding: 1rem">
<div style="min-height: 100px;background-color: transparent;" fxLayout="row wrap" fxLayoutAlign="center center">
    <br/>
    <!-- <div fxLayout="column" fxFlex="5"></div> -->
    <div fxLayout="column" fxFlex="100">
        <form [formGroup]="formGroup" >
        <div fxLayout="row" style="margin-top: 10px;" fxLayoutAlign="center center" >
            
            <div fxLayout="column" class="base-field" fxFlex="100">
                
                <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-label> Base name </mat-label> 
                    <input required formControlName="name" matInput placeholder="base name">
                    
                </mat-form-field>
                <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.name.errors?.required">
                    <mat-icon>error</mat-icon>Base name is required.
                </mat-error>
                
            </div>
            <!-- <div fxLayout="column" fxFlex="20">
            </div> -->
        </div>
        <div fxLayout="row" style="margin-top: 10px;"  fxLayoutAlign="center center" >
            <div fxLayout="column" class="base-field" fxFlex="100">
                
                <mat-form-field appearance="outline" style="width: 100%;"> 
                    <mat-label> Base code </mat-label>
                    <input (keyup)="onKeySearch($event)"
                     formControlName="code" id="code" name="code" required  matInput placeholder="Base code">
                    <mat-icon color="accent" matSuffix style="cursor:pointer;" (click)="checkAndGetRandomCode(baseMap.code)">refresh</mat-icon>
                    
                </mat-form-field>
                <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.code.errors?.minlength">
                    <mat-icon class="error-icon">error</mat-icon>Base code must be at least 6 characters long.
                </mat-error>
                <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.code.errors?.maxlength">
                    <mat-icon class="error-icon">error</mat-icon>Base code must be a maximum of 12 characters long.
                </mat-error>
                <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.code.errors?.required">
                    <mat-icon class="error-icon">error</mat-icon>Base code is required.
                </mat-error>
                
            </div>
            <mat-spinner  [diameter]="20" *ngIf="spinner"></mat-spinner>
            <!-- <div fxLayout="column" fxFlex="20">
            </div> -->
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isNameExists" >
            <div style="color: brown" fxLayout="column" class="base-field" fxFlex="100">
               <mat-icon class="error-icon">error</mat-icon> Base code is not available.
            </div>
            <!-- <div fxLayout="column" fxFlex="20"> 
           </div> -->
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" >
            <div style="font-size: small;" fxLayout="column" class="base-field" fxFlex="100">
                Base code can have lowercase letters, digits or hyphens. This can't be changed later.
            </div>
            <!-- <div fxLayout="column" fxFlex="20"> 
           </div> -->
        </div>
    </form>
    </div>
    <!-- <div fxLayout="column" fxFlex="5"></div> -->
</div>
<br/>
<div fxLayout="row wrap" fxLayoutAlign="center center">
    <!-- <div fxLayout="column" fxFlex="5"></div> -->
    <div fxLayout="column" fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxLayout="column" fxFlex="30">
            </div>
            
            <div fxLayout="column" fxFlex="30">
                <button mat-stroked-button (click)="cancelCreation()" [mat-dialog-close]="false" color="primary"  type="button">
                    Cancel
                  </button>
            </div>
            <div fxFlex="2"></div>
            <div fxLayout="column" fxFlex="30">
                <button mat-flat-button (click)="createBase()" [disabled]="isNameExists || formGroup.controls.code.errors || spinner"  color="primary" type="button">
                    Create
                  </button>
            </div>
            
       </div>
    </div>
    <!-- <div fxLayout="column" fxFlex="5"></div> -->
</div>
</mat-card>