import { Component, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlowService } from "../flow.service";
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SmsService } from 'src/app/shared/services/sms-service.service';
import { MatDialog } from '@angular/material/dialog';
import { QoutaPopupComponent } from 'src/app/shared/qouta-popup/qouta-popup.component';
import { formatDate, isPlatformBrowser } from '@angular/common';
import { FlowCollaborationComponent } from '../flow-collaboration/flow-collaboration.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { GCMService } from 'src/app/client-platform/gcm/gcm.service';
import { WorkspaceService } from 'src/app/shared/services/workspace.service';
import { UIConfigService } from 'src/app/shared/services/UIConfigService';

@Component({
  selector: 'app-flow-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class FlowHomeComponent implements OnInit {

  error = false;
  authError: boolean = false;
  spinner: boolean = false
  isBrowser: boolean;
  routerSubscriptionParams: any;
  workspace: any;
  errorMsg: string;

  constructor(
    public router: Router,
    public fs: FlowService,
    public authService: AuthServiceService,
    public resourcePermissionService: ResourcePermissionService,
    // private spinner: SpinnerService,
    private _snackBar: MatSnackBar,
    private smsService: SmsService,
    private dialog: MatDialog,
    private themeService: ThemeService,
    private ngZone: NgZone,
    private connectionService: ConnectionService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: Object,
    private GCMService: GCMService,
    public workspaceService: WorkspaceService,
    public flowService: FlowService,
    public uic: UIConfigService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

  async ngOnInit() {
    if(!this.isBrowser) return;
    var qParams:any = {}
    this.routerSubscriptionParams = this.route.queryParams.subscribe(async (params:any) => {
      console.log("routeData params", params);
      qParams = params || {};
      this.uic.manageProductMenu("set", ["connection", "flow"]);
      this.uic.manageBetaProduct("set", [])
      this.uic.manageMenu("set", ["products", "resource"]);
    })
    if (
      !this.connectionService.selectedWorkSpace &&
      !this.authService.loggedIn
    ) {
      console.log("Not logged in")

      this.authService.navigateParams = { entityType: 'flow-signup' };
      this.router.navigate(['/sign-up'], {queryParams: qParams});

    } else if (this.connectionService.selectedWorkSpace) {
      this.spinner = true
      console.log("workspace already selected")
      this.spinner = false
    } else {
      this.spinner = true
      console.log("authService.loggedIn", this.authService.loggedIn)
      console.log("authService userProfile", this.authService.userProfile)

      // let account = await this.GCMService.getGCMAccount(qParams.accountId);
      // let latestOrder = account?.clientoptions?.orders[account?.clientoptions?.orders?.length - 1];
      // await this.getWorkspace(latestOrder);

      try {
        await this.checkAndGetWorkspace(qParams)
      } catch(e){
        this.errorMsg = "Entitlement is still cancelled after 3 attempts."
        return;
      }


      this.fs.flowCreateConfiguration.creationMode = 'template';
      this.fs.flowCreateConfiguration.templateFilters = this.GCMService.clientMap[qParams.client].templateFilters;
      this.fs.flowCreateConfiguration.appsIncluded = this.GCMService.clientMap[qParams.client].boxes;
      console.log("connectionService.selectedWorkSpace", this.connectionService.selectedWorkSpace)
      // let res = await this.connectionService.getWorkSpaceId()
      // console.log("workspace set", res)

      let flows = await this.flowService.getAllFlows();
      console.log("flows", flows)
      this.spinner = false;
      if(!flows || flows?.length == 0) {
        this.router.navigate(['flow', 'new'], {queryParams: qParams});
      } else {
        this.router.navigate(['flow'], {queryParams: qParams});
      }
    }
  }

  async checkAndGetWorkspace(qParams: any) {
    const MAX_RETRIES = 3;
    const DELAY_MS = 3000;

    for (let attempt = 1; attempt <= MAX_RETRIES; attempt++) {
        const account = await this.GCMService.getGCMAccount(qParams.accountId);
        console.log("account", account);

        let latestOrder = account?.clientoptions?.orders[account?.clientoptions?.orders?.length - 1];
        console.log("latestOrder", latestOrder);

        // Check if the latestOrder exists and is not "ENTITLEMENT_CANCELLED"
        if (latestOrder && latestOrder.state !== "ENTITLEMENT_CANCELLED") {
            await this.getWorkspace(latestOrder);
            return; // Exit the function if we successfully move forward
        }

        // If we're not on the last attempt, wait for 2 seconds before retrying
        if (attempt < MAX_RETRIES) {
            console.log(`Attempt ${attempt} failed. Waiting for ${DELAY_MS / 1000} seconds before retrying...`);
            await new Promise(resolve => setTimeout(resolve, DELAY_MS));
        } else {
            // If all attempts have failed, show an error message
            console.error("Failed to retrieve valid entitlement after 3 attempts. Please try again later.");
            throw new Error("Entitlement is still cancelled after 3 attempts.");
        }
    }
  }


  async getWorkspace(latestOrder){
    let query = {
      filter: `options.clientInstanceId=${latestOrder.orderId}|string`,
      page: '1|1|1'
    };
    let workspaces = await this.workspaceService.getWorkspaceFromQuery(query, this.authService.userProfile.email);
    this.workspace = workspaces[0];
    if(!this.workspace.created_by){
      let workspace = this.workspace;
      workspace.created_by = this.authService.userProfile.email;
      workspace.modified_by = this.authService.userProfile.email;
      workspace.users = [this.authService.userProfile._id];
      this.workspace = await this.workspaceService.updateWorkspace(workspace, this.authService.userProfile.email)
      await this.handleSMSSubscription(latestOrder);
    }
    console.log("this.workspace", this.workspace);
    this.workspaceSelected(this.workspace);
  }

  async workspaceSelected(workspace) {
    this.connectionService.selectedWorkSpace = workspace._id;
    this.connectionService.selectedWorkSpaceMap = workspace;
    this.connectionService.workSpaceId = this.connectionService.selectedWorkSpace;
    this.connectionService.userProfile = this.authService.userProfile;
  }

  async handleSMSSubscription(latestOrder){
    let subscription = {
      license_email: this.authService.userProfile.email,
      payment_gateway_type: "MANUAL"
    };
    let plan = {
      plan_type: latestOrder.plan,
      product: "APPIWORKS",
      frequency: "month"
    }
    this.smsService.handelSubscription(subscription, plan);
  }

  navigateToSignUp(){
    this.router.navigate(['sign-up']);
  }

}
