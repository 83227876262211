import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientPlatformService } from 'src/app/client-platform/client-platform.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';

@Component({
  selector: 'mapping-add-attribute',
  templateUrl: './mapping-add-attribute.dialog.html',
  styleUrls: ['./mapping-add-attribute.dialog.css'],
})
export class MappingAddAttributeDialog {
  isError: boolean = false;
  statusMessage: string;
  canContinueEnabled: boolean = false;
  attrResponse: any;
  availableBoxTypes: string[] = ["string", "number", "date", "datetime", "boolean"];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MappingAddAttributeDialog>,
    private spinnerService: SpinnerService,
    private clientPlatformService: ClientPlatformService
  ) {}


  onChangeOfAttributeName(value: string) {
    this.data.attribute.name = value.replace(' ', '_');
  }

  async createCustomAttribute() {
    this.spinnerService.show();
    this.isError = false;
    this.statusMessage = '';
    
    const payload = { 
      parameters: {
        object: this.data?.options?.object?.__id,
        attributes: [this.data.attribute]
      }
    }

    this.attrResponse = await this.clientPlatformService.executeBoxFn(this.data?.options?.connection, payload, 'createattribute');

    if (this.attrResponse?.error) {
      this.isError = true;
      this.statusMessage = this.attrResponse.error?.error || this.attrResponse.error;
    } else {
      this.statusMessage = 'Attribute created successfully!';
      this.dialogRef.close(this.attrResponse);
    }
    this.spinnerService.hide();
  }

}
