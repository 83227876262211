<div class="commonBody background">
  <br />
  <div fxLayout="row">
    <div fxLayout="column" fxFlex="5"></div>
    <div fxLayout="column" fxFlex="90" fxLayoutAlign="center center">
      <h2><b>Sharing and collaboration</b></h2>
    </div>
    <div fxLayout="column" fxFlex="5"></div>
  </div>

  <mat-tab-group style="max-height: 50vh;">

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon  class="example-tab-icon">admin_panel_settings</mat-icon>
        Bloom Members
      </ng-template>
      <app-common-member  *ngIf="isMemberReady" [restoreInput]="restoreInput" [resourceMap]="dataInput.bloomMap" [resourceType]="'bloom'" [availableRoles]="availableRoles"></app-common-member>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">shield</mat-icon>
        Roles
      </ng-template>
      <app-role-view [resource_type]="'bloom'" [resource_id]="dataInput.bloomMap.code"> </app-role-view>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">token</mat-icon>
        Privileges
      </ng-template>
      <app-privilege-view [resource_type]="'bloom'" [resource_id]="dataInput.bloomMap.code"> </app-privilege-view>
    </mat-tab>
</mat-tab-group>

<br/>

  <!-- <div fxLayout="row" class="mat-h1">
    <h3 style="margin-bottom: 0px"><b>Bloom Members</b></h3>
  </div>
  <div *ngIf="!isShowInvite">
    <app-bloom-member [bloomMap]="dataInput.bloomMap" ></app-bloom-member>
  </div> -->


  <div fxLayout="row" class="mat-h4">
    <h3 style="margin-bottom: 0px"><b>Published bloom access</b></h3>
  </div>

  <div fxLayout="row">
    <mat-radio-group [(ngModel)]="bloomMap.access_type"  (change)="onAccessChange()">
      <mat-radio-button value ="private">  Private (Bloom members only) &nbsp; </mat-radio-button>
      <mat-radio-button value ="public">  Public (Any one)&nbsp; </mat-radio-button>
    </mat-radio-group>
    <mat-checkbox [disabled]="bloomMap.optional_login" *ngIf="bloomMap.access_type == 'public'" (change)="onAccessChange()" [(ngModel)]="bloomMap.require_login"> Requires sign in / sign up </mat-checkbox>
    <mat-checkbox [disabled]="bloomMap.require_login" *ngIf="bloomMap.access_type == 'public'" (change)="onAccessChange()" [(ngModel)]="bloomMap.optional_login"> Optional sign in / sign up </mat-checkbox>
  </div>



  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="bloomMap.access_type == 'public'">
    <div > Default public role &nbsp; </div>
    <div >
      <mat-form-field class="custom_select example-full-width" *ngIf="availableRoles.length > 0">
          <mat-select  [(ngModel)] = 'bloomMap.default_user_role' name="role" >
            <mat-option *ngFor="let role of availableRoles"  [value]="role._id">
               <span>{{role.name}}</span> </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center"
  *ngIf="bloomMap.access_type == 'private' || (bloomMap.access_type == 'public' && (bloomMap.optional_login || bloomMap.require_login))">
    <div > Landing Page &nbsp; </div>
    <div >
      <mat-radio-group [(ngModel)]="bloomMap.landing_page_type"  (change)="onLandingChange()">
        <mat-radio-button value ="default"> Default &nbsp; </mat-radio-button>
        <mat-radio-button value ="page">  Page &nbsp; </mat-radio-button>

      </mat-radio-group>
    </div>
    <div *ngIf="bloomMap.landing_page_type == 'page'">
      <mat-form-field class="custom-padding" *ngIf="pageData.length > 0">
          <mat-select (selectionChange)="onPageSelected()" [(ngModel)] = 'bloomMap.landing_page' name="page" >
            <mat-option *ngFor="let page of pageData"  [value]="page.code">
               <span>{{page.name}}</span> </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
  </div>

  <br/>

  <div fxLayout="row" fxLayoutAlign="stretch" *ngIf="!isShowInvite">
    <button mat-stroked-button color="primary" (click)="isShowInvite = true"  type="button" [disabled]="!resourcePermission.hasAccess(['bloom.management'])">
      Invite Members
    </button>
  </div>

  <div fxLayout="row" class="mat-h4" *ngIf="isShowInvite">
    <h3 style="margin-bottom: 0px"><b>Invite Members</b></h3>
  </div>
  <div *ngIf="isShowInvite">
    <app-resource-collaboration
      (cancel)="isShowInvite = false"
      (emailSent)="isShowInvite = false; resetLoading()"
      [resourceMap]="dataInput.bloomMap"
      [resourceType]="dataInput.resourceType"
      [workspaceMap]="dataInput.workspaceMap"
    >
    </app-resource-collaboration>
  </div>

</div>
