<div class="chip-list-wrapper" >

<mat-form-field class="full-width">
  <input #autoInput
  matInput
  type="text"
  (focus)="getFilteredOptions()"
  [placeholder]="placeholder"
  [matAutocomplete]="auto"
  [formControl]="selectControl">
</mat-form-field>

<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayFn"
  (optionSelected)="toggleSelection($event)">
  <mat-option *ngFor="let data of filteredData | async" [value]="data"
  (click)="$event.stopPropagation()">
    <span>{{data.name ? data.name : data.__id ? data.__id : data}}</span>
  </mat-option>
</mat-autocomplete>
</div>
