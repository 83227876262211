import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { ListPanelModule } from 'src/app/bloom/specialized-panels/list-panel/list-panel.module';
import { FlowInstanceComponent } from './flow-instance.component';
import { FlowProgressComponent } from './flow-progress/flow-progress.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
    declarations: [
        FlowInstanceComponent,
        FlowProgressComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        SpinnerModule,
        ListPanelModule,
    ],
    providers: [],
    exports: [
        FlowInstanceComponent,
        FlowProgressComponent
    ]
})
export class FlowInstanceModule { }
