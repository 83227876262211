import { LocaleService } from './../services/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthServiceService } from '../services/auth-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SidenavService } from '../sidenav-bar/sidenav.service';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { environment } from 'src/environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { ThemeService } from '../services/theme.service';
import { isPlatformBrowser, Location, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { UIConfigService } from '../services/UIConfigService';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent implements OnInit {
  languageList = {
    en: 'English',
    fr: 'Français'
    // ru: 'Pусский',
    // ja: '日本語'
  };
  recheckIfInMenu: boolean = false;
  menuStates = {
    products: false
  };
  showToolbar: boolean;
  isBrowser: any;

  get authService() { return this._authService; }
  @ViewChild('menuProduct' , {static: false}) menuTriggerMenuProducts: MatMenuTrigger;
  @ViewChild('menuIntegration') menuTriggerMenuIntegrations: MatMenuTrigger;
  @ViewChild('menuSolution') menuTriggerMenuSolutions: MatMenuTrigger;
  @ViewChild('menuResource') menuTriggerMenuResources: MatMenuTrigger;
  constructor(
    public _authService: AuthServiceService,
    public router: Router,
    public sidenavService: SidenavService,
    public connectionService: ConnectionService,
    public translate: TranslateService,
    public pageService: PageService,
    private themeService: ThemeService,
    private localeService: LocaleService,
    private location: Location,
    public uic: UIConfigService,
    @Inject(PLATFORM_ID) private platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    }

    window:any;
  isGuestPage: boolean = false;

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.showToolbar = false;
      return;
    }
    this.window = window;
    let routeUrl = window?.location?.pathname || null;
    console.log("window routeUrl", routeUrl)
    if(!routeUrl) routeUrl = this.router.url;
    console.log("routeUrl", this.router.url)
    const containsSubstring = ['/client-platform', '/p/', '/redirect', 'connection/manage', '/oauth/authorize'].some(substring => routeUrl.includes(substring));
    if(!containsSubstring && !this.connectionService.isClientPlatform && !this.pageService.isSubdomainBloom){
      this.showToolbar = true;
    }


    // if(!this.isBrowser) {
    //   const containsSerSubstring = ['/viewer-canvas'].some(substring => routeUrl.includes(substring));
    //   if(containsSerSubstring) this.showToolbar = false;
    // }

    console.log("showToolbar", this.showToolbar)
    this.themeService.closeMenu$.subscribe((e) => {

      this.closePopup(e)
    })

  }

  closePopup(e){
    if(!e || e == "product"){
      this.menuTriggerMenuProducts?.closeMenu()
    } else if (e == "resource"){
      this.menuTriggerMenuResources?.closeMenu()
    }

  }

  isMenuOpen(menu: string): boolean {
    return this.menuStates[menu];
  }

  onMenuHover(e ){
    this.menuStates[e] = true;
    switch(e){
      case 'products':
        this.menuTriggerMenuProducts?.openMenu();
        this.menuTriggerMenuIntegrations?.closeMenu();
        this.menuTriggerMenuSolutions?.closeMenu();
        this.menuTriggerMenuResources?.closeMenu();
      break;
      case 'resource':
        this.menuTriggerMenuResources?.openMenu();
        this.menuTriggerMenuIntegrations?.closeMenu();
        this.menuTriggerMenuSolutions?.closeMenu();
        this.menuTriggerMenuProducts?.closeMenu();
      break;
      case 'integrations':
        this.menuTriggerMenuIntegrations?.openMenu();
        this.menuTriggerMenuProducts?.closeMenu();
      this.menuTriggerMenuSolutions?.closeMenu();
      this.menuTriggerMenuResources?.closeMenu();
      break;
      case 'solutions':
        this.menuTriggerMenuSolutions?.openMenu();
        this.menuTriggerMenuIntegrations?.closeMenu();
        this.menuTriggerMenuProducts?.closeMenu();
        this.menuTriggerMenuResources?.closeMenu();
      break;
    }
  }


  onMenuHoverLeave(e, event?: any){
    // this.menuStates[e] = false;
    setTimeout(() => {
      if (this.recheckIfInMenu === false) {
        switch(e){
          case 'products':
            this.menuTriggerMenuProducts?.closeMenu();
          break;
          case 'resource':
            this.menuTriggerMenuResources?.closeMenu();
          break;
          case 'integrations':
            this.menuTriggerMenuIntegrations?.closeMenu();
          break;
          case 'solutions':
            this.menuTriggerMenuSolutions?.closeMenu();
          break;
        }
      }
    }, 1000);

  }

  toggleLeftSideNav() {
    if (this.sidenavService.mobileQuery.matches) {
      this.sidenavService.toggle('left');
    } else {
      this.sidenavService.setSidenavWithModes();
    }
  }

  // onConnectionClicked() {
  //   this.router.navigate(['connection'], {
  //     queryParams: {
  //       workspaceid: this.connectionService.workSpaceId,
  //       authorization: encodeURIComponent(
  //         this.connectionService.preAuthenticatedToken
  //       ),
  //     },
  //   });
  // }
  // onBloomClicked() {
  //   this.router.navigate(['bloom']);
  // }

  // onFlowClicked() {
  //   this.router.navigate(['flow']);
  // }

  navigateToArticles(){
    window.open(`${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.ARTICLE}/articles`, "_blank")
  }

  navigateToIntegrations(){
    window.open(`${environment.BASE_URL}/p/${environment.INTERNAL_BLOOM.INTEGRATIONS}/all-apps`, "_blank")
  }

  switchLang(lang: string) {
    this.localeService.changeLocale(lang)
  }

  navigateToSignUp(){
    this.router.navigate(['sign-up'], {queryParams: this.authService.navigateParams});
  }
  navigateToLogin(){
    if(!this.authService.loggedIn) this.router.navigate([''], {queryParams: this.authService.navigateParams});
  }
}
