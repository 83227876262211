import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-field-picker',
  templateUrl: './field-picker.component.html',
  styleUrls: ['./field-picker.component.scss']
})
export class FieldPickerComponent implements OnInit {

  @Input() inputFieldMap: any;
  @Output() selectedFields = new EventEmitter<any>();

  iconMap: any = {
    "string": "short_text",
    "number": "pin",
    "date": "event",
    "datetime": "date_range",
    "boolean": "toggle_on",
    "bool": "toggle_on",
    "phone_number": "phone",
    "array": "data_array",
    "object": "data_object",
    "image_url": "link",
    "enumeration": "format_list_numbered"
  };
  isExpanded: boolean = true;

  constructor() {
    
  }

  onExpansionPanelClick(event: Event): void {
    event.stopPropagation(); // Prevent the click event from reaching the document and closing the menu
  }
  ngOnInit(): void {
    console.log("INPUT FIELDS RECEVIED",this.inputFieldMap)
    if(this.inputFieldMap?.list?.length > 1) this.isExpanded = false
  }

  insertField(fieldObj: any){
    console.log('Selected Field Object : ', fieldObj);
    this.selectedFields.emit(fieldObj);
  }

}
