<div fxLayout="row">
  <div fxLayout="column" fxFlex="30" fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxLayoutAlign="center center"> 
        <img class="image" [src]="environment.LOGO_BASE_URL + logo">
      </div>
   </div>
  </div >
  <div fxLayout="column" fxFlex="70" fxLayoutAlign="center center">
        <div class="banner">
          <div class="title">{{ Title }}</div>
          <div class="tagline">{{ Tagline }}</div>
          <ng-content></ng-content>
        </div>
  </div >
</div> 