import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConnectionSuccessComponent } from './connection-success/connection-success.component';
// import { ExecutionHistoryComponent } from './execution-history/execution-history.component';

const routes: Routes = [
  {
    path: 'google/google-forms/v2',
    loadChildren: () => import('../google/google-forms-new/google-forms.module').then(m => m.GoogleFormsModule)
  },
  {
    path: 'hubspot',
    loadChildren: () => import('../hubspot/hubspot.module').then(m => m.HubspotModule)
  },
  {
    path: 'google/hyperlist',
    loadChildren: () => import('../google/hyperlist/hyperlist.module').then(m => m.HyperlistModule)
  },
  // {
  //   path: 'execution-history', component: ExecutionHistoryComponent
  // },
  {
    path: 'zoho/zoho-crm', loadChildren: () => import('../zoho/zoho-crm/extn.module').then(m => m.ExtnModule)
  },
  {
    path: 'connection/success', component: ConnectionSuccessComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ClientPlatformRoutingModule { }
