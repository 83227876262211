
import { NgModule } from '@angular/core';
import { BloomRoutingModule } from './bloom-routing.module';
import { BloomWithoutRoutingModule } from './bloom-without-routing.module';
import { WidgetDialogModule } from '../shared/dialog/widget-dialog/widget.dialog.module';

@NgModule({
    declarations: [],
    imports: [
        BloomRoutingModule,
        BloomWithoutRoutingModule,
        WidgetDialogModule
    ],
    exports: []
})
export class BloomModule { }
