<div
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill
  [class.selectedNow]="selectedWidgetId == widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId == widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onOuterClick()"
  (dblclick)="onDoubleClicked()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <div
    class="innerCover"
    fxLayout fxLayoutAlign="start center"
    (mousedown)="$event.stopPropagation()"
    *ngIf="!textEditMode"
  >
    <mat-label
      (click)="onClick($event)"
      [ngStyle]="styles" for=""
      class="link"
    >
      {{ widgetMeta.config.linkText.value || "" }}
    </mat-label>
  </div>
  <mat-form-field class="textEdit" *ngIf="textEditMode" (mousedown)="$event.stopPropagation()">
    <input matInput #textEditInput (focusout)="onFocusOut($event)" type="text" [(ngModel)]="widgetMeta.config.linkText.value"
      #paraTextArea fxFlexFill cdkTextareaAutosize cdkAutosizeMinRows="2">
  </mat-form-field>
</div>


<div
  *ngIf="!builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill
  (click)="onOuterClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <div
    class="innerCover"
    fxLayout fxLayoutAlign="start center"
  >
    <mat-label
      (click)="onClick($event)"
      [ngStyle]="styles" for=""
      class="link"
    >
      {{ widgetMeta.config.linkText.value || "" }}
    </mat-label>
  </div>
</div>
