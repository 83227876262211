import { Injectable } from '@angular/core';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service'
import { BloomLoggedUserExpression } from '../built-in-expression/expressionClasses/BloomLoggedUser';


interface Field {
    __id: string;
    name?: string;
    dataType?: string;
    value?: any
}
export interface SystemFields {
  fields: string[],
  firstName: Field,
  lastName: Field,
  loggedInEmail: Field,
  currentISOdateTime: Field
}

@Injectable({
  providedIn: 'root'
})
export class SystemFieldsService {
  systemFields: SystemFields = {
    fields: ["firstName","lastName","loggedInEmail", "currentISOdateTime"],
    firstName: {
        __id: "systemField.firstName",
        name: "First Name",
        dataType: "string",
        value: ""
    },
    lastName: {
        __id: "systemField.lastName",
        name: "Last Name",
        dataType: "string",
        value: ""
    },
    loggedInEmail: {
        __id: "systemField.loggedInEmail",
        name: "Logged In Email",
        dataType: "string",
        value: ""
    },
    currentISOdateTime: {
        __id: "systemField.currentISOdateTime",
        name: "Current date Time",
        dataType: "datetime",
        value: ""
    }
  }
  constructor(
    public authService: AuthServiceService,
    public bloomLoggedUserExpression: BloomLoggedUserExpression
     ) {}

   async generateSystemFields(){
    try {
      let config = this.bloomLoggedUserExpression.getExpressionDetail()
      var userProfile = this.bloomLoggedUserExpression.getBloomUser(config);
      // console.log("user profile", JSON.parse(JSON.stringify(userProfile)))
      if (!userProfile || Object.keys(userProfile).length < 1) userProfile = await this.authService.getWorkspaceUser();
      console.log("[API-EXECUTION] [system fields] Logged In User Profile : ",userProfile);

      this.systemFields.firstName.value = userProfile?.first_name;
      this.systemFields.lastName.value = userProfile?.last_name;
      this.systemFields.loggedInEmail.value = userProfile?.email;
    } catch(e){
      console.error("[Error on system fields]", e)
    }
    let curDateTime = new Date().toISOString();
    this.systemFields.currentISOdateTime.value = curDateTime;
    console.log("[API-EXECUTION] system fields generated", this.systemFields)
   }

   async getSystemFields(){
    await this.generateSystemFields()
    return this.systemFields
   }

   async getFieldValue(id:any){
    console.log("id", id)
    id = id.split('.')[1]
    await this.generateSystemFields()
    return this.systemFields?.[id]?.value
   }
}
