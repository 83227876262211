<mat-dialog-actions *ngIf="listPanelService.subPanelMetas.length > 1" fxLayoutAlign="end end">
  <button
        mat-stroked-button color="primary"  (click)="closeSubPanel()">Go Back<mat-icon>arrow_back</mat-icon></button>
</mat-dialog-actions>
<mat-dialog-content *ngIf="listPanelService.subPanelMeta?.isSubPanel" fxLayout="column">
  <div fxFlex="100" fxLayout fxLayoutAlign="center center" fxLayout="row">
    <div fxLayout fxLayoutAlign="center center" >
     <h3> {{listPanelService.subPanelMeta.key}} </h3>
    </div>
    <div fxLayout fxFlex="5" >

    </div>
    <!-- <div>
      <button *ngIf="listPanelService.subPanelMetas.length > 1"
        mat-stroked-button color="primary"  (click)="closeSubPanel()">Go Back<mat-icon>arrow_back</mat-icon></button>
    </div> -->
  </div>
  <br/>
  <div fxLayout="row" fxLayoutAlign="center center">
    <app-data-table
      [panelMeta]="listPanelService.subPanelMeta.panelMeta"
      [processedData]="listPanelService.subPanelMeta.readyTableData"
      [builderMode]="listPanelService.builderMode"
      [viewType]="listPanelService.currentViewType"
      [isInputData]="listPanelService.subPanelMeta.isInputData"
      [isSubPanel]="listPanelService.subPanelMeta?.isSubPanel"
      (subPanel)="createSubListPanel($event)"
    >
    </app-data-table>
  </div>
</mat-dialog-content>
