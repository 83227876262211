
<div class="draft-canvas-wrap container-with-thin-scrollbar">
  <div fxLayout="row" fxLayoutAlign="center center" *ngIf="submitButtonWidget" id="widgetActionConfigContainer">
    <app-widget-action-config style="width: 80vw;" [widgetMeta]="submitButtonWidget" [panelId]="submitButtonPanelId"
      [config]="widgetActionConfig">
    </app-widget-action-config>
  </div>

  <div fxLayout="row">
    <div fxLayout="column" fxFlex="2"></div>
    <div fxLayout="column" fxFlex="96">
        <div fxLayout="row"> <h2>Integrations</h2> </div>
        <div fxLayout="row"> Choose an application to integrate and automate on form submission.</div>
        <div style="height: 1rem;"></div>
        <div>
          <app-box-picker  (selectedBox)="newAppSelected($event)" [config]="appConfig" header="' '"></app-box-picker>
        </div>
    </div>
    <div fxLayout="column" fxFlex="2"></div>
  </div>
</div>


