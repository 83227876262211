<div class="outerCover" *ngIf="builderMode" [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow" (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()" (click)="onClick()"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>
  <div class="innerCover">
    <!-- <mat-form-field>
      <mcc-timer-picker mccConnectedTimerPicker [mccConnectedTimerPickerOrigin]="inputTrigger"></mcc-timer-picker>
      <input matInput autocomplete="off" mccTimerPickerOrigin
        placeholder="{{ widgetMeta.config.placeholder.value }}" #inputTrigger="mccTimerPickerOrigin"
        formControlName="timer" />
      <button mat-icon-button matSuffix>
        <mat-icon>schedule</mat-icon>
      </button>
    </mat-form-field> -->
    <!-- <div class="ngx-timepicker-field-example">
      <ngx-timepicker-field [defaultTime]="'09:00 am'" [disabled]="true"></ngx-timepicker-field>
    </div> -->

    <!-- <mat-form-field [appearance]="widgetMeta.config.appearance?.value || 'outline'">
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input type="time" disabled matInput [name]="widgetMeta.name"/>
    </mat-form-field> -->
    <mat-form-field [appearance]="widgetMeta.config.appearance?.value || 'outline'">
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input matInput type="time" disabled [name]="widgetMeta.name">
  </mat-form-field>
  </div>
</div>


<div class="outerCover" *ngIf="!builderMode" (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
>
  <div class="innerCover">
    <!-- <form (ngSubmit)="userInputDetected(form)" [formGroup]="form">
      <mat-form-field *ngIf="!widgetMeta.config.viewOnly?.value">
        <mcc-timer-picker mccConnectedTimerPicker [mccConnectedTimerPickerOrigin]="inputTrigger"></mcc-timer-picker>
        <input matInput autocomplete="off" placeholder="{{ widgetMeta.config.placeholder.value }}"
          formControlName="timer" />
        <button mat-icon-button matSuffix mccTimerPickerOrigin #inputTrigger="mccTimerPickerOrigin">
          <mat-icon>schedule</mat-icon>
        </button>
      </mat-form-field>
    </form> -->
    <!-- <div class="ngx-timepicker-field-example">
      <ngx-timepicker-field [defaultTime]="'09:00 am'" (timeChanged)="userInputDetected($event)"></ngx-timepicker-field>
    </div> -->

    <form action="#">
      <mat-form-field [appearance]="widgetMeta.config.appearance?.value || 'outline'">
        <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
        <input type="time"
          onfocus="this.showPicker()"
          (ngModelChange)="widgetMeta.config?.timeValue.value = $event"
          [ngModel]="widgetMeta.config?.timeValue?.value"
          (change)="userInputDetected($event)" matInput [name]="widgetMeta.name"
        />
        </mat-form-field>
        <!-- (ngModelChange)="widgetMeta.config?.timeValue.value = $event"  -->
    </form>

    <div *ngIf="widgetMeta.config.viewOnly?.value">{{ widgetMeta.config.timeValue?.value || "" }}</div>
  </div>
</div>
