import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs';
import { FlowService } from 'src/app/flow/flow.service';

@Component({
  selector: 'app-trigger-event',
  templateUrl: './trigger-event.component.html',
  styleUrls: ['./trigger-event.component.scss']
})
export class TriggerEventComponent implements OnInit {

  @Input() editMode:boolean;
  @Input() "hideRibbon": boolean = false;
  @Input() "flowMap": any;
  @Output() "onConfigure" = new EventEmitter<any>();
  @Output() "sourceFields" = new EventEmitter<any>();
  @Output() "appSelected" = new EventEmitter<any>();

  boxEventListingACFrom = new UntypedFormControl();
  boxEventListingOptions: any;
  boxEvent: any;
  selectedEventBox: any;
  eventOptions:any;
  constructor(
    private ngZone: NgZone,
    private fs: FlowService
  ) { }


  async ngOnInit() {
    if(this.flowMap.trigger_type == "app_event"){
      this.fs.boxEvents = await this.fs.getEvents(this.flowMap.trigger.box_id);

      let event = this.fs.boxEvents.find(
        (event) => event.__id == this.flowMap.trigger.event
      );
      this.boxEvent = event;
      this.constructOptionalInputs(event)
      if(event)this.boxEventListingACFrom.setValue(event);
      this.getSourceFields()
    }
  }

  async boxSelected(box: any) {
    let flow = this.flowMap;
    this.selectedEventBox = box;
    flow.trigger.box_id = box.__id;
    flow.trigger.box_name = box.name;
    flow.trigger.box_logo_url = box.logo;
    this.fs.boxEvents = await this.fs.getEvents(box.__id);
    console.log("apps selected", box)
    this.appSelected.emit(box);
    this.fs.userMadeChanges.next(true)
  }

  setFilterOptions(type) {
    switch (type) {
      case "trigger_box_event":
        this.boxEventListingOptions = this.getFilteredOptions(this.boxEventListingACFrom, this.fs.boxEvents, "name")
        break;
    }
  }
  inputRecevied(e){
    if(Object.keys(e).length == 0) return;
    e = JSON.parse(JSON.stringify(e));
    delete e.requiredInputFields;
    this.flowMap.trigger.webhook.optionMap = e;
    console.log("inputRecevied", this.flowMap.trigger.webhook.optionMap);
    this.fs.userMadeChanges.next(true)
  }

  constructOptionalInputs(e){
    if(!e) return;
    let event = JSON.parse(JSON.stringify(e));

    let result:any = { input: {list: []}};
    result.__id = event.__id;
    event?.options?.forEach(element => {
      result.input.list.push(element.__id);
      result.input[element.__id] = element
    });
    this.eventOptions = result;
  }

  displayFuntion(key, subject) {
    if (!subject) return undefined;
    return key ? subject[key] : subject;
  }

  getSourceFields() {
    let flow = this.flowMap;
    console.log("this.flowMap", this.flowMap)
    if(flow.trigger_type == "app_event"){
      let event = this.fs.boxEvents.find(
        (event) => event.__id == flow.trigger?.event
      );
      this.boxEvent = event;
      this.constructOptionalInputs(event)
      console.log("this.boxEvent", this.boxEvent)
      if(this.boxEvent?.options){
        if(!flow.trigger?.webhook) flow.trigger.webhook = {optionMap: {}};
        if(!flow.trigger.webhook?.optionMap) flow.trigger.webhook.optionMap = {};
      }
      let sourceFields:any = JSON.parse(JSON.stringify(event.fields));
      sourceFields?.forEach(element => {
        if(element.__id) element.__id = "system." + element.__id;
      });

      if(!flow.trigger.trigger_options) flow.trigger.trigger_options = {};
      flow.trigger.trigger_options.fields = sourceFields;
      this.sourceFields.emit(sourceFields);
      // this.onConfigure.emit(this.flowMap.trigger.);
    }
  }

  getFilteredOptions(form: UntypedFormControl, values: any, key?: any) {
    return form.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, values, key))
      );
  }

  private _filter(value: string, array?: any, key?: any): string[] {
    value = value ? value : ""
    if (typeof value != "string") return;
    const filterValue = value.toLowerCase();
    if (key) {
      return array.filter(option => option[key].toLowerCase().includes(filterValue));
    } else {
      return array.filter(option => option.toLowerCase().includes(filterValue));
    }
  }



}

