import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BallAtomSpinnerComponent } from './ball-atom-spinner/ball-atom-spinner.component';
import { BallClipRotateSpinnerComponent } from './ball-clip-rotate-spinner/ball-clip-rotate-spinner.component';
import { BallFussionSpinnerComponent } from './ball-fussion-spinner/ball-fussion-spinner.component';
import { BallPulseSpinnerComponent } from './ball-pulse-spinner/ball-pulse-spinner.component';
import { BallTrianglePathSpinnerComponent } from './ball-triangle-path-spinner/ball-triangle-path-spinner.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SquareLoaderSpinnerComponent } from './square-loader-spinner/square-loader-spinner.component';
import { TimerSpinnerComponent } from './timer-spinner/timer-spinner.component';
import { LoadingTextSpinnerComponent } from './loading-text/loading-text.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatGridListModule } from '@angular/material/grid-list';

@NgModule({
  declarations: [BallAtomSpinnerComponent,
    TimerSpinnerComponent,
    BallTrianglePathSpinnerComponent,
    BallPulseSpinnerComponent,
    BallClipRotateSpinnerComponent,
    BallFussionSpinnerComponent,
    SquareLoaderSpinnerComponent,
    SpinnerComponent,
    LoadingTextSpinnerComponent,
    SkeletonComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatGridListModule
  ],
  exports: [
    BallAtomSpinnerComponent,
    TimerSpinnerComponent,
    BallTrianglePathSpinnerComponent,
    BallPulseSpinnerComponent,
    BallClipRotateSpinnerComponent,
    BallFussionSpinnerComponent,
    SquareLoaderSpinnerComponent,
    SpinnerComponent,
    LoadingTextSpinnerComponent,
    SkeletonComponent
  ]
})
export class SpinnerModule { }
