import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormService } from '../form.service';
import { Router } from '@angular/router';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SectionPopupComponent } from '../section-popup/section-popupcomponent';
import { FormContentService } from '../form-content-service';

@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.scss']
})
export class FormSettingsComponent implements OnInit {

  form: any
  selectedPage: any;
  isSuccessPageSelected: boolean;

  constructor(
    private formService: FormService,
    private pageService: PageService,
    public router: Router,
    private dialog: MatDialog,
    private formContentService: FormContentService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.formService.formMeta.subscribe(data => {
      this.form = data;
      if(!this.selectedPage){
        if(!this.form?.sections || this.form.sections?.length == 0){
          this.selectedPage = this.form;
        } else if(this.form.sections?.length > 0){
          this.selectedPage = this.form.sections[0];
        }
      }
      console.log("[form settings] form meta", this.form)
    })
  }

  gotoThankYou(){
    if(this.form.code){
      this.isSuccessPageSelected = true;
      this.router.navigate(["form", "build", this.form.code, 'success'])
      this.formService.pageSelectionRequest.next({code: "__success"});
    }
  }

  async drop(e){

    if(e.currentIndex != e.previousIndex) {
      let previousIndex = e.previousIndex; //call remove index
      let currentIndex = e.currentIndex;
      let sectionToAdd = this.form.sections[e.previousIndex];
      if(['main', 'introduction'].includes(sectionToAdd.code)) return;
      await this.replaceSectionButtons(currentIndex, previousIndex);
    }
  }

  async replaceSectionButtons(index, previousIndex){
    let isMovedBelow = false;
    if(index > previousIndex) isMovedBelow = true;
    // console.log("index", index);
    // console.log("previousIndex", previousIndex);
    // console.log("isMovedBelow", isMovedBelow)

    if(isMovedBelow) {
      previousIndex = previousIndex;
      index = index + 1;
    }
    let section = this.form.sections[previousIndex];
    let prevSection = this.form.sections[index - 1];
    let nextSection = this.form.sections[index];

    // console.log("section", section)
    // console.log("prevSection", prevSection)
    // console.log("nextSection", nextSection)

    //take nextSectionsBackButton
    var movedSectionsBackButton = await this.generateBackButton(prevSection.code);//JSON.parse(JSON.stringify(nextSectionActionPanel.layoutMap[nextSectionColId][nextSectionRowId]['elements'][0]));

    //take prevSectionsNextButton
    let prevSectionActionPanel = prevSection.panels[prevSection.panels.length - 1];
    let prevSectionColId = prevSectionActionPanel.layoutMap.list[0]
    let prevSectionRowId = prevSectionActionPanel.layoutMap[prevSectionColId].list[0];
    var movedSectionsNextButton = JSON.parse(JSON.stringify(prevSectionActionPanel.layoutMap[prevSectionColId][prevSectionRowId]['elements'][1]));

     //take currentPrevSectionsNextButton
    let currentPrevSection = this.form.sections[previousIndex - 1];
    let currentSectionActionPanel = currentPrevSection.panels[currentPrevSection.panels.length - 1];
    let currentSectioncolId = currentSectionActionPanel.layoutMap.list[0]
    let currentSectionrowId = currentSectionActionPanel.layoutMap[currentSectioncolId].list[0];
    var currentSectionsNextButton = JSON.parse(JSON.stringify(currentSectionActionPanel.layoutMap[currentSectioncolId][currentSectionrowId]['elements'][1]));
    var currentSectionsBackButton = await this.generateBackButton(section.code)



     //take currentSectionsBackButton
    // console.log("moved section back button", movedSectionsBackButton)
    // console.log("moved section next button", movedSectionsNextButton)
    // console.log("next section back", currentSectionsBackButton)
    // console.log("pre section next ", currentSectionsNextButton)


    this.removePage(previousIndex);
    this.form.sections.splice(index, 0, section);

    if(isMovedBelow) index = index - 1;
    // console.log("index ", index)
    let curActionPanel = this.form.sections[index].panels[this.form.sections[index].panels.length - 1];
    let colId = curActionPanel.layoutMap.list[0];
    let rowId = curActionPanel.layoutMap[colId].list[0];
    curActionPanel.layoutMap[colId][rowId]['elements'][0] = movedSectionsBackButton;
    curActionPanel.layoutMap[colId][rowId]['elements'][1] = movedSectionsNextButton;

    let prevActionPanel = this.form.sections[index - 1].panels[this.form.sections[index - 1].panels.length - 1];
    colId = prevActionPanel.layoutMap.list[0];
    rowId = prevActionPanel.layoutMap[colId].list[0];
    prevActionPanel.layoutMap[colId][rowId]['elements'][1] = currentSectionsNextButton;

    if(nextSection){
      let nextActionPanel = this.form.sections[index + 1].panels[this.form.sections[index + 1].panels.length - 1];
      colId = nextActionPanel.layoutMap.list[0];
      rowId = nextActionPanel.layoutMap[colId].list[0];
      nextActionPanel.layoutMap[colId][rowId]['elements'][0] = currentSectionsBackButton;
    }
    this.cdr.detectChanges();
    }

  removePage(i){
    let prevIndex = Number(i) - 1;
    let nextIndex = Number(i) + 1;
    let currentActionPanel = this.form.sections[i].panels[this.form.sections[i].panels.length - 1];


    //get current buttons
    let colId = currentActionPanel.layoutMap.list[0]
    let rowId = currentActionPanel.layoutMap[colId].list[0];
    var nextButton = JSON.parse(JSON.stringify(currentActionPanel.layoutMap[colId][rowId]['elements'][1]));
    var backButton = JSON.parse(JSON.stringify(currentActionPanel.layoutMap[colId][rowId]['elements'][0]));

    //add next button into previous action next button
    let prevActionPanel = this.form.sections[prevIndex].panels[this.form.sections[prevIndex].panels.length - 1];
    colId = prevActionPanel.layoutMap.list[0];
    rowId = prevActionPanel.layoutMap[colId].list[0];
    prevActionPanel.layoutMap[colId][rowId]['elements'][1] = nextButton;

    //add selected back button into next action back button
    if(this.form.sections?.[nextIndex]) {
      let nextActionPanel = this.form.sections[nextIndex].panels[this.form.sections[nextIndex].panels.length - 1];
      colId = nextActionPanel.layoutMap.list[0];
      rowId = nextActionPanel.layoutMap[colId].list[0];
      nextActionPanel.layoutMap[colId][rowId]['elements'][0] = backButton;
    }

    this.selectedPage = this.form.sections[prevIndex];
    this.form.sections.splice(i, 1);
    this.setPage(this.selectedPage)
    this.updateForm(this.form);
  }

  async addIntroduction(){

    let defaultPanels = this.formContentService.getDefaultIntroductionPannels(this.form);
    let introductionPage:any = {
      code: "introduction",
      name: "Introduction",
      panels: defaultPanels,
      created_at: `${new Date().toISOString()}|date`,
      'noDrag': true
    }

    let isNewSection = false;
    if(!this.form?.sections || this.form.sections.length == 0) {
      isNewSection = true;
    }

    //creating default introduction page
    // introductionPage = await this.pageService.createPanel('label', introductionPage, null, null, null, "Introduction");
    // introductionPage.panels[introductionPage.panels.length - 1].id = new Date().setSeconds(new Date().getSeconds() + 5).valueOf();
    // introductionPage = await this.pageService.createPanel('separator', introductionPage, null, null, null, null, {addseconds: 5});
    // introductionPage.panels[introductionPage.panels.length - 1].id = new Date().setSeconds(new Date().getSeconds() + 10).valueOf();
    introductionPage = await this.pageService.createPanel('button', introductionPage, null, null, null, "Start", {addseconds: 7});
    introductionPage.panels[introductionPage.panels.length - 1].id = new Date().setSeconds(new Date().getSeconds() + 20).valueOf();
    let submitPanel = introductionPage.panels[introductionPage.panels.length - 1];
    submitPanel.alignment = "center";
    let colId = submitPanel.layoutMap.list[0]
    let rowId = submitPanel.layoutMap[colId].list[0]
    let submitButton = submitPanel.layoutMap[colId][rowId]['elements'][0];
    submitButton.gridX = 4;
    submitButton.config.buttonText.value = "Start";
    submitButton['actionConfig'] = {
      'actions' : []
    };
    let actionConfig = this.createNavAction();

    actionConfig.actionMap.page = isNewSection ? "main" : this.form.sections[0].code;
    submitButton['actionConfig']['actions'].push(actionConfig);
    submitButton['noDelete'] = true;
    submitButton['noDrag'] = true;
    submitButton['noClone'] = true
    submitPanel['noDelete'] = true
    submitPanel['noDrag'] = true;
    submitPanel['noClone'] = true
    submitPanel["noInsert"] = true
    submitPanel["isSubmitPanel"] = true
    console.log("introductionPage", introductionPage);
    // this.form.introductionPage = introductionPage;
    let panels = this.form.panels;


    if(isNewSection) {
      this.form.sections = [];
      this.form.sections[0] = introductionPage;
      this.form.sections[1] = {
        code: "main",
        name: "Main",
        noDrag: true,
        panels : panels
      }

      let mainSection = this.form.sections[1];
      let buttonPanel = mainSection.panels[mainSection.panels.length - 1];
      let tempSection = await this.pageService.createPanel('button', {panels: []}, null, null, null, "Back");
      colId = tempSection.panels[0].layoutMap.list[0];
      rowId = tempSection.panels[0].layoutMap[colId].list[0];

      let backButton = tempSection.panels[0].layoutMap[colId][rowId]['elements'][0];
      backButton.gridX = 3;
      backButton.config.buttonText.value = "Back";
      backButton.config.buttonType.value = "Stroked";
      backButton['actionConfig'] = {
        'actions' : []
      };
      let backActionConfig = this.createNavAction();
      backActionConfig.actionMap.page = "introduction";
      backButton['actionConfig']['actions'].push(backActionConfig);
      backButton['noDelete'] = true;
      backButton['noDrag'] = true;

      colId = buttonPanel.layoutMap.list[0];
      rowId = buttonPanel.layoutMap[colId].list[0];
      buttonPanel['noDelete'] = true;
      buttonPanel["noClone"] = true;
      buttonPanel['noDrag'] = true
      buttonPanel["noInsert"] = true
      buttonPanel.layoutMap[colId][rowId]['elements'].unshift(backButton);
    } else {
      this.form.sections.unshift(introductionPage);

      //set back button
      let secPage = this.form.sections[1];
      let actionPanel = secPage.panels[secPage.panels.length - 1];
      colId = actionPanel.layoutMap.list[0];
      rowId = actionPanel.layoutMap[colId].list[0];
      if(actionPanel.layoutMap[colId][rowId]['elements']?.length == 1){
        let backButton = await this.generateBackButton(introductionPage.code);
        actionPanel.layoutMap[colId][rowId]['elements'].unshift(backButton);
      }
    }

    this.setPage(introductionPage);
    this.updateForm(this.form);
  }

  setPage(page){

    const urlSegments = this.router.url.split('/');
    // Check if the last segment is 'success'
    if (urlSegments[urlSegments.length - 1] === 'success') {
      this.router.navigate(["form", "build", this.form.code], { fragment: 'edit' })
    }
    this.isSuccessPageSelected = false;

    this.formService.pageSelectionRequest.next(page);
    this.selectedPage = page;
    this.formService.selectedPage = page;

  }

  async createNewSection(){
    let num = this.form.sections?.[0]?.code == 'indroduction' ? this.form?.sections?.length : this.form?.sections?.length || 1;
    var nameDiolog = this.dialog.open(SectionPopupComponent, {
      minWidth: "60%",
      data: { name: "Section " + num}
    });
    var nameDiologResult = await nameDiolog.afterClosed().toPromise();
    if(!nameDiologResult) return;

    await this.addSection(nameDiologResult);
  }

  async addSection(secNameMap){
    let isNewSection = false;
    if(!this.form?.sections || this.form.sections.length == 0) {
      this.form.sections = [];
      this.form.sections[0] = {
        code: "main",
        name: "Main",
        noDrag: true,
        panels : this.form.panels
      }
      isNewSection = true;
    }

    let num = this.form.sections[0].code == 'indroduction' ? this.form.sections.length + 1 : this.form.sections.length;
    let sectionPage:any = {
      code: `${secNameMap.name.toLowerCase().replace(/ /g, "_") || "section_"}` + num,
      name:  secNameMap.name || "Section " + num,
      panels: [],
      created_at: `${new Date().toISOString()}|date`,
    }
    sectionPage = await this.pageService.createPanel('label', sectionPage, null, null, null, sectionPage.name);
    sectionPage.panels[sectionPage.panels.length - 1].id = new Date().setSeconds(new Date().getSeconds() + 5).valueOf();
    sectionPage = await this.pageService.createPanel('separator', sectionPage, null, null, null, null, {addseconds: 5});
    sectionPage.panels[sectionPage.panels.length - 1].id = new Date().setSeconds(new Date().getSeconds() + 10).valueOf();
    await this.constructSectionButtons(sectionPage, this.form.sections[this.form.sections.length - 1], isNewSection)
    this.setPage(sectionPage);
    this.updateForm(this.form);
  }


  async constructSectionButtons(sectionPage, prevSection, isNewSection){

    //setting up current section action
    let curSectionButtonPanel = prevSection.panels[prevSection.panels.length - 1];
    let colId = curSectionButtonPanel.layoutMap.list[0]
    let rowId = curSectionButtonPanel.layoutMap[colId].list[0];

    //if prev back button exists
    if(curSectionButtonPanel.layoutMap[colId][rowId]['elements']?.length == 2){
      let curBackButton = curSectionButtonPanel.layoutMap[colId][rowId]['elements'][0];
      var prevBackButton = JSON.parse(JSON.stringify(curSectionButtonPanel.layoutMap[colId][rowId]['elements'][0]));
      curBackButton.actionConfig.actions[0].actionMap.page = prevSection.code;
    } else {
      let curBackButton = await this.generateBackButton(prevSection.code);
      curSectionButtonPanel.layoutMap[colId][rowId]['elements'].unshift(curBackButton);
    }
    sectionPage.panels.push(curSectionButtonPanel);

    //altering previous page actions
    let tempPage = await this.pageService.createPanel('button', {panels: []}, null, null, null, "Introduction");
    tempPage.panels[tempPage.panels.length - 1].id = new Date().setSeconds(new Date().getSeconds() + 20).valueOf();
    let submitPanel = tempPage.panels[tempPage.panels.length - 1]; //action panel
    colId = submitPanel.layoutMap.list[0]
    rowId = submitPanel.layoutMap[colId].list[0]

    //next button
    let nextButton = submitPanel.layoutMap[colId][rowId]['elements'][0];
    nextButton.gridX = 4;
    nextButton.config.buttonText.value = "Next";
    nextButton['actionConfig'] = {
      'actions' : []
    };
    let actionConfig = this.createNavAction();
    actionConfig.actionMap.page = sectionPage.code;
    nextButton['actionConfig']['actions'].push(actionConfig);
    nextButton['noDelete'] = true
    nextButton['noDrag'] = true
    submitPanel['noDelete'] = true
    submitPanel['noDrag'] = true
    submitPanel["noInsert"] = true;
    submitPanel["noClone"] = true;

    //back button
    let backButton
    if(prevBackButton){
      backButton = prevBackButton;
    } else {
      if(this.form.sections[this.form.sections.length - 2]){
        let pageCode = this.form.sections[this.form.sections - 2].code;
        backButton = await this.generateBackButton(pageCode);
      }
    }
    if(backButton) submitPanel.layoutMap[colId][rowId]['elements'].unshift(backButton);
    prevSection.panels[prevSection.panels.length - 1] = submitPanel;

    console.log("new sectionPage", sectionPage);
    console.log("prevSection prevSection", prevSection, this.form);
    this.form.sections.push(sectionPage);
  }

  reverseArray<T>(array: T[]): T[] {
    return array.reverse();
  }

  createNavAction(){
    return {
      "event": "click",
      "action": "navigation",
      "formSuccessAction": true,
      "actionMap": {
        "mapping": [],
        "parameters": [],
        origin: "formpanel",
        page: "",
        "pageType": "form_section"
      }
    }
  }

  async generateBackButton(page){
    let tempSection = await this.pageService.createPanel('button', {panels: []}, null, null, null, "Back");
    let colId = tempSection.panels[0].layoutMap.list[0];
    let rowId = tempSection.panels[0].layoutMap[colId].list[0];
    let backButton = tempSection.panels[0].layoutMap[colId][rowId]['elements'][0];
    backButton.gridX = 3;
    backButton.config.buttonText.value = "Back";
    backButton.config.buttonType.value = "Stroked";
    backButton['actionConfig'] = {
      'actions' : []
    };
    let backActionConfig = this.createNavAction();
    backActionConfig.actionMap.page = page;
    backButton['actionConfig']['actions'].push(backActionConfig);
    backButton['noDelete'] = true;
    backButton['noDrag'] = true;
    backButton['noClone'] = true;
    return backButton;
  }

  updateForm(form){
    console.log("[FORM-UPDATE] form setting", form)
    this.formService.formMeta.next(form || this.form); // Update the form meta with the new widget
    this.formService.userMadeChanges.next(true);
  }

}
