import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { CustomPaginator } from 'src/app/modules/organization/connection/connection-listing/CustomPaginatorConfiguration';

//Search Object
class searchObject {
  box_name?: string;
  box_category?: string;
  box_organization?: string;
  sort_by?: string = 'name=ASC';
  page?: string = '1|10';
  length?: number;
  filter?: string;
}

@Component({
  selector: 'app-listing',
  templateUrl: './app-listing.component.html',
  styleUrls: ['./app-listing.component.scss'], providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }, // Here
  ],
})
export class AppListingComponent implements OnInit, AfterViewInit {

  @ViewChild('appPaginator', { static: false })
  public _appPaginator: MatPaginator;
  isBrowser: boolean;
  get connectionService() { return this._connectionService; }

  //flags
  showSearchMenu: boolean = false;
  private _boxNotFound: boolean = false;
  get boxNotFound() { return this._boxNotFound }
  set boxNotFound(v) { this._boxNotFound = v; }

  //Form Control
  boxSearchInput: UntypedFormControl = new UntypedFormControl('');
  boxCategoryInput: UntypedFormControl = new UntypedFormControl('');
  boxOrganizationInput: UntypedFormControl = new UntypedFormControl('');
  sortSelect: UntypedFormControl = new UntypedFormControl('');

  //Observable
  filteredOptionsOrganization: Observable<any[]>;
  filteredOptionsCategory: Observable<any[]>;

  //local variables
  get header() { return this._header }
  /**set header text */
  @Input() set header(v) { this._header = v }
  private _header: string;

  sortTypes: any = ['name', 'type', 'status'];
  boxCategory = [];
  boxOrganization = [];
  allBoxes = [];
  @Input() config: any;
  //Output
  @Output() selectedBoxChange = new EventEmitter<any>();

  //display method of autoxcomplete
  displayBoxFuntion(subject: any) {
    return subject ? subject.name : undefined;
  }

  //error icon
  showErrorIcon(html_id: string) {
    this.document.getElementById(html_id).setAttribute('style', 'display:none');
    document
      .getElementById(html_id + 'error')
      .setAttribute('style', 'display:inline');
  }

  constructor(private _connectionService: ConnectionService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
     }

  ngOnInit(): void {
    if(!this.isBrowser) return;
  }

  ngAfterViewInit(): void {
    console.log(this._appPaginator.pageIndex + 1);
    console.log(this._appPaginator.pageSize);

    this._connectionService.spinner = true;
    this.boxSearchInput.valueChanges.pipe(startWith(''), //debounceTime(1000), distinctUntilChanged(),
      map((event: any) => event)).subscribe((response) => {
        let sortValue = this.sortSelect.value ? this.sortSelect.value : 'name';
        let searchobj: searchObject = {
          box_name: this.boxSearchInput.value ? `name%${this.boxSearchInput.value}` : null,//
          sort_by: `${sortValue}=ASC`,
          page: `${this._appPaginator.pageIndex + 1}|${this._appPaginator.pageSize}`,
          length: this._appPaginator.pageSize
        };
        this.appFilter(searchobj).then(() => {
          this._connectionService.spinner = false;
        }).catch(() => {
          this._connectionService.spinner = false;
        });
      })

    //Get Box Category Option From Box API
    this.connectionService.getBoxCategory().then((res: any) => {
      this.boxCategory = res?.data || [];
      this.filteredOptionsCategory =
        this.boxCategoryInput.valueChanges.pipe(
          startWith(''),// debounceTime(1000), distinctUntilChanged(),
          map((value) => this._filterCategory(value))
        );
    });

    //Get Box Organization Option From Box API
    this.connectionService.getBoxOrganization().then((res: any) => {
      this.boxOrganization = res?.data || [];
      this.filteredOptionsOrganization =
        this.boxOrganizationInput.valueChanges.pipe(
          startWith(''), //debounceTime(1000), distinctUntilChanged(),
          map((value) => this._filterOrganization(value))
        );
    });;

    //Box Category Input Autocomplete
    this.boxCategoryInput.valueChanges.pipe(//debounceTime(1000), distinctUntilChanged(),
      map((event: any) => event)).subscribe((res) => {
        if (res == "") {
          console.log(this.boxCategoryInput.value)
          let sortValue = this.sortSelect.value ? this.sortSelect.value : 'name';
          let searchobj: searchObject = {
            box_name: `name%${this.boxSearchInput.value}`,
            sort_by: `${sortValue}=ASC`,
            page: `${this._appPaginator.pageIndex + 1}|${this._appPaginator.pageSize}`,
            length: this._appPaginator.pageSize,
            box_organization: `organization%${typeof this.boxOrganizationInput.value == "object" ? this.boxOrganizationInput.value.__id : this.boxOrganizationInput.value || ''}`,
            box_category: `categories%${this.boxCategoryInput.value || ''}`
          };
          this.appFilter(searchobj);
        }
      })

    //Box Organization Input Autocomplete
    this.boxOrganizationInput.valueChanges.pipe(//debounceTime(1000), distinctUntilChanged(),
      map((event: any) => event)).subscribe((res) => {
        if (res == "") {
          console.log(this.boxCategoryInput.value)
          let sortValue = this.sortSelect.value ? this.sortSelect.value : 'name';
          let searchobj: searchObject = {
            box_name: `name%${this.boxSearchInput.value}`,
            sort_by: `${sortValue}=ASC`,
            page: `${this._appPaginator.pageIndex + 1}|${this._appPaginator.pageSize}`,
            length: this._appPaginator.pageSize,
            box_organization: `organization%${this.boxOrganizationInput.value || ''}`,
            box_category: `categories%${typeof this.boxCategoryInput.value == "object" ? this.boxCategoryInput.value.__id : this.boxCategoryInput.value || ''}`
          };
          this.appFilter(searchobj);
        }
      })
  }

  //Invoke Box API
  async appFilter(searchObj?: searchObject) {
    let sortValue = this.sortSelect.value ? this.sortSelect.value : 'name';
    let searchobj: searchObject = searchObj ? searchObj : {
      box_name: `name%${this.boxSearchInput.value}`,
      sort_by: `${sortValue}=ASC`,
      page: searchObj ? `${this._appPaginator.pageIndex + 1}|${this._appPaginator.pageSize}` : `1|${this._appPaginator.pageSize}`,
      length: this._appPaginator.pageSize,
      box_organization: `organization=${this.boxOrganizationInput.value.__id || ''}`,
      box_category: `categories=${this.boxCategoryInput.value.__id || ''}`
    };
    if(this.config?.filter) searchobj.filter = this.config?.filter;
    if(this.config?.sort) searchobj.sort_by = this.config?.sort;
    await this.connectionService.getBoxes(searchobj).then((res: any) => {
      if (res?.data != null) {
        this.boxNotFound = false;
        this.allBoxes = res.data
        if(!searchObj) this._appPaginator.firstPage();
      } else {
        this.allBoxes = [];
        this.boxNotFound = true
      }
    });
  }

  //Box Pagination
  pageChanged($event) {
    console.log($event)
    let sortValue = this.sortSelect.value ? this.sortSelect.value : 'name';
    let searchobj: searchObject = {
      box_name: `name%${this.boxSearchInput.value}`,
      sort_by: `${sortValue}=ASC`,
      page: `${this._appPaginator.pageIndex + 1}|${this._appPaginator.pageSize}`,
      length: this._appPaginator.pageSize,
      box_organization: `organization=${this.boxOrganizationInput.value.__id || ''}`,
      box_category: `categories=${this.boxCategoryInput.value.__id || ''}`
    };
    this.appFilter(searchobj);
  }

  //filter method for autocomplete
  private _filterOrganization(value: any): any[] {
    let filterValue;
    if (typeof value == 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.name.toLowerCase();
    }
    return this.boxOrganization.filter((option: any) =>
      option?.name?.toLowerCase().includes(filterValue) || ''
    );
  }

  //filter method for autocomplete
  private _filterCategory(value: any): any[] {
    let filterValue;
    if (typeof value == 'string') {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.name.toLowerCase();
    }
    return this.boxCategory.filter((option: any) =>
      option?.name?.toLowerCase().includes(filterValue) || ''
    );
  }

}
