import { SharedModule } from '../app-shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AppFilterConfigComponent } from './app-filter-config.component';
import { ObjectParameterInputsModule } from '../input-parameters/object-parameter-inputs/object-parameter-inputs.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

@NgModule({
  declarations: [AppFilterConfigComponent],
  imports: [
    CommonModule,
    SharedModule,
    ObjectParameterInputsModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AppFilterConfigComponent
  ]
})
export class AppFilterConfigModule { }
