<div fxLayout="column" fxLayoutAlign="start start">
    <!-- this div is for create custom object--> 
    <div *ngIf="type == 'customObject'">
        <h1>Add Object</h1>
        <object-parameter-inputs [object]="dialogData.createObjectFunction" (inputParameter)="inputRecevied($event)"></object-parameter-inputs>
    </div>
    
    <!-- this div is for create custom attribute -->
    <div *ngIf="type == 'customAttribute'">
        <h1>Add Attributes</h1>
        <attribute-parameter-inputs [attribute]="dialogData.createAttributeFunction.input.attributes" (inputParameter)="attributesReceived($event)"></attribute-parameter-inputs>
    </div>
    
    <div fxLayout="row">
        <div fxLayoutAlign="center center" style="margin: 5px;">
            <button fxLayoutAlign="center center" mat-raised-button color="primary" style="margin-top: 2rem;" (click)="execute()">
                Execute
            </button>
            <button fxLayoutAlign="center center" mat-raised-button color="warn" style="margin-top: 2rem; margin-left: 1rem;" (click)="close()">
                Cancel
            </button>
        </div>
    </div>
    <mat-spinner *ngIf="spinner" diameter="50" class="spinner" color="primary"></mat-spinner>
    <div *ngIf="executionResponse && executionResponse.error;" style="margin-top: 1rem;">
        <h2>Result</h2>
        {{executionResponse | json}}
    </div>
</div>

