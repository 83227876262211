import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { BoxService } from '../../services/box-service.service';

@Component({
  selector: 'chart-dynamic-attribute-config',
  templateUrl: './chart-dynamic-attribute-config.component.html',
  styleUrls: ['./chart-dynamic-attribute-config.component.scss']
})
export class ChartDynamicAttributeConfig implements OnInit {

  @Input() widgetMeta: any
  @Input() attributes: any
  @Output() newWidgetMeta: any = new EventEmitter<any>()

  config: any
  filters: any

  filteredNameAttributes: any;
  filteredValueAttributes: any;
  nameAttributeControl = new UntypedFormControl();
  valueAttributeControl = new UntypedFormControl();

  boxName: string;
  boxId: string;
  connectionId: string;
  boxObjectId: string;
  boxConfigToken: string;

  attributeOptions: any[] = []
  getFnOptions: any[] = []

  nameAttribute: any;
  namePath: any;
  valueAttribute: any
  valuePath: any

  getAttrFn: any;
  // attributes: any = []
  isAttributesReady: boolean = false

  lockOperation: boolean = false
  advancedModeOpenName: boolean = false
  advancedModeOpenValue: boolean = false

  selectedMetricOperation = 'no_op'
  // dataSourceType: string = 'dynamic'    // static | dynamic
  staticData: any[] = []
  editModeIndex: number = -1

  formatMap: any = {
    date: {
      options: [
        {
          name: "Date",
          value: 'dd-mm-yy'
        },
        {
          name: "Day",
          value: 'dd'
        },
        {
          name: "Month",
          value: 'mm'
        },
        {
          name: "Year",
          value: 'yy'
        },
      ]
    }
  }


  OPERATIONS = [
    {value: 'no_op', viewValue: '-- Direct mapping --'},
    {value: 'sum', viewValue: 'Sum'},
    {value: 'average', viewValue: 'Average'},
    {value: 'count', viewValue: 'Count'},
    {value: 'min', viewValue: 'Minimum'},
    {value: 'max', viewValue: 'Maximum'},
    {value: 'count_distinct', viewValue: 'Count Distinct'},
  ];

  constructor(
    private boxService: BoxService,
  ) {
  }

  ngOnInit(): void {

    // console.log("[CHART DATA CONFIG] onInit(): widgetMeta", this.widgetMeta)

    this.filteredNameAttributes = this.nameAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._attributeFilter(value))
    );

    this.filteredValueAttributes = this.valueAttributeControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.__id)),
      map(value => this._attributeFilter(value))
    );

    if (!this.widgetMeta.config.dataSource?.chartOrder){
      this.widgetMeta.config.dataSource.chartOrder = {
        field: 'none',
        order: "ASC"
      }
    }

    this.config = JSON.parse(JSON.stringify(this.widgetMeta.config.dataSource))
    // console.log("config received", this.config)
    this.filters = this.config.filter || {}
    // this.dataSourceType = this.config.dataSourceType || 'dynamic'
    if(!this.config.dataSourceType) this.config.dataSourceType = 'dynamic'

    // && this.config.connectionId
    if(this.config && this.config.boxId && this.config.boxObjectId){
      this.valueAttribute = this.config.metric.attribute
      this.valueAttributeControl.patchValue(this.valueAttribute)
      this.nameAttribute = this.config.dimensions?.[0]?.attribute
      this.nameAttributeControl.patchValue(this.nameAttribute)
      this.namePath = this.config.dimensions?.[0]?.drillDownPath
      this.boxConfigToken = this.config.boxConfigToken
      // this.valuePath = this.config.valuePath
      this.selectedMetricOperation = this.config.metric.operation
    }

    if(this.attributes.length){
      this.isAttributesReady = true
      if(this.config.metric && !this.config.metric?.attribute?.__id){
        if(!this.valueAttribute) this.valueAttribute = this.intelliSenseAttribute(this.attributes, 'metric')
        if(this.valueAttribute) this.valueAttributeControl.patchValue(this.valueAttribute)
      }
      if(this.config.dimensions?.length){
        if(!this.nameAttribute) this.nameAttribute = this.intelliSenseAttribute(this.attributes, 'dimension')
        if(this.nameAttribute) this.nameAttributeControl.patchValue(this.nameAttribute)
      }
    }

    console.log("config", this.config)
  }

  displayFn(attr) {
    let val =  (attr && attr.__id) ? attr.__id : ''
    return val
  }

  private _attributeFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.attributes.filter(option => option.__id.toLowerCase().includes(filterValue));
  }

  formatSelection(event){
    // console.log("formatSelection selected", this.nameAttribute)
    // console.log("event selected", event)
    this.nameAttribute.format = event.value;
    this.saveChanges()
  }

  nameAttributeSelected(event: any){
    console.log("dimension selected", event)
    this.nameAttribute = event.option.value
    this.saveChanges()
  }

  valueAttributeSelected(event: any){
    this.valueAttribute = event.option.value
    this.saveChanges()
  }

  metricOperationSelected(event){
    // console.log("value attribute", this.valueAttribute)
    this.config.metric.operation = this.selectedMetricOperation
    this.saveChanges()
  }

  filterSelected(data){
    this.config['filter'] = data
    console.log("filter received", data);
    this.saveChanges()
  }

  sortSelected(data){
    console.log("sort received", data)
    this.config['sort'] = data
  }

  getUniqueValue(){
    let valuePoint = this.staticData.length
    let value = (this.config.dimensions?.[0]?.attribute?.__id || 'item') + ' ' + valuePoint
    while(this.staticData.findIndex(data => data.value == value) > -1){
      value = this.config.dimensions?.[0]?.attribute?.__id || 'item' + " " + ++valuePoint
    }
    return value
  }

  /**
   * type: string, 'dimension' | 'metric'
   * @returns attribute if matched, null otherwise
   */
  intelliSenseAttribute(attributes: any[], type: string){
    let dimRegex = /category|status|classification|state|country|city|region|type|stage/i
    let metricRegex = /amount|count|total|rate|price|cost/i
    let regex: any

    if(type == 'dimension') regex = dimRegex
    else if(type == 'metric') regex = metricRegex
    else return

    for(let i = 0; i < attributes.length; i++){
      if(attributes[i]['__id'] == '_id') {
        i++;
        continue;
      }

      if(regex.test(attributes[i].__id)){
        return attributes[i]
      }
    }
    this.saveChanges()
    return null
  }


  saveChanges(){
    this.config['dimensions'] = [
      {
        attribute: this.nameAttribute,
        drillDownPath: this.namePath
      }
    ]
    this.config['metric'] = {
      attribute: this.valueAttribute,
      operation: this.selectedMetricOperation
    }
    console.log("emitting data source", this.config)
    this.widgetMeta.config.dataSource = this.config
    this.newWidgetMeta.emit(this.widgetMeta)
  }

}
