<div
  *ngIf="builderMode"
  class="outerCover"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [class.selectedNow]="selectedWidgetId === widgetMeta.id"
  [class.hoveredNow]="selectedWidgetId === widgetMeta.id? false: hoveredNow"
  (mouseenter)="widgetMouseenter()"
  (mouseleave)="widgetMouseleave()"
  (click)="onClick()"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  fxLayout fxLayoutAlign="center center"
>
  <widget-drag-handle [class.invisible]="(selectedWidgetId !== widgetMeta.id && !hoveredNow)"></widget-drag-handle>

  <div class="innerCover">
    <mat-form-field fxFlexFill [appearance]="this.widgetMeta.config?.appearance?.value ? this.widgetMeta.config.appearance.value : 'standard'">
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input matInput type="datetime-local" [ngModel]="displayValue" [placeholder]="widgetMeta.config.placeholder.value" [required]="widgetMeta?.required ? widgetMeta.required : false" (input)="newInput($event)">
    </mat-form-field>
    <!-- [value]="value" -->
  </div>
</div>


<div
  *ngIf="!builderMode && !widgetMeta.config?.hidden?.value"
  class="outerCover"
  (click)="onClick()"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  [matTooltip]="widgetMeta.config.tooltip && widgetMeta.config.tooltip.enabled ? widgetMeta.config.tooltip.value : ''"
  [matTooltipDisabled]="!widgetMeta.config.tooltip || !widgetMeta.config.tooltip.enabled"
  fxLayout fxLayoutAlign="center center"
>
  <div class="innerCover" *ngIf="!widgetMeta.config.viewOnly || !widgetMeta.config.viewOnly.value">
    <mat-form-field [fxFlex]="100" [appearance]="this.widgetMeta.config?.appearance?.value ? this.widgetMeta.config.appearance.value : 'standard'">
      <mat-label>{{ widgetMeta.config.placeholder.value }}</mat-label>
      <input 
        matInput
        onfocus="this.showPicker()" 
        type="datetime-local" 
        [ngModel]="displayValue"
        [placeholder]="widgetMeta.config.placeholder.value"
        [required]="widgetMeta?.required ? widgetMeta.required : false" 
        (input)="newInput($event)" 
        >
        <!-- [value]="value" -->
    </mat-form-field>
  </div>
  <div *ngIf="widgetMeta.config.viewOnly && widgetMeta.config.viewOnly.value && widgetMeta.config.dateTimeValue.value"
    class="innerCover label-z-index content"
    [ngStyle]="styles"
  >
  <!-- {{ widgetMeta.config.placeholder.value }} {{ widgetMeta.config.placeholder.value ? ':&nbsp;' : ''}} {{displayValue || initValue() }} -->
    {{displayValue || initValue() }}
  </div>
</div>
