<mat-dialog-content>
<div fxLayout="row" fxLayoutAlign="center center" class="heading">
  <h3>Delete {{ type == 'object'? 'Object' : 'Base' }}</h3>
</div>

<div style="height: 1rem;"></div>

<div>
  <span>Are you sure you want to delete {{ type == 'object'? 'Object' : 'Base' }} '{{ data?.name }}'?</span>
</div>

<div style="height: 1rem;"></div>

<div class="warning">This action is not reversible.</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close (click)="dontDelete()">Cancel</button>
  <button mat-stroked-button color="warn" (click)="delete()">Delete</button>
</mat-dialog-actions>
