import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateTimePickerComponent } from './datetimepicker.component';
import { MccTimerPickerModule } from 'material-community-components/timer-picker';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MccTimerPickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    DateTimePickerComponent,
  ],
  exports: [
    DateTimePickerComponent
  ],
  providers: [DatePipe]
})
export class DateTimePickerModule { }
