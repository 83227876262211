import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  private hideScrollBarSubject = new Subject<boolean>();
  hideScrollBar$ = this.hideScrollBarSubject.asObservable();

  hideScrollBar(hide: boolean) {
    this.hideScrollBarSubject.next(hide);
  }
}
