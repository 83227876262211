<h2 mat-dialog-title>Select your attributes to create Form</h2>
<!--
<mat-dialog-content class="mat-typography"> -->
  <div>
    <div></div>
    <div fxLayout="column">
      <div class="attributes-table">
        <div class="example-list">

          <div class="table-header" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="th" fxFlex="5">#</span>
            <span class="th" fxFlex="5"></span>
            <span class="th" fxFlex="40">Attribute</span>
            <span class="th" fxFlex="40">Widget</span>
            <span class="th" fxFlex="10"></span>
          </div>

          <div
            class="example-box smallText"
            style="cursor: default;"
            *ngFor="let attr of attributes;let i = index"
            fxLayout="row wrap" fxLayoutAlign="space-between center"
          >

            <!-- SERIAL NUMBER -->
            <div class="serial-number td" fxFlex="5">{{ i + 1 }}</div>

            <!-- FIELD TYPE ICON -->
            <div class="type-icon td" fxFlex="5" style="opacity: 60%;"><mat-icon>label_important</mat-icon></div>

            <!-- ATTRIBUTE NAME -->
            <div class="attribute-id td" fxFlex="40" fxLayout fxLayoutAlign="start center">{{attr.name}}</div>

            <!-- WIDGET TYPE -->
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="40">
              <button
                *ngIf="attr.fieldType === 'attribute'"
                matTooltip="Choose widget type"
                mat-button
                fxFlexFill
                class="widgetSelectionButton"
                [matMenuTriggerFor]="menu"
                aria-label="Widget selection menu"
              >
                <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                  <mat-icon *ngIf="widgetMap[attr.widgetType]">{{ widgetMap[attr.widgetType].icon || '' }}</mat-icon>
                  <span *ngIf="attr.fieldType === 'attribute'" class="selectedWidget">{{ widgetMap[attr.widgetType].name }}</span>
                  <mat-icon>arrow_drop_down</mat-icon>
                </div>
              </button>

              <mat-menu #menu="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
                <div *ngFor="let widType of widgetMap.list;">
                  <button mat-menu-item (click)="widgetTypeSelected(i, widType)">
                    <mat-icon>{{widgetMap[widType].icon}}</mat-icon>
                    <span>{{widgetMap[widType].name}}</span>
                  </button>
                </div>
              </mat-menu>
            </div>

            <!-- DELETE ICON -->
            <div class="remove td" fxFlex="10">
              <button mat-icon-button color="warn" (click)="removeListAttribute($event, i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            </div>
          </div>
        </div>

        <div class="fieldTypeWrap" style="margin-top: .5rem;" fxLayout fxLayoutAlign="start center" fxLayoutGap=".5rem">

          <button mat-stroked-button matTooltip="Choose fields" color="primary" [matMenuTriggerFor]="menu"
           (click)="selectAttributeMenuOpened()">
              <mat-icon class="iconInButton">checklist</mat-icon>
              <span class="addFieldText">Choose Attributes</span>
          </button>
          <mat-menu #menu="matMenu" >
            <app-attribute-selection
              [boxObjectAttributes]="boxObjectAttributes"
              [listAttributes]="attributes"
              [supportApplyButton]="true"
              (listAttributeSelected)="attributeSelectionChanged($event)"
            ></app-attribute-selection>
          </mat-menu>
        </div>
    </div>
    <div></div>
  </div>
<!-- </mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button color="primary" (click)="onSave()" cdkFocusInitial>Save</button>
</mat-dialog-actions> -->

