import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { ChartService } from '../../services/chart.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

declare var google: any;

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit, OnChanges {

  @Input() records: any[];
  @Input() widgetMeta: any;
  @Input() chartDimensions: any
  @Input() builderMode: any

  chartData: any

  skimmedData: any = []
  isBrowser: boolean;

  constructor(
    private chartService: ChartService,
    private widgetService: WidgetService,
    @Inject(PLATFORM_ID) platformId?: Object,
    @Inject(DOCUMENT) private document?: Document,
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    console.log("LINE CHART COMPONENT")
    google.charts.load('current', { packages: ['corechart'] });
    this.chartService.builderMode = this.builderMode
    this.buildChart(true);

    this.widgetService.$widgetDragStarted.subscribe(data => {
      console.log("widget move start alert", data)
      this.chartService.setChartDataCache(this.widgetMeta.id, this.skimmedData)
      // if(data.id == this.widgetMeta.id){
      // }
    })
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.chartDimensions?.currentValue && !changes.chartDimensions?.firstChange){
      console.log("DIMENSIONS RECEIVED", changes.chartDimensions?.currentValue)
      this.buildChart()
    }
    if(changes.widgetMeta?.currentValue && !changes.widgetMeta?.firstChange){
      console.log("widget meta change", changes.widgetMeta.currentValue)
      this.buildChart()
    }
    if(changes.records?.currentValue && !changes.records?.firstChange){
      console.log("records change", changes.records.currentValue)
      this.buildChart(true)
    }
  }

  ngDoCheck(){}

  async buildChart(rebuild?: boolean) {
    var func = async (chart: any) => {
      this.chartData = new google.visualization.DataTable();
      let config = this.widgetMeta?.config?.dataSource
      this.chartData.addColumn('string', config?.dimensions?.[0]?.attribute?.name || "Item");
      this.chartData.addColumn('number', config?.metric?.attribute?.name || "Amount");

      this.chartService.setPrecision(this.widgetMeta.config.precision?.value)

      if(rebuild){
        if (this.records?.[0]?.["__USECACHED__"]) {
          this.skimmedData = this.chartService.getChartDataCache(this.widgetMeta.id)
          this.chartService.clearChartCacheForWidget(this.widgetMeta.id)
          return
        } else {
          console.log("records", this.records)
          let pageData: any
          let isGrouped: boolean = this.records[0]?.__GROUPED__ || false
          if(this.records[0]?.__START__){
            this.clearCache()
            pageData = this.records.shift()
          }
          let rawRecords: any[] = []
          this.records.forEach(item => {
            if(item !== '__END__') rawRecords.push(item)
          })
          let res = this.chartService.processLineData(config, rawRecords, isGrouped)
          this.skimmedData = this.chartService.merge(this.skimmedData, res, config, pageData?.pageNumber || 1, pageData?.pageSize || res.length, rawRecords.length)
        }
      }
      console.log("skimmed data for chart", JSON.parse(JSON.stringify(this.skimmedData)))
      this.chartData.addRows(this.skimmedData)

      let yAxisTitle = ''
      console.log("widgetMeta", this.widgetMeta)
      if (this.widgetMeta.config.yAxisTitle?.value) {
        yAxisTitle = this.widgetMeta.config.yAxisTitle?.value
      } else{
        if (this.widgetMeta.config.dataSource.metric.operation == 'no_op'){
          yAxisTitle = this.widgetMeta.config.dataSource.metric?.attribute?.name
        } else{
          yAxisTitle = this.widgetMeta.config.dataSource.metric.operation + " of " + this.widgetMeta.config.dataSource.metric?.attribute?.name
        }
      }

      var options = {
        title: this.widgetMeta.config.title?.value || `${this.widgetMeta.config.dataSource.dimensions?.[0]?.attribute?.name}`,
        titleTextStyle: {
          color: this.widgetMeta.textFormat?.color?.value || '#444',    // any HTML string color ('red', '#cc00cc')
          fontName: this.widgetMeta.textFormat?.fontFamily?.value || 'Arial', // i.e. 'Times New Roman'
          fontSize: this.widgetMeta.textFormat?.fontSize?.value || 18, // 12, 18 whatever you want (don't specify px)
          bold: this.widgetMeta.textFormat?.bold?.value || false,    // true or false
          italic: this.widgetMeta.textFormat?.italic?.value || false  // true of false
        },
        width: this.chartDimensions.width || 250,
        height: this.chartDimensions.height || 150,
        backgroundColor: this.widgetMeta.config.backgroundColor?.value || '#FFFFFF',
        chartArea: { 'bottom': '20%', 'top': '20%', 'left': '20%', 'right': '10%', 'width': '100%', 'height': '100%'},
        legend: {'position': 'none'},
        vAxis: {
          title: yAxisTitle,
          textStyle: {fontSize: 12}
        }
      };
      chart().draw(this.chartData, options);
    }

    var chart = () => new google.visualization.LineChart(this.document.getElementById(this.widgetMeta.id));

    var callback = () => func(chart);

    google.charts.setOnLoadCallback(callback);
  }

  clearCache(){
    this.skimmedData = []
  }
  isDelimitor(){
    console.log("last item", this.records[this.records.length - 1])
    if(this.records[this.records.length - 1] == '__END__') return true
    else return false
  }

}
