import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { WidgetManager } from '../../../WidgetManager';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { SystemFieldsService } from 'src/app/shared/services/system-fields.service';
import { FlowService } from 'src/app/flow/flow.service';

@Component({
  selector: 'app-action-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.scss']
})
export class FlowActionComponent implements OnInit {
  flowList: any = [];
  panelFields: any[];
  parameterOptions: { list: any[]; };
  sourceFields: any[];
  spinner: boolean;

  constructor(
    public metaService: MetaService,
    public widgetService: WidgetService,
    public systemFieldsService: SystemFieldsService,
    public flowService: FlowService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {

   }

   widgetManager: any = WidgetManager;
   whitelabeledFields: any = [];

  @Input() "actionConfig": any;
  @Input() "widget": any;
  @Input() "hideUseInput": boolean;

  config: any;
  selectedActionMap:any

  async ngOnInit() {
    console.log("init actionConfig", this.actionConfig)
    this.getPanelsList()
    this.getFlows();
    let te = new TemplateEngine();
    this.metaService.pageMeta.subscribe(pageMeta => {
      this.sourceFields = [];
      // this.pageMeta = pageMeta

      pageMeta.panels.forEach(panelMeta => {
        let panel = panelMeta;
        let widgets = this.widgetService.getWidgetsFromPanel(panel);
        widgets.forEach((widget ) => {

          if(WidgetManager.getWidget(widget.type)?.getMappingConfig(this.widget)){
            let config = WidgetManager.getWidget(widget.type)?.getMappingConfig();
            let data = {
              panel: panel,
              widget: widget
            }
            this.sourceFields.push(te.fillTemplateObject(config, data))
          }
        })
      })


    })
  }


  getPanelsList(){
    let te = new TemplateEngine();
    this.metaService.pageMeta.subscribe(pageMeta => {
      this.panelFields = [];
      // this.pageMeta = pageMeta

      console.log("pageMeta", pageMeta)
      pageMeta.panels.forEach(panelMeta => {
        let panel = panelMeta;
        if(panel.type == "listpanel"){
          this.panelFields.push({id: panel.id, name: panel.name});
        }
      })


    })

  }

  async getFlows(){
  //  console.log("get flows hit")
  this.spinner = true;
   let lists = await this.flowService.getAllFlows(1, 100);
   console.log("flows fetched", JSON.parse(JSON.stringify(lists)))
    this.flowList = [];
    // lists = lists.filter(flow => (!flow.code && !flow.published_flow_id) || (flow.code && flow.is_published))  // list old flows or new flows that has associated published flow
    for (let index = 0; index < lists.length; index++) {
      const element = lists[index];
      if(element.is_published && element.isActive && element.trigger_type == "webhook")
        this.flowList.push({_id: element._id, name: element.name, trigger: element.trigger});
    }
    console.log("flowList", this.flowList)
    this.spinner = false;
  }

  useOutputCheckedEvent(e, actionMap){
    actionMap.useOutput = e.checked
  }

  selectedFlow(event){
    this.parameterOptions = {list: []};
    this.actionConfig.actionMap.flow = event;
    console.log("selectedFlow", this.actionConfig.actionMap.flow);

    const extractParameters = (dataArray) => {
      if (dataArray?.length > 0) {
        dataArray.forEach(element => {
          element.__id = element.__id.replace("system.", "");
          this.parameterOptions.list.push(element.__id);
          this.parameterOptions[element.__id] = element;
        });
      }
    };


    this.ngZone.run(() => {
      extractParameters(this.actionConfig.actionMap.flow?.trigger?.query_data);
      extractParameters(this.actionConfig.actionMap.flow?.trigger?.payload_data);
      this.cdr.detectChanges();
      console.log("this.parameterOptions", this.parameterOptions);
    });
  }

  selectedPanel(event){
    console.log("selectedPanel", event);
    this.actionConfig.actionMap.panel = event;
  }

  inputOptionsRecevied(e){

    this.actionConfig.actionMap.inputParams = e;
    console.log("this.actionConfig.actionMap.inputParams", this.actionConfig.actionMap.inputParams)
  }


}
