<mat-dialog-content>
  <div *ngIf="pageCreationSpinner" class="spinner" fxLayoutAlign="center center">
    <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
  </div>
<div class="heading" style="    width: auto;">{{data?.isCloning? 'Clone Page' : 'New Page'}}</div>

<div fxLayout="column" fxLayoutAlign="center start" >
  <mat-form-field class="creatingPageName" style="width: 100%">
    <mat-label>Page name</mat-label>
    <input matInput type="text" id="myInput" [(ngModel)]="newName" (keydown)="create($event)">
  </mat-form-field>
  <div *ngIf="isError" style="color: brown;">
    {{pageCreateError}}
  </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close (click)="cancel()">Cancel</button>
  <button mat-flat-button color="primary" [disabled]="!newName.length || newName.trim() === ''" (click)="create()">{{data?.isCloning? 'Clone' : 'Create'}}</button>
</mat-dialog-actions>
