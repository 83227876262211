export const BarChartConfig: any = {
  dataSource: { // to be directly appended with the main dataSource in chart.ts
    dimensions: [
      {
        attribute: {},
        drillDownPath: '',
      }
    ],
    sort: {
      sortEnabled: false,
      sortAttributes: []
    },
  },
  otherProps: {
    props: ['title', 'yAxisTitle'],
    title: {
      displayName: "Bar chart title",
      type: "text",
      value: "",
    },
    yAxisTitle: {
      displayName: "Vertical axis title",
      type: "text",
      value: "",
    },
    precision: {
      displayName: "Decimal point precision",
      type: "text",
      value: 2,
    }
  }
}
