import { Component, OnInit, Input, Output, EventEmitter, OnChanges, NgZone, SimpleChanges, Inject, PLATFORM_ID, ViewChild, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// import { AddConnectionDialogComponent } from '../add-connection-dialog/add-connection-dialog.component'
import { WidgetConfigurationComponent } from '../widget-configuration/widget-configuration.component';
// import { AlignmentSettingsComponent } from '../../common-components-module/alignment-settings/alignment-settings.component'
import { MetaService } from '../../services/meta-service';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { ResizeService } from '../../page/panel/service/resize.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AvailableOptions } from '../../models/widgetClasses/commonWidgetProps';
import { WidgetService } from '../../services/widget-service.service';
import { PageService } from '../../services/page-service.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormService } from 'src/app/form/form.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css'],
})
export class ContextMenuComponent implements OnInit, OnChanges, OnDestroy {

  @Input() actionConfig: any = { actions: []};  //an object containing "actions" array of names of all possible actions (see onInit of any widget)
  @Input() widgetMeta: any;
  @Input() panelId: any;
  @Input() layout: any
  @Input() layoutRow: any
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Output() contextChanged = new EventEmitter()
  @Output() closeMenu = new EventEmitter()
  value1: any;
  newOption: any = '';
  styles: any;
  pageMeta: any;
  sizes: any = [];
  spinner: boolean = false;
  ColorControl: UntypedFormControl = new UntypedFormControl('')
  optionsMap: any = {}

  isBUI: boolean = false
  boldExists: boolean = false
  underlineExists: boolean = false
  italicExists: boolean = false

  fontClasses: any = [
    'Title',
    'Heading 1',
    'Heading 2',
    'Heading 3',
    'Heading 4',
    'Subtitle',
    'Normal',
    'Small',
    'Tiny',
  ]

  fontFamilies: any = [
    'Arial',
    'Verdana',
    'Helvetica',
    'Tahoma',
    'Trebuchet',
    'Times New Roman',
    'Georgia',
    'Courier New',
    'Brush Script MT',
  ]

  @Output() onMouseLeaveMenu: EventEmitter<any> = new EventEmitter();
  availableOptions: any[] = []  // applies for select widget
  isBrowser: boolean;

  @ViewChild('fontClassMenuTrigger') fontClassMenuTrigger: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) fontFamilyMenuTrigger: MatMenuTrigger;

  closeContextMenuRequestSub: any
  pageMetaSub: any

  constructor(
    private dialog: MatDialog,
    private metaService: MetaService,
    private adminService: AdminService,
    private widgetService: WidgetService,
    public pageService: PageService,
    public formService: FormService,
    public authService: AuthServiceService,
    public ngZone: NgZone,
    public cdr: ChangeDetectorRef,
    private snackBar?: MatSnackBar,
    @Inject(DOCUMENT) public document?: Document,
    @Inject(PLATFORM_ID) platformId?: Object
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    }

  mouseLeaveMenu(){
    this.onMouseLeaveMenu.emit("leave")
  }

  ngOnInit(): void {
    if(!this.isBrowser) return;
    this.pageMetaSub = this.metaService.pageMeta.subscribe(pageMeta => {
      this.pageMeta = pageMeta
      // console.log(this.pageMeta)
    })
    this.initAvailableOptions()

    // console.log("wdgetMeta", JSON.parse(JSON.stringify(this.widgetMeta)))
    // console.log("[CONTEXT MENU] onInit actionConfig", this.actionConfig)

    // this.ColorControl.valueChanges.pipe(debounceTime(3000), distinctUntilChanged()).subscribe(() => {
    //   console.log("widgetMeta", this.widgetMeta)
    // })
    this.closeContextMenuRequestSub = this.formService.closeContextMenuRequest.subscribe(() => {
      this.closeMenu.emit(true)
    })



    if(this.actionConfig?.actions?.includes('bold')){
      this.isBUI = true
      this.boldExists = true
    }
    if(this.actionConfig?.actions?.includes('underline')){
      this.isBUI = true
      this.underlineExists = true
    }
    if(this.actionConfig?.actions?.includes('italic')){
      this.isBUI = true
      this.italicExists = true
    }

    //initiate sizes array
    for (let index = 8; index <= 96; index++) {
      this.sizes.push(index)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.actionConfig?.currentValue){
      // console.log("actionconfig", this.actionConfig)
    }
    if(changes.widgetMeta?.currentValue){
      if (this.widgetMeta?.textFormat?.color?.value)
      this.ColorControl.setValue(this.widgetMeta.textFormat.color.value);
    }
  }

  ngOnDestroy(): void {
    this.closeContextMenuRequestSub?.unsubscribe()
    this.pageMetaSub?.unsubscribe()
  }

  /**
   * opens up the widget configuration dialog
   * @returns NA
   */
  onEdit() {
    return new Promise((resolve, reject) => {
      console.log("onEdit : printing in context menu", this.widgetMeta)

      this.widgetService.openWidgetSidebarSettings.next({
        widgetMeta: this.widgetMeta,
        panelId: this.panelId
      })

      this.closeMenu.emit(true)


      // const dialogRef = this.dialog.open(WidgetConfigurationComponent, {
      //   width: '90vw',
      //   // height: "90vh",
      //   panelClass: "widgetconfigdiolog",
      //   data: {
      //     widgetMeta: this.widgetMeta,
      //     panelId: this.panelId
      //   }
      // });

      // dialogRef.componentInstance.settingsChanged.subscribe(event => {
      //   this.widgetMeta = event;
      //   let emitterdata = {
      //     actionType: 'updateStyles',
      //     data: event,
      //     widgetId: this.widgetMeta.id
      //   }
      //   // this.action.emit(emitterdata)
      //   this.metaService.setContextChanged(emitterdata)
      //   this.contextChanged.emit(emitterdata);
      // })

      // dialogRef.afterClosed().subscribe(result => {
      //   console.log('widget configuration dialog closed', JSON.parse(JSON.stringify(result || "")));
      //   if (result) {
      //     let emitterData: any = {
      //       actionType: 'settingsChanged',
      //       data: result,
      //       widgetId: this.widgetMeta.id
      //     }
      //     // this.action.emit(emitterData)
      //     this.metaService.setContextChanged(emitterData)
      //     this.contextChanged.emit(emitterData);
      //   } else {
      //     console.log("widget edit cancelled")
      //   }
      // });
    })
  }

  initAvailableOptions(){
    this.widgetMeta.config.props.forEach((property: any) => {
      // console.log("prop", property)
      // console.log("widget meta", JSON.parse(JSON.stringify(this.widgetMeta)))
      if(['select', 'buttonColor'].includes(this.widgetMeta.config[property].type) && AvailableOptions[property] && AvailableOptions[property].widgetsSupported.includes(this.widgetMeta?.type)){
        this.optionsMap[property] = AvailableOptions[property]?.availableOptions || []
      }
    })
  }

  // savePageMeta(pageMeta: any) {
  //   //update the pagemeta in db
  //   this.metaService.update(this.pageMeta)
  //     .then(res => {
  //       console.log("context menu: meta updated",res)
  //     })
  //     .catch(err => {
  //       console.error("context menu: error in updating meta", err)
  //     })
  // }
  /**
   * ----------------------------------- BOLD CHANGED ---------------------------
   * @param event
   */
  boldChanged(event: any) {
    console.log("bold changed", event)
    // event.stopPropagation();
    // event.preventDefault();

    this.ngZone.run(() => {
      this.widgetMeta.textFormat.bold.value = !this.widgetMeta.textFormat.bold.value;
    })

    // if (event.value[0] == 'bold') {
    //   this.widgetMeta.textFormat.bold.value = true
    // } else if (event.value.length == 0) {
    //   this.widgetMeta.textFormat.bold.value = false
    // }
    let emitterData = {
      actionType: 'updateStyles',
      data: this.widgetMeta,
      widgetId: this.widgetMeta.id
    }

    this.metaService.setContextChanged(emitterData)
    this.contextChanged.emit(emitterData);
    // this.action.emit(emitterData)
  }

  /**
   * ----------------------------------ITALICS CHANGED -------------------------------
   * @param event
   */
  italicChanged(event: any) {
    this.widgetMeta.textFormat.italic.value = !this.widgetMeta.textFormat.italic.value;

    let emitterData = {
      actionType: 'updateStyles',
      data: this.widgetMeta,
      widgetId: this.widgetMeta.id
    }
    // this.action.emit(emitterData)
    this.metaService.setContextChanged(emitterData)
    this.contextChanged.emit(emitterData);
  }

  /**
   * --------------------------------------------- UNDERLINE CHANGED --------------------------
   * @param event
   */
  underlineChanged(event: any) {
    this.widgetMeta.textFormat.underline.value = !this.widgetMeta.textFormat.underline.value;

    let emitterData = {
      actionType: 'updateStyles',
      data: this.widgetMeta,
      widgetId: this.widgetMeta.id
    }
    // this.action.emit(emitterData)
    this.metaService.setContextChanged(emitterData)
    this.contextChanged.emit(emitterData);
  }

  /**
   *
   * @param colorInput
   */
  colorChanged(color: any) {
    console.log("font color changed", color)
    this.widgetMeta.textFormat.color.value = color
    this.emitChanges()
    this.pageService.addToColorPalette(color)
  }

  backgroundColorChanged(newColor: string) {
    console.log("applying background color", newColor)
    this.widgetMeta.config.backgroundColor.value = newColor;
    this.emitChanges()
    this.pageService.addToColorPalette(newColor);
  }

  /**
   * ----------------------------------------- FONT STYLE CHANGED ------------------------------
   * @param event
   */
  fontStyleChanged(event: any) {
    console.log("font style changed", event)
    this.widgetMeta.textFormat.fontFamily.value = event
    console.log("widgetMeta", this.widgetMeta)

    // this.updatePageMeta()

    //send updated page meta to subject, so as to make it available all across
    // this.metaService.pageMeta.next(this.pageMeta)

    this.fontFamilyMenuTrigger.closeMenu();
    this.cdr.detectChanges();

    //emit action so that generateStyles() function is run
    let emitterData = {
      actionType: 'updateStyles',
      data: this.widgetMeta,
      widgetId: this.widgetMeta.id
    }
    this.metaService.setContextChanged(emitterData);
    this.contextChanged.emit(emitterData);
    //update the pagemeta in db
    // this.savePageMeta(this.pageMeta)
  }



  /**
   * ----------------------------------------- FONT SIZE CHANGED ------------------------------
   * @param size
   */
  fontSizeChanged(event: any) {
    console.log("font size changed", event, "widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta)))
    const inputElement = event.target as HTMLInputElement;
    let value = parseInt(inputElement.value, 10);

    if (isNaN(value) || value < 8) {
      value = 8;
    } else if (value > 72) {
      value = 72;
    }

    this.widgetMeta.textFormat.fontSize.value = value
    console.log("font size changed", value, "widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta)))
    this.emitChanges()
  }

  increaseFontSize(){
    console.log("increase font size hit")
    if(this.widgetMeta.textFormat.fontSize.value + 1 <= 72){
      this.widgetMeta.textFormat.fontSize.value ++
    }
    console.log("font size changed, widgetMeta", JSON.parse(JSON.stringify(this.widgetMeta)))
    this.emitChanges()
  }

  decreaseFontSize(){
    if(this.widgetMeta.textFormat.fontSize.value - 1 >= 8){
      this.widgetMeta.textFormat.fontSize.value --
    }
    this.emitChanges()
  }

  emitChanges(){
    let emitterData = {
      actionType: 'updateStyles',
      data: this.widgetMeta,
      widgetId: this.widgetMeta.id
    }
    this.metaService.setContextChanged(emitterData)
    this.contextChanged.emit(emitterData);
  }


  /**
   * ------------------------------- FONT CLASS CHANGED ------------------------------
   * @param fontClass
   */
  fontClassChanged(fontClass: any) {
    console.log("font size changed", fontClass)
    this.widgetMeta.textFormat.class.value = fontClass
    switch (fontClass) {
      case 'Title':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 37

        break;
      case 'Heading 1':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 24

        break;
      case 'Heading 2':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 21

        break;
      case 'Heading 3':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 19

        break;
      case 'Heading 4':
        this.widgetMeta.textFormat.bold.value = true;
        this.widgetMeta.textFormat.fontSize.value = 18;
        this.widgetMeta.textFormat.italic.value = true;

        break;
      case 'Subtitle':
        this.widgetMeta.textFormat.fontSize.value = 24
        break;
      case 'Normal':
        this.widgetMeta.textFormat.fontSize.value = 14

        break;
      case 'Small':
        this.widgetMeta.textFormat.fontSize.value = 12
        break;
      case 'Tiny':
        this.widgetMeta.textFormat.fontSize.value = 8
        break;

      default:
        break;
    }
    this.fontClassMenuTrigger.closeMenu()
    this.cdr.detectChanges()
    console.log("widgetMeta", this.widgetMeta)

    // this.updatePageMeta()
    console.log("check if changed", this.pageMeta)

    //send updated page meta to subject
    // this.metaService.pageMeta.next(this.pageMeta)

    //emit action so that generateStyles() function is run
    let emitterData = {
      actionType: 'updateStyles',
      data: this.widgetMeta,
      widgetId: this.widgetMeta.id
    }
    this.metaService.setContextChanged(emitterData)
    this.contextChanged.emit(emitterData);

    //update the pagemeta in db
    // this.savePageMeta(this.pageMeta)
  }

  //--------------------------------------------------------------------------------------
  //------------------------------ widget specific actions -------------------------------
  //--------------------------------------------------------------------------------------

  customPropertyChanged(event: any, propName: string) {
    this.widgetMeta.config[propName]['value'] = event.value
    let emitterdata = {
      actionType: 'customPropertyUpdate',
      data: this.widgetMeta,
      propertyName: propName,
      widgetId: this.widgetMeta.id
    }
    this.metaService.setContextChanged(emitterdata)
    this.contextChanged.emit(emitterdata);
  }

  buttonColorTypeChanged(event: any) {
    this.widgetMeta.config['buttonColorType']['customValue'] = '';
    this.widgetMeta.config['buttonColorType']['value'] = event.value
    this.emitChanges()
  }

  buttonColorChanged(newColor: string) {
    console.log("applying background color", newColor)
    this.widgetMeta.config.buttonColorType.customValue = newColor;
    this.emitChanges()
    this.pageService.addToColorPalette(newColor);
  }

  customInputNumberPropertyChanged(event: any, propName: string) {
    this.widgetMeta.config[propName]['value'] = !isNaN(parseInt(event.srcElement.value)) ? parseInt(event.srcElement.value) : this.widgetMeta.config[propName]['value']
    let emitterdata = {
      actionType: 'customPropertyUpdate',
      data: this.widgetMeta,
      propertyName: propName,
      widgetId: this.widgetMeta.id
    }
    this.metaService.setContextChanged(JSON.parse(JSON.stringify(emitterdata)))
    this.contextChanged.emit(emitterdata);
  }

  alignmentChanged(data){
    this.widgetMeta.config.alignment.value = data
    let emitterdata = {
      actionType: 'updateStyles',
      data: this.widgetMeta,
      widgetId: this.widgetMeta.id
    }
    this.metaService.setContextChanged(emitterdata)
    this.contextChanged.emit(emitterdata);
    // this.updatePageMeta()
    // this.metaService.pageMeta.next(this.pageMeta)
  }

  onDelete() {
    console.log("on delete")
    let emitterdata = {
      actionType: 'delete',
      widgetId: this.widgetMeta.id,
      layoutRow: this.layoutRow,
      layout: this.layout,
      data: this.widgetMeta
    }
    this.metaService.setContextChanged(emitterdata)
    this.contextChanged.emit(emitterdata);
  }

  /**
   * FOR ICON WIDGET
   * @param iconName this is a return value from Google Icon Picker
   */
  onChangeIcon(iconName: any) {
    console.log("onChangeIcon hit", iconName)
    let emitterData = {
      actionType: 'changeIcon',
      returnData: iconName,
      widgetId: this.widgetMeta.id
    }
    this.metaService.setContextChanged(emitterData)
    this.contextChanged.emit(emitterData);
  }

  onAddOption() {
    //add in local array
    // this.widgetMeta.config.availableOptions.options.push({value: this.newOption})
    console.log("local widget meta array changed, added option", this.widgetMeta.config.availableOptions.value)

    let emitterData = {
      actionType: 'newOption',
      data: this.widgetMeta,
      newOption: this.newOption,
      widgetId: this.widgetMeta.id
    }

    this.metaService.setContextChanged(emitterData)
    // this.contextChanged.emit(emitterData);
  }

  onRemoveOption(option) {
    let emitterData = {
      actionType: 'removeOption',
      data: this.widgetMeta,
      removeOption: option,
      widgetId: this.widgetMeta.id
    }
    //remove the option from local array
    // let tempArray: any = []
    // this.widgetMeta.config.availableOptions.value.forEach(opt => {
    //   if (opt !== option) {
    //     tempArray.push(opt)
    //   }
    // });
    // this.widgetMeta.config.availableOptions.value = tempArray
    // console.log("local widget meta array changed, removed", this.widgetMeta.config.availableOptions.value)

    //emit the event for remote change
    this.metaService.setContextChanged(emitterData)
    // this.contextChanged.emit(emitterData);
  }

  async imageSelectionChange(event: any, id: string) {
    this.spinner = true;
    await this.adminService.fileUpload(event).then((res: any) => {
      let inputRef = <HTMLInputElement>this.document.getElementById(id);
      inputRef.value = res;
      this.widgetMeta.config.src.value = res
      let emitterdata = {
        actionType: 'customPropertyUpdate',
        data: this.widgetMeta,
        propertyName: id,
        widgetId: this.widgetMeta.id
      }
      this.spinner = false;
      this.metaService.setContextChanged(emitterdata)
      this.contextChanged.emit(emitterdata);
    });
  }

  // updatePageMeta(){
  //   this.pageMeta.panels.forEach(panel => {
  //     if (panel.id == this.panelId) {
  //       console.log("panel found")
  //       panel.widgets.forEach(widget => {
  //         console.log("widget found", widget)
  //         if (widget.id == this.widgetMeta.id) {
  //           widget = this.widgetMeta
  //         }
  //       });
  //     }
  //   });
  // }

  clicked(event){
    console.log("actionconfig", this.actionConfig)
    console.log("event",event)
    event.stopPropagation();
    // event.preventDefault();
  }

  resetSkew(actionType: string){
    let emitterData = {
      actionType: actionType,
      data: JSON.parse(JSON.stringify(this.widgetMeta)),
      widgetId: this.widgetMeta.id
    }
    console.log("emitter data", emitterData)
    this.metaService.setContextChanged(emitterData)
    this.contextChanged.emit(emitterData);
  }

  resetResize(actionType: string){
    let emitterData = {
      actionType: actionType,
      data: JSON.parse(JSON.stringify(this.widgetMeta)),
      widgetId: this.widgetMeta.id
    }
    console.log("emitter data", emitterData)
    this.metaService.setContextChanged(emitterData)
    this.contextChanged.emit(emitterData);
  }

  stopIterativeDataLoad(actionType: string){
    let emitterData = {
      actionType: actionType,
      data: null,
      widgetId: this.widgetMeta.id
    }
    console.log("emitter data", emitterData)
    this.metaService.setContextChanged(emitterData)
    this.contextChanged.emit(emitterData);
  }

  cloneWidget(){
    console.log(this.widgetMeta, this.panelId, this.layoutRow, this.layout)
    let newWidget = JSON.parse(JSON.stringify(this.widgetMeta));
    delete newWidget.noDelete
    delete newWidget.id
    newWidget['id'] = Date.now().toString();
    let panelMeta;

    let isForm: boolean = false
    if(this.authService?.subproduct == "form") isForm = true


    let pageMeta = isForm ? this.formService.formMeta.value : this.metaService.pageMeta.value;

    if(isForm){
      pageMeta = this.formService.selectedPage;
    }

    let index;
    pageMeta.panels.forEach((panel, i) => {
       if(panel.id == this.panelId){
        panelMeta = panel
        index = i
       }
    });
    console.log("panel meta", panelMeta)

    let widgetIndex
    let layoutId
    let layoutRowId

    panelMeta.layoutMap.list.forEach(colId => {
      console.log(colId)
      panelMeta.layoutMap[colId]['list'].forEach(rowId => {
        console.log(rowId, panelMeta.layoutMap[colId][rowId])
        panelMeta.layoutMap[colId][rowId].elements.forEach((widget, i) => {
          console.log(widget)
          if(widget.id == this.widgetMeta.id){
            console.log(widget)
            widgetIndex = i
            layoutId = colId
            layoutRowId = rowId
          }
        });
      });
    });
    newWidget.name = this.widgetMeta.name + 'c_' + (panelMeta.layoutMap[layoutId]['list'].length + 1)
    console.log("before clone addition", JSON.parse(JSON.stringify(pageMeta)))
    pageMeta.panels[index].layoutMap[layoutId][layoutRowId].elements.splice(widgetIndex + 1, 0, newWidget)
    this.widgetService.widgetCloneRequest.next({
      newWidget: newWidget,
      currentPanel: panelMeta,
      panelId: this.panelId
    })

    console.log("page meta after addition of new widget", JSON.parse(JSON.stringify(pageMeta)))
    this.globalPageMetaUpdate()
  }

  globalPageMetaUpdate(){
    console.log("global update hit")
    this.metaService.pageMeta.next(this.pageMeta)
    console.log("nexted into meta service pageMeta")
    this.pageService.pageMeta = this.pageMeta
    this.metaService.userMadeChanges.next(true);
  }
}
