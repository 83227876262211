import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { TemplateEngine } from 'src/app/core/common/TemplateEngine';
import * as Moment from 'moment-timezone';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FlowContentService } from 'src/app/flow/flow-content.service';

@Component({
  selector: 'step-action',
  templateUrl: './step-action.component.html',
  styleUrls: ['./step-action.component.scss'],
})
export class StepActionComponent implements OnInit {
  @Input() 'step': any;
  @Input() 'sourceFields': any;
  @Input() 'editMode': boolean = true;
  @Output() 'onConfigure' = new EventEmitter<any>();
  @Output() 'stepSourceFields' = new EventEmitter<any>();
  @Output() 'actionChanged' = new EventEmitter<any>();
  @Output() 'userChanges' = new EventEmitter<any>();

  systemFields: any = this.flowContentService.systemFields;
  supportedTypesForIncremental: any = ['timestamp', 'datetime', 'date'];
  stepOptions: any = {
    connectionOptions: [],
    actionFields: [],
    box: {},
    action: {},
  };

  connectionSelectionConfig: any = {}
  optionalInputs = {
    options: {
      object: this.stepOptions.action.__id ? this.stepOptions.action.__id.split('/')[0] : ''
    }
  }
  incrementalImportDateTimeFields: any[];
  showSharedParameterInputs: boolean = true;

  constructor(private ngZone: NgZone, private flowContentService: FlowContentService) {}

  ngOnInit(): void {
    console.log("===> STEP", this.step)
    console.log("sourceFields--->",this.step.step_code, "--->", this.sourceFields);
    if(!this.step.step_config) this.step.step_config = { incremental_map: {}};
    if(!this.step.step_config.incremental_map) this.step.step_config.incremental_map = {};
    let clonedFields = JSON.parse(JSON.stringify(this.step?.step_options?.step_fields));
    if(this.step?.step_options?.step_fields) this.incrementalImportDateTimeFields = this.constructIncrementalFields(clonedFields.filter(field => this.supportedTypesForIncremental.includes(field.dataType)));
  };

  constructIncrementalFields(array){
    let updatedArray = array.map(item => {
      const idParts = item.__id.split('.');
        item.__id = idParts.slice(1).join('.')
        return item
    });
    return updatedArray;
  }

  async stepActionSelected(fields: any) {
    console.log("stepAction Selected", fields)

    this.stepOptions.actionFields = fields;
    console.log("step options", JSON.parse(JSON.stringify(this.stepOptions)))
    this.onConfigure.emit(this.step);
    this.emitStepSourceFields()
    this.userMadeChanges();
  }

  retriggerSharedParameterInputs() {
    this.showSharedParameterInputs = false;
    setTimeout(() => {
      this.showSharedParameterInputs = true;
    }, 0);  // Timeout ensures Angular has enough time to re-render
  }

  appSelected(e){
    console.log("appselected", e);
    this.step.box_id = e.__id;
    this.step.box_logo_url = e.logo;
    this.step.box_name = e.name;
    this.stepOptions.box = e;

    this.connectionSelectionConfig = { box_id: this.step.box_id }
    this.userMadeChanges();
    // this.actionConfig.actionMap.boxId = e.__id;
  }

  mappingAppInfo(box:any){
    return {
      app: box?.name || box.__id,
      logo : {url: box?.logo}
    }
  }

  setIncrementalQuery(event){
    console.log("setIncrementalQuery", event, this.step);
    if(!this.step.step_config) this.step.step_config = {};
    this.step.step_config.incremental_query = event.checked;
  }

  incrementalInputReceived(event){
    console.log("incrementalInputReceived", event);
    if(!this.step?.step_config?.incremental_map) this.step.step_config.incremental_map = {};
    this.step.step_config.incremental_map.incremental_query_field = event.value;
    let valueMap = this.incrementalImportDateTimeFields.find(obj => obj.__id === event.value);
    this.step.step_config.incremental_map.incremental_filters = [];
    let object = {
      attribute: valueMap.__id,
      dataType: valueMap.dataType,
      filterType: "static_filter",
      operator: ">",
      value: "${systemField.lastExecutionTime}"
    }
    this.step.step_config.incremental_map.incremental_filters = [object];
    // this.step.step_config.incremental_map.incremental_filter = `${valueMap.__id}>${'${systemField.lastExecutionTime}'}|${valueMap.dataType || 'string'}`
    console.log("incrementalInputReceived valueMap", valueMap, this.step)
    this.userMadeChanges();
  }

  connectionSelected(event){
    console.log("connection received")

    this.step.connection = event._id;
    this.stepOptions.connection = event
    console.log("stepOptions now", this.stepOptions)
    this.userMadeChanges();
  }

  baseSelected(base){
    this.step['baseMap'] = {
      box_id: base.storage_box,
      base_id: base._id
    };
    this.step.boxConfigToken = base.storage_token;
    this.userMadeChanges();
  }

  async emitStepSourceFields(){

    let stepSourceFields = [];
    let stepCode = this.step.step_code;
    let actionFields = JSON.parse(JSON.stringify(this.stepOptions?.actionFields));
    for (let i = 0; i < actionFields.length; i++) {
      let element = actionFields[i];
      element.__id = `${element.__id || element.name}`;
      element.__id = `${stepCode}.${element.__id}`;
      stepSourceFields.push({...element});
    }

    if(this.stepOptions?.action?.output?.list){
      let output = JSON.parse(JSON.stringify(this.stepOptions?.action?.output));
      output?.list?.forEach(element => {
        let obj = output[element];
        obj.__id = stepCode + "." + obj.__id || obj.name;
        stepSourceFields.push(obj);
      });
    }
    console.log("[emitStepSourceFields]", stepSourceFields);
    if(!this.step.step_options)this.step.step_options = {};
    this.step.step_options.step_fields = stepSourceFields;
    let clonedFields = JSON.parse(JSON.stringify(stepSourceFields));
    this.incrementalImportDateTimeFields = this.constructIncrementalFields(clonedFields.filter(field => this.supportedTypesForIncremental.includes(field.dataType)));
    this.stepSourceFields.emit(stepSourceFields);
  }

  onmappingChange(e) {
    console.log("[onMappingChange]")
    this.step.mapping = JSON.parse(JSON.stringify(e));
    this.userMadeChanges();
    this.onConfigure.emit(this.step);
  }
  selectedAction(action){
    this.retriggerSharedParameterInputs();
    console.log("[selectedAction] action --->", action)
    this.userMadeChanges();
    this.actionChanged.emit(action);
  }

  userMadeChanges() {

    this.userChanges.emit(true);
  }

  attributeOptionsInput(event, step){
    if(!step.actionMap) step.actionMap = {};
    step.actionMap.attributeInputOptions = event;
    console.log("attributeOptionsInput step.actionMap", step.actionMap)
  }

  inputRecevied(e){
    console.log("[inputReceived]", e)
    // if(e.sort){ e.sortConfig = e.sort; delete e.filter }
    // if(e.sort){ e.filterConfig = e.filter; delete e.sort }
    // if(e.limit){ delete e.limit}
    let temp = {
      query: e.query,
      filterConfig: e.filter,
      groupConfig: e.group,
      __limit: e.limit || e.query?.page?.split('|')[1] || 100
    }
    Object.assign(temp, e);
    console.log("temp", temp)
    this.step.inputParams = JSON.parse(JSON.stringify(temp));
    console.log("inputRecevied and params is", this.step.inputParams);
    this.onConfigure.emit(this.step);
    this.userMadeChanges();
  }

  // onIntentChange(type){
  //   this.intentChanged.emit(type);
  // }
}
