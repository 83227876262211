<div class="skeleton-container" [ngClass]="{ centerOfAll: center }" style="padding: 10px;">
  <mat-grid-list cols="24" gutterSize="10px">
    @for (tile of skeletonSpinnerGridMap; track tile) {
      <mat-grid-tile
        class="skeleton-element loading"
        [colspan]="tile.cols"
        [rowspan]="tile.rows"
        [ngClass]="tile.shape">
      </mat-grid-tile>
    }
  </mat-grid-list>
</div>
