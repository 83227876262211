// src/app/screenshot.service.ts
import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {

  constructor() { }

  // Method to capture screenshot
  async captureScreen(element: HTMLElement = document.body, fileName) {
    try {
      // Capture the screenshot as a canvas
      const canvas = await html2canvas(element);
      // Convert the canvas to a Blob (binary data)
      const blob = await this.canvasToBlob(canvas);
      // Convert the Blob to an ArrayBuffer
      const buffer = await this.blobToArrayBuffer(blob);
      var nodeBuffer = []
      let UintBuffer = new Uint8Array(buffer);
      for (var i = 0; i < UintBuffer.byteLength; i++) {
        nodeBuffer.push(UintBuffer[i])
      }
      // Convert ArrayBuffer to Uint8Array to get the buffer in [255, 216, 255, ...] format
      let result:any = {
        payload: {
          buffer: nodeBuffer,
          size: blob.size,
          mimetype: blob.type
        },
        url: await this.blobToDataURL(blob)
      }

      if(fileName) {
        result.payload.filename = fileName + "." + this.getFileExtension(blob.type);
      }

      return result;
    } catch (error) {
      console.error('Error capturing screenshot:', error);
      throw error;
    }
  }

  private blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('Blob to Data URL conversion failed'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  // Method to convert canvas to Blob
  private canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Canvas to Blob conversion failed'));
        }
      }, 'image/png');
    });
  }

  // Method to convert Blob to ArrayBuffer
  private blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as ArrayBuffer);
        } else {
          reject(new Error('Blob to ArrayBuffer conversion failed'));
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  private getFileExtension(mimeType: string): string {
    const mimeTypesToExtensions: { [key: string]: string } = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/webp': 'webp',
    };
    return mimeTypesToExtensions[mimeType] || 'png';
  }
}
