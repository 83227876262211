import { QuillModule } from 'ngx-quill';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerModule } from 'src/app/shared/spinner/spinner.module';
import { DateTimePickerModule } from 'src/app/shared/datetimepicker/datetimepicker.module';
import { DragnDropFileModule } from 'src/app/shared/dropfile/dragndropfile.module';
import { DurationPickerModule } from 'src/app/shared/duration-picker/duration-picker.module';
import { MccTimerPickerModule } from 'material-community-components/timer-picker';
import { AppSelectionModule } from 'src/app/shared/app-selection/app-selection.module';
import { BasicChartsModule } from 'src/app/shared/basic-charts/basic-charts.module';
import { WidgetsModule } from '../widgets/widgets.module';
import { WidgetWrapperComponent } from './widget-wrapper.component';
import { ConnectionWidgetModule } from '../widgets/components/connection/connection.module';
import { ConnectionListWidgetModule } from '../widgets/components/connection-list/connection-list.module';
// import { GoogleChartsModule } from 'angular-google-charts';

@NgModule({
  declarations: [
    WidgetWrapperComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SpinnerModule,
    DurationPickerModule,
    MccTimerPickerModule,
    DateTimePickerModule,
    DragnDropFileModule,
    AppSelectionModule,
    QuillModule.forRoot(),
    BasicChartsModule,
    WidgetsModule,
    ConnectionWidgetModule,
    ConnectionListWidgetModule
  ],
  exports: [
    WidgetWrapperComponent
  ]
})
export class WidgetsWrapperModule { }
