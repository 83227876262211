import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { formService } from 'src/app/bloom/services/meta-service';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { FormService } from '../form.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { StarchService } from 'src/app/shared/services/starch.service';
import { AutomationService } from 'src/app/bloom/services/automation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { WidgetManager } from 'src/app/bloom/models/WidgetManager';

const PERIODIC_INTERVAL = 6000000   //miliseconds, duration after which, meta will be saved periodically

@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss']
})
export class FormPageComponent implements OnInit, OnDestroy {

  routeParameterSubscription: any
  isFormReady: boolean = false
  builderMode: boolean = false
  form: any
  activePanel: any = null
  hoveredPanelId: any = '';
  selectedWidgetId: any = '';
  timerId: any = '';
  widgetCreationLock: boolean = false

  fragmentSubscription: any
  widgetDragSubscription: any

  base: any
  objectId: any;
  attributes: any[];
  page: any;

  spinner: boolean = false;
  pageSelectSubscription: any;

  widgetCloneSubscription: any;

  @Input() type: any
  constructor(
    private route: ActivatedRoute,
    private pageService: PageService,
    public formService: FormService,
    private widgetService: WidgetService,
    private starchService: StarchService,
    private automationService: AutomationService,
    private metaService: MetaService,
    private _snackBar: MatSnackBar

  ) {}

  ngOnInit(): void {

    this.fragmentSubscription = this.route.fragment.subscribe((fragment: string) => {
      this.formService.builderMode = fragment == 'edit'
      console.log("formService.builderMode", this.formService.builderMode)
      if (this.formService.builderMode) {
        this.periodicSaveInit()
      }
    });

    this.routeParameterSubscription = this.route.params.subscribe((routeData) => {
      console.log('route data received: ', routeData, this.type, this.formService?.formMeta?.value?.code);
      let formCode = routeData.formcode;
      if(this.type == 'internal' && this.formService?.formMeta?.value?.code) {
        formCode = this.formService?.formMeta?.value?.code;
        this.formService.builderMode = false;
      }
      this.getForm(formCode)
    });

    this.pageSelectSubscription = this.formService.pageSelectionRequest.subscribe(async (page) => {
      if(!page || page == "__success") return;
      this.isFormReady = false;
      this.page = page;
      console.log('page data received: ', page);
      this.isFormReady = true;
      this.widgetCreationLock = false;
    })

    this.widgetCloneSubscription = this.widgetService.widgetCloneRequest.subscribe(async (data) => {
      console.log("reached clone", data);
      console.log("hovered panel id", this.hoveredPanelId)
      console.log("'found panel", data.currentPanel, this.pageService?.newlyCreatedWidgetMap);
        let newlyCreatedWidgetMap = {
          panel: data.currentPanel,
          widget: data.newWidget
        }
        await this.createStarchAttribute(newlyCreatedWidgetMap)
    })

    this.widgetDragSubscription = this.widgetService.widgetDragCreationRequest.subscribe(async (widgetType) => {
      console.log("reached page drag", widgetType)
      if (!this.formService.builderMode || this.widgetCreationLock || !this.pageService.isPointerInCanvas) return
      this.widgetCreationLock = true;
      console.log("hovered panel id", this.pageService.hoveredPanelId)
      let currentPanelId = this.pageService.hoveredPanelId != -1 ? this.pageService.hoveredPanelId : null;
      console.log("hovered panelId", currentPanelId)
      let currentPanel = this.page.panels?.find(p => p.id == currentPanelId)
      console.log("'found panel", currentPanel)
      console.log('widget service working:', widgetType, "for page", this.page);

      if(currentPanel?.type == 'formpanel'){
        console.log("in form")
        if(this.formService.isInputWidget(widgetType)){
          await this.addAttributeToBase(currentPanelId, { widgetType: widgetType })
        }
      }else{
        console.log("in else", this.pageService?.newlyCreatedWidgetMap?.panel)
        this.page = await this.pageService.createPanel(widgetType, this.page, currentPanelId, null, true);

        if(this.pageService?.newlyCreatedWidgetMap?.panel){
          await this.createStarchAttribute()
        }
        //
      }

      console.log("widget added to page", this.page)
      this.formService.formMeta.next(this.form)
      this.formService.userMadeChanges.next(true);

      this.widgetCreationLock = false
    })

    this.metaService.$contextChanged.subscribe((contextActions: any) => {
      this.updateAttributeMap(contextActions.widgetId, contextActions?.data);
    })
  }

  ngOnDestroy(): void {
    this.widgetDragSubscription?.unsubscribe();
    this.pageSelectSubscription?.unsubscribe();
  }

  async newWidgetCreated(data){
    console.log("NEW WIDGET CREATED IN FORM PAGE", data)
    console.log("form", JSON.parse(JSON.stringify(this.form)))
    let createdAtrribute = await this.createStarchAttribute({widget: data.widget, panel: data.panel})
    if(data.panel.type == 'formpanel') {
      data.panel.formAttributes.push(createdAtrribute)
      console.log("form attr added", JSON.parse(JSON.stringify(data.panel)))
    }
    // this.form.panels.forEach((panel, i) => {
    //   if(data.panel.id == panel.id) {
    //     console.log("matched", data.panel.id, panel.id)
    //     this.form.panels[i] = data.panel
    //   }
    // })
    console.log("[new widget created] form meta appended", JSON.parse(JSON.stringify(this.form)))
    this.formService.formMeta.next(this.form)
  }

  async createStarchAttribute(newlyCreatedWidgetMap?: any){
    console.log("create starch attribute hit", newlyCreatedWidgetMap)
    let section = this.form;
    if(!newlyCreatedWidgetMap) newlyCreatedWidgetMap = this.pageService.newlyCreatedWidgetMap;
    if(!newlyCreatedWidgetMap?.widget || !this.formService.isInputWidget(newlyCreatedWidgetMap?.widget?.type)) return;
    if(this.form.sections?.length > 0 ) section = this.form.sections[this.form.sections.length - 1];
    let submitButton = this.formService.getSubmitButton(section, this.form);
    console.log("submitButton", submitButton);
    console.log("(this.pageService?.newlyCreatedWidgetMap", newlyCreatedWidgetMap);

    let mapping = submitButton?.widget?.actionConfig?.actions?.[0]?.actionMap?.mapping || [];
    console.log("mapping", mapping);
    let attribute = {
      name: "field " + (mapping.length + 1),
      __id: "field " + (mapping.length + 1),
      widgetType: newlyCreatedWidgetMap?.widget?.type || "input",
      dataType: "string",
      defaultValue: "",
      enabled: true,
      editable: true
    }

    let res: any;
    try{
      res = await this.createBaseObjectAttribute(this.objectId, attribute)
      console.log("base attribute created", res);
      if(!this.form.attribute_name_map) {
        this.form.attribute_name_map = {};
      }

      this.form.attribute_name_map[attribute.name] = {
        wId: newlyCreatedWidgetMap.widget.id,
        wName: newlyCreatedWidgetMap.widget.name
      }
    }catch(e){
      console.log("error in creating attribute", e)
    }

    await this.getBaseAttributes()
    console.log("attributes fetched", this.attributes)

    let attr = this.attributes.find(a => a.__id == attribute.name)
    attr['enabled'] = true
    attr['editable'] = true
    attr['widgetType'] = newlyCreatedWidgetMap?.widget?.type || 'input'
    let newMap = this.automationService.createMapping(attr, newlyCreatedWidgetMap?.widget, newlyCreatedWidgetMap?.panel?.id)
    mapping.push(newMap);
    console.log("mapping after", mapping);
    console.log("created attribute", attribute)
    return attribute
  }

  updateAttributeMap(widgetId, widget){
    if(this.form?.attribute_name_map) {
      for (const key in this.form.attribute_name_map) {
        if (Object.prototype.hasOwnProperty.call(this.form.attribute_name_map, key)) {
          const element = this.form.attribute_name_map[key];
          if(element.wId == widgetId) {
            element.wName = widget?.name;
          }
        }
      }
    }
    this.formService.userMadeChanges.next(true);
    this.formService.formMeta.next(this.form)
  }

  async addAttributeToBase(panelId, attributeToCreate?: any){
    console.log("--- [ADD ATTRIBUTE TO BASE] ---", panelId, attributeToCreate)
    let panel = this.page.panels.find(p => p.id == panelId)
    if(!panel || panel?.type != 'formpanel') { console.log("not a form panel"); return }
    if(!panel.baseId) { console.log("baseId does not exist in panel"); return }
    if(!this.base) { console.log("base does not exist"); return }

    // create attribute
    let attribute = {
      name: this.getUniqueAttributeName(),
      dataType: "string",
      defaultValue: ""
    }

    this.attributes.push({
        name : attribute.name,
        __id:  attribute.name,
    });

    // fetch attributes
    // await this.getBaseAttributes();
    console.log("attributes fetched", this.attributes)

    let attr = this.attributes.find(a => a.__id == attribute.name)
    attr['enabled'] = true
    attr['editable'] = true
    attr['widgetType'] = attributeToCreate.widgetType || 'input'
    console.log("attribute ready to attach", attr)

    // attach new attribute to form
    panel.formAttributes.push(attr)
    console.log("attirbute attached to form", panel.formAttributes)
    panel = this.automationService.generateFormWidgets(panel.formAttributes, panel, panel.submitButtonMeta, panel.submitButtonMeta['actionConfig']['actions'][0]['actionMap'].action)
    console.log("panel updated", panel);


    let widgets = this.automationService.getWidgetsFromPanel(panel);
    widgets.forEach(element => {
      if(attribute.name == element.name.toLocaleLowerCase())this.form.attribute_name_map[attribute.name] = {
        wId: element.id,
        wName: element.name
      }
    });
    try{
      let res = await this.createBaseObjectAttribute(this.objectId, attribute)
      console.log("base attribute created", res);
      let submitPanel = this.page.panels.find(p => p.isSubmitPanel)
      let colId = submitPanel.layoutMap.list[0]
      let rowId = submitPanel.layoutMap[colId].list[0]
      submitPanel.layoutMap[colId][rowId]['elements'][0] = this.automationService.getSubmitButtonMeta(panel)
    }catch(e){
      console.log("error in creating attribute", e)
    }
    await this.getBaseAttributes();

  }

  getUniqueAttributeName(){
    console.log("GET UNIQUE ATTRIBUTE NAME")
    let attributes = this.attributes
    // if(!attributes.length) await this.getBaseAttributes()
    console.log("base object attributes", attributes)

    let counter = attributes?.length || 1;
    let name = "field " + counter
    while(attributes?.find(attr => attr.name == name)){
      counter ++
      name = "field " + counter
    }
    return name
  }

  async getBaseAttributes(){
    console.log("[GET BASE ATTRIBUTES]")
    // get attributes
    let getAttrPayload = {
      object: this.objectId,
      options: {
        relationObject: 'starch_relationship'
      }
    }
    this.attributes = await this.starchService.getBaseAttributes(this.base, getAttrPayload)
  }

  async createBaseObjectAttribute(baseObjectId: any, attribute: any){
    console.log("[CREATE BASE OBJECT ATTRIBUTE]")
    // create base object attribute
    let payload = {
      attributes: [
        {
          name: attribute.name,
          dataType: attribute.dataType,
          defaultValue: attribute.defaultValue || ""
        }
      ],
      object: baseObjectId,
      options: {
        relationObject: 'starch_relationship'
      }
    }
    let attrCreateResponse: any
    try{
      attrCreateResponse = await this.starchService.createBaseAttributes(this.base, payload)
      console.log("attribute created", attrCreateResponse)
      return attrCreateResponse
    }catch(e){
      throw new Error("attribute could not be created")
    }
  }

  periodicSaveInit() {
    if (!this.formService.builderMode) {
      return
    }
    this.timerId = setInterval(async () => {
      try{
        await this.formService.updateForm(this.form)
        this.formService.userMadeChanges.next(false)
        console.log("routine page update successful")
      }catch(err){
        console.error("error in updating page meta")
      }
    }, PERIODIC_INTERVAL)
  }

  async getForm(code: string){
    this.spinner = true
    let res: any
    try{
      console.log("form code", code, this.formService?.formMeta?.value)
      if(code && this.formService?.formMeta?.value?.code == code){
        res = this.formService.formMeta.value
      } else  {
        res = await this.formService.getFormByCode(code);
        this.formService.formMeta.next(res);
      }

    }catch(e){
      this.spinner = false
      console.log("could not load form", e)
      throw new Error("could not load form")
    }
    console.log("form loaded", res)
    if(!res) {
      console.error("form not found", code)
      this.spinner = false
      this._snackBar.open("Form not found!", "Ok")
      throw new Error("form not found")
    }
    this.form = res;

    if(this.formService?.selectedPage) {
      this.page = this.formService.selectedPage;
    } else if(!this.form?.sections || this.form.sections?.length == 0){
      this.form.sections = [];
      this.form.sections[0] = {
        code: "main",
        name: "Main",
        noDrag: true,
        panels : this.form.panels
      }
      this.page = this.form.sections[0];
      this.formService.selectedPage = this.page;
    } else if(this.form.sections?.length > 0){
      this.page = this.form.sections[0];
      this.formService.selectedPage = this.page;
    }
    this.formService.pageSelectionRequest.next(this.page);

    if(this.form?.theme?.selectedTheme) this.formService.themeSubject.next(this.form.theme.selectedTheme);
    else if(!this.form?.theme) {
      this.formService.currentTheme = null;
      this.formService.themeSubject.next({style: {}});
    }

    if(!this.form.attribute_name_map) {
      this.form.attribute_name_map = {};
    }

    if(!this.form.successPageConfig && !this.form?.sections){
      this.form.successPageConfig = {
        actionType: 'thankyou',
        richTextContentHtml: '<h1 class="ql-align-center">🙌</h1><h1 class="ql-align-center"><strong>Thank you for submitting the form.</strong></h1><p class="ql-align-center">Your submission has been received successfully.</p>',
        externalUrl: '',
        isCelebrate: true
      }
      let submitPanel = this.form.panels[this.form.panels.length - 1]
      let colId = submitPanel.layoutMap.list[0]
      let rowId = submitPanel.layoutMap[colId].list[0]
      let button = submitPanel.layoutMap[colId][rowId].elements[0]
      let navActionIndex = button.actionConfig.actions.findIndex(action => action.formSuccessAction == true)
      if(navActionIndex > -1) button.actionConfig.actions[navActionIndex] = this.formService.createNavAction()
      else button.actionConfig.actions.push(this.formService.createNavAction())

      submitPanel.layoutMap[colId][rowId].elements[0] = button;
      this.form.panels[this.form.panels.length - 1] = submitPanel;
      if (this.formService.builderMode) this.periodicSaveInit();
    }

    // check and fetch associated starch base if not already fetched
    this.checkAndFetchStarchBase();


    let pmForm = this.form;
    if(this.form?.sections?.length){
      pmForm = {panels: []}
      this.form.sections.forEach(element => {
        pmForm.panels = pmForm.panels.concat(element.panels)
      });
    }
    console.log("pmForm", pmForm)
    this.pageService.generatePageModel(pmForm);
    console.log("[getForm 2] form meta fetched", JSON.parse(JSON.stringify(this.form)))
    this.clearWidgetValuesInPage()

    this.formService.formMeta.next(this.form)
    this.isFormReady = true
    this.spinner = false
    return
  }


  /**
   * all widgets from all sections and all panels other than form panel will be reset to default value
   */
  clearWidgetValuesInPage(){
    console.log("clear widget values from page hit", JSON.parse(JSON.stringify(this.form)))
    this.form.sections.forEach(section => {
      section.panels.forEach(panelMeta => {
        if(panelMeta.type == 'formpanel') return
        panelMeta.layoutMap.list.forEach(colId => {
          panelMeta.layoutMap[colId]['list'].forEach(rowId => {
            panelMeta.layoutMap[colId][rowId].elements.forEach(wid => {
              if(wid.type === 'button' || !this.formService.isInputWidget(wid.type)) return
              let temp = WidgetManager.getWidget(wid.type, wid.id, wid.name)
              Object.keys(wid).forEach(prop => temp[prop] = wid[prop])
              temp.setValue('')
              wid = temp
            })
          })
        })
      })
    })
    console.log("all widgets cleared", JSON.parse(JSON.stringify(this.form)))
  }


  async checkAndFetchStarchBase(){

    //TODO - need to get the base and objectid from submit
    // let section = this.form;
    // if(this.form.sections?.length > 0 ) section = this.form.sections[this.form.sections.length - 1];
    // let submitButton = this.formService.getSubmitButton(section, this.form);
    // console.log("submitButton", submitButton)

    for (let i = 0; i < this.form.panels?.length; i++) {
      const panel = this.form.panels[i];
      // find the form panel containing baseId
      if(panel.type == 'formpanel' && panel.baseId && !this.base){
        try{
          this.base = await this.starchService.getStarchBase(panel.baseId)
          console.log("base", this.base)
        }catch(e){
          console.error("underlying starch base not found", e)
          this._snackBar.open(`Underlying starch base ${panel.baseId} not found`, "Ok")
        }
        if(!this.base) {
          console.error("underlying starch base not found")
          this.spinner = false
          this._snackBar.open("underlying starch base not found!", "Ok")
          throw new Error("underlying starch base not found")
        }
        this.objectId = panel.boxObjectId
        try{
          await this.getBaseAttributes()
        }catch(e){
          console.error("base attributes could not be fetched", e)
          this._snackBar.open(`base attributes could not be fetched`, "Ok")
        }
        break
      }
    }
    this.spinner = false
  }

  panelDelete(panelId: any) {
    let i = this.page.panels.findIndex(panel => panel.id == panelId)
    if(i > -1) this.page.panels.splice(i, 1)
    this.formService.userMadeChanges.next(true);
    this.formService.formMeta.next(this.form)
  }

  onPanelSelect(panelId: number) {
    this.activePanel = panelId;
    console.log('panel selected', panelId);

    //searches through the panels array by id and updates selectedNow property.
    this.page.panels.forEach((panel) => {
      if (panel.id == panelId) {
        panel.selectedNow = true;
        this.pageService.selectedLayout = null;
        this.pageService.selectedPanel = panel;
        this.pageService.panelSelected = true;
      } else {
        panel.selectedNow = false;
      }
    });
  }

  newPanelMeta(metaReceived) {
    console.log("[PAGE] new panel meta received", metaReceived)
    let index = this.page.panels.findIndex(panel => panel.id == metaReceived.id)
    this.page.panels[index] = metaReceived;
    this.formService.userMadeChanges.next(true);
    this.formService.formMeta.next(this.form)
  }

  userInputHandler(event: any) {
    this.pageService.updatePageModel(event);
  }

  onPanelMouseenter(panelId: number) {
    if(this.page.panels.findIndex(panel => panel.id == panelId) > -1){
      this.hoveredPanelId = panelId;
      this.pageService.hoveredPanelId = panelId;
    }
  }

  onPanelMouseleave(panelId: number) {
    this.hoveredPanelId = -1;
    this.pageService.hoveredPanelId = -1;
  }

  widgetSelection(event: any) {
    console.log('widget selection received in form', event);
    this.selectedWidgetId = event;
  }

  async widgetDeletion(deletionPayload) {
    console.log("deletion request received in form", deletionPayload, this.page)
    this.page.panels.forEach((panel) => {
      if (panel.id == deletionPayload.panelId) {
        // panel.formAttributes = panel.formAttributes.filter(attr => attr.__id !== deletionPayload.widgetId?.split('-')?.[1])
        if(panel.widgets.length > 0){
          panel.widgets.forEach((widget, i, obj) => {
            if (widget.id == deletionPayload.widgetId) {
              obj.splice(i, 1)
            }
          });
        } else {
          panel.layoutMap?.[deletionPayload.layoutId]?.[deletionPayload.layoutRowId]?.['elements'].forEach((widget, i, obj) => {
            if (widget.id == deletionPayload.widgetId) {
              obj.splice(i, 1)

              if(obj.length == 0){
                delete panel.layoutMap?.[deletionPayload.layoutId]?.[deletionPayload.layoutRowId]
                let deletionIndex = panel.layoutMap[deletionPayload.layoutId].list.findIndex(rowId => rowId == deletionPayload.layoutRowId)
                if(deletionIndex >= 0) panel.layoutMap[deletionPayload.layoutId].list.splice(deletionIndex, 1)
                // panel.layoutMap[deletionPayload.layoutId].list = panel.layoutMap?.[deletionPayload.layoutId]?.list.filter(rowId => rowId !== deletionPayload.layoutRowId)
              }
            }
          });
        }

        let deletionIndex = panel.formAttributes?.findIndex(attr => attr.__id == deletionPayload.widgetId?.split('-')?.[1])
        if(deletionIndex >= 0) panel.formAttributes.splice(deletionIndex, 1)
      }
    });
    console.log("after widget deletion", this.form)
    this.formService.userMadeChanges.next(true);
    console.log("[widget deletion] form meta nexting", JSON.parse(JSON.stringify(this.form)))
    this.formService.formMeta.next(this.form)
  }

  panelDeselected() {
    // console.log('deselected');
    this.activePanel = null;
    this.selectedWidgetId = -1;
  }

  panelReorder(event: any) {
    // console.log("panel reorder drop", event)
    moveItemInArray(
      this.page.panels,
      event.previousIndex,
      event.currentIndex
    );

    console.log("panel list after reorder", this.page.panels)
    // this.formService.pageMeta.next(this.form)
    this.formService.userMadeChanges.next(true);
    this.formService.formMeta.next(this.form)
  }

}
