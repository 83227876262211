<!-- <spinner [center]="true" size="la-2x" color="grey" type="ball-triangle-path"></spinner> -->

<div>

    <div fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="!hideHeader">
        <!-- <div fxLayout="column" fxFlex="5"></div> -->
        <div fxLayout="column" fxFlex="100">
            <div fxLayout="row" *ngIf="!input?.hideTitle">
               <h1> {{input?.title ? input.title : 'New Form'}} </h1>
            </div>
        </div>
        <!-- <div fxLayout="column" fxFlex="5"></div> -->
    </div>


    <div style="min-height: 100px;background-color: transparent;" fxLayout="row wrap" fxLayoutAlign="center center">
        <!-- <br/> -->
        <!-- <div fxLayout="column" fxFlex="5"></div> -->
        <div fxLayout="column" fxFlex="100">
            <form [formGroup]="formGroup" >
            <div fxLayout="row" style="margin-top: 10px;" fxLayoutAlign="center center" >

                <div fxLayout="column" class="bloom-field" fxFlex="80">

                    <mat-form-field appearance="outline" style="width: 80%;">
                        <mat-label> Form name </mat-label>
                        <input required formControlName="name" matInput placeholder="Form name">

                    </mat-form-field>
                    <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.name.errors?.required">
                        <mat-icon>error</mat-icon>Form name is required.
                    </mat-error>

                </div>
                <div fxLayout="column" fxFlex="20">
                    <!-- Name   [(ngModel)]="formMap.name" -->
                </div>
            </div>
            <div fxLayout="row" style="margin-top: 10px;"  fxLayoutAlign="center center" >
                <!-- <div fxLayout="column" fxFlex="40">
                    code (ngModelChange)="onChangeInput($event)"
                </div> -->
                <div fxLayout="column" class="bloom-field" fxFlex="80">

                    <mat-form-field appearance="outline" style="width: 80%;">
                        <mat-label> Form code </mat-label>
                        <input (keyup)="onKeySearch($event)"
                         formControlName="code" id="code" name="code" required  matInput placeholder="Bloom code">
                        <mat-icon color="accent" matSuffix style="cursor:pointer;" (click)="checkAndGetRandomCode(formMap.code)">refresh</mat-icon>

                    </mat-form-field>
                    <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.code.errors?.minlength">
                        <mat-icon class="error-icon">error</mat-icon>Form code must be at least 6 characters long.
                    </mat-error>
                    <mat-error fxLayoutAlign="start center" style="color: brown" *ngIf="formGroup.controls.code.errors?.required">
                        <mat-icon class="error-icon">error</mat-icon>Form code is require.
                    </mat-error>

                </div>
                <mat-spinner  [diameter]="20" *ngIf="spinner"></mat-spinner>
                <div fxLayout="column" fxFlex="20">
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="isNameExists" >
                <div style="color: brown" fxLayout="column" class="bloom-field" fxFlex="80">
                   <mat-icon class="error-icon">error</mat-icon> Form code is not available.
                </div>
                <div fxLayout="column" fxFlex="20">
               </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" >
                <div style="font-size: small;" fxLayout="column" class="bloom-field" fxFlex="80">
                    Form code can have lowercase letters, digits or hyphens. This can't be changed later.
                </div>
                <div fxLayout="column" fxFlex="20">
               </div>
            </div>


        </form>
        </div>
    <!-- <div fxLayout="column" fxFlex="5"></div> -->
</div>
    <!-- <br/>
    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="5"></div>
        <div fxLayout="column" fxFlex="90">
            <div fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="column" fxFlex="30">
                </div>

                <div fxLayout="column" fxFlex="30">
                    <button mat-stroked-button  [mat-dialog-close]="false" color="primary"  type="button">
                        Cancel
                      </button>
                </div>
                <div fxFlex="2"></div>
                <div fxLayout="column" fxFlex="30">
                    <button mat-flat-button (click)="createForm()" [disabled]="isNameExists || formGroup.controls.code.errors || spinner"  color="primary" type="button">
                        Create
                      </button>
                </div>

           </div>
        </div>
        <div fxLayout="column" fxFlex="5"></div>
    </div> -->



