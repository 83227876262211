<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="searchConfigWrap">

  <div class="header" fxLayout="row" fxLayoutAlign="space-between start">
    <div mat-dialog-title>Where do you want to search?</div>
    <button #closeButton mat-button mat-dialog-close style="cursor: pointer;">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>

    <div style="height: 1rem;"></div>

    <app-app-selection
      [existingBox]="selectedBoxId"
      [config]="{isSupportStarch: true}"
      (selectedBox)="appSelected($event)"
    >
    </app-app-selection>

    <app-connection-selection
      *ngIf="isAppSelected && selectedBoxId !== 'starch'"
      [existingCon]="selectedConnectionId"
      [config]="{ box_id: selectedBoxId, focus: true }"
      (selectedConnection)="boxSelected($event)"
    ></app-connection-selection>

    <base-selection *ngIf="isAppSelected && selectedBoxId === 'starch'" [existingBase]="panelMeta.baseId"
    (selectedBase)="baseSelected($event)"></base-selection>

    <!-- <app-select-box
      [oldValue]="panelMeta.boxName"
      (boxInput)="boxSelected($event)"
      (boxSelectionError)="boxSelectionError($event)"
    >
    </app-select-box>
    <small *ngIf="isBoxConfigError" style="color: red;">{{ boxConfigError.error.message | json }}</small> -->

    <!-- canGetBoxObjects -->
    <app-select-box-object
      *ngIf="canGetBoxObjects || !firstHit"
      [boxId]="getSelectedBoxId()"
      [connectionId]="getSelectedConnectionId(selectedBoxId)"
      [boxConfigToken]="boxConfigToken"
      [oldValue]="panelMeta.boxObjectId"
      [disableBoxObjectInput]="disableBoxObjectInput"
      (boxObjectInput)="boxObjectSelected($event)"
      (boxObjectSelectionError)="boxObjectSelectionError($event)"
    >
    </app-select-box-object>
    <small *ngIf="isBoxObjectConfigError" style="color: red;">{{ (boxObjectConfigError.error?.message || "") | json }}</small>

    <div *ngIf="terminationError" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1rem" class="terminationError">
      <div fxFlex="5"><mat-icon>error</mat-icon></div>
      <div fxFlex="95">{{ terminationErrorMessage }}</div>
    </div>

    <div *ngIf="isAttributeReady">
      <mat-form-field style="width: 90%;margin-bottom: -15px;">
        <mat-label>Title for the search panel</mat-label>
        <input matInput placeholder="Search panel title..." [(ngModel)]="panelMeta.searchPanelTitle">
      </mat-form-field>
      <div fxFlex="15">
        <mat-checkbox color="warn" [checked]="panelMeta.hideTitle"
        (change)="hideTitleChanged($event)">Hide title</mat-checkbox>
      </div>
    </div>


    <div *ngIf="collectAttrOptions">
      <div style="height: 1rem;"></div>
      <div class="subheading">Input params to fetch attributes</div>
      <!-- <div style="height: .5rem;"></div> -->
      <shared-parameter-inputs
        [action]="getAttrFn"
        [existingInputs]="availableInputParams"
        [isBoxObject]="canGetBoxObjects"
        [isOnlyOptions]="true"
        (inputParameter)="attrOptionInputsRecevied($event)"
      ></shared-parameter-inputs>

      <button
        mat-stroked-button
        color="primary"
        (click)="constructGetOptions()"
      >
        <mat-icon>east</mat-icon>
      </button>
    </div>


    <div *ngIf="collectGetOptions">
      <div style="height: 1rem;"></div>
      <div class="subheading">Input params to get record</div>
      <!-- <div style="height: .5rem;"></div> -->
      <shared-parameter-inputs
        [action]="getFn"
        [existingInputs]="availableInputParams"
        [isOnlyOptions]="true"
        (inputParameter)="getOptionInputsRecevied($event)"
      ></shared-parameter-inputs>

      <button
        mat-stroked-button
        color="primary"
        (click)="getAttributes()"
      ><mat-icon>east</mat-icon>
      </button>
    </div>


    <!-- <div *ngIf="isOptionsToCollect" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="1rem">
      <mat-form-field *ngFor="let option of attributeOptions; let i = index">
        <mat-label>{{ option.name }}</mat-label>
        <input matInput (input)="optionChanged($event, i)" [required]="option.mandatory" [value]="option.defaultValue || ''">
      </mat-form-field>
      <button mat-stroked-button color="primary" (click)="getAttributes()"><mat-icon>east</mat-icon></button>
    </div>

    <div class="attribute-error-cover">
      <small *ngIf="isAttributeError" class="attribute-error">{{ attributeError.error.message ? attributeError.error.message : attributeError?.error | json }}</small>
    </div> -->

    <!----------------------------------- SEARCH SETTING --------------------------------->
    <div *ngIf="attributeSpinner || gettingObjFunSpinner" fxLayout fxLayoutAlign="center center">
      <mat-spinner  [diameter]="40"
      ></mat-spinner>
    </div>
    <!-- <div class="attributeSelection" *ngIf="isAttributeReady">
      <div fxLayout="row" fxLayoutAlign="start center"  style="margin-bottom: -15px;">
        <div style="font-size: 1.2rem; font-weight: bold;">Search attributes</div>
        <mat-icon matTooltip="{{ searchAttributeInfo }}" [matTooltipPosition]="'right'" style="cursor: help; opacity: 50%;">info</mat-icon>
      </div>


      <mat-form-field class="example-chip-list" appearance="standard" style="margin-bottom: -25px;">
        <mat-chip-list
          #chipList
          cdkDropList
          cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="drop($event)"
          aria-label="Attribute selection"
        >
          <mat-chip
            *ngFor="let attribute of searchAttributes"
            [removable]="removable"
          >
            <div cdkDrag class="chipitem">
              <span>{{ attribute.__id }}</span>
              <mat-icon *ngIf="removable" style="cursor: pointer; opacity: 50%;" (click)="removeSearchAttribute(attribute)">cancel</mat-icon>
            </div>
          </mat-chip>
          <input
            placeholder="Choose search attribute"
            #searchAttributeInput
            [formControl]="searchAttributeControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addSearchAttribute($event)">
        </mat-chip-list>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnAttribute" (optionSelected)="selectedSearchAttribute($event)">
          <mat-option *ngFor="let attribute of filteredSearchAttributes | async" [value]="attribute">
            {{attribute.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div> -->



    <div class="attributes-table" *ngIf="isAttributeReady">

      <div>
        <mat-checkbox class="subheading" [checked]="panelMeta.commonSearchField" (change)="commonSearchFieldChanged($event)" color="primary">Common search field</mat-checkbox>.
      </div>

      <div class="example-list" cdkDropList (cdkDropListDropped)="drop($event)">

        <div class="table-header" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="th" fxFlex="3"></span>
          <span class="th" fxFlex="2">#</span>
          <span class="th" fxFlex="5"></span>
          <span class="th" fxFlex="40" fxLayout="row" fxLayoutAlign="start center">{{attributeDisplayName | titlecase}}</span>
          <span class="th" fxFlex="20">Widget</span>
          <span class="th" fxFlex="13"></span>
          <span class="th" fxFlex="7"></span>
          <span class="th" fxFlex="5"></span>
          <span class="th" fxFlex="5"></span>
        </div>

        <div
          class="example-box smallText"
          style="cursor: default;"
          *ngFor="let attr of searchAttributes;
          let i = index"
          cdkDrag
          (mouseenter)="hoveredIndex = i"
          (mouseleave)="hoveredIndex = -1"
          fxLayout="row wrap" fxLayoutAlign="space-between center"
        >
          <!-- DRAG INDICATOR -->
          <div class="reorder td" fxFlex="3">
            <mat-icon cdkDragHandle style="cursor: move; opacity: 60%;" [style.visibility]="hoveredIndex == i ? 'visible' : 'hidden'">drag_indicator</mat-icon>
          </div>

          <!-- SERIAL NUMBER -->
          <div class="serial-number td" fxFlex="2">{{ i + 1 }}</div>
          <span class="th" fxFlex="5"></span>

          <!-- ATTRIBUTE NAME -->
          <div class="attribute-id td" fxFlex="40" fxLayout fxLayoutAlign="start center">{{attr.name}}</div>

          <!-- WIDGET TYPE -->
          <div fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
            <button
              matTooltip="chose widget type to display the value"
              mat-button
              fxFlexFill
              [disabled]="panelMeta.commonSearchField"
              class="widgetSelectionButton"
              [matMenuTriggerFor]="menu"
              aria-label="Widget selection menu"
            >
              <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                <mat-icon style="opacity: 60%;" *ngIf="widgetMap[attr.widgetType]">{{ widgetMap[attr.widgetType].icon || '' }}</mat-icon>
                <span class="selectedWidget">{{ widgetMap[attr.widgetType].name }}</span>
                <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
              </div>
            </button>

            <mat-menu #menu="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
              <div *ngFor="let widType of widgetMap.list;">
                <button mat-menu-item (click)="widgetTypeSelected(i, widType)">
                  <mat-icon>{{widgetMap[widType].icon}}</mat-icon>
                  <span>{{widgetMap[widType].name}}</span>
                </button>
              </div>
            </mat-menu>
          </div>
          <span class="th" fxFlex="13"></span>
          <span class="th" fxFlex="7"></span>
          <div fxFlex="5" class="td">
            <button mat-icon-button #attributeMenu="matMenuTrigger" [matMenuTriggerFor]="menu2">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu2="matMenu">
              <button mat-menu-item (click)="drillDownToggle(i)">
                <mat-icon color="{{attr.isDrillDown?'primary':''}}">{{attr.isDrillDown?'check_box':'check_box_outline_blank'}}</mat-icon>
                Drill down
              </button>
            </mat-menu>
          </div>

          <mat-card appearance="outlined" *ngIf="attr.isDrillDown" fxFlex=100 fxLayout="column" fxLayoutAlign="start start">
            <div fxLayoutAlign="end center" style="width: 100%;">
              <button mat-flat-button (click)="drillDownToggle(i)">
                <mat-icon color="{{attr.isDrillDown?'primary':''}}">{{attr.isDrillDown?'check_box':'check_box_outline_blank'}}</mat-icon>
                Drill down
              </button>
            </div>
            <div fxLayoutAlign="flex-start center" style="width: 100%;">
              <div fxFlex="10"></div>
              <div fxFlex="90" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap=".5rem">
                <div style="height: 1rem" fxFlex="100"></div>
                <div fxFlex="100" fxLayoutAlign="start center">
                  <div fxLayoutAlign="start center">
                    <span style="color: #444">Nested property access for</span>
                    <span style="color: black">&nbsp;{{attr.__id}}</span></div>
                  </div>
                  <div style="font-size: .8rem; color: #777; font-style: italic; ">path may be separated by dot for deep nesting</div>
                <div style="height: .5rem" fxFlex="100"></div>

                <ng-container *ngFor="let nestedProperty of attr.nestedProperties; let j = index">
                  <div fxFlex="100" fxLayoutAlign="start center">
                      <mat-form-field fxFlex="45" appearance="outline" style="margin-bottom: -1.25em">
                        <input matInput placeholder="Nested value path" [(ngModel)]="nestedProperty.path">
                      </mat-form-field>

                      <div class="fieldType td" fxFlex="35" fxLayout="row" fxLayoutAlign="center center">
                        <button
                          matTooltip="choose input type"
                          mat-button
                          fxFlexFill
                          class="widgetSelectionButton"
                          [matMenuTriggerFor]="menu"
                          aria-label="Widget selection menu"
                        >
                          <div fxLayout fxLayoutAlign="space-between center" class="smallText" fxFlexFill>
                            <mat-icon style="opacity: 60%;" *ngIf="widgetMap[attr.widgetType].icon">{{ widgetMap[attr.widgetType].icon || '' }}</mat-icon>
                            <span class="selectedWidget">{{ widgetMap[attr.widgetType].name }}</span>
                            <mat-icon style="opacity: 60%;">arrow_drop_down</mat-icon>
                          </div>
                        </button>

                        <mat-menu #menu="matMenu" yPosition="below" (closed)="widgetMenuClosed($event)">
                          <div *ngFor="let widType of widgetMap.list;">
                            <button mat-menu-item (click)="nestedValueWidgetSelected(widType, i, j)">
                              <mat-icon>{{widgetMap[widType].icon}}</mat-icon>
                              <span>{{widgetMap[widType].name}}</span>
                            </button>
                          </div>
                        </mat-menu>
                      </div>

                      <mat-icon fxFlex="10" (click)="deleteNestedProperty(i, j)" style="color: #444">delete</mat-icon>
                  </div>
                </ng-container>

                <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                  <button mat-stroked-button (click)="addNestedproperty(i)">
                    <mat-icon>add</mat-icon>
                    Access another property
                  </button>
                </div>

              </div>
            </div>
          </mat-card>

          <!-- DELETE ICON -->
          <div class="remove td" fxFlex="5">
            <button mat-icon-button (click)="removeListAttribute($event, i)">
              <mat-icon style="opacity: 60%;">delete</mat-icon>
            </button>
          </div>
        </div>
      </div>


      <button mat-raised-button matTooltip="Choose fields" [matMenuTriggerFor]="menu" (click)="selectAttributeMenuOpened()">
        <mat-icon class="iconInButton">checklist</mat-icon>
        <span class="addFieldText">Choose {{attributeDisplayName | titlecase}}</span>
      </button>
      <mat-menu #menu="matMenu" >
        <app-attribute-selection
          [boxObjectAttributes]="boxObjectAttributes"
          [listAttributes]="searchAttributes"
          [supportApplyButton]="true"
          (listAttributeSelected)="attributeSelectionChanged($event)"
        ></app-attribute-selection>
      </mat-menu>
    </div>




    <!-- <mat-divider></mat-divider> -->

    <!----------------------------------- SORT SETTING --------------------------------->
    <!-- <div class="attributeSelection" *ngIf="isAttributeReady">

      <div fxLayout="row" fxLayoutAlign="start center"  style="margin-bottom: -15px;">
        <div style="font-size: 1.2rem; font-weight: bold;">Sort attributes</div>
        <mat-icon matTooltip="{{ sortAttributeInfo }}" [matTooltipPosition]="'right'" style="cursor: help; opacity: 60%;">info</mat-icon>
      </div>

      <mat-form-field class="example-chip-list" appearance="standard" style="margin-bottom: -25px;">
        <mat-chip-list
          #chipList
          cdkDropList
          cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="dropSortAttribute($event)"
          aria-label="Attribute selection"
        >
          <mat-chip
            *ngFor="let attribute of sortAttributes"
            [removable]="removable"
          >
            <div cdkDrag class="chipitem">
              <span>{{ attribute.__id }}</span>
              <mat-icon *ngIf="removable" style="cursor: pointer; opacity: 50%;" (click)="removeSortAttribute(attribute)">cancel</mat-icon>
            </div>
          </mat-chip>
          <input
            placeholder="Choose sort attribute"
            #sortAttributeInput
            [formControl]="sortAttributeControl"
            [matAutocomplete]="auto2"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addSortAttribute($event)">
        </mat-chip-list>

        <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnAttribute" (optionSelected)="selectedSortAttribute($event)">
          <mat-option *ngFor="let attribute of filteredSortAttributes | async" [value]="attribute">
            {{attribute.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div> -->

    <!-- <mat-divider></mat-divider> -->


    <!-- <div *ngIf="isAttributeReady" style="width: 75%;">
        <mat-label>Page size <span style="font-size: .8rem; color: #333;">(number of search results to show at once)</span></mat-label>
        <input class="pageSizeInput" matInput type="number" [(ngModel)]="panelMeta.pageSize" style="margin: 0 1rem; text-align: center;">
    </div> -->

    <div *ngIf="isAttributeReady">
      <mat-form-field style="width: 90%;margin-bottom: -15px;">
        <mat-label>Search button text</mat-label>
        <input matInput placeholder="write name for the search button..." [(ngModel)]="panelMeta.searchButtonText">
      </mat-form-field>
    </div>

    <div *ngIf="isAttributeReady" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap=".5rem">
      <mat-form-field fxFlex="90%" style="width: 90%;margin-bottom: -15px;">
        <mat-label>Choose a List Panel to show the results</mat-label>
        <mat-select [(ngModel)]="panelMeta.outputListPanelId" (selectionChange)="listPanelSelected($event)" #listPanelInput [placeholder]="outputListPanelName">
          <mat-select-trigger>{{outputListPanelName}}</mat-select-trigger>
          <mat-option *ngFor="let lp of availableListPanels" [value]="lp.id">
            {{lp.name}}
          </mat-option>
          <mat-option value="auto_create_list" style="font-size: 1.1rem; font-weight: 500;">Auto create List Panel</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <div *ngIf="!firstHit">{{ "selected: " + outputListPanelName }}</div> -->

      <!-- <form [formGroup]="form" style="width: 100%;">
        <mat-form-field appearance="standard" style="width: 75%;">
          <mat-label>Choose a List Panel to show the results</mat-label>
          <mat-select [formControl]="listPanelControl" name="food" (selectionChange)="listPanelSelected($event)">
            <mat-select-trigger>{{listPanelControl.value}}</mat-select-trigger>
            <mat-option *ngFor="let lp of availableListPanels" [value]="lp.id">
              {{lp.name}}
            </mat-option>
            <mat-option value="auto_create_list" style="font-size: 1.1rem; font-weight: 500;">Auto create List Panel</mat-option>
          </mat-select>
        </mat-form-field>
        <p>Selected: {{listPanelControl.value}}</p>
      </form> -->


      <!-- <mat-form-field appearance="standard">
        <mat-label>Choose a List Panel to show the results</mat-label>
        <mat-select (selectionChange)="listPanelSelected($event)">
          <mat-option *ngFor="let lp of availableListPanels" [value]="lp.id">
            {{lp.name}}
          </mat-option>
          <mat-option value="auto_create_list" style="font-size: 1.1rem; font-weight: 500;">Auto create List Panel</mat-option>
        </mat-select>
      </mat-form-field> -->


      <!-- <mat-form-field class="example-full-width" appearance="standard" fxFlex="75%">
        <mat-label>Choose a List Panel to show the results</mat-label>
        <input
          #listPanelInput
          type="text"
          aria-label="list panel"
          matInput
          [formControl]="selectOutputListControl"
          [matAutocomplete]="auto"
        >
        <button *ngIf="listPanelInput.value" matSuffix mat-icon-button aria-label="Clear" (click)="selectOutputListControl.patchValue('')">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="listPanelSelected($event)">
          <mat-option *ngFor="let lp of filteredLists | async" [value]="lp.id">
            <span>{{lp.name}}</span>
          </mat-option>
          <mat-option value="auto_create_list">
            <span class="createPage" style="font-size: 1.1rem; font-weight: 500;">Auto create List Panel</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field> -->
    </div>

  </mat-dialog-content>
</div>

<!---------------------------- SAVE CONFIG BUTTON ----------------------------------->
<div class="sticky" fxLayoutAlign="center center" *ngIf="isReadyToSave">
  <button
    fxLayoutAlign="center center"
    [disabled]="saveDisabled"
    matButton
    mat-raised-button
    color="primary"
    (click)="saveConfig()"
  >
    {{ firstHit? 'Create search panel' : 'Save configuration' }}
  </button>
</div>