<div class="mat-typography">
  <div fxLayout="row" *ngIf="replacedActionMaps?.length > 0">
    <div fxLayout="column" fxFlex="100">
      <div fxLayout="row" fxLayoutAlign="start center">
        Map your connections
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="5"></div>
        <div fxFlex="30">Apps</div>
        <div fxFlex="65">Your connections</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let action of replacedActionMaps; let i = index">
          <div fxFlex="5">
            <img class="boxLogo" [src]="action.boxMap.logo" alt="">
          </div>
          <div fxFlex="30">{{ action.boxMap.name }}</div>
          <div fxFlex="65">
            <app-connection-selection
              [config]="{'box_id': action.boxId}"
              (selectedConnection)="connectionSelected($event, action)"
            ></app-connection-selection>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" *ngIf="replacedActionMaps?.length == 0">
    <div fxLayout="column" fxFlex="100">
      <div fxLayout="row" fxLayoutAlign="start center">
        You are all set.
      </div>
    </div>
  </div>

</div>




