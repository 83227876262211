<mat-card
  appearance="outlined"
  [style.background-color]="widgetMeta.config.backgroundColor ? widgetMeta.config.backgroundColor.value : 'transparent'"
  *ngIf="skimmedData && skimmedData.length" class="card" style="box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;"
>
  <div class="wrap">
    <div style="height: 1rem"></div>
    <div fxLayoutAlign="center center" class="score" [ngStyle]="styles"><span>{{ skimmedData[1] }}</span></div>
    <div style="height: .7rem"></div>
    <div fxLayoutAlign="center center"><mat-divider [inset]="true" style="width: 70%"></mat-divider></div>
    <div style="height: .7rem"></div>
    <div fxLayoutAlign="center center" class="subject"><span>{{ customScoreLabel || skimmedData[0] }}</span></div>
    <div style="height: 1rem"></div>
  </div>
</mat-card>
